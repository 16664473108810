import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Input } from '@matterapp/matter-ui';
import { 
  getRoleValueFromProps,
  MEMBERS_DROPDOWN_OWNER_ITEM,
  MEMBERS_DROPDOWN_ADMIN_ITEM,
  MEMBERS_DROPDOWN_MEMBER_ITEM,
  MEMBERS_DROPDOWN_CHANNEL_ADMIN_ITEM
} from './helpers';
import { MembersList, EditButton, EditWrapper, UploadButton } from './styles';
import { 
  MEMBERS_PANEL_TEXT, 
  formatPeers
} from '../sharedConsts';
import { useLazyQuery } from '@apollo/client';
import searchWorkspaceMembers from 'graphql-queries/queries/workspace/searchWorkspaceMembers';
import { debounce } from 'lodash';
import { getDropdownInviteMessage } from 'hooks/composer/shared';
import theme from '@matterapp/matter-theme';
import { useQuery } from '@apollo/client';
import getMembers from 'graphql-queries/queries/workspace/getMembers';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from 'hooks';
import { Resources } from '@matterapp/routing';

const roles = [
  MEMBERS_DROPDOWN_OWNER_ITEM,
  MEMBERS_DROPDOWN_ADMIN_ITEM,
  MEMBERS_DROPDOWN_MEMBER_ITEM,
  MEMBERS_DROPDOWN_CHANNEL_ADMIN_ITEM
];

const SearchInput = styled(Input)(() => {
  return {
    height: theme.spacing.quad,
    marginTop: theme.spacing.half,
    marginBottom: theme.spacing.single,
    width: '100%',
    'input&': {
      '-webkit-appearance': 'none',
      padding: `${theme.spacing.threeQuarters} ${theme.spacing.single}`,
      borderColor: theme.colors.blacks[30],
      borderRadius: theme.borderRadius.M,
      borderWidth: '1px',
      transition: `border ${theme.transitions.times.singleAndHalf}`
    }
  };
});

const SubHeader = styled.div(() => ({
  marginTop: '-24px',
  marginBottom: theme.spacing.single
}));

const debouncedSearch = debounce((variables, query) => {
  query({
    variables
  });
}, 300);

export default function MemberSettings(props) {
  const {
    workspace,
    tenant
  } = props;
  const { queryParams } = useQueryParams();
  const { isEnterprise } = tenant;
  const isAdminSettings = !queryParams.isChannelSetting;
  const navigate = useNavigate();
  const { slackWorkspace, teamsWorkspace, id: workspaceId, tenantId } = workspace || {};
  const primaryChannel = slackWorkspace?.primaryChannel || teamsWorkspace?.teamName;
  const [searchString, setSearchString] = useState();
  const [searchMembers, { loading: isSearching, data }] = useLazyQuery(searchWorkspaceMembers);
  const variables = { limit: 50 };

  if (isAdminSettings && isEnterprise) {
    variables.tenantId = tenantId;
  } else {
    variables.workspaceId = workspaceId;
  }

  const { data: membersData, loading: isLoadingMembers } = useQuery(getMembers, {
    variables
  });

  const { workspaceMembers: { members: acceptedMembers } } = membersData || { workspaceMembers: { members: [] } };

  useEffect(() => {
    if (searchString?.length) {
      debouncedSearch(isAdminSettings ? {
        tenantId,
        searchString
      }: {
        workspaceId: workspace.id,
        searchString
      }, searchMembers);
    }
  }, [searchString]);

  const isLoading = isSearching || isLoadingMembers;
  let membersList = data?.searchMembers && searchString?.length 
    ? (data?.searchMembers || []).map(member => ({
      ...member,
      fullName: member.person?.fullName,
      photoUrl: member.person?.photoUrl
    }))
    : formatPeers(acceptedMembers);

  if (isLoading) {
    membersList = [];
  }

  let copy = slackWorkspace 
    ? `Channel members automatically sync with your #${primaryChannel} channel on Slack.`
    : `Channel members automatically sync with your ${primaryChannel} team.`;

  if (isEnterprise) {
    copy = 'Members automatically sync with Matter channels.';
  }

  return (
    <Modal.Panel.Main
      header={MEMBERS_PANEL_TEXT.HEADER}
      headerActions={<UploadButton 
        size={'S'} 
        onClick={() => navigate(Resources.membersBulkUpdateProps.path({ workspaceId: workspace?.id }))}
      >
        Bulk Update Member Properties
      </UploadButton>
      }
    >
      <SubHeader>
        {copy}
      </SubHeader>
      <SearchInput
        loading={false}
        onChange={({ target }) => {
          const searchString = target.value;
          setSearchString(searchString);
        }}
        value={searchString}
        placeholder="Search members"
      />
      {!!membersList?.length && (
        <MembersList
          peers={membersList}
          renderPeerActions={(member) => {
            const role = getRoleValueFromProps(member, workspaceId, isEnterprise && !isAdminSettings ? tenant : null);
            const isMember = role === 'member';
            const roleLabel = roles.find(({ value }) => value === role)?.label;

            return (
              <EditWrapper>
                {isMember ? null : (
                  <strong style={{ marginRight: '0.5rem' }}>{roleLabel.toUpperCase()}</strong>
                )}
                <EditButton 
                  color={'black'}
                  onClick={() => navigate(Resources.workspaceSettingsMemberProperties.path({ workspaceId, memberId: member.id }))}
                >
                  EDIT
                </EditButton>
              </EditWrapper>
            );
          }}
          showEmailAsSubHeader
        />
      )}
      {!membersList?.length && !isLoading && (
        getDropdownInviteMessage(primaryChannel)
      )}
      {isLoading && (
        <b>Loading...</b>
      )}
    </Modal.Panel.Main>
  );
};
