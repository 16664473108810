import styled from 'styled-components';
import { Box, Paper } from '@mui/material';
import { IconNew, Checkbox, Input, Modal } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';

export const MemberSettingsFooter = styled(Modal.Footer.WithActionButtons)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  zIndex: 1,
  padding: theme.spacing(3),
  borderTop: 'none',
  borderRadius: '8px'
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '100%',
  svg: {
    height: '20px',
    marginLeft: theme.spacing(0.5)
  },
  p: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(2)}!important`
  }
}));

export const DropdownContainer = styled(Box)(({ theme }) => ({
  width: '400px',
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  cursor: 'pointer',
  width: '100%',
  display: 'inline-block',
  color: theme.palette.blacks[80],
  boxSizing: 'border-box',
  textAlign: 'left',
  border: '1px solid #99999B',
  borderRadius: '4px',
  '&:focus': {
    color: theme.palette.blacks[80],
    background: 'white',
    borderColor: theme.palette.blues[50],
    boxShadow: '0px 2px 4px rgba(52, 55, 153, 0.2)',
  },
  '&::placeholder': {
    color: theme.palette.blacks[40]
  },
  '&:disabled': {
    border: 'none'
  },
  fontSize: '17px',
  lineHeight: '24px',
  paddingRight: '17px!important'
}));

export const TextWithInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
}));

export const StyledIcon = styled(IconNew)(() => ({
  marginLeft: theme.spacing.half
}));

export const InputWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(0.5),
  width: '100%',
  '> div:first-of-type': {
    width: '100%'
  },
  input: {
    width: '100%',
    [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    }
  }
}));

export const LineWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
  justifyContent: 'flex-start',
  marginBottom: theme.spacing(3),
  width: '100%',
  button: {
    alignSelf: 'center'
  }
}));

export const BirthdayWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginTop: theme.spacing(0.5),
  '> div:first-child': {
    width: '50%',
    marginRight: theme.spacing(2)
  },
  '> div:nth-child(2)': {
    width: '50%'
  },
  'input:disabled': {
    backgroundColor: `${theme.palette.blacks[10]}!important`,
    color: theme.palette.blacks[90]
  }
}));

export const Divider = styled.div(() => ({
  borderTop: `1px solid ${theme.colors.blacks[10]}`,
  margin: `${theme.spacing.single} 0`
}));

export const RadioList = styled(Checkbox.RadioList.Settings)(({ theme }) => ({
  [`& ${Checkbox.RadioList.Label}`]: {
    lineHeight: theme.spacing(1.5),
    fontWeight: 'bold'
  },
  [theme.breakpoints.down('sm')]: {
    div: {
      textAlign: 'left'
    }
  }
}));

export const ClearButton = styled.div(() => ({
  marginLeft: '12px'
}));
