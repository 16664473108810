import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Tooltip } from '@matterapp/matter-ui';
import { 
  MainWrapper,
  SearchInput
} from './styles';
import { useQuery } from '@apollo/client';
import getMatterTeams from 'graphql-queries/queries/matterTeams/getMatterTeams';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from 'hooks';
import CreateTeamModal from './CreateTeamModal';
import { 
  Caption, 
  NoComments,
  StyledBodyRow,
  HeaderCell,
  DesktopOnlyCell
} from 'components/Table/styles';
import { 
  Box,
  Typography,
  TableHead,
  TableSortLabel,
  TableBody,
  Table,
  TableRow,
  TableCell
} from '@mui/material';
import { EditButton, TopSection } from '../DataProperties/styles';
import moment from 'moment-timezone';
import { Resources } from '@matterapp/routing';
import SyncedIcon from './syncedIcon';

const SubHeader = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2)
  }
}));

const GroupNameCell = styled(TableCell)(({ theme }) => ({
  width: '30%', 
  maxWidth: '290px',
  [theme.breakpoints.down('sm')]: {
    width: '60%'
  }
}));

export default function MatterTeamSettings(props) {
  const {
    workspace,
    tenant
  } = props;
  const { queryParams } = useQueryParams();
  const [isCreatingTeam, setCreatingTeam] = useState(false);
  const [searchString, setSearchString] = useState();
  const [order, setOrder] = useState('asc');
  const { isEnterprise, hrisSettings } = tenant;
  const { teamsMapping } = hrisSettings || {};
  const isAdminSettings = !queryParams.isChannelSetting;
  const navigate = useNavigate();
  const { id: workspaceId, tenantId } = workspace || {};

  const variables = { limit: 50 };

  if (isAdminSettings && isEnterprise) {
    variables.tenantId = tenantId;
  } else {
    variables.workspaceId = workspaceId;
  }

  const { data: matterTeamsData } = useQuery(getMatterTeams, {
    variables: {
      tenantId: parseInt(tenantId)
    },
    skip: !tenantId
  });

  const { matterTeams = [] } = matterTeamsData || {};

  const filteredTeams = matterTeams
    .filter((team) => {
      if (searchString) {
        return team.name.toLowerCase().includes(searchString.trim().toLowerCase());
      }
      return true;
    });
  
  return (
    <Box>
      <TopSection>
        <Typography variant='h4'>
          Groups
        </Typography>
        <Button 
          size={'S'} 
          onClick={() => setCreatingTeam(true)}
        >
          Create Group
        </Button>
      </TopSection>
      <SubHeader>
        <Typography variant='body1'>
          Empower all managers in your organization with their group&apos;s results. To learn more, visit the <a href='https://help.matterapp.com/en/articles/10309906-member-groups' target='_blank' rel='noopener noreferrer'> Help Center</a>.
        </Typography>
      </SubHeader>
      <SearchInput
        loading={false}
        onChange={({ target }) => {
          const searchString = target.value;
          setSearchString(searchString);
        }}
        value={searchString}
        placeholder="Search Groups"
      />
      <MainWrapper>
        <CreateTeamModal 
          workspace={workspace}
          teams={matterTeams}
          tenant={tenant}
          isOpen={isCreatingTeam}
          onClose={() => setCreatingTeam(false)}
        />
        <Table size='large'>
          {!filteredTeams.length ? (
            searchString || !matterTeamsData ? (
              <Caption>
                <NoComments variant='h6' component='p'>
                  <b>{!matterTeamsData ? ' ' : 'No matches'}</b>
                </NoComments>
              </Caption>
            ) : (
              <Caption>
                <NoComments variant='h6' component='p'>
                  <b>No groups yet</b>
                </NoComments>
                <NoComments variant='body1' component='p'>
                  Create groups to empower your managers.
                </NoComments>
              </Caption>
            )
          ) : null}
          <TableHead>
            <TableRow sx={{ borderBottom: '1px solid #E6E6E7' }}>
              <HeaderCell visibleOnMobile>
                <TableSortLabel
                  active
                  direction={order}
                  onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                >
                  Group
                </TableSortLabel>
              </HeaderCell>
              <HeaderCell visibleOnMobile sx={{ maxWidth: '122px' }}>Members</HeaderCell>
              <HeaderCell sx={{ whiteSpace: 'nowrap' }}>Sub-Groups</HeaderCell>
              <HeaderCell>Managers</HeaderCell>
              <HeaderCell>Created on</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTeams
              .sort((a, b) => {
                if (order === 'asc') {
                  return a.name.localeCompare(b.name);
                } else {
                  return b.name.localeCompare(a.name);
                }
              })
              .map((team) => {
                const { id, name, managers = [], subteams = [], membersCount, createdAt } = team;
                const isSynced = teamsMapping?.find(({ matterTeamId }) => matterTeamId === id);

                return (
                  <StyledBodyRow 
                    onClick={(e) => {
                      navigate(
                        Resources.workspaceSettingsTeamPage.path({ workspaceId, teamId: id })
                      );
                    }}
                    key={id} 
                  >
                    <GroupNameCell>
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography 
                          variant='body2' 
                          sx={{ 
                            mr: 1, 
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            width: 'auto'
                          }}
                        >
                          <b>{name}</b>
                        </Typography>
                        
                        {isSynced && (
                          <Tooltip
                            dark
                            content='This group is synced with your HRIS.'
                          >
                            <SyncedIcon />
                          </Tooltip>
                        )}
                      </Box>
                    </GroupNameCell>
                    <TableCell>
                      <Typography variant='body2'>
                        {membersCount || 0}
                      </Typography>
                    </TableCell>
                    <DesktopOnlyCell>
                      <Typography variant='body2'>
                        {subteams?.length || 0}
                      </Typography>
                    </DesktopOnlyCell>
                    <DesktopOnlyCell>
                      <Typography variant='body2'>
                        {managers?.length || 0}
                      </Typography>
                    </DesktopOnlyCell>
                    <DesktopOnlyCell sx={{ maxWidth: '25%' }}>
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}>
                        <Typography variant='body2' color='blacks.90' sx={{ textWrap: 'nowrap' }}>
                          {moment(parseInt(createdAt)).format('MMM DD, YYYY')}
                        </Typography>
                        <EditButton 
                          variant='outlined'
                        >
                          EDIT
                        </EditButton>
                      </Box>
                    </DesktopOnlyCell>
                  </StyledBodyRow>
                );
              })}
          </TableBody>
        </Table>
      </MainWrapper>
    </Box>
  );
};
