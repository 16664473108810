import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Clickable } from '../Clickable/Clickable';
import omitStyled from '../../libs/omitStyled';
import theme, { ColorPalettes, getColorObj } from '@matterapp/matter-theme';

const DEFAULT_SIZE = 30;
const DEFAULT_COLOR = theme.colors.blacks[50];
const DISABLED_COLOR = theme.colors.blacks[30];

const Container = styled(Clickable)`
  display: flex;
  align-items: center;
`;

const Wrapper = omitStyled('div', ['checked', 'color', 'size'])`
  border-radius: 100%;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-left: 8px;
  position: relative;
  transition: border-color ${theme.transitions.times.default};

  ${({ checked, color, disabled, size }) => {
    const borderColor = checked ? color[50] : DEFAULT_COLOR;
    return `
      border: 1px solid ${disabled ? DISABLED_COLOR : DEFAULT_COLOR};
      height: ${size}px;
      width: ${size}px;
      min-width: ${size}px;
      max-width: ${size}px;
      z-index: 1;

            
      ${Container}:hover & {
        border-color: ${disabled ? DISABLED_COLOR : color[50]};
      }

      &:after {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        border-radius: 100%;
        border-width: ${checked ? '12px' : '0px'};
        border-color: ${disabled ? DISABLED_COLOR : borderColor};
        border-style: solid;
        z-index: 0;
        opacity: ${checked ? 0 : 1};
        transition: border-width 0.5s, opacity ${checked ? '0.6s' : '0s 0.5s'};
      }
    `;
  }}
`;

const Icon = omitStyled('div', ['checked', 'color', 'size'])`
  border-radius: 100%;
  transition: background-color ${theme.transitions.times.default};

  ${({ checked, color, disabled, size }) => {
    const halfSize = size / 2;
    const calculatedSize = halfSize % 2 == 0 ? halfSize : halfSize + 1;
    const iconColorDisabled = checked ? DISABLED_COLOR : 'transparent';
    const iconColor = checked ? color[50] : 'transparent';
    return `
      height: ${calculatedSize}px;
      width: ${calculatedSize}px;
      background-color: ${disabled ? iconColorDisabled : iconColor};
    `;
  }}
`;

const Radio = (props) => {
  const {
    name,
    className,
    children,
    color,
    checked,
    disabled,
    onClick,
    size,
    value,
  } = props;
  const selectedColor = getColorObj(color) || theme.colors.blues;
  const sharedProps = { checked, color: selectedColor, disabled, size };
  const otherProps = {};
  if (name) {
    otherProps.name = name;
  }
  return (
    <Container className={className} disabled={disabled} onClick={onClick} value={value} {...otherProps} type="radio">
      <Wrapper {...sharedProps}>
        <Icon {...sharedProps} />
      </Wrapper>
      {children}
    </Container>
  );
};

Radio.Icon = Icon;
Radio.Wrapper = Wrapper;

Radio.defaultProps = {
  color: ColorPalettes.Blue,
  size: DEFAULT_SIZE,
};

Radio.propTypes = {
  /** Additional style class. */
  className: PropTypes.string,
  /** Color of the radio. */
  color: PropTypes.oneOf(Object.keys(ColorPalettes)),
  /** If the radio button is checked. */
  checked: PropTypes.bool,
  /** Additional content */
  children: PropTypes.node,
  /** Callback when button is clicked. */
  onClick: PropTypes.func,
  /** Size of radio button in pixels. */
  size: PropTypes.number,
  /** The value of the radio. */
  value: PropTypes.string,
};

export default Radio;
