import React, { useState, useEffect } from 'react';
import { 
  MEMBERS_DROPDOWN_OWNER_ITEM,
  MEMBERS_DROPDOWN_ADMIN_ITEM,
  MEMBERS_DROPDOWN_MEMBER_ITEM,
  MEMBERS_DROPDOWN_CHANNEL_ADMIN_ITEM
} from './helpers';
import TransferOwnershipModal from './TransferOwnerhipModal';
import { 
  RadioList,
  StyledPaper
} from './styles';
import { Typography } from '@mui/material';
import { ACTIONS } from './reducer';

export default function MemberPropertiesPermissions(props) {
  const { member, isAdminSettings, isEnterprise, setIsTransferConfirmed, isTransferConfirmed, state, dispatch } = props;
  const [isTransferringModalOpen, setIsTransferringModalOpen] = useState(false);
  const isChannelPermissions = isEnterprise ? !isAdminSettings : false;
  const { settings, roleChanged } = state;
  const { role } = settings;

  const { isSelf } = member || {};

  useEffect(() => {
    if (roleChanged && role === MEMBERS_DROPDOWN_OWNER_ITEM.value && !isTransferConfirmed) {
      setIsTransferringModalOpen(true);
    }
  }, [roleChanged]);

  return (
    <StyledPaper>
      <Typography variant='h3' sx={{ mb: 3 }}>Member Permission</Typography>
      <RadioList
        showRadioAfterLabel
        disabled={isSelf || (!roleChanged && role === MEMBERS_DROPDOWN_OWNER_ITEM.value)}
        onChange={(_, { value }) => {
          dispatch({ type: ACTIONS.UPDATE_ROLE, payload: value });
        }}
        values={isChannelPermissions 
          ? [
            MEMBERS_DROPDOWN_CHANNEL_ADMIN_ITEM,
            MEMBERS_DROPDOWN_MEMBER_ITEM
          ] 
          : [  
            MEMBERS_DROPDOWN_OWNER_ITEM,
            MEMBERS_DROPDOWN_ADMIN_ITEM,
            MEMBERS_DROPDOWN_MEMBER_ITEM
          ]}
        value={role}
      />
      <TransferOwnershipModal 
        isOpen={isTransferringModalOpen}
        onClickCancel={() => {
          setIsTransferringModalOpen(false);
          dispatch({ type: ACTIONS.RESET_ROLE });
        }}
        onClickConfirm={() => {
          setIsTransferConfirmed(true);
          setIsTransferringModalOpen(false);
        }}
        member={member}
      />
    </StyledPaper>
  );
}
