import React, { useContext, useState, useEffect, useReducer, useRef } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { Box, Typography, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import saveSurveyResponse from 'graphql-queries/mutations/surveys/saveSurveyResponse';
import getSurveyQuery from 'graphql-queries/queries/surveys/getUserSurvey';
import getCustomSurveyQuery from 'graphql-queries/queries/surveys/getCustomSurvey';
import { useMutation, useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { Button as MatterButton, Loader } from '@matterapp/matter-ui';
import Toast from 'components/Toast/Toast';
import {
  StyledModal,
  Content,
  Header,
  Footer,
  CloseButton,
  Body,
  BodyInternalWrapper
} from '../SurveyEditor/styles';
import { Resources } from '@matterapp/routing';
import styled from 'styled-components';
import SelectTypeQuestion from './SelectTypeQuestion';
import TextTypeQuestion from './TextTypeQuestion';
import reducer, { getInitialState, ACTIONS, getStateTransferObject } from './reducer';
import ConfirmModal from './ConfirmModal';
import LeaveModal from './LeaveModal';

const StyledFooter = styled(Footer)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end'
}));

const SendButton = styled(MatterButton.Simple.Primary.Vivid)(({ theme }) => ({
  padding: `0 ${theme.spacing(7.5)}`
}));

const SurveyWrapper = styled(Paper)(({ theme }) => ({
  width: '608px',
  maxWidth: '608px',
  backgroundColor: theme.palette.white.main,
  padding: theme.spacing(3),
  margin: '0 auto',
  marginBottom: theme.spacing(33.5),
  [theme.breakpoints.down('md')]: {
    width: 'auto',
    margin: `0 ${theme.spacing(1)}`,
    marginBottom: theme.spacing(33.5)
  }
}));

export default function SurveyResponseView() {
  const { currentTenant, currentWorkspaceId: workspaceId } = useContext(UserContext);
  const [state, dispatch] = useReducer(reducer, getInitialState([]));
  const [closing, setClosing] = useState(false);
  const [customSurveyId, setCustomSurveyId] = useState(null);
  const { canSave, hasAnswered } = state;
  const { rewardSettings, pulseSettings } = currentTenant || {};
  const { isRewardsActive } = rewardSettings || {};
  const { coinsPerSurvey, rewardsActive } = pulseSettings || {};
  let coinsReceived = isRewardsActive && rewardsActive ? coinsPerSurvey : 0;
  const params = useParams();
  const { surveyId } = params;

  const [saveResults, { loading: savingQuestion, data: savedSurveyData }] = useMutation(saveSurveyResponse);
  const { data: surveyData } = useQuery(getSurveyQuery, {
    variables: {
      surveyId
    },
    skip: !surveyId
  });

  const { data: customSurveyData } = useQuery(getCustomSurveyQuery, {
    variables: {
      surveyId: customSurveyId,
      tenantId: currentTenant?.id
    },
    skip: !customSurveyId || !currentTenant
  });

  const { customSurvey } = customSurveyData || {};
  const { survey } = surveyData || {};
  const activeSurvey = survey || customSurvey;

  if (customSurvey) {
    if (customSurvey.hasRewards && customSurvey.coins) {
      coinsReceived = customSurvey.coins;
    } else {
      coinsReceived = 0;
    }
  }

  useEffect(() => {
    if (params.customSurveyId) {
      setCustomSurveyId(params.customSurveyId);
    } else if (survey?.customSurveyId) {
      setCustomSurveyId(survey.customSurveyId);
    }
  }, [params, survey]);
  
  const surveyIsInactive = survey && survey?.status !== 'active';
  let title = null;

  if (activeSurvey) {
    title = customSurvey?.name || 'Pulse Survey';
  }

  useEffect(() => {
    if (activeSurvey) {
      dispatch({
        type: ACTIONS.INIT_STATE,
        payload: activeSurvey
      });
    }
  }, [activeSurvey]);

  useEffect(() => {
    if (canSave) {
      const isPreview = customSurvey?.status === 'draft';
      
      saveResults({
        variables: {
          ...getStateTransferObject(state, activeSurvey.id, isPreview),
          workspaceId
        }
      }).catch(error => {
        Toast.error(error.message);
      }) ;
    }
  }, [canSave]);

  const navigate = useNavigate();

  const containerRef = useRef(null);

  function onClose() {
    if (!savedSurveyData && hasAnswered && !closing) {
      setClosing(true);
    } else {
      navigate(
        Resources.workspacesActivityFeed.path({ workspaceId })
      );
    }
  }

  const showLoader = !activeSurvey || (survey?.customSurveyId && !customSurvey);
  
  return (
    <StyledModal
      open
      onClose={onClose}
    >
      {showLoader ? (
        <Content>
          <Loader />
        </Content>
      ) : (
        <Content>
          <Header>
            <Typography variant='body1'>
              {title}
            </Typography>

            <CloseButton 
              component={CloseIcon}
              onClick={onClose}
            />
          </Header>
          <Body ref={containerRef}>
            <ConfirmModal 
              isOpen={savedSurveyData}
              onClose={onClose}
              header={customSurvey ? 'Survey Sent!' : 'Pulse Survey Sent!'}
              coinsReceived={coinsReceived}
              isPulse={!customSurvey}
            />
            <ConfirmModal 
              isOpen={surveyIsInactive}
              onClose={onClose}
              header='This survey is no longer available for responses.'
              error={true}
            />
            <LeaveModal
              onResume={() => setClosing(false)}
              onClose={onClose}
              isOpen={closing}
            />
            <BodyInternalWrapper>
              { !activeSurvey || savedSurveyData || surveyIsInactive ? (
                null
              ) : (
                <SurveyWrapper>
                  <Typography variant='body1'>
                    {!customSurvey || customSurvey?.isAnonymous ? (
                      <><b>Anonymous Survey:</b> All answers are shared anonymously.</>
                    ) : (
                      <><b>Attributed Survey:</b> This survey is not anonymous.</>
                    )}
                    {activeSurvey.questions.map((question, index) => {
                      let QuestionComponent = SelectTypeQuestion;

                      if (question.type === 'text') {
                        QuestionComponent = TextTypeQuestion;
                      }

                      return (
                        <QuestionComponent 
                          {...question} 
                          key={question.id} 
                          index={index} 
                          isAnonymous={!customSurvey || customSurvey?.isAnonymous}
                          values={state.answers.find(({ id }) => id == question.id)}
                          onChange={(value) => {
                            dispatch({
                              type: ACTIONS.ADD_ANSWER,
                              payload: { id: question.id, answer: value }
                            });
                          }}
                          onAddComment={(value) => {
                            dispatch({
                              type: ACTIONS.ADD_COMMENT,
                              payload: { id: question.id, comment: value }
                            });
                          }}
                        />
                      );
                    })}
                  </Typography>
                </SurveyWrapper>
              )}
            </BodyInternalWrapper>
          </Body>
          <StyledFooter>
            <Box>
              {savedSurveyData || surveyIsInactive ? null : (
                <SendButton
                  size='M'
                  color='blue'
                  rcLabel='send-survey-response-button'
                  onClick={async () => {
                    dispatch({ type: ACTIONS.VALIDATE });
                  }}
                  disabled={savingQuestion}
                >
                  {savingQuestion ? 'Sending...' : 'Send'}
                </SendButton>
              )}
            </Box>
          </StyledFooter>
        </Content>
      )}
    </StyledModal>
  );
};

