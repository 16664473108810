import React from 'react';
import { Routes } from 'react-router-dom';

import {
  ResponseFlowWelcome,
  ResponseFlowBeginFeedback,
  ResponseFlowThankYou,
  ResponseFlowCantRateSelf,
  ResponseFlowTimeline,
  RecurringResponseFlowTimeline,
  //
  AuthRequestVerification,
  AuthMagicLink,
  AuthMagicLinkVerification,
  AuthSignout,
  AuthLoginGateway,
  AuthRedirectOnLoginViaToken,
  //
  Root,
  HomeMain,
  SkillsMain,
  SkillDetails,
  FeedbackRequestsMain,
  FeedbackRequestsView,
  FeedbackMain,
  FeedbackMainFallback,
  AnalyticsMain,
  RewardsStore,
  RewardsHistory,
  RewardsManagerOverview,
  RewardsManagerCustomRewards,
  RewardsReports,
  RewardsFunding,
  RewardsAddFunding,
  RewardsSendWire,
  PulseDashboard,
  PulseCategoryDetails,
  PulseEnpsDetails,
  PulseQuestionLibrary,

  SurveysDashboard,
  SurveyResults,
  CreateSurvey,
  SurveyResponse,
  SurveyPreview,

  TeamsInstallRedirect,
  //
  UserSettingsMain,
  UserSettingsNotifications,
  UserSettingsCelebrations,
  UserSettingsTeamsNotifications,
  //
  AccountClosed,
  PageNotFound,
  TestAuth,
  TenantZero,
  //
  FeedbackFlow,
  FeedbackReceivedViewFeedback,
  //
  WorkspacesActivityFeed,
  WorkspacesUpgrade,
  WorkspacesMembers,
  WorkspacesRecurring,
  DeclineRecurringFeedbackGateway,
  //
  InternalAdminMain,
  InternalAdminFunding,
  InternalAdminWorkspace,
  InternalAdminFundingBreakdown,
  // Settings
  HrisSyncHistory
} from './bindings';

export default () => (
  <Routes>
    {AnalyticsMain}
        
    {AuthSignout}
    {AuthRequestVerification}
    {AuthRedirectOnLoginViaToken}
    {AuthLoginGateway}
    {AuthMagicLink}
    {AuthMagicLinkVerification}

    {TestAuth}

    {DeclineRecurringFeedbackGateway}
    
    {Root}
    {HomeMain}

    {HrisSyncHistory}
    
    {FeedbackMain}
    {FeedbackMainFallback}
    {FeedbackRequestsView}
    {FeedbackRequestsMain}
    {FeedbackReceivedViewFeedback}
    {FeedbackFlow}
    
    {PulseDashboard}
    {PulseCategoryDetails}
    {PulseEnpsDetails}
    {PulseQuestionLibrary}

    {SurveysDashboard}
    {SurveyResults}
    {SurveyResponse}
    {SurveyPreview}
    {CreateSurvey}
    
    {RewardsStore}
    {RewardsHistory}
    {RewardsManagerOverview}
    {RewardsManagerCustomRewards}
    {RewardsReports}
    {RewardsFunding}
    {RewardsAddFunding}
    {RewardsSendWire}

    {ResponseFlowWelcome}
    {ResponseFlowBeginFeedback}
    {ResponseFlowTimeline}
    {RecurringResponseFlowTimeline}
    {ResponseFlowCantRateSelf}
    {ResponseFlowThankYou}
    
    {SkillsMain}
    {SkillDetails}

    {TenantZero}

    {WorkspacesActivityFeed}
    {WorkspacesRecurring}
    {WorkspacesMembers}
    {TeamsInstallRedirect}
    
    {PageNotFound}
    {AccountClosed}

    {UserSettingsMain}
    {UserSettingsNotifications}
    {UserSettingsCelebrations}
    {UserSettingsTeamsNotifications}

    {WorkspacesUpgrade}
    {InternalAdminMain}
    {InternalAdminFunding}
    {InternalAdminFundingBreakdown}
    {InternalAdminWorkspace}
  </Routes>
);
