import { 
  Box,
  Typography,
  Paper,
  Divider
} from '@mui/material';
import styled from 'styled-components';
import { colors, font, lineHeight, spacing, media } from '@matterapp/matter-theme';
import { Clickable } from '@matterapp/matter-ui';

export const FilterBarWrapper = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(3)} 0`
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  maxWidth: '950px',
  [theme.breakpoints.down('sm')]: {
    '.MuiPaper-root': {
      borderRadius: 0
    }
  }
}));

export const MobileBreadCrumb = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 0
  }
}));

export const TopWrapper = styled(Box)(({ theme }) => ({
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(2)} 0`,
    margin: `0 ${theme.spacing(1)}`
  }
}));

export const ResultsWrapper = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(3)}`,
  [theme.breakpoints.down('sm')]: {
    padding: `0 ${theme.spacing(2)}`
  }
}));

export const SummarySection = styled(Paper)(({ theme }) => ({
  padding: `${theme.spacing(6)} ${theme.spacing(3)}`,
  marginTop: theme.spacing(3), 
  display: 'flex', 
  minHeight: '312px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    height: 'auto',
    margin: 0,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    hr: {
      display: 'none'
    }
  }
}));

export const SurveyName = styled(Typography)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block'
  }
}));

export const SummaryNumbers = styled(Box)(({ theme }) => ({
  display: 'flex', 
  flexDirection: 'column', 
  width: '200px',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'normal',
    'div:first-of-type': {
      marginRight: theme.spacing(3)
    },
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.blacks[10]}`
  }
}));

export const FilterTabsContainer = styled.div`
  display: none;
  ${media.M`
    display: flex;
    border: 1px solid ${colors.blacks[10]};
    height: ${spacing.tripleAndHalf};
    color: ${colors.blacks[60]};
    line-height: ${lineHeight.L};
    font-size: ${font.size.S};
    border-radius: ${spacing.quarter};
    button:nth-child(2) {
      border-left: 1px solid ${colors.blacks[10]};
      border-right: none;
      border-top-right-radius: ${spacing.quarter};
      border-bottom-right-radius: ${spacing.quarter};
    }
    button:nth-child(1) {
      border-top-left-radius: ${spacing.quarter};
      border-bottom-left-radius: ${spacing.quarter};
    }
  `}
`;

export const FilterTab = styled(Clickable)`
  font-size: ${font.size.S};
  line-height: ${lineHeight.M};
  text-align: center;
  width: 127px;
  background: ${({ isActive }) => isActive ? colors.blues[1] : 'none'};
  color: ${({ isActive }) => isActive ? colors.blues[60] : colors.blacks[60]};
  &:hover, &:active {
    cursor: pointer;
    background: ${colors.blues[1]};
    color: ${colors.blues[60]};
  }
`;

export const SummaryDetails = styled(Box)(({ theme }) => ({
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    alignSelf: 'flex-start'
  }
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: 0, 
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    margin: 0, 
  }
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  width: '55px',
  height: '35px',
  border: `1px solid ${theme.palette.blues[30]}`,
  color: theme.palette.blues[50],
  borderRadius: '4px',
  backgroundColor: theme.palette.white.main
}));

export const NoResponses = styled(Typography)({
  color: '#C6C6C7', 
  textTransform: 'uppercase',
  margin: '0 auto'
});

export const NoResponsesWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '100%',
  overflow: 'hidden'
});

export const QuestionWrapper = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(3), 
  paddingLeft: theme.spacing(8), 
  paddingRight: theme.spacing(8), 
  height: '320px', 
  overflowX: 'hidden', 
  overflowY: 'scroll',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1)
  }
}));

export const Result = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
    hr: {
      marginTop: theme.spacing(2)
    }
  }
}));
