import React from 'react';
import UpgradeModal from 'modules/workspaces/shared/UpgradeModal';
import PurpleBanner from 'components/PurpleBanner';

export default function TrialBanner(props) {
  const { isAdminMember, expiresInDays, upgradeModalProps, onClickUpgrade, roundAllCorners } = props;

  const paymentButtonLabel = isAdminMember ? 'Add Payment Info' : 'Upgrade to Pro';
  const plural = expiresInDays > 1 ? 'days' : 'day';

  return (
    <>
      <UpgradeModal {...upgradeModalProps} />
      <PurpleBanner
        roundAllCorners={roundAllCorners}
        isCollapsable
        id={'free-trial-banner'}
        expandedHeader={`Trial ending in ${expiresInDays} ${plural}`}
        collapasedHeader={<>Trial ending in <strong>{expiresInDays} {plural}</strong></>}
        description="You're currently on a free 14-day trial. Upgrade to keep all Matter Pro features - unlimited kudos, custom kudos, gift cards and company rewards, analytics, and more!"
        buttonLabel={paymentButtonLabel}
        onButtonClick={onClickUpgrade}
      />
    </>
  );
};
