import React, { useEffect, useState } from 'react';
import { Button, Confirm, Message } from '@matterapp/matter-ui';
import {
  DeleteButtonWrapper,
  MembersList,
  SearchDropdown,
  MainWrapper
} from './styles';
import { 
  Box,
  Typography,
  Divider,
  Table,
  Pagination,
  CircularProgress
} from '@mui/material';
import { 
  Caption, 
  NoComments
} from 'components/Table/styles';
import { debounce } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';
import searchWorkspaceMembers from 'graphql-queries/queries/workspace/searchWorkspaceMembers';
import addMemberToMatterTeam from 'graphql-queries/mutations/matterTeams/addMemberToMatterTeam';
import deleteMatterMember from 'graphql-queries/mutations/matterTeams/deleteMatterMember';
import Toast from 'components/Toast/Toast';
import BulkAddMembers from './BulkAddMembers';
import { useQuery } from '@apollo/client';
import getMatterTeamMembers from 'graphql-queries/queries/matterTeams/getMatterTeamMembers';

const debouncedSearch = debounce((searchString, tenantId, query) => {
  query({
    variables: {
      tenantId,
      searchString,
      excludeCurrentUser: false
    },
    fetchPolicy: 'network-only'
  });
}, 300);

const MAX_MEMBERS_PER_PAGE = 20;

export default function MembersTab({ 
  matterTeam, 
  tenantId, 
  isSynced,
  refetchTeam
}) {
  const [hasPagination, setHasPagination] = useState(false);
  const [pagesCount, setPagesCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const { ownMemberCount = 0, id: teamId } = matterTeam;
  const [deletingMember, setDeletingMember] = useState(null);
  const [bulkAddIsOpen, setBulkAddIsOpen] = useState(false);
  const [searchMembers, { data: searchMembersData }] = useLazyQuery(searchWorkspaceMembers);
  const [addMemberToMatterTeamMutation, { data: addedData, loading: addingMember}] = useMutation(addMemberToMatterTeam);
  const [deleteMatterMemberMutation, { data: deletedData, loading: deleting}] = useMutation(deleteMatterMember);
  const { data: matterTeamMembersData, loading: loadingMembers, refetch } = useQuery(getMatterTeamMembers, {
    variables: {
      tenantId,
      teamId: parseInt(teamId),
      memberLimit: MAX_MEMBERS_PER_PAGE,
      offset: (currentPage - 1) * MAX_MEMBERS_PER_PAGE
    },
    skip: !tenantId || !teamId,
    fetchPolicy: 'network-only'
  });
  const members = matterTeamMembersData?.matterTeamMembers || [];

  let memberItems = (searchMembersData?.searchMembers || [])
    .filter((member) => !members.some((m) => m.person.id === member.person.id));

  memberItems = [...memberItems.map((member) => {
    return {
      label: member.person.fullName,
      value: member.person.id,
      subLabel: member.person.email
    };
  })];

  let membersCountText = `This group has ${ownMemberCount} members.`;

  if (ownMemberCount === 1) {
    membersCountText = `This group has ${ownMemberCount} member.`;
  }

  function resetData() {
    setCurrentPage(1);
    refetchTeam();
    refetch({
      tenantId,
      teamId: parseInt(teamId),
      memberLimit: MAX_MEMBERS_PER_PAGE,
      offset: 0
    });
  }

  useEffect(() => {
    if (matterTeam?.ownMemberCount > MAX_MEMBERS_PER_PAGE) {
      setHasPagination(true);
      setPagesCount(Math.ceil(matterTeam.ownMemberCount / MAX_MEMBERS_PER_PAGE));
    }
  }, [matterTeam]);

  useEffect(() => {
    if (addedData && !addingMember) {
      resetData();
    }
  }, [addedData, addingMember]);

  useEffect(() => {
    if (deletedData && !deleting) {
      resetData();
    }
  }, [deletedData, deleting]);

  return (
    <MainWrapper>
      <BulkAddMembers 
        tenantId={tenantId}
        teamId={matterTeam.id}
        isOpen={bulkAddIsOpen}
        resetData={resetData}
        onClose={() => setBulkAddIsOpen(false)}
      />
      <Confirm
        isOpen={!!deletingMember}
        onClose={() => setDeletingMember(null)}
        header='Are you sure?'
        subHeader='Once you remove a member, you can always add them back. Member data will not be effected.'
        confirmButtonLabel='Yes, Delete'
        onClickConfirm={async () => {
          const memberId = deletingMember;

          setDeletingMember(null);

          try {
            await deleteMatterMemberMutation({
              variables: {
                tenantId,
                teamId: matterTeam.id,
                memberId
              }
            });
  
            Toast.success('The member has been removed');
          } catch (error) {
            Toast.error(error.message);
          }
        }}
        onClickCancel={() => setDeletingMember(null)}
      />
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        marginBottom: 2
      }}>
        <Typography variant="h4">Add Members</Typography>
        {!isSynced && (
          <Button 
            color={'blue'}
            size={'S'}
            onClick={() => setBulkAddIsOpen(true)}
          >
            Bulk Add Members by Email
          </Button>
        )}
      </Box>
      <Box>
        {isSynced ? (
          <Message.Warning
            showColonAfterHeader
            header='HRIS sync is enabled for this group'
            content={(
              <>
                 Manage membership through your HRIS. Membership on Matter will be automatically updated during every 24 hours during the sync.
              </>
            )}
          />
        ) : (
          <SearchDropdown
            filterItems
            items={memberItems}
            isValidValue={() => true}
            onChangeInput={(e, { value }) => { 
              debouncedSearch(value, tenantId, searchMembers); 
            }}
            onSelect={(_, item ) => {
              if (item.item) {
                addMemberToMatterTeamMutation({
                  variables: {
                    teamId: matterTeam.id,
                    tenantId,
                    personId: item.item.value
                  }
                });
              }
            }}
            onChange={() => {}}
            fluid
            showValuesAsSubLabels={false}
            value={[]}
            placeholder="Enter name"
          />
        )}
      </Box>
      <Typography variant='body1' sx={{ mt: 3 }}>{membersCountText}</Typography>
      <Divider />
      {loadingMembers ? (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 6, mb: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <MembersList
          peers={members?.map((member) => ({
            ...member,
            fullName: member.person?.fullName,
            photoUrl: member.person?.photoUrl
          }))}
          renderPeerActions={(member) => {
            const { role } = member;

            return (
              <DeleteButtonWrapper>
                {role === 'member' ? null : (
                  <strong style={{ marginRight: '0.5rem' }}>{role.toUpperCase()}</strong>
                )}
                {!isSynced && (
                  <Button 
                    color={'red'}
                    size={'S'}
                    onClick={() => setDeletingMember(member.id)}
                  >
                  Remove
                  </Button>
                )}
              </DeleteButtonWrapper>
            );
          }}
          showEmailAsSubHeader
        />
      )}
      {hasPagination && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination 
            sx={{ mt: 3, mb: 0 }}
            count={pagesCount} 
            page={currentPage} 
            color='blue'
            onChange={(_, value) => {
              setCurrentPage(value);
            }} 
          />
        </Box>
      )}
      {members?.length === 0 && (
        <Table size='large'>
          <Caption>
            <NoComments variant='h6' component='p'>
              <b>No members yet</b>
            </NoComments>
            <NoComments variant='body1' component='p'>
              Add members to the group.
            </NoComments>
          </Caption>
        </Table>
      )}
    </MainWrapper>
  );
};
