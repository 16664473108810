import React from 'react';
import searchWorkspaceMembers from 'graphql-queries/queries/workspace/searchWorkspaceMembers';
import { useLazyQuery } from '@apollo/client';
import { Button as MatterButton } from '@matterapp/matter-ui';
import { Box, Typography } from '@mui/material';
import { FormattedGroup as Group } from 'components/Group';
import {
  SubHeader,
  StyledRadio,
  SearchInput
} from './styles';
import { ACTIONS } from './state';
import { debounce } from 'lodash';
import { getRecipients } from './helpers';
import { StyledPaper } from '../styles';
import { MemberListItem, RemoveMemberButton } from 'components/AddMembersModal/AddMembersModal';

const debouncedSearch = debounce((searchString, tenantId, query) => {
  query({
    variables: {
      tenantId,
      searchString,
      excludeCurrentUser: false
    },
    fetchPolicy: 'network-only'
  });
}, 300);

export default function AudienceSection({ 
  settings, 
  tenant, 
  currentWorkspace, 
  dispatch,
  members,
  setExcludeModalOpen,
  saveSettings
}) {
  const { id: tenantId, activeMembersCount } = tenant;
  const { sendToAll, recipients, excludeMembers } = settings;
  const globalItems = getRecipients(tenant, currentWorkspace, true);
  const [searchMembers, { data }] = useLazyQuery(searchWorkspaceMembers);
  const AUDIENCE_OPTIONS = [`My Organization (${tenant?.name}) — ${activeMembersCount} members`, 'Only members I specify'];
  const recipientMembers = members.filter((member) => recipients.map(({ value }) => parseInt(value)).includes(member.person.id));
  const memberItems = data?.searchMembers || recipientMembers || [];

  const items = [...globalItems, ...memberItems.map((member) => {
    return {
      label: member.person.fullName,
      value: member.person.id,
      subLabel: member.person.email
    };
  })];

  return (
    <StyledPaper>
      <Group header="Audience" marginBottom='0px'>
        <Typography variant='body1' component='p'>
        Who would you like included in the Pulse Survey?
        </Typography>
        <Box sx={{ mt: 3, mb: 3 }}>
          <StyledRadio 
            onChange={() => {
              dispatch({ type: ACTIONS.TOGGLE_AUDIENCE });
            }}
            value={sendToAll ? AUDIENCE_OPTIONS[0] : AUDIENCE_OPTIONS[1]}
            values={AUDIENCE_OPTIONS}
          />
        </Box>
        {sendToAll ? (
          <>
            <SubHeader>
              <Typography variant='h6' component='p'>
              Excluded Members
              </Typography>
              <MatterButton 
                onClick={() => setExcludeModalOpen(true)}
                size='S'
              >
              Add member
              </MatterButton>
            </SubHeader>
            <Typography variant='body2' component='p' sx={{ mt: 2 }}>
            Exclude members (e.g., admins) from being sent pulse surveys and from being included in Pulse Survey analytics.
            </Typography>
            {members
              .filter(({ person }) => excludeMembers.includes(String(person.id)))
              .map(({ person }) => (
                <MemberListItem
                  key={person.id}
                  peer={person}
                >
                  <RemoveMemberButton size='S' color='black' onClick={() => {
                    const newMembers = excludeMembers.filter((personId) => personId !== person.id);

                    saveSettings({ excludeMembers: newMembers });
                  }}>
                    REMOVE
                  </RemoveMemberButton>
                </MemberListItem>
              ))}
          </>
        ) : (
          <Box>
            <Typography variant='h5' component='p'>
            Survey Recipients:
            </Typography>
            <SearchInput
              filterItems
              formatTagLabel={(value) => {
                let label = [...recipients.filter(({ label }) => !!label), ...globalItems].find((item) => item.value === value)?.label;

                if (!label) {
                  label = members?.find((member) => member.person.id == value)?.person.fullName;
                }
   
                return label;
              }}
              items={items}
              isValidValue={() => true}
              onChange={(_, { value: updatedList }) => {
                if (updatedList.length < recipients.length) {
                  const removedRecepient = recipients.find((recipient) => !updatedList.find(value => value === recipient.value));
          
                  dispatch({ type: ACTIONS.REMOVE_RECIPIENT, payload: removedRecepient });
                }
              }}
              onChangeInput={(e, { value }) => { debouncedSearch(value, tenantId, searchMembers); }}
              onSelect={(_, item ) => {
                if (!item?.value.map) {
                  dispatch({ type: ACTIONS.ADD_RECIPIENT, payload: item.item });
                }
              }}
              placeholder='Select channel(s), or user(s).'
              fluid
              disabled={recipients[0]?.value === 'ALL'}
              showValuesAsSubLabels={false}
              value={recipients.map(({ value }) => value)}
            />
          </Box>
        )}
      </Group>
    </StyledPaper>
  );
}