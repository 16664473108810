import React from 'react';
import { 
  Box,
  Typography,
  Divider
} from '@mui/material';
import { Advisors } from '@matterapp/matter-ui';
import getCustomHrisProperties from 'graphql-queries/queries/hris/getCustomHrisProperties';
import { useQuery } from '@apollo/client';
import { Resources } from '@matterapp/routing';
import { useNavigate } from 'react-router-dom';
import XButton from 'components/ClearButton/ClearButton';

export default function HrisCustomFieldsPanel({ tenant, workspaceId, hasHris, customFieldsMapping, setCustomFieldsMapping }) {
  const { hrisSettings } = tenant || {};
  const { status, providerCustomProps= [] } = hrisSettings || {};
  const { data: customHrisPropsData } = useQuery(getCustomHrisProperties, {
    variables: {
      tenantId: tenant.id
    }
  });
  const navigate = useNavigate();

  const { customHrisProperties = [{
    id: '',
    hrisField: 'No custom fields available'
  }] } = customHrisPropsData || {};

  if (!hasHris || !status || status === 'disconnected' || !providerCustomProps?.length) {
    return null;
  }

  let propertyOptions = customHrisProperties
    .filter(({ id }) => !customFieldsMapping.some(({ customPropertyId }) => customPropertyId === id))
    .map(({ id, label }) => {
      return { value: id, label };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  if (!propertyOptions?.length) {
    propertyOptions = [{
      id: '',
      label: 'No custom fields available'
    }];
  }
  
  return (
    <Box sx={{ mb: 3 }}>
      <Divider sx={{ mb: 6, mt: 6 }} />
      <Typography variant='h4' sx={{ mb: 2 }}>
        Custom Fields
      </Typography>
      <Typography variant='body1' sx={{ mb: 2 }}>
        Import custom fields from your HRIS into <a style={{ cursor: 'pointer' }} onClick={() => navigate(Resources.workspaceSettingsDataProps.path({ workspaceId }))}>member properties</a>. 
        Add any custom fields as needed. 
        To enable synchronization, map each custom field to the corresponding HRIS field using the dropdown menu. 
        Member properties currently in use within <a style={{ cursor: 'pointer' }} onClick={() => navigate(Resources.workspaceSettingsSegments.path({ workspaceId }))}>segments</a> will be disabled in the dropdown 
        and cannot be assigned until they are removed from all segments.
      </Typography>
      <Typography variant='body1' sx={{ mb: 2 }}>
        <b>Warning:</b> By enabling a sync for a particular field, the HRIS will become the source of truth and overwrite existing custom field values and data on Matter.
      </Typography>
      {providerCustomProps
        .map((propertyName) => {
          let selectedItem = customFieldsMapping
            .find(({ hrisField }) => hrisField === propertyName);

          if (selectedItem) {
            const label = customHrisProperties.find(({ id }) => id === selectedItem.customPropertyId)?.label;

            if (label) {
              selectedItem = { label, value: selectedItem.customPropertyId };
            } else {
              selectedItem = null;
            }
          }

          return (
            <Box key={propertyName} sx={{ mb: 3 }}>
              <Typography variant='body1' sx={{ mb: 1, fontWeight: 'bold' }}>
                {propertyName}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '97%', marginRight: '10px' }}>
                  <Advisors.RecurringModal.Dropdown
                    placeholder='Select member property'
                    selectedOption={selectedItem}
                    handleChangeSelection={(choice) => {
                      const existingMapping = customFieldsMapping.find(({ hrisField }) => hrisField === propertyName);
                  
                      if (existingMapping) {
                        setCustomFieldsMapping([
                          { hrisField: propertyName, customPropertyId: choice.value },
                          ...customFieldsMapping.filter(({ hrisField }) => hrisField !== propertyName)
                        ]);
                      } else {
                        setCustomFieldsMapping([...customFieldsMapping, { hrisField: propertyName, customPropertyId: choice.value }]);
                      }
                    }}
                    options={propertyOptions}
                  />
                </Box>
                {selectedItem && <XButton onClick={() => {
                  setCustomFieldsMapping(customFieldsMapping.filter(({ hrisField }) => hrisField !== propertyName));
                }}/>}
              </Box>
            </Box>
          );
        })}
    </Box>
  );

};
