import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Dropdown } from '@matterapp/matter-ui';
import searchWorkspaceMembers from 'graphql-queries/queries/workspace/searchWorkspaceMembers';
import updateMaxAllotedCoins from 'graphql-queries/mutations/workspaceMembers/updateMaxAllotedCoins';
import { useLazyQuery, useMutation } from '@apollo/client';
import { 
  StyledModalBody, 
  CustomAllowanceWrapper,
  StyledGroup
} from './styles';
import {
  InputWrapper,
  StyledInput
} from '../DefaultAllowanceSection/styles';
import { MAX_ALLOWED_COINS, FREQUENCY_OPTIONS } from  '../DefaultAllowanceSection/DefaultAllowanceSection';
import getMembersWithCustomCoins from 'graphql-queries/queries/workspace/getMembersWithCustomCoins';
import Toast from 'components/Toast/Toast';
import { getDropdownInviteMessage } from 'hooks/composer/shared';

export default function AddCustomAllowanceModal(props) {
  const { workspace, isOpen, onClose, tenant, allowanceResetFrequency } = props;
  const { id: tenantId } = tenant;
  const { slackWorkspace, teamsWorkspace } = workspace || {};
  const [searchMembers, { loading: isLoadingResults, data }] = useLazyQuery(searchWorkspaceMembers);
  const [selectedMembers, updateSelectedMembers] = useState([]);
  const [hasError, updateHasError] = useState(false);
  const [selectedMaxCoins, updateSelectedMaxCoins] = useState();
  const [canSave, updateCanSave] = useState(false);
  const [updateMemberSettings, { loading: savingSettings, data: savedMembers }] = useMutation(updateMaxAllotedCoins);
  const primaryChannel = slackWorkspace?.primaryChannel;
  const selectedFrequency = FREQUENCY_OPTIONS.find(({ value }) => value === allowanceResetFrequency);

  function resetAndClose() {
    updateHasError(false);
    updateSelectedMembers([]);
    updateSelectedMaxCoins();
    onClose();
  }

  function onAllowanceChange(_, target) {
    const value = parseInt(target.value, 10);
    updateSelectedMaxCoins(value);

    if (!value || value > MAX_ALLOWED_COINS || value < 0) {
      updateHasError(true);
    } else {
      updateHasError(false);
    }
  }

  async function onSearch(value) {
    if (value) {
      searchMembers({
        variables: {
          tenantId,
          searchString: value
        }
      });
    }
  }

  function onChange(_, { value: updatedList }) {
    if (updatedList.length < selectedMembers.length) {
      const newMembers = selectedMembers.filter(({ value }) => updatedList.find(email => email === value));
      updateSelectedMembers(newMembers);
    }
  }

  function onSelect(_, item ) {
    if (item) {
      updateSelectedMembers([...selectedMembers, item.item]);
    }
  }

  function formatMembers(members) {
    return members.map((member) => {
      return {
        ...member,
        label: member.person.fullName,
        subLabel: member.person.email,
        value: member.person.email
      };
    });
  }

  useEffect(() => {
    if (hasError || !selectedMembers.length || !selectedMaxCoins) {
      updateCanSave(false);
    } else {
      updateCanSave(true);
    }
  }, [selectedMembers, selectedMaxCoins, hasError]);

  useEffect(() => {
    if (!savingSettings && savedMembers) {
      Toast.success('You successfully added a custom allowance');

      resetAndClose();
    }
  }, [savingSettings, savedMembers]);

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={resetAndClose}
      size="large"
    >
      <Modal.Header
        header="Set Custom Allowance"
        right={<Modal.Buttons.CloseIcon onClick={resetAndClose} />}
      />
      <StyledModalBody>
        <StyledGroup dividerBelow>
          <Dropdown.Email.Tags
            filterItems
            formatTagLabel={(email) => selectedMembers.find((member) => member.value === email)?.label}
            items={formatMembers(data?.searchMembers || [])}
            showValuesAsSubLabels
            isValidValue={() => true}
            onChange={onChange}
            onSearch={onSearch}
            onSelect={onSelect}
            isLoadingResults={isLoadingResults}
            emptyMenuMessageNoItems={getDropdownInviteMessage(primaryChannel, !!teamsWorkspace)}
            placeholder="Enter name(s)"
            fluid
            isInitiallyPristine
          />
        </StyledGroup>
        <h5>Custom Allowance</h5> 
        <CustomAllowanceWrapper>
          <div>
            <b>{selectedFrequency?.capitalized} Coins for this Channel Member</b><br />
              10 Matter Coins = 1 USD
          </div>
          <InputWrapper>
            <StyledInput
              placeholder="Enter coin amount"
              type="number"
              onChange={onAllowanceChange}
              errorMessage={hasError ? 'Must be between 1-250,000' : null}
              showErrorMessageBelow
              errorMessageClassName="allowanceInputError"
              value={selectedMaxCoins ? selectedMaxCoins.toString() : null} 
            />
          </InputWrapper>
        </CustomAllowanceWrapper>
      </StyledModalBody>
      <Modal.Footer.WithCancelSave
        canClickSave={canSave}
        onClickCancel={resetAndClose}
        onClickSave={() => {
          updateMemberSettings({
            variables: {
              memberIds: selectedMembers.map(({ id }) => id),
              maxCoins: selectedMaxCoins,
              tenantId
            },
            refetchQueries: [
              { 
                query: getMembersWithCustomCoins,
                variables: {
                  tenantId,
                }
              },
            ]
          });
        }}
        sendIsLoading={savingSettings}
      />
    </Modal>
  );
}

AddCustomAllowanceModal.propTypes = {
  workspace: PropTypes.object,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool
};

