import styled from 'styled-components';
import React from 'react';

const ClearButton = styled.span({
  cursor: 'pointer',
  fontSize: '17px',
  fontWeight: 'bold',
  color: 'red'
});



export default function ClearXButton({ onClick }) {
  return (
    <ClearButton onClick={onClick}>
      X
    </ClearButton>
  );
};
