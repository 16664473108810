import React from 'react';
import styled from 'styled-components';

const Img = styled.img(({
  filter: 'opacity(12%) grayscale(1)',
  transition: 'filter 0.2s',
  ':hover': {
    filter: 'opacity(30%) grayscale(0)',
  }
}));

export default function ToTheMoonComposer(props) {
  return (
    <Img 
      width="200"
      height="120"
      src="https://mattertest.imgix.net/kudos/3d/tothemoon-200x120.png" 
      alt="To the moon" 
      {...props} 
    />
  );
}
