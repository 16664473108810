import React from 'react';
import { Resources } from '@matterapp/routing';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import AdminRoute from 'modules/routes/components/AdminRoute';
import { AdminPage } from '../pages/internal-admin';
import RewardsFunding from 'routes/pages//internal-admin/RewardsFunding';
import RewardsFundingBreakdown from 'routes/pages/internal-admin/RewardsFundingBreakdown';
import { Route } from 'react-router-dom';

export const InternalAdminMain = (
  <Route
    exact
    path={Resources.adminAccess.pattern}
    element={(
      <PrivateRoute>
        <AdminPage />
      </PrivateRoute>
    )}
  />
);

export const InternalAdminWorkspace = (
  <Route
    exact
    path={Resources.adminWorkspaceAccess.pattern}
    element={(
      <AdminRoute>
        <AdminPage />
      </AdminRoute>
    )}
  />
);

export const InternalAdminFunding = (
  <Route
    exact
    path={Resources.adminAccessFunding.pattern}
    element={(
      <AdminRoute>
        <RewardsFunding />
      </AdminRoute>
    )}
  />
);

export const InternalAdminFundingBreakdown = (
  <Route
    exact
    path={Resources.adminAccessFundingBreakdown.pattern}
    element={(
      <AdminRoute>
        <RewardsFundingBreakdown />
      </AdminRoute>
    )}
  />
);
