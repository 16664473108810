
import React, { useContext } from 'react';
import { 
  Typography
} from '@mui/material';
import { 
  SeetingsWrapper,
  IconWrapper,
  HeaderWrapper, 
  SubSection,
  SubSectionWrapper,
  SubSectionHeader
} from './styles';
import RightChevron from './icons/rightChevron';
import DataIcon from './icons/data';
import UserContext from 'context/UserContext/UserContext';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';
import AdminsSettingsHoc from './AdminsSettingsHoc';

export default function DataManagementSettings({ isChannelSetting}) {
  const { currentWorkspaceId: workspaceId, isAdminMember } = useContext(UserContext);
  const navigate = useNavigate();

  if (!isAdminMember || isChannelSetting) {
    return null;
  }

  return (
    <SeetingsWrapper>
      <HeaderWrapper>
        <IconWrapper>
          <DataIcon />
        </IconWrapper>
        <Typography variant='h4'>Data and Insights</Typography>
      </HeaderWrapper>
      <SubSectionWrapper>
        <AdminsSettingsHoc>
          <SubSection onClick={() => navigate(Resources.workspaceSettingsDataProps.path({ workspaceId }))}>
            <SubSectionHeader>
              <Typography variant='h5' component='p'>Properties</Typography>
              <RightChevron />
            </SubSectionHeader>
            <Typography variant='body2'>
              Create and edit properties to segment members.
            </Typography>
          </SubSection>
        </AdminsSettingsHoc>
        <AdminsSettingsHoc>
          <SubSection onClick={() => navigate(Resources.workspaceSettingsSegments.path({ workspaceId }))}>
            <SubSectionHeader>
              <Typography variant='h5' component='p'>Segments</Typography>
              <RightChevron />
            </SubSectionHeader>
            <Typography variant='body2'>
              Use segments to analyze data according to properties.
            </Typography>
          </SubSection>
        </AdminsSettingsHoc>
      </SubSectionWrapper>
    </SeetingsWrapper>
  );
}
