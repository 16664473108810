import React from 'react';
import { Resources } from '@matterapp/routing';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import RewardsOverviewPage from 'routes/pages/rewards-manager/Overview';
import CustomRewardsPage from 'routes/pages/rewards-manager/CustomRewards';
import RewardsReportsPage from 'routes/pages/rewards-manager/Reports';
import RewardsFundingPage from 'routes/pages/rewards-manager/Funding';
import RewardsAddFundingPage from 'routes/pages/rewards-manager/AddFunding';
import RewardsSendWirePage from 'routes/pages/rewards-manager/SendWire';
import { Route } from 'react-router-dom';

export const RewardsManagerOverview = (
  <Route
    exact
    path={Resources.rewardsManager.pattern}
    element={(
      <PrivateRoute adminsOnly>
        <RewardsOverviewPage />
      </PrivateRoute>    
    )}
  />
);

export const RewardsManagerCustomRewards = (
  <Route
    exact
    path={Resources.customRewards.pattern}
    element={(
      <PrivateRoute adminsOnly>
        <CustomRewardsPage />
      </PrivateRoute>    
    )}
  />
);

export const RewardsReports = (
  <Route
    exact
    path={Resources.rewardsReports.pattern}
    element={(
      <PrivateRoute adminsOnly>
        <RewardsReportsPage />
      </PrivateRoute>    
    )}
  />
);

export const RewardsFunding = (
  <Route
    exact
    path={Resources.rewardsFunding.pattern}
    element={(
      <PrivateRoute adminsOnly>
        <RewardsFundingPage />
      </PrivateRoute>    
    )}
  />
);

export const RewardsAddFunding = (
  <Route
    exact
    path={Resources.rewardsAddFunding.pattern}
    element={(
      <PrivateRoute adminsOnly>
        <RewardsAddFundingPage />
      </PrivateRoute>    
    )}
  />
);

export const RewardsSendWire = (
  <Route
    exact
    path={Resources.rewardsSendWire.pattern}
    element={(
      <PrivateRoute adminsOnly>
        <RewardsSendWirePage />
      </PrivateRoute>    
    )}
  />
);
