import React from 'react';
import { Input } from '@matterapp/matter-ui';
import { Box, Typography } from '@mui/material';
import { ACTIONS } from './reducer';
import { InputWrapper } from './styles';

export default function IsBetweenInput({ 
  rule, 
  index, 
  type,
  dispatch
}) {
  const { values } = rule;
  let hasError = false;

  if (values[0] && values[1]) {
    if (type === 'numeric') {
      hasError = parseInt(values[0]) >= parseInt(values[1]);
    } else if (type === 'date') {
      hasError = new Date(values[0]) >= new Date(values[1]);
    }
  }

  return (
    <Box>
      <InputWrapper>
        <Input
          type={type === 'numeric' ? 'number' : 'date'}
          onChange={(e) => dispatch({ type: ACTIONS.UPDATE_SELECTED_VALUES, payload: { index, values: [e.target.value, values[1]] } })}
          value={values[0]} 
          errorMessage={hasError}
          isHideErrorMessage
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          type={type === 'numeric' ? 'number' : 'date'}
          onChange={(e) => dispatch({ type: ACTIONS.UPDATE_SELECTED_VALUES, payload: { index, values: [values[0], e.target.value] } })}
          value={values[1]} 
          disabled={!values[0]}
        />
      </InputWrapper>
      {hasError && (
        <Typography variant='body2' color='error'>
          The first {type === 'date' ? 'date' : 'value'} must be lower than the second.
        </Typography>
      )}
    </Box>
  );
}
