import React, { useEffect, useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Message, Button } from '@matterapp/matter-ui';
import disconnectHrisMutation from 'graphql-queries/mutations/workspaces/disconnectHris';
import updateHrisSettingsMutation from 'graphql-queries/mutations/hris/updateHrisSettings';
import HrisConnectPanel from './HrisConnectPanel';
import { Typography, Box } from '@mui/material';
import HrisFieldsPanel from './HrisFieldsPanel';
import HrisCustomFieldsPanel from './HrisCustomFieldsPanel';
import HrisTeamMappingsPanel from './HrisTeamMappingsPanel';
import DialogContext from 'context/DialogContext/DialogContext';
import Toast from 'components/Toast';
import styled from 'styled-components';
import { SettingsFooter } from '../styles';
import { isEqual } from 'lodash';

const DisconnectButton = styled(Button)(({ theme }) => ({
  ':after': {
    content: '"Disconnect HRIS"',
    [theme.breakpoints.down('sm')]: {
      content: '"Disconnect"'
    }
  }
}));

export default function HrisSettings(props) {
  const { tenant, workspace } = props;
  const { openDialog } = useContext(DialogContext);
  const { subscriptions, hrisSettings } = tenant || {};
  const { status, syncingProps, customFieldsMapping: customPropsSyncMapping = [],  } = hrisSettings || {};
  const [customFieldsMapping, setCustomFieldsMapping] = useState(customPropsSyncMapping || []);
  const [teamsMapping, setTeamsMapping] = useState(hrisSettings?.teamsMapping || []);
  const [syncFields, setSyncFields] = useState(syncingProps || []);
  const hasHris = subscriptions?.some((product ) => product === 'hris');
  const [hrisMutation, { loading: disconnectingHris }] = useMutation(disconnectHrisMutation);
  const [updateHrisSettings, { loading: updatingHris, data: updatedHris }] = useMutation(updateHrisSettingsMutation);
  const [canClickSave, setCanClickSave] = useState(false);

  useEffect(() => {
    setSyncFields(syncingProps || []);
  }, [syncingProps]);
  
  useEffect(() => {
    const hasChanges = !isEqual(syncFields, syncingProps) 
      || !isEqual(customFieldsMapping, customPropsSyncMapping)
      || !isEqual(teamsMapping, hrisSettings?.teamsMapping.map(({ providerDepartmentName, matterTeamId }) => ({ providerDepartmentName, matterTeamId })));

    setCanClickSave(hasChanges);
  }, [syncFields, customFieldsMapping, teamsMapping]);

  useEffect(() => {
    if (tenant) {
      setCustomFieldsMapping(customPropsSyncMapping || []);
    }
  }, [tenant]);

  useEffect(() => {
    if (!updatingHris && updatedHris) {
      Toast.success('Your settings have been saved');
    }
  }, [updatingHris, updatedHris]);

  if (!tenant) { 
    return null; 
  }

  return (
    <Box sx={{ pb: hasHris && status ? 0 : 3 }}>
      <Modal.Panel.Main
        header='HRIS Integration'
        headerActions={hasHris && status ? (
          <DisconnectButton 
            size={'S'} 
            color={'black'} 
            loading={disconnectingHris} 
            onClick={() => {
              openDialog({
                onConfirm: () => {
                  hrisMutation({ variables: { tenantId: tenant.id }});

                  Toast.success(`You’ve successfully disconnected HRIS.`);
                },
                header: 'Are you sure?',
                confirmButtonLabel: 'Yes, Disconnect',
                subHeader: (
                  <>
                    Disconnecting your HRIS will not remove any data from Matter. However, data will not longer be automatically synced with new data from your HRIS. You can always reconnect or connect to another HRIS after disconnecting.
                  </>
                )
              });
            }}
          />
        ) : null}
        footer={hasHris && status ? (
          <SettingsFooter
            canClickPrimary={canClickSave && !updatingHris}
            primaryLabel='Save'
            onClickPrimary={() => {
              updateHrisSettings({
                variables: {
                  tenantId: tenant.id,
                  settings: {
                    syncingProps: syncFields,
                    customFieldsMapping: customFieldsMapping.map(({ hrisField, customPropertyId }) => ({ hrisField, customPropertyId })),
                    teamsMapping: teamsMapping.map(({ providerDepartmentName, matterTeamId }) => ({ providerDepartmentName, matterTeamId }))
                  }
                }
              });

              setCanClickSave(false);
            }}
          />
        ) : null}
      >
        <Typography variant='body1' sx={{ mt: -2 }}>
          Connect your Human Resource Information System (HRIS) for your organization. To learn more, visit the <a href='https://help.matterapp.com/en' target='_blank' rel='noopener noreferrer'> Help Center</a>.
        </Typography>
        {hasHris ? 
          <HrisConnectPanel
            tenant={tenant}
          /> : 
          <Box sx={{ mt: 3 }}>
            <Message.Warning
              showColonAfterHeader
              header='Approval Required'
              content={(
                <>
                  HRIS integration requires a Matter paid plan.&nbsp;
                  <a 
                    target="_blank" 
                    href="mailto:hello@matterapp.com?subject=HRIS integration"
                    rel='noopener noreferrer'
                  >
                    Contact Matter
                  </a> to have HRIS integration capabilities added to your plan.
                </>
              )}
            />
            <Button.Primary.Vivid
              color={'blue'}
              style={{ marginTop: 24 }}
              onClick={() => {
                window.open('mailto:hello@matterapp.com?subject=HRIS integration');
              }}
            >
              Contact Matter
            </Button.Primary.Vivid>
          </Box>
        }
        <HrisFieldsPanel 
          tenant={tenant} 
          hasHris={hasHris} 
          syncFields={syncFields}
          setSyncFields={setSyncFields}
        />
        <HrisCustomFieldsPanel 
          tenant={tenant} 
          hasHris={hasHris} 
          workspaceId={workspace.id}
          setCustomFieldsMapping={setCustomFieldsMapping}
          customFieldsMapping={customFieldsMapping}
        />
        <HrisTeamMappingsPanel 
          tenant={tenant} 
          hasHris={hasHris} 
          workspaceId={workspace.id}
          setTeamsMapping={setTeamsMapping}
          teamsMapping={teamsMapping}
        />
      </Modal.Panel.Main>
    </Box>
  );
}
