import React, { useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import PulseFiltersContext from 'context/PulseFiltersContext/PulseFiltersContext';
import { useQuery } from '@apollo/client';
import { AppLayout } from 'modules';
import getEnpsDetails from 'graphql-queries/queries/analytics/getEnpsDetails';
import { 
  Box,
  Typography
} from '@mui/material';
import { Loader } from '@matterapp/matter-ui';
import { LoaderContainer } from '../styles';
import QuestionComments from './QuestionComments';
import { dateFilters, buildCustomDateRange } from '../dateFilters';
import DateFilterBar from 'components/DateFilterBar/DateFilterBar';
import EnpsScore from '../EnpsScore';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import { Resources } from '@matterapp/routing';

export default function EnpsDetailPage() {
  const { 
    selectedFilter, 
    setSelectedFilter,
    segmentIds,
    handleSegmentChange,
    teamIds,
    handleTeamChange,
    handleWorkspaceChange,
    selectedWorkspaceIds
  } = useContext(PulseFiltersContext);
  const { startDate, endDate } = selectedFilter;

  const { isLoadingCurrentWorkspace, currentTenant, currentWorkspaceId: workspaceId } = useContext(UserContext);

  const { data, loading: loadingEnps } = useQuery(getEnpsDetails, {
    variables: {
      tenantId: currentTenant?.id,
      segmentIds,
      teamIds,
      startDate: startDate.format('YYYY-MM-DD'), 
      endDate: endDate.format('YYYY-MM-DD'),
      workspaceIds: selectedWorkspaceIds
    },
    skip: !currentTenant
  });

  const { enpsDetails = {} } = data || {};
  const { comments } = enpsDetails || {};
  const isLoading = isLoadingCurrentWorkspace || loadingEnps;

  const handleChangeFilter = (value, isCustom) => {
    let selectedValue;

    if (isCustom) {
      selectedValue = buildCustomDateRange(value.from, value.to);
    } else {
      selectedValue = dateFilters.find((filter) => filter.value === value);
    }
    
    if (startDate.format('YYYY-MM-DD') === selectedValue.startDate.format('YYYY-MM-DD') && endDate.format('YYYY-MM-DD') === selectedValue.endDate.format('YYYY-MM-DD')) {
      return;
    }

    setSelectedFilter(selectedValue);
  };

  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={
        isLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <Box sx={{ maxWidth: '950px' }}>
            <BreadCrumbs 
              sx={{ mb: 3 }}
              crumbs={[
                {
                  label: 'Pulse Survey',
                  href: Resources.pulseDashboard.path({ workspaceId })
                },
                {
                  label: 'Employee Net Promoter Score (eNPS)',
                  href: Resources.pulseEnpsDetails.path({ workspaceId })
                }
              ]}
            />
            <DateFilterBar 
              startDate={selectedFilter.startDate}
              endDate={selectedFilter.endDate}
              value={selectedFilter.value}
              onChange={handleChangeFilter}
              groupedBy={selectedFilter.groupBy}
              items={dateFilters}
              onSegmentChange={handleSegmentChange}
              selectedSegmentIds={segmentIds}
              onTeamChange={handleTeamChange}
              selectedTeamIds={teamIds}
              onChannelChange={handleWorkspaceChange}
              selectedWorkspaceIds={selectedWorkspaceIds}
              disclaimer={(
                <Typography variant='body2' component='p' sx={{ color: 'blacks.50' }}>
                  Scores calculated on a 30-day rolling time window (<a href='https://help.matterapp.com/en/articles/8828295-pulse-survey-guide' target='_blank' rel='noopener noreferrer'>learn more</a>).
                </Typography>
              )}
            />
            <EnpsScore 
              analyticsData={enpsDetails || {}}
              workspaceId={workspaceId} 
              sx={{ mb: 3 }}
            />
            <QuestionComments
              comments={comments || []}
            />
          </Box>
        )}
    />
  );
}
