import React, { useContext } from 'react';
import { 
  SettingsContainer
} from '../styles';
import AdminsSettingsHoc from '../AdminsSettingsHoc';
import UserContext from 'context/UserContext/UserContext';
import { AppLayout } from 'modules';
import { Resources } from '@matterapp/routing';
import PulseSettingsPage from './PulseSettings';
import { useNavigate } from 'react-router-dom';
import SettingsNavigation from '../SettingsNavigation';
import styled from 'styled-components';

const StyledSettingsContainer = styled(SettingsContainer)(({ theme }) => ({
  '.MuiPaper-root': {
    padding: theme.spacing(3)
  }
}));

export default function RewardsSettings() {  
  const { currentWorkspaceId: workspaceId, currentTenant, currentWorkspace, isLoadingCurrentUser } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={(
        <AdminsSettingsHoc redirectUrl={Resources.workspaceSettingsMain.path({ workspaceId })}>
          <StyledSettingsContainer>
            <SettingsNavigation currentLabel='Pulse' />
            {!currentWorkspace 
              ? null 
              : (
                <PulseSettingsPage 
                  tenant={currentTenant}
                  workspace={currentWorkspace}
                  isLoading={isLoadingCurrentUser}
                  onClickCancel={() => navigate(Resources.workspaceSettingsMain.path({ workspaceId }))}
                />
              )}
          </StyledSettingsContainer>
        </AdminsSettingsHoc>
      )}
    />
  );
}
