import React, { useEffect, useContext, useState } from 'react';
import { Typography, Paper, Divider, Box } from '@mui/material';
import UserContext from 'context/UserContext/UserContext';
import { Checkbox, Dropdown } from '@matterapp/matter-ui';
import searchWorkspaceMembers from 'graphql-queries/queries/workspace/searchWorkspaceMembers';
import getTenantMembersByPersonIds from 'graphql-queries/queries/workspace/getTenantMembersByPersonIds';
import getTenantMembers from 'graphql-queries/queries/workspace/getMembers';
import { useLazyQuery, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { ACTIONS } from './reducer';
import { debounce } from 'lodash';
import { getRecipients } from './consts';
import { StyledRadio } from './styles';

const TopSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3)
}));
  
const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '100%',
  maxWidth: '560px',
  '&:nth-child(even)': {
    paddingTop: 0
  }
}));

const SearchInput = styled(Dropdown.Email.Tags)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
  'input&': {
    borderColor: theme.palette.blacks[30],
    borderRadius: theme.spacing(1),
    borderWidth: '1px'
  }
}));

const ToggleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.blacks[5],
  width: '100%',
  padding: theme.spacing(2),
  margin: `${theme.spacing(2)} 0`
}));

const debouncedSearch = debounce((searchString, tenantId, query) => {
  query({
    variables: {
      tenantId,
      searchString,
      excludeCurrentUser: false
    },
    fetchPolicy: 'network-only'
  });
}, 300);

export default function AudienceScreen(props) {
  const { state, dispatch } = props;
  let matterTeams = props.matterTeams || [];
  const { currentTenant, currentWorkspace, currentUser } = useContext(UserContext);
  const { fullName, slackHandle } = currentUser || {};
  const { id: tenantId } = currentTenant || {};
  const { sendOnMyBehalf, sendReminder, recipients, showErrors } = state;
  const [inputFilter, setInputFilter] = useState();
  const { data: membersData } = useQuery(getTenantMembers, {
    variables: {
      tenantId,
      limit: 10
    }
  });

  matterTeams = matterTeams.map(({ id, name }) => ({
    label: name,
    value: `matterTeam-${id}`,
    subLabel: ''
  }));

  const [searchMembers, { data }] = useLazyQuery(searchWorkspaceMembers);
  const [getMembers, { data: searchMembersData }] = useLazyQuery(getTenantMembersByPersonIds);
  const initialMembers = searchMembersData?.tenantMembersByPersonIds;

  let globalItems = getRecipients(currentTenant, currentWorkspace);

  if (inputFilter) {
    globalItems = globalItems.filter(({ label }) => label.toLowerCase().includes(inputFilter.toLowerCase()));
  }
  
  const members = data?.searchMembers || membersData?.workspaceMembers?.members || [];
  const SENDER_OPTIONS = [`My Organization (${currentTenant?.name})`, slackHandle ? `${fullName} (@${slackHandle})` : fullName];
  const items = [...globalItems, ...matterTeams, ...members.map((member) => {
    return {
      label: member.person.fullName,
      value: member.person.id,
      subLabel: member.person.email
    };
  })];

  useEffect(() => {
    const personIds = recipients
      .map(({ value }) => parseInt(value, 10))
      .filter(Boolean);

    if (personIds.length) {
      getMembers({
        variables: {
          personIds,
          tenantId
        }
      });
    }
  }, []);

  function onChange(_, { value: updatedList }) {
    if (updatedList.length < recipients.length) {
      const removedRecepient = recipients.find((recipient) => !updatedList.find(value => value === recipient.value));

      dispatch({ type: ACTIONS.REMOVE_RECIPIENT, payload: removedRecepient });
    }
  }

  function onSelect(_, item ) {
    if (!item?.value.map) {
      dispatch({ type: ACTIONS.ADD_RECIPIENT, payload: item.item });
    }
  }

  return (
    <Box>
      <Paper sx={{ padding: 0, mt: 3, ml: 1, mr: 1 }}>
        <TopSection>
          <Typography variant='h5' component='p'>
            Audience
          </Typography>
        </TopSection>
        <Divider sx={{ margin: 0, mb: 1 }} />
        <Section>
          <Typography variant='h5' component='p'>
            Survey Sender:
          </Typography>
          <StyledRadio 
            onChange={() => {
              dispatch({ type: ACTIONS.TOGGLE_SENDER });
            }}
            value={sendOnMyBehalf ? SENDER_OPTIONS[1] : SENDER_OPTIONS[0]}
            values={SENDER_OPTIONS}
          />
        </Section>
        <Section>
          <Typography variant='h5' component='p'>
            Survey Recipients:
          </Typography>
          <SearchInput
            filterItems
            formatTagLabel={(value) => {
              let label = [...recipients.filter(({ label }) => !!label), ...globalItems, ...matterTeams].find((item) => item.value === value)?.label;
              
              if (!label) {
                label = initialMembers?.find((member) => member.person.id == value)?.person.fullName;
              }

              return label;
            }}
            items={items}
            isValidValue={() => true}
            onChange={onChange}
            errorMessage={showErrors ? true : ''}
            onChangeInput={(e, { value }) => { 
              setInputFilter(value);
              debouncedSearch(value, tenantId, searchMembers);
            }}
            onSelect={onSelect}
            placeholder='Select your entire org, channel(s), or user(s).'
            fluid
            disabled={recipients[0]?.value === 'ALL'}
            showValuesAsSubLabels={false}
            value={recipients.map(({ value }) => value)}
          />
          {showErrors && (
            <Typography variant='body2' component='p' color='error' sx={{ mt: 0.5 }}>
              Please select at least one recipient
            </Typography>
          
          )}
        </Section>
      </Paper>
      <Paper sx={{ padding: 0, mt: 3, mb: '187px', ml: 1, mr: 1 }}>
        <TopSection>
          <Typography variant='h5' component='p'>
            Recipient Reminders
          </Typography>
        </TopSection>
        <Divider sx={{ margin: 0, mb: 1 }} />
        <Section>
          <Typography variant='body1' component='p'>
            Increase participation by enabling reminders.
          </Typography>
          <ToggleContainer>
            <Typography variant='body1' component='p' >
              Reminders to Recipients
            </Typography>
            <Checkbox.Toggle
              size='S'
              checked={sendReminder}
              onChange={() => {
                dispatch({ type: ACTIONS.TOGGLE_REMINDER });
              }}
            />
          </ToggleContainer>
          <Typography variant='body2' component='p' color='blacks.50'>
            Matter will send smart reminders at optimal times per participant.
          </Typography>
        </Section>
      </Paper>
    </Box>
  );
}