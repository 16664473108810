import React, { useContext, useState, useReducer } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { AppLayout } from 'modules';
import ContainerNavigation from './ContainerNavigation';
import { useMutation } from '@apollo/client';
import updateMemberProperties from 'graphql-queries/mutations/workspaceMembers/updateMemberProperties';
import getCurrentMemberProperties from 'graphql-queries/queries/rewards/getCurrentMemberProperties';
import { Typography, Box } from '@mui/material';
import styled from 'styled-components';
import { Dropdown, Button, Tooltip, Message } from '@matterapp/matter-ui';
import { 
  monthsMap, 
  getDaysInMonthList,
  getMemberBirthdayMonthAndDay,
  getMemberAnniversaryMonthDayAndYear,
  getYearsList,
  monthsOptions
} from 'routes/pages/settings/Members/helpers';
import { CELEBRATION_OPTIONS } from 'routes/pages/settings/MemberProperties/helpers';
import { getInitialState, memberPropsReducer, ACTIONS } from 'routes/pages/settings/MemberProperties/reducer';
import { useEffect } from 'react';
import Toast from 'components/Toast';
import ContinuousIcon from 'routes/pages/settings/icons/continuous';

const Header = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

const EntryWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4)
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: 'fit-content',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  svg: {
    height: '28px',
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5)
  }
}));

const MonthDropdown = styled(Dropdown)(({ theme }) => ({
  width: '190px'
}));

const CelebrationDropdown = styled(Dropdown)(({ theme }) => ({
  width: '315px',
  maxWidth: '100%'
}));

const DropdownWrapper = styled.div(({ theme }) => ({
  marginRight: theme.spacing(2),
  'input:disabled': {
    backgroundColor: `${theme.palette.blacks[10]}!important`,
    color: theme.palette.blacks[90]
  }
}));

const DayDropdown = styled(MonthDropdown)(({ theme }) => ({
  width: '110px'
}));

const YearDropdown = styled(MonthDropdown)(({ theme }) => ({
  width: '140px'
}));

const SaveButton = styled(Button.Primary.Vivid)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(4)
}));

const ClearButton = styled.div(() => ({
  color: 'red',
  cursor: 'pointer',
  fontSize: '17px',
  fontWeight: 'bold'
}));

const LineWrapper = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'flex-start',
  width: '100%',
  button: {
    alignSelf: 'center'
  }
}));

function getBirthdayString(day, month) {
  if (!day || !month) {
    return null;
  }
  return `${month} ${day}`;
};

function getHiredOnString(year, month, day) {
  if (!year || !month || !day) {
    return null;
  }

  let monthNumber = monthsOptions.indexOf(month) + 1;
  let dayNumber = parseInt(day);

  if (monthNumber < 10) {
    monthNumber = `0${monthNumber}`;
  }

  if (dayNumber < 10) {
    dayNumber = `0${dayNumber}`;
  }

  return `${year}-${monthNumber}-${dayNumber}`;
}

export default function UserSettingsCelebrations() {
  const { memberProperties, currentTenant, currentWorkspace, currentUser } = useContext(UserContext);
  const { celebrationSettings } = currentWorkspace || {};
  const { usersCanModifyCelebrationDates } = celebrationSettings || {};
  const { hrisSettings } = currentTenant || {};
  const { syncingProps = [] } = hrisSettings || {};
  const [birthDay, setBirthDay] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [hiredDay, setHiredDay] = useState('');
  const [hiredMonth, setHiredMonth] = useState('');
  const [hiredYear, setHiredYear] = useState('');
  const [hiredOn, setHiredOn] = useState('');
  const [updateMemberPropertiesMutation, { loading }] = useMutation(updateMemberProperties);
  const updatedBirthday = getBirthdayString(birthDay, birthMonth);
  const birthdayChanged = updatedBirthday !== memberProperties?.birthday;
  const hiredOnChanged = hiredOn !== memberProperties?.hiredOn;
  const [state, dispatch] = useReducer(memberPropsReducer, getInitialState({}));
  const { settings, canSave: canSaveSettings } = state;
  const { 
    birthdayVisibility, 
    anniversaryVisibility
  } = settings;
  const canSave = birthdayChanged || hiredOnChanged || canSaveSettings;
  const isBirthdaysActive = currentTenant?.workspaces.find(({ celebrationSettings }) => celebrationSettings?.isBirthdaysActive);
  const isAnniversariesActive = currentTenant?.workspaces.find(({ celebrationSettings }) => celebrationSettings?.isAnniversariesActive);
  const celebrationsEnabled = isBirthdaysActive || isAnniversariesActive;
  
  useEffect(() => {
    if (memberProperties) {
      const [birthdayMonth, birthDay] = getMemberBirthdayMonthAndDay(memberProperties);
      const [hiredYear, hiredMonth, hiredDay] = getMemberAnniversaryMonthDayAndYear(memberProperties);
 
      setBirthDay(birthDay);
      setBirthMonth(birthdayMonth);
      setHiredDay(hiredDay);
      setHiredMonth(hiredMonth);
      setHiredYear(hiredYear);

      setHiredOn(memberProperties.hiredOn);

      dispatch({ type: ACTIONS.RESET_INITIAL_SETTINGS, payload: { member: memberProperties } });
    }
  }, [memberProperties]);

  useEffect(() => {
    setHiredOn(getHiredOnString(hiredYear, hiredMonth, hiredDay));
  }, [hiredDay, hiredMonth, hiredYear]);

  async function onSave() {
    const variables = {
      personId: currentUser.personId,
      tenantId: currentTenant.id,
      memberProperties: {
        birthday: getBirthdayString(birthDay, birthMonth),
        hiredOn,
        birthdayVisibility,
        anniversaryVisibility
      }
    };

    await updateMemberPropertiesMutation({
      variables,
      refetchQueries: [{
        query: getCurrentMemberProperties,
        variables: {
          tenantId: currentTenant.id
        }
      }]
    });

    Toast.success(`Successfully updated your settings`);
  }

  if (!memberProperties) {
    return null;
  }

  return (
    <>
      <AppLayout
        layout={AppLayout.Layouts.TwoColumnFixedLeft}
        oldLayoutVersion={1}
        containerNavigation={<ContainerNavigation />}
      >
        <Box sx={{ maxWidth: '480px' }}>
          <Header variant="h3">
            Celebrations
          </Header>
          {celebrationsEnabled ? (
            <>
              <Typography variant="body1">
                Enter one or more of your celebration dates so Matter can celebrate your special day with your team.
              </Typography>
              {isBirthdaysActive && (
                <EntryWrapper>
                  <IconWrapper>
                    <Typography variant="h3">
                      Birthday
                    </Typography>
                    {syncingProps.includes('birthday') && (
                      <Tooltip
                        dark
                        content='This property automatically syncs with your HRIS.'
                        style={{ marginLeft: '4px' }}
                      >
                        <ContinuousIcon />
                      </Tooltip>
                    )}
                  </IconWrapper>
                  <LineWrapper>
                    <DropdownWrapper>
                      <MonthDropdown
                        value={birthMonth}
                        items={monthsMap}
                        disabled={syncingProps.includes('birthday') || !usersCanModifyCelebrationDates}
                        onChange={(_, { value }) => {
                          setBirthMonth(value);
                        }}
                        placeholder="Month"
                        size={Dropdown.sizes.M}
                        showSelectedItemInMenu
                      />
                    </DropdownWrapper>
                    <DropdownWrapper>
                      <DayDropdown
                        value={birthDay}
                        disabled={syncingProps.includes('birthday') || !usersCanModifyCelebrationDates}
                        items={getDaysInMonthList(birthMonth)}
                        onChange={(_, { value }) => {
                          setBirthDay(value);
                        }}
                        placeholder="Day"
                        size={Dropdown.sizes.M}
                        showSelectedItemInMenu
                      />
                    </DropdownWrapper>
                    {birthDay && birthMonth && !syncingProps.includes('birthday') && usersCanModifyCelebrationDates ? (
                      <ClearButton onClick={() => {
                        setBirthDay('');
                        setBirthMonth('');
                      }}>
                        X
                      </ClearButton>
                    ) : null}
                  </LineWrapper>
                </EntryWrapper>
              )}
              {isAnniversariesActive && (
                <EntryWrapper>
                  <IconWrapper>
                    <Typography variant="h3">
                      Work Anniversary
                    </Typography>
                    {syncingProps.includes('hired_on') && (
                      <Tooltip
                        dark
                        content='This property automatically syncs with your HRIS.'
                        style={{ marginLeft: '4px' }}
                      >
                        <ContinuousIcon />
                      </Tooltip>
                    )}
                  </IconWrapper>
                  <LineWrapper>
                    <DropdownWrapper>
                      <MonthDropdown
                        disabled={syncingProps.includes('hired_on') || !usersCanModifyCelebrationDates}
                        value={hiredMonth}
                        items={monthsMap}
                        onChange={(_, { value }) => {
                          setHiredMonth(value);
                        }}
                        placeholder="Month"
                        size={Dropdown.sizes.M}
                        showSelectedItemInMenu
                      />
                    </DropdownWrapper>
                    <DropdownWrapper>
                      <DayDropdown
                        value={hiredDay}
                        disabled={syncingProps.includes('hired_on') || !usersCanModifyCelebrationDates}
                        items={getDaysInMonthList(hiredMonth)}
                        onChange={(_, { value }) => {
                          setHiredDay(value);
                        }}
                        placeholder="Day"
                        size={Dropdown.sizes.M}
                        showSelected
                        ItemInMenu
                      />
                    </DropdownWrapper>
                    <DropdownWrapper>
                      <YearDropdown
                        disabled={syncingProps.includes('hired_on') || !usersCanModifyCelebrationDates}
                        value={hiredYear}
                        items={getYearsList()}
                        onChange={(_, { value }) => {
                          setHiredYear(value);
                        }}
                        placeholder="Year"
                        showSelectedItemInMenu
                      />
                    </DropdownWrapper>
                    {hiredOn && !syncingProps.includes('hired_on') && usersCanModifyCelebrationDates ? (
                      <ClearButton onClick={() => {
                        setHiredDay('');
                        setHiredMonth('');
                        setHiredYear('');
                      }}>
                        X
                      </ClearButton>
                    ) : null}
                  </LineWrapper>
                </EntryWrapper>
              )}
              <EntryWrapper>
                <Typography variant="h3" sx={{ mb: 1 }}>
                  Public Recognition
                </Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>
                  Choose how you would like to be recognized. 
                </Typography>
                {isBirthdaysActive && (
                  <LineWrapper>
                    <DropdownWrapper>
                      <Typography variant='body1' sx={{ fontWeight: 500, mb: 1 }}>
                      Birthday
                      </Typography>
                      <CelebrationDropdown
                        value={birthdayVisibility}
                        items={CELEBRATION_OPTIONS}
                        onChange={(_, { value }) => {
                          dispatch({ type: ACTIONS.UPDATE_BIRTHDAY_VISIBILITY, payload: value });
                        }}
                        placeholder="Month"
                        size={Dropdown.sizes.M}
                        showSelectedItemInMenu
                      />
                    </DropdownWrapper>
                  </LineWrapper>
                )}
                {isAnniversariesActive && (
                  <LineWrapper sx={{ mt: 3 }}>
                    <DropdownWrapper>
                      <Typography variant='body1' sx={{ fontWeight: 500, mb: 1 }}>
                        Work Anniversary
                      </Typography>
                      <CelebrationDropdown
                        value={anniversaryVisibility}
                        items={CELEBRATION_OPTIONS}
                        onChange={(_, { value }) => {
                          dispatch({ type: ACTIONS.UPDATE_ANNIVERSARY_VISIBILITY, payload: value });
                        }}
                        placeholder="Month"
                        size={Dropdown.sizes.M}
                        showSelectedItemInMenu
                      />
                    </DropdownWrapper>
                  </LineWrapper>
                )}
              </EntryWrapper>
              <SaveButton
                submitting={loading}
                onClick={onSave}
                fluid
                size="large"
                disabled={!canSave}
              >
                Save
              </SaveButton>
            </>
          ) : (
            <Message.Warning
              showColonAfterHeader
              content='Celebrations have been disabled by your Matter admin. Contact your Matter admin to enable them in your workspace.'
            />
          )}
        </Box>
      </AppLayout>
    </>
  );
}
