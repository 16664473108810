import styled from 'styled-components';
import {  Button, IconNew, Checkbox, Input, Modal } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';
import { Box } from '@mui/material';

export const DividerWrapper = styled(Box)(({ theme }) => ({
  position: 'relative'
}));

export const LogicOperator = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '45%',
  top: '30%',
  border: `1px solid ${theme.colors.blacks[50]}`,
  borderRadius: '4px',
  color: theme.colors.blacks[50],
  fontWeight: 500,
  backgroundColor: theme.colors.blacks[5],
  padding: `0 ${theme.spacing(1)}`
}));

export const StyledInput = styled(Input)(() => ({
  cursor: 'pointer',
  width: '100%',
  display: 'inline-block',
  color: theme.colors.black,
  boxSizing: 'border-box',
  textAlign: 'left',
  border: `${theme.inputs.borderWidth} ${theme.inputs.borderStyle} ${theme.inputs.borderColor}`,
  borderRadius: theme.inputs.borderRadius,
  '&:focus': {
    color: theme.colors.black,
    background: 'white',
    borderColor: theme.inputs.borderActiveColor,
    boxShadow: '0px 2px 4px rgba(52, 55, 153, 0.2)',
  },
  '&::placeholder': {
    color: theme.inputs.placeholderColor
  },
  fontSize: theme.fontSize.S,
  lineHeight: theme.lineHeight.M,
  padding: theme.spacing.threeQuarters
}));

export const InputWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  width: '100%',
  input: {
    width: '100%',
    [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    }
  }
}));

export const EditButton = styled(Button)(() => ({
  fontSize: theme.fontSize.base,
  lineHeight: theme.fontSize.base,
  padding: theme.spacing.half,
  minHeight: 0
}));

export const EditWrapper = styled.div({
  display: 'flex',
  alignItems: 'baseline'
});

export const StyledIcon = styled(IconNew)(() => ({
  marginLeft: theme.spacing.half
}));

export const SubHeader = styled.h4(() => ({
  fontWeight: 'bold',
  fontSize: theme.fontSize.S
}));

export const LineWrapper = styled.div(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'flex-end',
  marginBottom: theme.spacing.half,
  width: '100%',
  button: {
    alignSelf: 'center'
  }
}));

export const MemberSettingWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing.double,
  width: '100%',
  [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    padding: theme.spacing.single
  }
}));

export const MemberInfo = styled.p(() => ({
  fontWeight: 500,
  fontSize: theme.fontSize.S
}));

export const BirthdayWrapper = styled.div(() => ({
  display: 'flex',
  marginLeft: theme.spacing.single,
  width: '50%',
  '> div:first-child': {
    width: '80%',
    marginRight: theme.spacing.half
  },
  '> div:nth-child(2)': {
    width: '35%'
  }
}));

export const RadioList = styled(Checkbox.RadioList.Settings)(() => ({
  [`& ${Checkbox.RadioList.Label}`]: {
    lineHeight: theme.spacing.single,
    fontWeight: 'bold'
  }
}));

export const ClearButton = styled.div(() => ({
  position: 'absolute',
  color: 'red',
  right: '-19px',
  top: '20%',
  cursor: 'pointer',
  fontSize: '17px',
  fontWeight: 'bold'
}));

export const DropdownWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));

export const RulesWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: theme.spacing(1),
  '> div': {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '40%'
    }
  }
}));

export const LineItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}));

export const StyledModalBody = styled(Modal.Body)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  overflow: 'visible',
  '& > div:first-child': {
    overflowY: 'visible'
  }
}));

export const StyledModal = styled(Modal)(({ theme }) => ({
  '.ReactModal__Content': {
    overflow: 'visible',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      borderRadius: 0
    }
  }
}));
