import CreateScreen from './CreateScreen';
import BuilderScreen from './Builder/BuilderScreen';
import AudienceScreen from './AudienceScreen';
import ScheduleScreen from './ScheduleScreen';
import WrapupScreen from './WrapupScreen';
import moment from 'moment-timezone';
import { feedbackFridayUtils } from '@matterapp/utils';

function isDateToday(date) {
  return moment(date).isSame(moment(), 'day');
}

export function getDeliveryTimes(date, timezone) {
  const maxEndHour = 23;
  let hour = 0;
  let minutes = '00';
  const result = [];

  if (isDateToday(date)) {
    const currentHour = moment().tz(timezone).hour();
    const currentMinutes = moment().tz(timezone).minutes();

    if (currentMinutes >= 30) {
      hour = currentHour + 1;
    } else {
      hour = currentHour;
      minutes = '30';
    }
  }
  
  while (hour < maxEndHour ) {
    const value = `${hour}:${minutes}`;

    result.push({
      value,
      label: feedbackFridayUtils.getUSFormattedTime(value)
    });
  
    if (minutes === '00') {
      minutes = '30';
    } else {
      hour++;
      minutes = '00';
    }
  }
  const value = `${hour}:${minutes}`;

  result.push({
    value,
    label: feedbackFridayUtils.getUSFormattedTime(value)
  });

  return result;
}

export const SCREEN_NAMES = {
  CREATE: 'CREATE',
  BUILDER: 'BUILDER',
  AUDIENCE: 'AUDIENCE',
  SCHEDULE: 'SCHEDULE',
  WRAP_UP: 'WRAP_UP'
};

export const FREQUENCY_OPTIONS = [
  { value: 'once', label: 'Does not repeat'},
  { value: 'daily', label: 'Daily', showWeekdays: true },
  { value: 'weekly', label: 'Weekly', showWeekdays: true, repeat: { label: 'Week', value: 'week', min: 1, max: 99 } },
  { value: 'monthly', label: 'Monthly', repeat: { label: 'Month', value: 'month', min: 1, max: 12 }, sendOnDay: true  },
  { value: 'yearly', label: 'Yearly', sendOnDay: true },
];

export const SCREENS = {
  [SCREEN_NAMES.CREATE]: CreateScreen,
  [SCREEN_NAMES.BUILDER]: BuilderScreen,
  [SCREEN_NAMES.AUDIENCE]: AudienceScreen,
  [SCREEN_NAMES.SCHEDULE]: ScheduleScreen,
  [SCREEN_NAMES.WRAP_UP]: WrapupScreen
};

export const SCREENS_ORDER = [
  SCREEN_NAMES.CREATE,
  SCREEN_NAMES.BUILDER,
  SCREEN_NAMES.AUDIENCE,
  SCREEN_NAMES.SCHEDULE,
  SCREEN_NAMES.WRAP_UP
];

export const QUESTION_TYPES = {
  TEXT: 'text',
  MULTIPLE_CHOICE: 'select',
  AGREE_DISAGREE: 'agreeDisagree',
  ENPS: 'enps'
};

// If particular day, say Monday, first appears on the second week of the month,
// it is the first Monday of the month
function getWeekdayFirstOccurenceWeekNumber(date) {
  const dayOfMonth = moment(date).day();
  const firstDayOfMonth = moment(date).startOf('month').day();

  if (dayOfMonth <= firstDayOfMonth) {
    return 1;
  }

  return 2;
}

function getWeekOfMonth(date) {
  const firstWeek = getWeekdayFirstOccurenceWeekNumber(date);
  const dayOfMonth = moment(date).date();
  const weekOfMonth = Math.ceil(dayOfMonth / 7) - (firstWeek - 1);
  let isLastWeek = false;

  if (weekOfMonth >= 4) {
    const lastDayOfMonth = moment(date).endOf('month').date();

    isLastWeek = dayOfMonth + 7 > lastDayOfMonth;
  }

  if (isLastWeek) {
    return {
      number: weekOfMonth,
      label: 'last'
    };
  }

  const mapNumberToWeek = {
    0: 'first',
    1: 'second',
    2: 'third',
    3: 'fourth'
  };

  return {
    number: weekOfMonth,
    label: mapNumberToWeek[weekOfMonth]
  };
}

export function parseFrequencyConfig(config, frequency) {
  if (!config) {
    return {
      sendOnDay: null,
      sendOnDays: []
    };
  }

  const { sendOnDay } = config;
 
  if (!sendOnDay) {
    return config;
  }
  
  const { value } = sendOnDay;

  const sendDate = config.sendDate ? moment(config.sendDate) : moment();
  const options = getRepeatOptions(frequency, sendDate);

  if (!options.length) {
    return config;
  }

  const option = options.find((option) => option.value === value);

  return {
    ...config,
    sendOnDay: option
  };
}

export function getRepeatOptions(frequency, date) {
  const momentDate = moment(date || new Date());
  const options = FREQUENCY_OPTIONS.find((option) => option.value === frequency);

  if (!options?.sendOnDay) {
    return [];
  }

  if (options.value === 'monthly') {
    return [
      {
        label: `On day ${moment(momentDate).format('D')}`,
        value: 'day',
        number: moment(momentDate).date()
      },
      {
        label: `On the ${getWeekOfMonth(momentDate).label} ${moment(momentDate).format('dddd')}`,
        value: 'weekday',
        number: getWeekOfMonth(momentDate).number,
        weekday: moment(momentDate).format('dddd')
      }
    ];
  }

  if (options.value === 'yearly') {
    return [
      {
        label: `On ${moment(momentDate).format('MMMM D')}`,
        value: 'day',
        number: moment(momentDate).date()
      },
      {
        label: `On the ${getWeekOfMonth(momentDate).label} ${moment(momentDate).format('dddd')} of ${moment(momentDate).format('MMMM')}`,
        value: 'weekday',
        number: getWeekOfMonth(momentDate).number,
        weekday: moment(momentDate).format('dddd')
      }
    ];
  }
}

export function getfrequencyConfig(state) {
  const {
    frequency,
    sendNow,
    sendDate,
    frequencyConfig: {
      sendOnDays
    }
  } = state;

  const option = FREQUENCY_OPTIONS.find(({ value }) => value === frequency);
  const frequencyConfig = {
    repeatValue: null,
    sendOnDay: null,
    sendOnDays: sendOnDays || []
  };

  if (option.showWeekdays && !sendOnDays?.length) {
    if (option.value === 'weekly') {
      frequencyConfig.sendOnDays.push(moment().format('dddd'));
    } else if (option.value === 'daily') {
      frequencyConfig.sendOnDays.push('Monday');
      frequencyConfig.sendOnDays.push('Tuesday');
      frequencyConfig.sendOnDays.push('Wednesday');
      frequencyConfig.sendOnDays.push('Thursday');
      frequencyConfig.sendOnDays.push('Friday');
    }
  }

  if (option.repeat) {
    frequencyConfig.repeatValue = 1;
  }

  if (option.sendOnDay) {
    frequencyConfig.sendOnDay = getRepeatOptions(option.value, sendNow || !sendDate ? moment() : moment(sendDate))[0];
  }

  return {
    ...state.frequencyConfig,
    ...frequencyConfig
  };
}

export const QUESTION_DETAILS = {
  [QUESTION_TYPES.TEXT]: {
    type: QUESTION_TYPES.TEXT,
    label: 'Text',
    category: 'custom',
    question: '',
    required: false,
    comments: false,
    maxLength: 250,
    errors: []
  },
  [QUESTION_TYPES.MULTIPLE_CHOICE]: {
    type: QUESTION_TYPES.MULTIPLE_CHOICE,
    label: 'Multiple Choice',
    question: '',
    maxLength: 250,
    category: 'custom',
    answerOptions: [{
      label: ''
    },
    {
      label: ''
    }],
    required: false,
    comments: false,
    errors: []
  },
  [QUESTION_TYPES.AGREE_DISAGREE]: {
    type: QUESTION_TYPES.AGREE_DISAGREE,
    label: 'Agree/Disagree',
    category: 'custom',
    question: '',
    maxLength: 250,
    required: false,
    comments: false,
    errors: []
  },
  [QUESTION_TYPES.ENPS]: {
    type: QUESTION_TYPES.ENPS,
    category: 'ENPS',
    label: 'eNPS',
    question: 'I would recommend my company as a great place to work.',
    maxLength: 250,
    required: false,
    comments: false,
    errors: []
  }
};

export function isQuestionCompleted(question) {
  switch (question.type) {
  case QUESTION_TYPES.TEXT: 
  case QUESTION_TYPES.AGREE_DISAGREE:
  case QUESTION_TYPES.ENPS: {
    return question.question?.trim().length > 0 && question.question?.length <= 250;
  }

  case QUESTION_TYPES.MULTIPLE_CHOICE: {
    return question.question?.length > 0 && question.question?.length <= 250 && question.answerOptions.every((option) => option.label?.trim().length > 0 && option.label?.trim().length <= 65) && question.answerOptions.length > 1;
  }

  default: {
    return false;
  }
  }
}

export function isCreateScreenCompleted(survey) {
  const { name, description } = survey;

  return name?.trim().length >= 5 && name?.trim().length <= 255 && description?.trim().length >= 25 && description?.trim().length <= 400;
}

export function isBuilderScreenCompleted(survey) {
  return survey.questions?.length > 0 && survey.questions.every(isQuestionCompleted);
}

export function isAudienceScreenCompleted(survey) {
  return survey.recipients?.length > 0;
}

export function isScheduleScreenCompleted(survey) {
  const { sendNow, sendDate, sendTime, closeSurveyOnDate, closingDate, timezone } = survey;

  if (!sendNow) {
    const tz = moment().tz(timezone).format('z');
    const sendDateValue = moment(`${sendDate} ${sendTime}:00 ${tz}`);

    if (!sendDate) {
      return false;
    }

    if (sendDate && moment().tz(timezone).isAfter(sendDateValue)) {
      return false;
    }
  }

  if (closeSurveyOnDate) {
    if (!closingDate) {
      return false;
    }

    if (moment().isAfter(moment(closingDate))) {
      return false;
    }    

    if (!sendNow && sendDate) {
      return moment(closingDate).isAfter(moment(sendDate));
    }
  }

  return true;
}

export function getCompletedScreens(survey = {}) {
  const completedScreens = [];

  if (isCreateScreenCompleted(survey)) {
    completedScreens.push(SCREEN_NAMES.CREATE);
  }

  if (isBuilderScreenCompleted(survey)) {
    completedScreens.push(SCREEN_NAMES.BUILDER);
  }

  if (isAudienceScreenCompleted(survey)) {
    completedScreens.push(SCREEN_NAMES.AUDIENCE);
  }

  if (isScheduleScreenCompleted(survey)) {
    completedScreens.push(SCREEN_NAMES.SCHEDULE);
  }

  return completedScreens;
}

export function getRecipients(tenant, currentWorkspace, sentTo, matterTeams = []) {
  if (!tenant || !currentWorkspace) {
    return [];
  }

  const { isEnterprise, workspaces } = tenant || {};
  const { activeMembersCount, slackWorkspace, teamsWorkspace } = currentWorkspace;
  const channelName = slackWorkspace ? `#${slackWorkspace.primaryChannel}` : teamsWorkspace?.channelName;
  const recipients = [];

  if (isEnterprise) {
    const channels = workspaces.map((workspace) => {
      const { slackWorkspace, teamsWorkspace, id } = workspace;

      if (slackWorkspace) {
        return {
          label: `#${slackWorkspace.primaryChannel}`,
          value: `channel-${id}`,
          subLabel: ''
        };
      } else if (teamsWorkspace) {
        return {
          label: teamsWorkspace.teamName,
          value: `channel-${id}`,
          subLabel: ''
        };
      }

    });
    recipients.push({
      label: `My Organization (${tenant.name})`,
      value: 'ALL',
      subLabel: ''
    });
    recipients.push(...channels);
  } else {
    recipients.push({
      label: `Everyone in ${channelName} (${sentTo || activeMembersCount} members)`,
      value: 'ALL',
      subLabel: ''
    });
  }

  if (matterTeams?.length) {
    matterTeams.forEach(({ id, name }) => {
      recipients.push({
        label: name,
        value: `matterTeam-${id}`,
        subLabel: ''
      });
    });
  }

  return recipients;
}