import React from 'react';
import PropTypes from 'prop-types';
import { ToggleContainer, ToggleWrapper, ToggleUnit } from 'components/ToggleGroup';
import { StyledPaper } from '../styles';
import { Typography } from '@mui/material';

export default function ApprovalSection(props) {
  const { autoApprove, notifyAdmins, onChange } = props;

  return (
    <StyledPaper>
      <Typography variant='h3' sx={{ mb: 3 }}>Approval Process</Typography>
      <Typography variant='body1'>
        By default admins need to manually approve reward requests. You can enable reward requests to be automatically approved.
      </Typography> 
      <ToggleWrapper>
        <ToggleContainer>
          Automatically Approve Rewards
          <ToggleUnit 
            checked={autoApprove}
            onChange={() => onChange(!autoApprove, false)}
          />
        </ToggleContainer>
        {autoApprove ? (
          <ToggleContainer>
            Notify Admins On All Redemptions
            <ToggleUnit 
              checked={notifyAdmins}
              onChange={() => onChange(autoApprove, !notifyAdmins)}
            />
          </ToggleContainer>
        ) : null}
      </ToggleWrapper>
    </StyledPaper>
  );
}

ApprovalSection.propTypes = {
  autoApprove: PropTypes.bool,
  notifyAdmins: PropTypes.bool,
  onChange: PropTypes.func
};
