import React, { useEffect, useRef, useState, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import { Loader, IconNew } from '@matterapp/matter-ui';
import theme from '@matterapp/matter-theme';
import { useFeedItemRenderProps } from './hooks';
import {
  FEEDITEM_THANKS_MUTATION,
  ADD_FEEDITEM_COMMENT_MUTATION,
  DELETE_FEEDITEM_COMMENT_MUTATION,
  DELETE_FEEDITEM_MUTATION,
  DELETE_FEEDITEM_RECIPIENT_MUTATION,
  SKILL_DETAILS_2_PAGE_QUERY,
  GET_ALL_CUSTOM_KUDOS_FOR_WORKSPACE
} from 'graphql-queries/queries';
import Toast from 'components/Toast/Toast';
import WorkspaceFeedItems from './Feeds/WorkspaceFeedItems';
import PersonalFeed from './Feeds/PersonalFeed';
import { FeedItemTypes, MAX_COMMENT_CHARACTER_LIMIT } from './consts';
import getWorkspaceFeed from 'graphql-queries/queries/workspace/getWorkspaceFeed';

const LoaderContainer = styled.div`
  padding: ${theme.spacing.singleAndHalf};
  position: relative;
  min-height: 160px;
  background: white;
  border-bottom: 1px solid ${theme.colors.blacks[10]};
`;

const SpinnerIconContainer = styled.div`
  display: flex;
  alignitems: center;
  justify-content: center;
  height: ${theme.spacing.tripleAndHalf};
  @keyframes spin { 100% { transform:rotate(360deg) } }
  margin-bottom: 16px;
  svg {
    display: block;
    height: ${theme.spacing.doubleAndHalf};
    width: ${theme.spacing.doubleAndHalf};
    animation: spin 4s linear infinite;
  }
`;

const IconSpinner = styled(IconNew).attrs(() => ({
  name: 'spinner',
}))``;

const SUPPORTED_FEED_HISTORY_COUNT = 30;

function FeedItemsList(props) {
  const hasReachedBottomRef = useRef(false);
  const feedItemRenderProps = useFeedItemRenderProps();
  const [toggleFeedItemThanks] = useMutation(FEEDITEM_THANKS_MUTATION);
  const [addFeedItemComment] = useMutation(ADD_FEEDITEM_COMMENT_MUTATION);
  const [deleteFeedItemComment] = useMutation(DELETE_FEEDITEM_COMMENT_MUTATION);
  const [deleteFeedItem] = useMutation(DELETE_FEEDITEM_MUTATION);
  const [deleteFeedItemRecipient] = useMutation(DELETE_FEEDITEM_RECIPIENT_MUTATION);
  const [showSpinner, setShowSpinner] = useState(false);
  const { currentWorkspace, currentUser, currentTenantWorkspaceIds } = useContext(UserContext);
  const {
    canLoadMoreItems,
    feedItemsList,
    workspaceFeed,
    workspace,
    isLoading,
    feedItemsProps,
    feedItemFooterProps,
    onLoadMore,
    feedExists,
    updateResultsOnComment = () => {},
    updateResultsOnDelete
  } = props;
  let workspaceId = props.workspaceId;

  if (!workspaceId) {
    workspaceId = feedItemsList ? feedItemsList[0]?.workspace?.id || currentWorkspace?.id : currentWorkspace?.id;
  }

  const getAllCustomKudosQuery = useQuery(GET_ALL_CUSTOM_KUDOS_FOR_WORKSPACE, {
    variables: {
      workspaceId,
      includeDeleted: true,
    },
  });

  const feedItems = workspaceFeed || [];

  let numberOfKudos = 0;
  let itemList;
  const pushNextSetToFinalList = () => {
    if (feedExists) {
      setShowSpinner(true);
      onLoadMore();
      hasReachedBottomRef.current = true;
      setTimeout(() => {
        setShowSpinner(false);
        hasReachedBottomRef.current = false;
      }, 2000);
    }
  };

  function compute() {
    let itemsList = [];
    const kudosItemList = [];

    (feedItemsList || feedItems).forEach((feedItem) => {
      const { payload } = feedItem;
      if (payload.__typename === FeedItemTypes.KUDOS) {
        numberOfKudos++;
        if (numberOfKudos <= SUPPORTED_FEED_HISTORY_COUNT) {
          kudosItemList.push(feedItem);
        }
      } else {
        itemsList.push(feedItem);
      }
    });
    itemsList = [...kudosItemList, ...itemsList];
    itemsList.sort((item1, item2) => {
      return moment(item2.eventTime) - moment(item1.eventTime);
    });

    return itemsList;
  };

  itemList = compute();
  useEffect(() => {
    itemList = compute();
  }, [feedItemsList, feedItems]);

  if (isLoading || getAllCustomKudosQuery.loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  const totalKudosList = getAllCustomKudosQuery.data?.customKudos || [];

  const { scrollTop, windowHeight, scrollHeight } = feedItemRenderProps;
  // reached bottom of the page
  if (!showSpinner && (scrollTop + windowHeight >= scrollHeight)) {
    if (!hasReachedBottomRef.current) {
      pushNextSetToFinalList();
    }
  }

  const renderSpinnerComponent = () => {
    if (!showSpinner || !canLoadMoreItems) { return null;}

    return (
      <SpinnerIconContainer> <IconSpinner fill={theme.colors.blacks[40]}/> </SpinnerIconContainer>
    );
  };

  const renderItemList = (list) => {
    return list.map((feedItem, index) => {
      const {
        id,
        payload,
        eventTime,
        thanks,
        comments,
        isMultipleReceiver,
        isAdmin,
        ...restProps
      } = feedItem;

      const onSendComment = async (e, { value }) => {
        if (value.length <= MAX_COMMENT_CHARACTER_LIMIT) {
          const result = await addFeedItemComment({
            variables: {
              feedItemId: id,
              comment: value,
            },
          });

          if (!!feedItems && result && !!result.data) {
            updateResultsOnComment(result.data.addFeedItemComment, index);
          }
        } else {
          Toast.error("You've exceeded 3,000 characters - the maximum for comments");
        }
      };

      const onClickThank = async () => {
        await toggleFeedItemThanks({
          variables: {
            feedItemId: id,
            isThanked: true,
          },
        });
      };

      const onClickDeleteComment = async (commentId) => {
        await deleteFeedItemComment({
          variables: {
            feedItemId: id,
            commentId,
          },
        });
      };
      
      const refetchQueries = [
        {
          query: getWorkspaceFeed,
          variables: { workspaceId },
        }
      ];

      const onClickDeleteFeedItem = async () => {
        if (feedItemsProps?.skillSlug && currentTenantWorkspaceIds.length) {
          refetchQueries.push({
            query: SKILL_DETAILS_2_PAGE_QUERY,
            variables: { 
              skillSlug: feedItemProps.skillSlug, 
              workspaceIds: currentTenantWorkspaceIds,
            }
          });
        }
        try {
          await deleteFeedItem({
            variables: {
              feedItemId: id,
              isHomeFeed: false,
              isAdmin,
            },
            refetchQueries,
          });
          updateResultsOnDelete(index);
        } catch (e) {
          console.log(e);
        }
      };

      const onClickRemoveMention = async () => {
        try {
          await deleteFeedItemRecipient({
            variables: {
              feedItemId: id,
              receiverId: currentUser.id,
            },
            refetchQueries,
          });
        } catch (e) {
          console.log(e);
        }
      };

      const renderProps = { ...feedItemRenderProps, index };

      const feedItemProps = {
        shouldAnimateIn: true,
        feedItemId: id,
        ...feedItemsProps,
        ...restProps,
        canDelete: feedItemsProps.canDelete,
      };

      const deleteProps = {
        isMultipleReceiver,
        isAdmin,
        onClickDeleteFeedItem,
        onClickRemoveMention,
      };

      const thankProps = {
        ...thanks,
        onClickThank,
        recipient: payload.giver,
      };

      const footerProps = {
        id,
        eventTime,
        thanks: thankProps,
        comments,
        onSendComment,
        onClickDeleteComment,
        currentUser,
        hideFooter: feedItemsProps?.hideFooter,
        ...feedItemFooterProps,
      };

      if (!workspaceFeed) {
        return (
          <PersonalFeed
            key={`personal-feed-${index}`}
            payload={payload}
            feedbackReceivedId={payload && payload.feedbackId}
            feedItemProps={feedItemProps}
            deleteProps={deleteProps}
            renderProps={renderProps}
            footerProps={footerProps}
            currentWorkspace={currentWorkspace}
            totalKudosList={totalKudosList}
          />
        );
      } else {
        return (
          <WorkspaceFeedItems
            key={`workspace-feed-${index}`}
            payload={payload}
            workspace={workspace}
            feedItemProps={feedItemProps}
            deleteProps={deleteProps}
            renderProps={renderProps}
            footerProps={footerProps}
            totalKudosList={totalKudosList}
          />
        );
      }
    });
  };

  return (
    <>
      {renderItemList(itemList)}
      {renderSpinnerComponent()}
    </>
  );
};

FeedItemsList.defaultProps = {
  canLoadMoreItems: true,
  feedItemsProps: {},
  feedItemFooterProps: {},
};

FeedItemsList.propTypes = {
  canLoadMoreItems: PropTypes.bool,
  isLoading: PropTypes.bool,
  feedItemsList: PropTypes.array,
  workspaceFeed: PropTypes.array,
  workspace: PropTypes.object,
  feedItemsProps: PropTypes.object,
  feedItemFooterProps: PropTypes.object,
  onLoadMore: PropTypes.func,
  feedExists: PropTypes.bool,
  updateResultsOnComment: PropTypes.func,
  updateResultsOnDelete: PropTypes.func,
  workspaceId: PropTypes.string,
};

export default FeedItemsList;
