import omitStyled from '../../libs/omitStyled';
import theme from '@matterapp/matter-theme';
import { SIZES } from './consts';

export default omitStyled('div', ['size', 'fluid', 'type', 'error', 'shortInline', 'marketingStyle'])`
  input,
  textarea {
    display: inline-block;
    color: black;
    box-sizing: border-box;
    text-align: left;

    border:
      ${theme.inputs.borderWidth}
      ${theme.inputs.borderStyle}
      ${theme.inputs.borderColor};
    border-radius: ${theme.inputs.borderRadius};

    transition:
      border .1s ease,
      background-color .1s ease,
      box-shadow .1s ease;

    &:focus {
      color: black !important;
      background: white !important;
      border-color: ${theme.inputs.borderActiveColor};
      box-shadow: 0px 2px 4px rgba(52, 55, 153, 0.2);
    }

    &::placeholder {
      color: ${theme.inputs.placeholderColor};
    }

    ${({ disabled }) => disabled && `
      &:disabled {
        color: #2E2E33;
        background-color: #E6E6E7;
        &::placeholder {
          color: ${theme.inputs.disabledPlaceholderColor};
        }
      }
    `};

    ${({ fluid }) => fluid && `
      display: block;
      width: 100%;
    `};

    ${({ error }) => error && `
      color: ${theme.inputs.errorTextColor};
      border-color: ${theme.inputs.errorBorderColor} !important;
      background-color: ${theme.inputs.errorBackgroundColor};
      &::placeholder {
        color: ${theme.inputs.errorPlaceholderColor};
      }
    `};

    ${({ size }) => size === SIZES.S && `
      font-size: ${theme.fontSize.XS};
      line-height: ${theme.lineHeight.S};
      padding: ${theme.spacing.half};
      height: ${theme.spacing.double};
    `};

    ${({ size }) => size === SIZES.M && `
      font-size: ${theme.fontSize.S};
      line-height: ${theme.lineHeight.M};
      padding: ${theme.spacing.threeQuarters};
      height: ${theme.spacing.triple};
    `};

    ${({ size }) => size === SIZES.L && `
      font-size: ${theme.fontSize.S};
      line-height: ${theme.lineHeight.M};
      padding: ${theme.spacing.threeQuarters};
      height: ${theme.spacing.tripleAndHalf};
    `};

    ${({ size }) => size === SIZES.XL && `
      font-size: ${theme.fontSize.S};
      line-height: ${theme.lineHeight.M};
      padding: ${theme.spacing.single};
      height: ${theme.spacing.quad};
    `};

    ${({ size }) => size === SIZES.XXL && `
      font-size: ${theme.fontSize.L};
      line-height: ${theme.lineHeight.L};
      padding: ${theme.spacing.single};
      height: ${theme.spacing.quad};
    `};

    ${({ size, shortInline }) => (size === SIZES.XL || size === SIZES.XXL) && shortInline && `
      height: 24px;
      line-height: 32px;
      padding: 12px 16px;
      height: 56px !important;
    `};

    ${({ marketingStyle }) => marketingStyle && `
      border: 2px solid ${theme.colors.blacks[20]};
      border-radius: ${theme.borderRadius.M} !important;
      &::placeholder {
        color: ${theme.colors.blacks[50]};
        text-align: left;
        font-weight: ${theme.fontWeight.medium};
      }
    `};
  }

  ${({ fluid }) => fluid && `
    display: block;
    width: 100%;
  `};

  ${({ marketingStyle }) => marketingStyle && `
    &::placeholder {
      color: ${theme.colors.blacks[60]};
      text-align: left;
      font-weight: ${theme.fontWeight.medium};
    }
  `};
`;
