import React, { useContext, useEffect } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { 
  Typography, 
  Box
} from '@mui/material';
import { Resources } from '@matterapp/routing';
import { useNavigate } from 'react-router-dom';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import BankIcon from './BankIcon';
import { 
  FundingWrapper,
  IconWrapper,
  NoFeeWrapper,
  PaperWrapper,
  FundingCopyWrapper
} from './styles';
import RightChevron from 'routes/pages/settings/icons/rightChevron';
import Toast from 'components/Toast/Toast';

export default function AddFundingPage() {
  const { 
    currentWorkspaceId,
    currentTenant
  } = useContext(UserContext);
  const navigate = useNavigate();
  const isPro = currentTenant?.tier === 'pro';

  useEffect(() => {
    if (currentTenant && !isPro) {
      Toast.error('Matter subscription required.');
    }
  }, [currentTenant]);

  return (
    <Box>
      <BreadCrumbs
        crumbs={[
          {
            label: 'Funding',
            onClick: () => navigate(Resources.rewardsFunding.path({ workspaceId: currentWorkspaceId }))
          },
          {
            label: 'Add Funds', 
            href: null
          }
        ]}
      />
      {isPro ? (
        <PaperWrapper>
          <Box>
            <Typography variant='h4' sx={{ mb: 3 }}>Select a Funding Method</Typography>
            <Typography variant='body1'>
              By default, approved reward requests are charged to your credit card, which includes a 3% processing fee. To avoid this fee and reduce frequent credit card transactions, you can prepay for rewards. Invoicing options (e.g., ACH/wire) are available with prepay.
            </Typography>
          </Box>
          <FundingWrapper 
            onClick={() => navigate(Resources.rewardsSendWire.path({ workspaceId: currentWorkspaceId }))}
          >
            <FundingCopyWrapper>
              <IconWrapper>
                <BankIcon />
              </IconWrapper>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h5' component='p'>
                Send ACH or Wire Transfer from your Bank
                </Typography>
                <Typography variant='body2' sx={{ color: 'blacks.50' }}>
                Access routing and account information.
                </Typography>
              </Box>
            </FundingCopyWrapper>
            <NoFeeWrapper>
              <Box>
                <Typography variant='body2'>
                  <b>NO FEE</b>
                </Typography>
              </Box>
              <RightChevron />
            </NoFeeWrapper>
          </FundingWrapper>
        </PaperWrapper>
      ) : null}
    </Box>
  );
};
