import React from 'react';
import { 
  Box,
  Typography,
  Divider
} from '@mui/material';
import { Advisors } from '@matterapp/matter-ui';
import getMatterTeams from 'graphql-queries/queries/matterTeams/getMatterTeams';
import { useQuery } from '@apollo/client';
import XButton from 'components/ClearButton/ClearButton';

export default function HrisTeamMappingsPanel({ tenant, hasHris, setTeamsMapping, teamsMapping }) {
  const { hrisSettings, id: tenantId } = tenant || {};
  const { status, providerDepartments = [] } = hrisSettings || {};
  const { data: matterTeamsData } = useQuery(getMatterTeams, {
    variables: {
      tenantId: parseInt(tenantId)
    },
    skip: !tenantId
  });

  const { matterTeams = [] } = matterTeamsData || {};

  const noData = !matterTeams?.length || !providerDepartments?.length;

  if (!hasHris || !status || status === 'disconnected' || noData) {
    return null;
  }

  let propertyOptions = matterTeams
    .filter(({ id }) => !teamsMapping.find(({ matterTeamId }) => matterTeamId === id))
    .map(({ id, name }) => {
      return { value: id, label: name };
    }).sort((a, b) => a.label.localeCompare(b.label));
  
  if (propertyOptions.length === 0) {
    propertyOptions = [{ value: '', label: 'No more groups to map' }];
  }

  return (
    <Box sx={{ mb: 3 }}>
      <Divider sx={{ mb: 6, mt: 6 }} />
      <Typography variant='h4' sx={{ mb: 2 }}>
        Group Mappings
      </Typography>
      <Typography variant='body1' sx={{ mb: 2 }}>
        Use the department data in your HRIS to assign and sync members in your Matter groups.
      </Typography>

      {providerDepartments
        .map((departmentName) => {
          let selectedItem = teamsMapping
            .find(({ providerDepartmentName }) => providerDepartmentName === departmentName);

          if (selectedItem) {
            const label = matterTeams.find(({ id }) => id === selectedItem.matterTeamId)?.name;

            if (label) {
              selectedItem = { label, value: selectedItem.matterTeamId };
            } else {
              selectedItem = null;
            }
          }

          return (
            <Box key={departmentName} sx={{ mb: 3 }}>
              <Typography variant='body1' sx={{ mb: 1, fontWeight: 'bold' }}>
                {departmentName}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '97%', marginRight: '10px' }}>
                  <Advisors.RecurringModal.Dropdown
                    placeholder='Select group on Matter'
                    selectedOption={selectedItem}
                    handleChangeSelection={(choice) => {
                      const existingMapping = teamsMapping
                        .find(({ providerDepartmentName }) => providerDepartmentName === departmentName);
                  
                      if (existingMapping) {
                        setTeamsMapping([
                          { providerDepartmentName: departmentName, matterTeamId: choice.value },
                          ...teamsMapping
                            .filter(({ providerDepartmentName }) => providerDepartmentName !== departmentName)
                        ]);
                      } else {
                        setTeamsMapping([...teamsMapping, { providerDepartmentName: departmentName, matterTeamId: choice.value }]);
                      }
                    }}
                    options={propertyOptions}
                  />
                </Box>
                {selectedItem && <XButton onClick={() => {
                  setTeamsMapping(teamsMapping.filter(({ providerDepartmentName }) => providerDepartmentName !== departmentName));
                }}/>}
              </Box>
            </Box>
          );
        })}
    </Box>
  );

};
