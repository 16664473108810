import React from 'react';
import PropTypes from 'prop-types';
import { Confirm } from '@matterapp/matter-ui';
import { FEEDBACK_RITUAL_CHANGE_FREQUENCY_MODAL_TEXT } from '../sharedConsts';

const ChangeRitualFrequencyConfirmModal = (props) => {
  const { isOpen, onClickCancel, onClickConfirm, ritualDateString, ritualName } = props;
  const subHeader = <>
    Your next {ritualName} will kick off on <strong>{ritualDateString}</strong>. 
    If there is a {ritualName} currently in progress, 
    it will be terminated.
  </>;

  return (
    <Confirm
      header={FEEDBACK_RITUAL_CHANGE_FREQUENCY_MODAL_TEXT.HEADER}
      subHeader={subHeader}
      cancelButtonLabel={FEEDBACK_RITUAL_CHANGE_FREQUENCY_MODAL_TEXT.CANCEL_BUTTON}
      confirmButtonLabel={
        FEEDBACK_RITUAL_CHANGE_FREQUENCY_MODAL_TEXT.CONFIRM_BUTTON
      }
      confirmButtonIsDestructive
      isOpen={isOpen}
      onClickCancel={onClickCancel}
      onClickConfirm={onClickConfirm}
    />
  );
};

ChangeRitualFrequencyConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onClickConfirm: PropTypes.func,
  ritualDateString: PropTypes.string
};

export default ChangeRitualFrequencyConfirmModal;
