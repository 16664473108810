import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';
import {
  ListWithHeader,
  NotificationItem,
  Button,
  Text,
} from '@matterapp/matter-ui';
import { useNewFeedbacksQuery } from 'hooks/useRemoteNotifications';
import viewFeedbackParam from 'helpers/viewFeedbackParam';

const renderItem = (feedback, params) => {
  const { isMultipleFeedback, numberOfPeople, onClick } = params;
  const { eventTime, payload, id } = feedback;
  const { giver, kudos } = payload;

  let text = `gave you${isMultipleFeedback ? ' new ' : ' '}feedback.`;

  if (kudos) {
    text = `gave you kudos.`;
  }

  const content = (
    <Text color="black">
      {text}
    </Text>
  );
  const Item = isMultipleFeedback
    ? NotificationItem.Multiple
    : NotificationItem;

  return (
    <Item
      isStackedOnMobile
      avatarUrl={giver.photoUrl}
      content={content}
      key={id}
      person={giver}
      eventTime={eventTime}
      numberOfPeople={numberOfPeople}
      actions={
        <Button.Primary.Vivid
          key="view"
          content={'View'}
          color={Button.buttonColors.PURPLE}
          size={Button.buttonSizes.S}
          onClick={onClick}
        />
      }
    />
  );
};

const getRenderItem = (params) => (feedback) => renderItem(feedback, params);

function UnreadFeedback(props) {
  const { onClickNotification, workspaceId, tenantId } = props;
  const navigate = useNavigate();

  const { newFeedbacksData, loading } = useNewFeedbacksQuery(tenantId);
  const { unreadFeedback: feedbackReceivedFeedItems } = newFeedbacksData;

  if (loading) {
    return null;
  }


  if (!feedbackReceivedFeedItems || !feedbackReceivedFeedItems.length) {
    return null;
  }
  const feedbacks = feedbackReceivedFeedItems.filter(({ payload }) => !payload.kudos);
  const kudos = feedbackReceivedFeedItems.filter(({ payload }) => payload.kudos);

  const isMultipleFeedback = feedbacks.length > 1;
  const isMultipleKudos = kudos.length > 1;
  const feedbackReceivedIdEncoded = viewFeedbackParam.encode(feedbacks);
  const kudosReceivedIdEncoded = viewFeedbackParam.encode(kudos);

  const viewFeedbackLink = Resources.feedbackFlow.path({
    feedbackReceivedIdEncoded: feedbackReceivedIdEncoded,
    workspaceId,
  });

  const viewKudosLink = Resources.feedbackFlow.path({
    feedbackReceivedIdEncoded: kudosReceivedIdEncoded,
    workspaceId,
  });

  const onClickView = (isKudos) => (e) => {
    onClickNotification(e);
    navigate(isKudos ? `${viewKudosLink}#1` : `${viewFeedbackLink}#1`);
  };

  return (
    <ListWithHeader
      header="Unread Feedback"
      headerType="invite"
      items={[feedbacks[0], kudos[0]].filter(Boolean)}
      renderItem={(item) => {
        const isKudos = item.payload.kudos;

        return getRenderItem({
          onClick: onClickView(isKudos),
          isMultipleFeedback: isKudos ? isMultipleKudos : isMultipleFeedback,
          numberOfPeople: isKudos ? kudos.length : feedbacks.length,
        })(item);
      }}
      rounded={false}
      showAllItemBorders
    />
  );
};

UnreadFeedback.defaultProps = {
  onClickNotification: () => null,
};

UnreadFeedback.propTypes = {
  onClickNotification: PropTypes.func,
  workspaceId: PropTypes.string,
};

export default UnreadFeedback;
