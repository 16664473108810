import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '@matterapp/matter-theme';
import Avatar from '../../../Avatar';
import Button from '../../../Button';
import { Clickable } from '../../../Clickable/Clickable';
import Dropdown from '../../../Dropdown';
import EditMessage from '../../../Composer/EditMessage';
import { Heading } from '../../../Composer/UnactivatedUserComposer/Customization/sharedStyle';
import { Modal, ModalFlow } from '../../../Modal';
import SkillTag from './SkillTag';
import SkillsList from './SkillsList';
import {
  MESSAGE_LENGTH,
  SkillPropType,
  AdvisorPropType
} from '../../consts';

const sharedStyle = `
  padding: 0 ${theme.spacing.singleAndHalf};
  padding-bottom: ${theme.spacing.singleAndHalf};
`;

const HeaderSection = styled.div`
  ${sharedStyle}
  border-bottom: 1px solid ${theme.colors.blacks[10]};
`;

const EmailDropdown = styled(Dropdown.Email.Tags).attrs(() => ({
  fluid: true,
  filterItems: true,
  showDropdownArrow: false,
  singleValue: true,
}))`
  height: ${theme.spacing.quadruple};
  & input {
    height: ${theme.spacing.quadruple};
    min-width: auto !important;
  }
`;

const DropdownContainer = styled.div`
  margin-top: ${theme.spacing.singleAndHalf};
`;

const BodySection = styled.div`
  ${sharedStyle}
`;

const MessageBox = styled.div`
  position: relative;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing.singleAndHalf};
  h3 {
    font-size: ${theme.fontSize.S};
    line-height: ${theme.lineHeight.M};
    font-weight: ${theme.fontWeight.bold};
    margin-left: ${theme.spacing.single};
  }
`;

const Clear = styled(Clickable)`
  position: absolute;
  top: 21px;
  ${theme.media.S`
    top: ${theme.spacing.singleAndHalf};
  `}

  right: 0;
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[50]};
  font-size: ${theme.fontSize.base};
  &:hover {
    color: ${theme.colors.black};
  }
  &:active {
    color: ${theme.colors.purples[50]};
  }
`;

export default class GiveFeedbackModal extends React.Component {
  static propTypes = {
    /** List of available skills to choose from. */
    skills: PropTypes.arrayOf(SkillPropType).isRequired,
    /** Advisor selected. */
    selectedAdvisor: AdvisorPropType,
    /** If the modal is open. */
    isOpen: PropTypes.bool.isRequired,
    /** If the skills list is still loading. */
    isLoadingPeerSkills: PropTypes.bool,
    /** Function to send feedback. */
    handleSendFeedback: PropTypes.func.isRequired,
    /** Function to close modal. */
    handleCloseModal: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      message: '',
    };
  }

  resetMessage() {
    this.setState({ message: '' });
  }

  handleMessageChange = (e, { message }) => {
    this.setState({ message });
  }

  handleClearMessage = () => {
    this.resetMessage();
  }

  handleSendFeedback = () => {
    this.props.handleSendFeedback(this.state.message);
    this.resetMessage();
  }

  handleGoBack = ({ decrementStep }) => decrementStep();

  handleCloseModal = (decrementStep) => {
    this.props.handleCloseModal();
    this.resetMessage();
    decrementStep && decrementStep();
  }

  renderFooter = ({ decrementStep }) => {
    const { message } = this.state;
    return (
      <Modal.Footer.ButtonContainer
        left={
          <Button.Simple
            color="black"
            onClick={() => this.handleCloseModal(decrementStep)}
          >
            Cancel
          </Button.Simple>
        }
        right={
          <Button.Simple.Primary.Vivid
            color="green"
            disabled={message.length < MESSAGE_LENGTH.MIN || message.length > MESSAGE_LENGTH.GIVE_FEEDBACK_MAX} 
            onClick={this.handleSendFeedback}
          >
            Send
          </Button.Simple.Primary.Vivid>
        }
      />
    );
  }

  render() {
    const { message } = this.state;
    const { 
      isOpen,
      selectedAdvisor,
      selectedDropdownUser,
      skills,
      selectedSkill,
      isLoadingPeerSkills,
      userDropdownProps,
      handleResetSelectedSkill,
    } = this.props;

    const placeholder = userDropdownProps?.dropdownPlaceholder || 'Select a Matter user...';
    return (
      <ModalFlow
        contentLabel="Give Feedback Modal"
        size="large"
        isModalOpen={isOpen}
        handleCloseModal={this.handleCloseModal}
        steps={[
          {
            renderTopRight: ({ decrementStep }) => <Modal.Header.Buttons.Close onClick={() => this.handleCloseModal(decrementStep)}/>,
            renderBody: ({ incrementStep }) => (
              <>
                <HeaderSection>
                  {selectedAdvisor ? 
                    <HeaderContainer>
                      <Avatar url={selectedAdvisor.photoUrl} size={48} />
                      <h3>{selectedAdvisor.fullName || selectedAdvisor.email}</h3>
                    </HeaderContainer> :
                    <DropdownContainer>
                      <EmailDropdown placeholder={placeholder} {...userDropdownProps}/>
                    </DropdownContainer>}
                </HeaderSection>

                <BodySection>
                  <MessageBox>
                    <EditMessage
                      hideMinCharacterCount={false}
                      hideMaxCharacterCount={false}
                      label={ 
                        <Heading label>
                          <h3>Your Message:</h3>
                        </Heading>}
                      maxLength={MESSAGE_LENGTH.GIVE_FEEDBACK_MAX}
                      minLength={MESSAGE_LENGTH.MIN}
                      message={message}
                      onChange={this.handleMessageChange}
                    />
                    <Clear onClick={this.handleClearMessage}>
                      Clear text
                    </Clear>
                    <SkillTag
                      disabled={!selectedAdvisor && !selectedDropdownUser}
                      handleNextStep={incrementStep}
                      selectedSkill={selectedSkill}
                      handleResetSkill={handleResetSelectedSkill}
                    />
                  </MessageBox>
                </BodySection>
              </>
            ),
            renderFooter: this.renderFooter,
            title: "Give Feedback",
            handleGoBack: this.handleGoBack,
          },
          {
            renderBody: ({ decrementStep }) => (
              <SkillsList
                userSkills={skills}
                handleChangeSkill={(e, {value}) => {
                  this.props.handleSelectSkill(e, { selectedSkill: value });
                  decrementStep();
                }}
                selectedSkill={selectedSkill}
                isLoading={isLoadingPeerSkills}
              />
            ),
            title: "Select Skill",
            handleGoBack: this.handleGoBack,
          },
        ]}
      />
    );
  }
}