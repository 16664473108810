import React from 'react';
import { AvatarCard, ProfileCard } from '@matterapp/matter-ui';

const Card = ({ minimal, profileCardData, useAvatarCard }) => {
  const { currentUser } = profileCardData;
  if (!currentUser) {
    return null;
  }
  if (useAvatarCard) {
    const { email, fullName, headline, photoUrl } = currentUser;
    return (
      <AvatarCard
        avatarProps={{ email, url: photoUrl }}
        title={fullName}
        rcLabel="profile-card"
        subHeader={headline}
      />
    );
  }
  return (
    <ProfileCard.Stats
      minimal={minimal}
      user={currentUser}
      qualityEndorsements={currentUser.endorsedQualities}
    />
  );
};

Card.defaultProps = {
  minimal: false,
};

export default Card;
