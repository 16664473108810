import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { CONNECTIONS_QUERY } from 'graphql-queries/queries';
import { ClickableLink, Text } from '@matterapp/matter-ui';
import { colors, font, spacing } from '@matterapp/matter-theme';

export const MIN_MESSAGE_LENGTH = 25;
export const MAX_MESSAGE_LENGTH = 250;

export const USER_SKILLS_QUERY = gql`
  query {
    currentUser {
      id
      pickedSkillDetails {
        skill {
          id
          name
        }
      }
    }
  }
`;

export const WORKSPACE_DROPDOWN_PLACEHOLDER = 'Select member...';

/*****************************************************************************/
/* TEXT REPLACEMENT HELPERS                                                  */
/*****************************************************************************/
export const REPLACE_TEXT = Text.WithReplacement.replaceText;

/** Pass in replacementText of an array with 2 elements [header, content]. */
export const ToastTextWithHeader = styled(Text.WithReplacement).attrs(() => ({
  replaceTextElement: ['strong', 'span'],
  text: `${REPLACE_TEXT} ${REPLACE_TEXT}`,
}))``;

/*****************************************************************************/
/* CONNECTIONS QUERY                                                         */
/*****************************************************************************/
export const useConnections = () => {
  const { data, error, loading } = useQuery(CONNECTIONS_QUERY);
  if (loading) {
    return { data, loading, error, currentUser: {}, connections: [] };
  }
  const { currentUser } = data;
  const { id, connections = [] } = currentUser;

  return { data, loading, error, currentUser, connections, id };
};

export const useConnectionsForComposer = () => {
  const { id, connections } = useConnections();
  const giveFeedbackConnections = [];
  const giveKudosConnections = [];

  connections.forEach((connection) => {
    const { peerActionsStatus, person } = connection;
    const { quickNote, kudos } = peerActionsStatus;
    const isSelf = person.userId === id;

    if (!isSelf && quickNote.canBeGiven) {
      giveFeedbackConnections.push(person);
    }
    if (!isSelf && kudos.canBeGiven) {
      giveKudosConnections.push(person);
    }
  });

  return {
    canGiveFeedback: !!giveFeedbackConnections.length,
    canGiveKudos: !!giveKudosConnections.length,
    giveFeedbackConnections,
    giveKudosConnections,
  };
};

/*****************************************************************************/
/* GET CURRENT USER SKILLS                                                   */
/*****************************************************************************/
export const useUserSkills = () => {
  const { data, loading } = useQuery(USER_SKILLS_QUERY);

  if (loading || !data) {
    return { loading, userSkills: [] };
  }

  const { currentUser } = data || {};
  const { pickedSkillDetails = [] } = currentUser || {};

  return {
    loading,
    userSkills: pickedSkillDetails?.map((psd) => psd.skill),
  };
};

/*****************************************************************************/
/* ON SEARCH WORKSPACE MEMEBRS                                               */
/*****************************************************************************/
export const textIncludes = (source = '', query = '') =>
  source.toLowerCase().includes(query.toLowerCase());

export const useOnSearchWorkspaceMembers = (workspaceMembers = []) => {
  const onSearchWorkspaceMembers = (query) => {
    const formattedMembers = workspaceMembers
      .map((member) => ({ ...member, ...member.person }))
      .filter((member) => {
        const { email, fullName, isSelf } = member;
        return (
          !isSelf &&
          (textIncludes(fullName, query) || textIncludes(email, query))
        );
      });
    return new Promise((resolve) => resolve(formattedMembers));
  };
  return (query) => onSearchWorkspaceMembers(query);
};

/*****************************************************************************/
/* SHARED DISABLED TEXT                                                      */
/*****************************************************************************/
const InviteText = styled(ClickableLink)`
  color: ${colors.blacks[50]};
  font-style: inherit;
  font-weight: bold;
`;

const DropdownMessage = styled.div`
  color: ${colors.blacks[30]};
  font-weight: ${font.weight.bold};
  padding: ${spacing.single};
  text-align: center;
  text-transform: uppercase;

  & ${InviteText} {
    color: ${colors.blacks[30]};
    font-weight: ${font.weight.bold};
    text-transform: uppercase;
  }
`;

export const getDisabledMessage = (actionText = '', invitePath, inviteChannel) => {
  const TEXT = `At least one member other than yourself needs to join the workspace ${actionText}. Invite team members to ${REPLACE_TEXT} on Slack.`;
  return (
    <Text.WithReplacement
      replaceText={
        <InviteText key={inviteChannel} href={invitePath}>
          {inviteChannel}
        </InviteText>
      }
      text={TEXT}
    />
  );
};

export const getDropdownInviteMessage = (primaryChannel, isTeams) => {
  const platform = isTeams ? 'Teams' : 'Slack';

  const TEXT = primaryChannel 
    ? `No matches, invite team members to ${REPLACE_TEXT} on ${platform}`
    : `No matches, invite team members on ${platform}`;
  const invitePath = `https://slack.com/app_redirect?channel=${primaryChannel}`;
  
  return (
    <DropdownMessage>
      <Text.WithReplacement
        replaceText={
          <InviteText key={primaryChannel} href={invitePath}>
            #{primaryChannel}
          </InviteText>
        }
        text={TEXT}
      />
    </DropdownMessage>
  );
};

/*****************************************************************************/
/* REQUEST FEEDBACK DISABLED TEXT                                            */
/*****************************************************************************/
export const getRequestFeedbackDisabledMessage = (invitePath, inviteChannel) =>
  getDisabledMessage('to request feedback', invitePath, inviteChannel);

/*****************************************************************************/
/* GIVE FEEDBACK DISABLED TEXT                                               */
/*****************************************************************************/
export const getGiveFeedbackDisabledMessage = (invitePath, inviteChannel) =>
  getDisabledMessage('to give feedback', invitePath, inviteChannel);

/*****************************************************************************/
/* KUDOS DISABLED TEXT                                                       */
/*****************************************************************************/
export const getKudosDisabledMessage = (invitePath, inviteChannel) =>
  getDisabledMessage('for you to send a kudos', invitePath, inviteChannel);
