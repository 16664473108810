import React from 'react';
import { useQuery } from '@apollo/client';
import { Modal, Loader } from '@matterapp/matter-ui';
import getStripeSubscriptionBySubscriptionId from 'graphql-queries/queries/billing/getStripeSubscriptionBySubscriptionId';
import { LoadingContainer, BillingModalContainer, ContactSalesButton } from './styles';
import BillingFooter from './BillingFooter';
import UpgradedPanel from './UpgradedPanel/UpgradedPanel';
import PaymentPanel from './PaymentPanel/PaymentPanel';
import { updateIntercom } from 'libs/tracking/intercom';
import getCurrentUser from 'graphql-queries/queries/user/getCurrentUser';

export default function BillingSettings(props) {
  const { 
    tenant, 
    workspace,
    loading, 
    refetchCurrentUser,
    setSavedParams,
    scheduleCallParams
  } = props;

  const { tier, stripeDowngradeSubscriptionId } = tenant || {};
  const isUpgraded = tier === 'pro';

  const { data: canceledSubscriptionData, loading: isSubscriptionLoading } = useQuery(getStripeSubscriptionBySubscriptionId, {
    variables: {
      subscriptionId: stripeDowngradeSubscriptionId,
    },
    skip: !stripeDowngradeSubscriptionId
  });
  const { data: userData } = useQuery(getCurrentUser);

  if (!tenant) { 
    return null; 
  }

  if (isSubscriptionLoading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  const canceledSubscription = canceledSubscriptionData?.getStripeSubscriptionBySubscriptionId;

  return (
    <BillingModalContainer>
      <Modal.Panel.Main
        header={!isUpgraded ? 'Upgrade to Pro' : 'Billing'}
        headerActions={!isUpgraded ? 
          <ContactSalesButton 
            onClick={() => {
              updateIntercom(null, { 
                showIntercomMessenger: true, 
              });
              window.Intercom('showNewMessage', 'Hello, I need help with Billing...');
            }}/> : null}
      >
        {isUpgraded ? 
          <UpgradedPanel
            isLoading={loading}
            workspace={workspace}
            tenant={tenant}
            canceledSubscription={canceledSubscription}
            refetchCurrentUser={refetchCurrentUser}
          /> : 
          <PaymentPanel 
            tenant={tenant}
            workspace={workspace}
            isLoading={loading}
            refetchCurrentUser={refetchCurrentUser}
            setSavedParams={setSavedParams}
          />
        }
        <BillingFooter 
          isUpgraded={isUpgraded} 
          isCanceled={!!canceledSubscription}
          workspaceId={workspace.id}
          userEmail={userData?.currentUser?.email}
          scheduleCallParams={scheduleCallParams}
        />
      </Modal.Panel.Main>
    </BillingModalContainer>
  );
}
