import React, { useState } from 'react';
import { ToggleContainer, ToggleWrapper, ToggleUnit } from 'components/ToggleGroup';
import { StyledPaper, InputWrapper } from '../styles';
import { Box, Typography } from '@mui/material';
import { Advisors, Input, Button as MatterButton } from '@matterapp/matter-ui';
import styled from 'styled-components';
import AddMembersModal, { MemberListItem, RemoveMemberButton } from 'components/AddMembersModal/AddMembersModal';
import getTenantMembersByPersonIds from 'graphql-queries/queries/workspace/getTenantMembersByPersonIds';
import { useQuery } from '@apollo/client';

const DropdownWrapper = styled(Box)(({ theme }) => ({
  width: '280px'
}));

const NotifyMembersWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));

const FREQUENCY_OPTIONS = [
  {
    label: 'Weekly',
    value: 'weekly'
  },
  {
    label: 'Monthly (recommended)',
    value: 'monthly'
  }];

export default function CoinMonitoringSection(props) {
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);
  const { 
    monitorCoins, 
    onChange, 
    monitorCoinsThreshold, 
    monitorCoinsFrequency, 
    onCoinsAmountChange, 
    onPeriodChange, 
    monitorCoinsNotifyMembers,
    tenantId,
    saveSettings
  } = props;
  const selectedFrequency = FREQUENCY_OPTIONS.find(({ value }) => value === monitorCoinsFrequency);

  const { data: membersData } = useQuery(getTenantMembersByPersonIds, {
    variables: {
      tenantId,
      personIds: monitorCoinsNotifyMembers
    },
    skip: !monitorCoinsNotifyMembers?.length
  });

  const members = membersData?.tenantMembersByPersonIds || [];

  return (
    <StyledPaper>
      <AddMembersModal 
        onSave={async (members) => {
          await saveSettings({
            monitorCoinsNotifyMembers: Array.from(new Set([...members.map(({ person }) => person.id), ...monitorCoinsNotifyMembers])) 
          });
        }}
        tenantId={tenantId}
        header={isNotifyModalOpen ? 'Notify Members' : 'Exclude Members'}
        isOpen={isNotifyModalOpen}
        onClose={() => {
          setIsNotifyModalOpen(false);
        }}
      />
      <Typography variant='h3' sx={{ mb: 3 }}>Coin Monitoring</Typography>
      <Typography variant='body1'>
        Add a coin limit to automatically prevent users from exploiting the coin rewards system while ensuring fairness and authenticity.
      </Typography> 
      <ToggleWrapper>
        <ToggleContainer>
          Coin Monitoring
          <ToggleUnit 
            checked={monitorCoins}
            onChange={onChange}
          />
        </ToggleContainer>
      </ToggleWrapper>
      {monitorCoins && (
        <Box sx={{ mt: 2 }}>
          <Typography variant='body1'>
            Number of coins a user can give to the same person within a specific timeframe.
          </Typography> 
          <InputWrapper>
            <Input
              errorMessage={!monitorCoinsThreshold || monitorCoinsThreshold == '0' ? ' ' : ''}
              showErrorMessageBelow
              onChange={(e) => onCoinsAmountChange(e.target.value)}
              value={monitorCoinsThreshold?.toString()} 
              style={{ width: '60px' }}
            />
            <Typography variant='body1' sx={{ ml: 1, mr: 1 }}>coins max per</Typography>
            <DropdownWrapper>
              <Advisors.RecurringModal.Dropdown
                options={FREQUENCY_OPTIONS}
                selectedOption={selectedFrequency.label}
                handleChangeSelection={(choice) => {
                  onPeriodChange(choice.value);
                }}
                width='100%'
                position={'absolute'}
              />
            </DropdownWrapper>
          </InputWrapper>
          <Typography variant='body2'>
            Once the coin limit is reached, Matter will still send the kudos, but without the coins. The kudos giver will receive a direct message explaining why the coins were not added. Admins, group managers, and groups are exempt from this rule.
          </Typography>

          <NotifyMembersWrapper>
            <Typography variant='h6'>
              Notified Members
            </Typography>
            <MatterButton 
              onClick={() => setIsNotifyModalOpen(true)}
              size='S'
            >
              Add member
            </MatterButton>
          </NotifyMembersWrapper>
          <Typography variant='body1' component='p' sx={{ mb: 3 }}>
            Optionally, specify which members are notified when a coin monitoring event occurs.
          </Typography>
          {members
            .filter(({ person }) => monitorCoinsNotifyMembers.includes(String(person.id)))
            .map(({ person }) => (
              <MemberListItem
                key={person.id}
                peer={person}
              >
                <RemoveMemberButton size='S' color='black' onClick={() => {
                  const newMembers = monitorCoinsNotifyMembers.filter((personId) => personId !== person.id);
                  
                  saveSettings({ monitorCoinsNotifyMembers: newMembers });
                }}>
                  REMOVE
                </RemoveMemberButton>
              </MemberListItem>
            ))}
        </Box>
      )}
    </StyledPaper>
  );
}
