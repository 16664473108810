import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import Avatar from '../../../Avatar';
import Kudos from '../../../Kudos';
import Panel from '../../../Panel';
import AvatarHeader from '../../AvatarHeader';
import { Footer } from '../../FeedItem';

const Container = styled.div`
  font-weight: ${theme.fontWeight.semiBold};
  padding: 0 ${theme.spacing.singleAndQuarter};
  border-top: 1px solid ${theme.colors.blacks[10]};
  padding-bottom: ${({ hideFooter }) => hideFooter ? theme.spacing.singleAndQuarter : ''};
  ${theme.media.S`
    padding: 0 ${theme.spacing.singleAndHalf};
    padding-bottom: ${({ hideFooter }) => hideFooter ? theme.spacing.singleAndHalf : ''};
  `}
`;

const WorkspaceLink = styled.a`
  color: ${theme.colors.blacks[90]};
  border-bottom: 1px solid ${theme.colors.blacks[90]};
  &:active {
    text-decoration: none;
    color: ${theme.colors.purples[60]};
  }
`;

const WorkspaceName = styled.span`
  color: ${theme.colors.blacks[90]};
`;

const ReceiversContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${theme.spacing.singleAndHalf};
  margin-bottom: ${theme.spacing.single};
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[90]};
  span {
    align-self: flex-start;
  }
`;

const Recipients = styled.div`
  display: flex;
  margin-left: 2px;
  white-space: nowrap;
  flex-wrap: wrap;
`;

const Receivers = styled.div`
  display: flex;
  margin-left: ${theme.spacing.half};
  margin-bottom: ${theme.spacing.half};
  > div {
    margin-right: ${theme.spacing.half};
  }
`;

const NameContainer = styled.div`
  display: flex;
  color: ${({ isInactive }) => isInactive ? theme.colors.blacks[60] : 'inherit'};
`;

const WorkspaceKudos = ({
  footerProps = {},
  giver,
  receivers,
  isWholeChannel,
  teamNames,
  channelName,
  index,
  kudos,
  renderProps,
  workspace,
  workspacePath,
  showMenu,
  feedItemProps,
  deleteProps,
  onClickAvatar,
  onClickDelete,
  onClickRemove,
  totalKudosList
}) => {
  const { hideBottomMargin } = feedItemProps;
  const { hideFooter } = footerProps;
  const [hasPlayedKudos, setHasPlayedKudos] = useState(false);
  const { scrollTop, windowHeight } = renderProps;

  const kudosRef = useRef();
  const playTimeout = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(playTimeout);
    };
  }, []);

  useEffect(() => {
    if (!hasPlayedKudos && windowHeight && kudosRef.current && kudosRef.current.getBoundingClientRect) {
      const bottomPosition = windowHeight - 40;
      const kudosTopPosition = kudosRef.current.getBoundingClientRect().top;
      if (kudosTopPosition < bottomPosition && !hasPlayedKudos) {
        playTimeout.current = setTimeout(() => {
          setHasPlayedKudos(true);
        }, 500);
      }
    }
  }, [scrollTop, windowHeight]);

  const subheader = workspace ? (
    <span>
      gave you Kudos in{' '}
      {workspacePath ? <WorkspaceLink href={workspacePath}>{workspace.name}.</WorkspaceLink> : <WorkspaceName>{workspace.name}</WorkspaceName>}
    </span>
  ) : (
    'gave Kudos.'
  );

  const panelHeader = (
    <AvatarHeader
      equalPadding
      header={giver.fullName}
      isInactive={giver.isInactive}
      deleteProps={deleteProps}
      subheader={subheader}
      photoUrl={giver.isInactive ? null : giver.photoUrl}
      badgeUrl={workspace ? workspace.iconUrl : null}
      showMenu={showMenu}
      onClickAvatar={onClickAvatar}
      onClickDelete={onClickDelete}
      onClickRemove={onClickRemove}
    />
  );

  let recipients = [];

  if (isWholeChannel) {
    recipients = (
      <Receivers>
        <NameContainer>
          <span>Everyone in #{channelName}</span>
        </NameContainer>
      </Receivers>
    );
  } else {
    if (teamNames?.length) {
      recipients = teamNames.map((teamName) => (
        <Receivers key={teamName}>
          <NameContainer>
            <span>{teamName}</span>
          </NameContainer>
        </Receivers>
      ));
    }

    recipients.push(receivers.map((receiver) => (
      <Receivers key={receiver.id}>
        <Avatar url={receiver.isInactive ? null : receiver.photoUrl} size={24} />
        <NameContainer isInactive={receiver.isInactive}>
          {receiver.isInactive ? `${receiver.fullName} [inactive] ` : receiver.fullName}
        </NameContainer>
      </Receivers>
    )));
  }

  return (
    <Panel.Base header={panelHeader} hideBottomMargin={hideBottomMargin}>
      <Container hideFooter={hideFooter}>
        <ReceiversContainer>
          <span>To</span> <Recipients>{recipients}</Recipients>
        </ReceiversContainer>
        <Kudos.Card
          className={`kudos-${index}`}
          playAnimation={hasPlayedKudos}
          playAnimationOnClick
          playAnimationOnHover
          ref={kudosRef}
          text={kudos.note}
          type={kudos.type}
          totalKudosList={totalKudosList}
        />
      </Container>
      {hideFooter ? null : <Footer {...footerProps} />}
    </Panel.Base>
  );
};

WorkspaceKudos.defaultProps = {
  renderProps: {
    scrollTop: 0,
    windowHeight: 0,
  },
  feedItemProps: {}
};

WorkspaceKudos.propTypes = {
  footerProps: PropTypes.shape(Footer.propTypes),
  renderProps: PropTypes.shape({
    scrollTop: PropTypes.number,
    windowHeight: PropTypes.number,
  }),
};

export default WorkspaceKudos;
