import React, { useState, createRef, useEffect } from 'react';
import {
  AddAllowanceButton,
  CustomAllowanceBlock,
  PeersWrapper,
  PeerListItem,
  RemoveButton
} from './styles';
import { StyledIcon } from '../DefaultAllowanceSection/styles';
import { Tooltip } from '@matterapp/matter-ui';
import Toast from 'components/Toast/Toast';
import { SubHeader } from '../DefaultAllowanceSection/styles';
import AddCustomAllowanceModal from './AddCustomAllowanceModal';
import getMembersWithCustomCoins from 'graphql-queries/queries/workspace/getMembersWithCustomCoins';
import { useQuery, useMutation } from '@apollo/client';
import RemoveAllowanceModal from './RemoveAllowanceModal';
import removeMaxAllotedCoins from 'graphql-queries/mutations/workspaceMembers/removeMaxAllotedCoins';
import { Typography } from '@mui/material';
import { colors } from '@matterapp/matter-theme';

export default function CustomAllowanceSection({ tenant, workspace, allowanceResetFrequency }) {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [removingAllowance, updateRemovingAllowance] = useState(null);
  const [isRemovingAllowanceModalOpen, setRemovingAllowanceModalOpen] = useState(false);
  const [buttonText, updateButtonText] = useState('Add Custom Allowance');
  const [removeAllowanceMutation, { data: removedAllowance }] = useMutation(removeMaxAllotedCoins);

  const { data } = useQuery(getMembersWithCustomCoins, {
    variables: {
      tenantId: tenant.id
    }
  });
  const { membersWithCustomCoins = [] } = data || {};
  const ref = createRef();

  useEffect(() => {
    if (ref?.current?.offsetWidth < 600) {
      updateButtonText('Add');
    }
  }, [ref]);

  function submitRemoveAllowance() {
    const { person, id } = removingAllowance;
    
    removeAllowanceMutation({
      variables: {
        tenantId: tenant.id,
        personId: person.id,
        memberId: id
      }
    });
  }

  function updateRemovingModalState(member) {
    updateRemovingAllowance(member);
    setRemovingAllowanceModalOpen(!!member);
  }

  useEffect(() => {
    if (removedAllowance) {
      Toast.success('You successfully removed a custom allowance');

      updateRemovingModalState();
    }
  }, [removedAllowance]);

  return (
    <CustomAllowanceBlock ref={ref}>
      <RemoveAllowanceModal 
        isOpen={isRemovingAllowanceModalOpen}
        onClickCancel={() => updateRemovingModalState()}
        fullName={removingAllowance?.person?.fullName}
        onClickRemove={submitRemoveAllowance}
      />
      <AddCustomAllowanceModal 
        workspace={workspace}
        tenant={tenant}
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        allowanceResetFrequency={allowanceResetFrequency}
      />
      <SubHeader>
        Custom Allowance
        <Tooltip
          dark
          content={`The custom allowance overrides the default allowance.`}
        >
          <StyledIcon 
            name="infoCircle" 
            fill={colors.blacks[30]}
          />
        </Tooltip>
        <AddAllowanceButton 
          onClick={() => setIsAddModalOpen(true)}
        >
          {buttonText}
        </AddAllowanceButton>
      </SubHeader>
      <Typography variant='body1' sx={{ mt: 2 }}>
        Set default coin allowances for specific channel members. Ideal for giving managers extra budget or for admins to give out for special recognition.
      </Typography>
      <PeersWrapper>
        {membersWithCustomCoins
          .filter(({ allotedCoins }) => allotedCoins)
          .map(({ id, person, allotedCoins }) => (
            <PeerListItem
              key={person.id}
              peer={person}
              content={`${allotedCoins.toLocaleString('en-US')} coins`}
            >
              <RemoveButton color="black" onClick={() => updateRemovingModalState({ id, person })}>
                REMOVE
              </RemoveButton>
            </PeerListItem>
          ))}
      </PeersWrapper>
    </CustomAllowanceBlock>
  );
}
