import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Tooltip } from '@matterapp/matter-ui';
import { Box, Button, Typography, Tabs, Tab } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useQuery } from '@apollo/client';
import getMatterTeams from 'graphql-queries/queries/matterTeams/getMatterTeams';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SegmentIcon from './SegmentIcon';
import LockIcon from './LockIcon';
import { Resources } from '@matterapp/routing';
import { useNavigate } from 'react-router-dom';
import CheckIcon from './CheckIcon';
import UserContext from 'context/UserContext/UserContext';
import getSegments from 'graphql-queries/queries/hris/getSegments';

const Header = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1)
}));

const ScrollBox = styled(Box)(() => ({
  maxHeight: '400px', 
  overflow: 'auto'
}));

const SegmentsCount = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '20px',
  width: '20px',
  background: theme.colors.blues[50],
  color: 'white',
  marginLeft: theme.spacing(2),
  borderRadius: '100%',
  span: {
    fontSize: '12px',
    fontWeight: 500,
  }
}));

const FilterButton = styled(Button)
  .withConfig({ shouldForwardProp: (prop) => {
    return prop !== 'isActive';
  } })(({ theme, isActive }) => ({
    textTransform: 'none',
    fontSize: 17,
    color: isActive ? theme.colors.blues[40] : theme.colors.blacks[60],
    marginRight: theme.spacing(2),
    fontWeight: 400,
    border: isActive ? `1px solid ${theme.colors.blues[40]}` : `1px solid ${theme.colors.blacks[10]}`,
    background: isActive ? theme.colors.blues[1] : '#FFF',
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
    ':hover, :active': {
      color: theme.colors.blues[40],
      background: theme.colors.blues[1],
      border: `1px solid ${theme.colors.blues[40]}`
    },
    '& .MuiSvgIcon-root': {
      marginLeft: theme.spacing(2),
      marginRight: 0
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }));

const StyledMenuItem = styled(MenuItem)
  .withConfig({ shouldForwardProp: (prop) => {
    return prop !== 'isLocked';
  } })(({ theme, isLocked }) => ({
    cursor: isLocked ? 'auto' : 'pointer',
    '&:not(.Mui-selected):hover': {
      backgroundColor: isLocked ? 'white' : theme.colors.blues[5]
    }, 
    '&.Mui-selected:hover': {
      backgroundColor: theme.colors.blues[50]
    },  
    '&:active, &.Mui-selected': {
      backgroundColor: isLocked ? 'white' : theme.colors.blues[50]
    },
  }));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& button.MuiTab-root': {
    textTransform: 'none',
    fontFamily: 'sans-serif'
  },
  '& .MuiPaper-root': {
    width: '390px',
    maxHeight: '465px',
    overflow: 'hidden',
    borderRadius: 6,
    borderColor: theme.colors.blues[40],
    borderWidth: '1px',
    borderStyle: 'solid',
    margin: `${theme.spacing(1)} 0`,
    padding: 0,
    paddingBottom: theme.spacing(3),
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiList-root': {
      height: '100%'
    },
    '& .MuiMenuItem-root': {
      color: theme.colors.blacks[90],
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      margin: 0,
      svg: {
        fontSize: 18,
        color: theme.colors.blacks[90]
      },
      p: {
        fontWeight: 500,
        maxWidth: '90%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }, 
      '&:active, &.Mui-selected': {
        p: {
          color: 'white',
        },
        svg: {
          color: 'white',
          width: 16,
          height: 16
        }
      },
    }
  },
}));

const CustomTab = styled(Tab)({
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: '0.4px',
  fontFamily: 'Roboto'
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      hidden={value !== index}
      {...other}
      sx={{ width: '100%', height: '100%', padding: 0 }}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </Box>
  );
}

function TeamsTab({ teams, isPulse, workspaceId, isAdmin, selectedTeamIds, onTeamChange, disabled }) {
  const navigate = useNavigate();

  return (
    <>
      <Header>
        <Typography variant='body1' sx={{ color: 'blacks.60', fontWeight: 500 }}>
          Groups
        </Typography>
      </Header>
      {!teams?.length && (
        <Typography variant='body2' sx={{ margin: 2 }}>
          NONE AVAILABLE. {isAdmin && <a style={{ cursor: 'pointer' }} onClick={() => navigate(Resources.workspaceSettingsTeams.path({ workspaceId }))}>CREATE GROUP.</a>}
        </Typography>
      )}
      {[...teams]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((team) => {
          const { name, id, membersCount } = team;
          const selected = selectedTeamIds.includes(id);
          const isLocked = isPulse && membersCount < 3;

          if (isLocked) {
            return (
              <Tooltip
                dark
                key={id}
                triggerStyles={{ width: '100%' }}
                content='You must have 3 or more members in your group.'
              >
                <StyledMenuItem 
                  isLocked={isLocked}
                  sx={{ mb: 1, width: '100%' }}
                >
                  <LockIcon />
                  <Typography variant='body1' sx={{ ml: 2, fontWeight: 500, color: 'blacks.90' }}>
                    {name}
                  </Typography> 
                </StyledMenuItem>
              </Tooltip>
            );
          }

          return (
            <StyledMenuItem 
              onClick={() => {
                if (!disabled) {
                  onTeamChange(team);
                }
              }}
              selected={selected}
              isLocked={disabled}
              disableRipple 
              key={id}
              sx={{ mb: 1 }}
            >
              {disabled ? (
                <LockIcon />
              ) : (
                selected ? <CheckIcon /> : <SegmentIcon />
              )}
              <Typography variant='body1' sx={{ ml: 2, fontWeight: 500, color: 'blacks.90' }}>
                {name}
              </Typography>
            </StyledMenuItem>
          );
        })}
    </>
  );
}

function SegmentsTab({ segments = [], workspaceId, onSegmentChange, selectedSegmentIds, isPulse }) {
  const navigate = useNavigate();

  return (
    <>
      <Header>
        <Typography variant='body1' sx={{ color: 'blacks.60', fontWeight: 500 }}>
          Segments
        </Typography>
      </Header>
      {!segments?.length && (
        <Typography variant='body2' sx={{ margin: 2 }}>
          NONE AVAILABLE. <a style={{ cursor: 'pointer' }} onClick={() => navigate(Resources.workspaceSettingsSegments.path({ workspaceId }))}>CREATE A SEGMENT</a>.
        </Typography>
      )}
      {[...segments]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((segment) => {
          const { name, id, memberCount } = segment;
          const selected = selectedSegmentIds.includes(id);
          const isLocked = isPulse && memberCount < 3;

          if (isLocked) {
            return (
              <Tooltip
                dark
                content='You must have 3 or more members in your segment.'
                triggerStyles={{ width: '100%' }}
              >
                <StyledMenuItem 
                  isLocked={isLocked}
                  key={id}
                  sx={{ mb: 1, width: '100%' }}
                >
                  <LockIcon />
                  <Typography variant='body1' sx={{ ml: 2, fontWeight: 500, color: 'blacks.90' }}>
                    {name}
                  </Typography> 
                </StyledMenuItem>
              </Tooltip>
            );
          }

          return (
            <StyledMenuItem 
              onClick={() => {
                onSegmentChange(segment);
              }}
              selected={selected}
              isLocked={isLocked}
              disableRipple 
              key={id}
              sx={{ mb: 1 }}
            >
              {selected ? <CheckIcon /> : <SegmentIcon />}
              <Typography variant='body1' sx={{ ml: 2, fontWeight: 500, color: 'blacks.90' }}>
                {name}
              </Typography>
            </StyledMenuItem>
          );
        })}
    </>
  );
}

function ChannelsTab({ selectedWorkspaceIds, workspaces, onChannelChange, disabled }) {
  if (!workspaces?.length) {
    return null;
  }

  return (
    <>
      <Header>
        <Typography variant='body1' sx={{ color: 'blacks.60', fontWeight: 500 }}>
          Channels
        </Typography>
      </Header>
      {[...workspaces]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((workspace) => {
          const { name, id } = workspace;
          const selected = selectedWorkspaceIds.includes(id);

          if (disabled) {
            return (
              <StyledMenuItem 
                isLocked={disabled}
                key={id}
                sx={{ mb: 1 }}
              >
                <LockIcon />
                <Typography variant='body1' sx={{ ml: 2, fontWeight: 500, color: 'blacks.90' }}>
                  {name}
                </Typography> 
              </StyledMenuItem>
            );
          }

          return (
            <StyledMenuItem 
              onClick={() => {
                if (!disabled) {
                  onChannelChange(id);
                }
              }}
              selected={selected}
              isLocked={disabled}
              disableRipple 
              key={id}
              sx={{ mb: 1 }}
            >
              {selected ? <CheckIcon /> : <SegmentIcon />}
              <Typography variant='body1' sx={{ ml: 2, fontWeight: 500, color: 'blacks.90' }}>
                {name}
              </Typography>
            </StyledMenuItem>
          );
        })}
    </>
  );
}

export default function FilterDropdown({ 
  workspaceId, 
  selectedSegmentIds, 
  onSegmentChange, 
  tenantId,
  selectedTeamIds,
  onTeamChange,
  onChannelChange,
  selectedWorkspaceIds,
  workspaces,
  isPulse
}) {
  const { isAdminMember, currentUser, currentTenant } = useContext(UserContext);
  const { isEnterprise } = currentTenant || {};
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);
  const open = Boolean(anchorEl);

  const { data: matterTeamsData } = useQuery(getMatterTeams, {
    variables: {
      tenantId: parseInt(tenantId)
    },
    fetchPolicy: 'network-only',
    skip: !tenantId
  });

  const { data: segmentsData } = useQuery(getSegments, {
    variables: {
      tenantId: currentTenant?.id
    },
    fetchPolicy: 'network-only',
    skip: !currentTenant || !isAdminMember
  });
  const { segments = [] } = segmentsData || {};
  const { matterTeams = [] } = matterTeamsData || {};

  const managerPersonIds = matterTeams.reduce((acc, team) => {
    const managerIds = team.managers.map(({ person }) => parseInt(person.id));

    return [...acc, ...managerIds];
  }, []);

  const teamsManager = managerPersonIds.includes(currentUser?.personId);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (_, newValue) => {
    setValue(newValue);
  };

  let channelsTab = [];
  let ChannelTabComponent = null;

  if (isEnterprise && onChannelChange) {
    ChannelTabComponent = (
      <ChannelsTab 
        handleClose={handleClose}
        workspaces={workspaces}
        selectedWorkspaceIds={selectedWorkspaceIds}
        onChannelChange={onChannelChange}
        disabled={selectedTeamIds?.length}
      />
    );

    channelsTab = [{
      label: 'Channels',
      TabComponent: ChannelTabComponent
    }];
  }
  const tabs = [
    {
      label: 'All',
      TabComponent: (
        <>
          {ChannelTabComponent}
          <TeamsTab 
            handleClose={handleClose}
            selectedTeamIds={selectedTeamIds}
            onTeamChange={onTeamChange}
            workspaceId={workspaceId}
            isAdmin={isAdminMember}
            teams={matterTeams}
            disabled={selectedWorkspaceIds?.length}
            isPulse={isPulse}
          />
          <SegmentsTab 
            handleClose={handleClose}
            workspaceId={workspaceId}
            segments={segments}
            onSegmentChange={onSegmentChange}
            isPulse={isPulse}
            selectedSegmentIds={selectedSegmentIds}
          />
        </>
      )
    },
    ...channelsTab,
    {
      label: 'Groups',
      TabComponent: (
        <TeamsTab 
          handleClose={handleClose}
          selectedTeamIds={selectedTeamIds}
          onTeamChange={onTeamChange}
          workspaceId={workspaceId}
          isAdmin={isAdminMember}
          teams={matterTeams}
          disabled={selectedWorkspaceIds?.length}
        />
      )
    },
    {
      label: 'Segments',
      TabComponent: (
        <SegmentsTab 
          handleClose={handleClose}
          workspaceId={workspaceId}
          segments={segments}
          onSegmentChange={onSegmentChange}
          selectedSegmentIds={selectedSegmentIds}
        />
      )
    },
  ];

  function getInnerComponent() {
    if (teamsManager && !isAdminMember) {
      return (
        <TeamsTab 
          handleClose={handleClose}
          selectedTeamIds={selectedTeamIds}
          onTeamChange={onTeamChange}
          workspaceId={workspaceId}
          isAdmin={isAdminMember}
          teams={matterTeams}
        />
      );
    }

    if (onTeamChange) {
      return (
        <Box sx={{ height: '100%' }}>
          <Box
            sx={{ 
              borderBottom: 1, 
              borderColor: 'divider'
            }}
          >
            <Tabs
              orientation="horizontal"
              value={value}
              onChange={handleTabChange}
              allowScrollButtonsMobile
            >
              {tabs.map(({ label }) => <CustomTab label={label} key={label} />)}
            </Tabs>
          </Box>
          {tabs.map(({ TabComponent, label }, idx) => (
            <TabPanel value={value} index={idx} key={label}>
              <ScrollBox>
                {TabComponent}
              </ScrollBox>
            </TabPanel>
          ))}
        </Box> 
      );
    }
  
    return (
      <SegmentsTab 
        handleClose={handleClose}
        workspaceId={workspaceId}
        segments={segments}
        onSegmentChange={onSegmentChange}
        selectedSegmentIds={selectedSegmentIds}
      />
    );
  }

  const count = (selectedSegmentIds?.length || 0) + (selectedTeamIds?.length || 0) + (selectedWorkspaceIds?.length || 0);

  return (
    <Box>
      <FilterButton
        variant='outlined'
        onClick={handleClick}
        endIcon={count ? (
          <SegmentsCount>
            <span>{count}</span>
          </SegmentsCount>
        ): (
          <KeyboardArrowDownIcon />
        )}
        isActive={open}
        disableRipple
      >
        Filters
      </FilterButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {getInnerComponent({ onTeamChange, value, handleTabChange, tabs, teamsManager, isAdminMember })}
      </StyledMenu>
    </Box>
  );
}
