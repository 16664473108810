import React, { useState, useEffect } from 'react';
import { Advisors, Button as MatterButton, Modal, Input } from '@matterapp/matter-ui';
import { Divider, Typography, Box } from '@mui/material';
import saveCustomHrisProperty from 'graphql-queries/mutations/hris/saveCustomHrisProperty';
import deleteCustomHrisProperty from 'graphql-queries/mutations/hris/deleteCustomHrisProperty';
import getCustomHrisProperties from 'graphql-queries/queries/hris/getCustomHrisProperties';
import { useMutation } from '@apollo/client';
import Toast from 'components/Toast/Toast';
import styled from 'styled-components';
import RemoveButton from 'routes/pages/surveys/SurveyEditor/Builder/RemoveButton';
import { isEqual } from 'lodash';
import { Confirm } from '@matterapp/matter-ui';

export const InputWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%',
  input: {
    width: '100%',
    [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    }
  }
}));

export const LineItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}));

export const StyledModalBody = styled(Modal.Body)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  overflow: 'visible',
  '& > div:first-child': {
    overflowY: 'visible'
  },
}));

export const StyledModal = styled(Modal)(({ theme }) => ({
  '.ReactModal__Content': {
    overflow: 'visible',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      borderRadius: 0
    }
  }
}));

const TYPE_OPTIONS = [{
  label: 'Text',
  value: 'text'
},
{
  label: 'Choices',
  value: 'choices'
},
{
  label: 'Date',
  value: 'date'
},
{
  label: 'Numeric',
  value: 'numeric'
}];

function RenderChoices({ options, updateOptions, canDelete }) {
  return (
    <Box>
      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
        Property Values
      </Typography>
      {options.map((value, index) => {
        return (
          <Box 
            key={index} 
            sx={{ mt: 2, display: 'flex', flexDirectionL: 'row', alignItems: 'center' }} 
          >
            <Input 
              value={value}
              focusOnMount={index === options.length - 1}
              disabled={!canDelete}
              onChange={(e) => {
                const newOptions = options.map((option, i) => {
                  if (i === index) {
                    return e.target.value;
                  }

                  return option;
                });

                updateOptions(newOptions);
              }}
              placeholder={`Value ${index + 1}`}
            />
            {options.length > 1 && canDelete && (
              <RemoveButton 
                sx={{ ml: 2 }} 
                onClick={() => {
                  const newOptions = options.filter((_, i) => i !== index);

                  updateOptions(newOptions);
                }}
              />
            )}
          </Box>
        );
      })}
      {canDelete && (
        <Box sx={{ mt: 2 }}>
          <MatterButton 
            size={'S'} 
            onClick={() => updateOptions([...options, ''])}
          >
            Add Option
          </MatterButton>
        </Box>
      )}
    </Box>
  );
}

export default function EditPropertyModal(props) {
  const { isOpen, onClose, tenant, property, canDelete } = props;
  const { id: tenantId } = tenant;
  const [creatingNew, updateCreatingNew] = useState(false);
  const [propertyName, updatePropertyName] = useState('');
  const [propertyType, updatePropertyType] = useState(TYPE_OPTIONS[0].value);
  const [options, updateOptions] = useState(['']);
  const [canSave, updateCanSave] = useState(false);
  const [removingProperty, updateRemovingProperty] = useState(null);
  const [saveCustomHrisPropertyMutation, { loading: savingProperty, data: savedProperty, error }] = useMutation(saveCustomHrisProperty);
  const [deleteCustomHrisPropertyMutation] = useMutation(deleteCustomHrisProperty);
  const nameHasError = propertyName?.trim().length > 60;

  useEffect(() => { 
    if (error) {
      Toast.error(error?.message);
    }
  }, [error]);

  useEffect(() => {
    if (!isOpen) {
      updatePropertyName('');
      updatePropertyType(TYPE_OPTIONS[0].value);
      updateCreatingNew(false);
      updateOptions(['']);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!property?.label) {
      updateCreatingNew(true);
    } else {
      updateCreatingNew(false);
      updatePropertyName(property.label);
      updatePropertyType(property.type);
      updateOptions(property?.options || ['']);
    }
  }, [property]);

  useEffect(() => {
    if (!savingProperty && savedProperty) {
      if (creatingNew) {
        Toast.success('You successfully added a custom property');
      } else {
        Toast.success('You successfully update a custom property');
      }

      onClose();
    }
  }, [savingProperty, savedProperty]);

  useEffect(() => {
    let optionsAreValid = true;
    let optionsChanged = true;
    const trimmed = propertyName?.trim();
    
    if (!trimmed || trimmed.length < 3 || nameHasError) {
      updateCanSave(false);
      return;
    }
    
    if (propertyType === 'choices') {
      optionsAreValid = options.length && !options.find((option) => !option);

      if (!creatingNew) {
        optionsChanged = !isEqual(property.options, options);
      }

      updateCanSave(trimmed !== property?.label || (optionsAreValid && optionsChanged));
    } else {
      updateCanSave(trimmed !== property?.label);
    }

  }, [propertyName, propertyType, options]);

  return (
    <StyledModal 
      isOpen={isOpen} 
      onClose={onClose}
      size="large"
    >
      <Modal.Header
        header={creatingNew ? 'Create Member Property' : 'Edit Member Property'}
        right={<Modal.Buttons.CloseIcon onClick={onClose} />}
      />
      <Confirm
        header="Are you sure?"
        subHeader={`Deleting the property will delete all associated data for all members.`}
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Yes, Delete"
        confirmButtonIsDestructive
        isOpen={!!removingProperty}
        onClickCancel={() => updateRemovingProperty(null)}
        onClickConfirm={async () => {
          await deleteCustomHrisPropertyMutation({
            variables: {
              id: property.id
            },
            refetchQueries: [{ query: getCustomHrisProperties, variables: { tenantId } }]
          });

          updateRemovingProperty(null);
          onClose();

          Toast.success('Your property has been deleted');
        }}
      />
      <StyledModalBody>
        <LineItem>
          <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
            Property Name
          </Typography>
          <InputWrapper>
            <Input
              type="text"
              placeholder="Enter a property name"
              onChange={(e) => updatePropertyName(e.target.value)}
              value={propertyName} 
              errorMessage={nameHasError ? 'Property name must be less than 60 characters' : ''}
              showErrorMessageBelow
            />
          </InputWrapper>
        </LineItem>
        <LineItem>
          <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
            Property Type
          </Typography>
          <InputWrapper>
            <Advisors.RecurringModal.Dropdown
              options={TYPE_OPTIONS}
              disabled={!creatingNew}
              selectedOption={TYPE_OPTIONS.find(({ value }) => value === propertyType)}
              handleChangeSelection={(choice) => {
                updatePropertyType(choice.value);
              }}
              width='100%'
              position={'absolute'}
            />
          </InputWrapper>
        </LineItem>
        {propertyType === 'choices' && (
          <RenderChoices 
            options={options}
            updateOptions={updateOptions}
            updateCanSave={(canSave) => updateCanSave(!!propertyName && canSave)}
            canDelete={canDelete}
          />
        )}
        {!creatingNew && canDelete && propertyName !== 'Location' && (
          <>
            <Divider />
            <Typography variant='body1' sx={{ fontWeight: 'bold', mt: 3 }}>
              Delete Property
            </Typography>
            <Typography variant='body1' sx={{ mt: 1, mb: 3 }}>
              <b>Warning:</b> Deleting the property will delete all associated data for all members.
            </Typography>
            <MatterButton 
              color='red'
              size={'S'} 
              onClick={() => {
                updateRemovingProperty(property.id);
              }}
            >
              Delete
            </MatterButton>
          </>
        )}
        {!creatingNew && !canDelete && (
          <>
            <Divider />
            <Typography variant='body1' sx={{ fontWeight: 'bold', mt: 3 }}>
              Member Property in Use by Segment(s)
            </Typography>
            <Typography variant='body2' sx={{ mt: 1 }}>
              Member properties that are used in segments cannot have their property types or values changed, 
              nor can they be deleted. To make modifications, first remove the member property from all segments.
            </Typography>
          </>
        )}
      </StyledModalBody>
      <Modal.Footer.WithCancelSave
        canClickSave={canSave && !savingProperty}
        saveLabel={savingProperty ? 'Saving...' : 'Save'}
        onClickCancel={onClose}
        onClickSave={() => {
          saveCustomHrisPropertyMutation({
            variables: {
              tenantId,
              id: property?.id,
              propertyDetails: {
                label: propertyName.trim(),
                type: propertyType,
                options
              }
            },
            refetchQueries: [{ query: getCustomHrisProperties, variables: { tenantId } }]
          });
        }}
        sendIsLoading={savingProperty}
      />
    </StyledModal>
  );
}
