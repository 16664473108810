import React from 'react';
import Kudos from '../../Kudos';
import Panel from '../../Panel';
import AvatarHeader from '../AvatarHeader';
import { Footer } from '../FeedItem';
import FeedItemLayout from '../FeedItemLayout';

const KUDOS_HEADER = 'gave you Kudos.';

export default class PersonalKudos extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      hasPlayedKudos: false,
    };
    this.kudosRef = React.createRef();
    this.playTimeout = null;
  }

  componentDidUpdate() {
    const { hasPlayedKudos } = this.state;
    const { renderProps = {} } = this.props;
    const { windowHeight } = renderProps;
    if (
      !hasPlayedKudos &&
      windowHeight &&
      this.kudosRef.current &&
      this.kudosRef.current.getBoundingClientRect
    ) {
      const bottomPosition = windowHeight - 40;
      const kudosTopPosition = this.kudosRef.current.getBoundingClientRect()
        .top;
      if (kudosTopPosition < bottomPosition && !hasPlayedKudos) {
        this.playTimeout = setTimeout(() => {
          this.setState({ hasPlayedKudos: true });
        }, 500);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.playTimeout);
  }

  renderHeader = () => {
    const { giver, showMenu, onClickDelete, isWholeChannel } = this.props;

    return (
      <AvatarHeader
        equalPadding
        header={giver.fullName}
        subheader={isWholeChannel ? 'gave kudos to Everyone' : KUDOS_HEADER}
        photoUrl={giver.photoUrl}
        showMenu={showMenu}
        onClickDelete={onClickDelete}
      />
    );
  };

  render() {
    const { feedItemProps, footerProps, renderProps = {}, kudos, totalKudosList } = this.props;
    const { note, type } = kudos;
    const { hideFooter } = footerProps;

    return (
      <Panel.Base header={this.renderHeader()} hideBottomMargin={feedItemProps.hideBottomMargin}>
        <FeedItemLayout.Body hideFooter={hideFooter}>
          <Kudos.Card
            className={`kudos-${renderProps.index}`}
            ref={this.kudosRef}
            playAnimation={this.state.hasPlayedKudos}
            playAnimationOnClick
            playAnimationOnHover
            text={note}
            type={type}
            totalKudosList={totalKudosList}
          />
        </FeedItemLayout.Body>
        {hideFooter ? null : <Footer {...footerProps} />}
      </Panel.Base>
    );
  }
}


PersonalKudos.defaultProps = {
  feedItemProps: {}
};