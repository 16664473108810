import React from 'react';

export const PLAN = { YEAR: 'Yearly', MONTH: 'Monthly'};
export const RECURRING_INTERVAL = { YEAR: 'year', MONTH: 'month'};
export const INTERVAL_LABEL = { YEAR: 'annually', MONTH: 'monthly' };
export const INTERVAL_LABEL_CAP = { YEAR: 'Annually', MONTH: 'Monthly' };

export const UPDATE_BILLING_PLAN = {
  HEADER: 'Are you sure?',
  SUBHEADER: <>This billing update will take effect immediately. For any questions, please reach out to <a href="mailto:hello@matterapp.com" rel="noopener noreferrer" target="_blank">hello@matterapp.com</a>.</>
};

export const monthsOptions = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const MESSAGE_LENGTH = {
  maxLength: 130
};

export const renewLinkHref = "mailto:billing@matterapp.com?subject=Looking%20to%20renew%20expiring%20plan%20-%20Matter%20Billing&body=Hi%2C%20I'm%20looking%20to%20renew%20my%20expiring%20Matter%20subscription.%20Please%20help.";
