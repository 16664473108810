import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import sendRewardRequest from 'graphql-queries/queries/rewards/sendRewardRequest';
import getRewardsHistory from 'graphql-queries/queries/rewards/getRewardHistory';
import getAllRewardRequests from 'graphql-queries/queries/rewards/getAllRewardRequests';
import getCurrentMemberProperties from 'graphql-queries/queries/rewards/getCurrentMemberProperties';
import getPendingRewardRequests from 'graphql-queries/queries/rewards/getPendingRewardRequests';
import getRewardsCatalog from 'graphql-queries/queries/rewards/getRewardsCatalog';
import { Clickable, IconNew as Icon } from '@matterapp/matter-ui';
import { colors, font, lineHeight, media, spacing } from '@matterapp/matter-theme';
import RewardsBanner from './RewardsBanner';
import RedemptionHistory from './RedemptionHistory';
import RewardsList from './RewardsList';
import RewardsFilterBar from './FilterBar';
import RewardsRedeemModal from './modals/RedeemModal';
import { COIN_BALANCE_TEXT } from '../consts';
import { REWARD_TYPES } from './consts';
import updateMemberRewardsCountry from 'graphql-queries/mutations/workspaceMembers/updateMemberRewardsCountry';
import Toast from 'components/Toast/Toast';

const sharedStyles = `
  width: 100%;
  height: ${spacing.doubleAndHalf};
  background: white;
  padding: ${spacing.half} ${spacing.singleAndHalf};
  color: ${colors.blacks[50]};
  font-size: ${font.size.S2};
  line-height: ${lineHeight.M};
  border-bottom: 1px solid ${colors.blacks[10]};
`;

const MobileCoinBalance = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -${spacing.singleAndHalf};
  ${sharedStyles}

  ${media.M`
    display: none;
  `}
`;

const MobileViewRedemptionHistory = styled(Clickable)`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.singleAndHalf};

  &:hover {
    color: ${colors.blues[50]};
  }

  &:active {
    color: ${colors.purples[60]}
  }

  ${sharedStyles}

  ${media.M`
    display: none;
  `}
`;

const LinkIcon = styled(Icon).attrs({
  name: 'internalLink',
})`
  margin-left: 6px;
  & path {
    fill: currentColor;
  }
`;

const DEFAULT_COUNTRY = 'US';
const DEFAULT_TYPE = 'all';

function RewardsMainColumn({ 
  isViewHistory, 
  currentCoinBalance, 
  onClickHistory, 
  onClickBack,
  memberRewardsCountry,
  rewardSettings,
  tenant
}) {
  const { isAdmin, isOwner, id: tenantId } = tenant || {};
  const isAdminMember = isAdmin || isOwner;
  const { isRewardsActive, disabledRewardTypes, autoApprove } = rewardSettings;
  const [selectedRewardType, setSelectedRewardType] = useState(DEFAULT_TYPE);
  const [selectedCountry, setSelectedCountry] = useState(memberRewardsCountry || DEFAULT_COUNTRY);
  const [selectedReward, setSelectedReward] = useState();
  const [isRedeemModalOpen, setIsRedeemModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [setMemberRewardsCountry] = useMutation(updateMemberRewardsCountry);

  const [requestReward, { loading: isRequestingReward, error: requestError }] = useMutation(sendRewardRequest, {
    refetchQueries: () => {
      const queriesToRefetch = [
        { 
          query: getRewardsHistory,
          variables: {
            tenantId,
          }
        },
        { 
          query: getRewardsCatalog,
          variables: {
            tenantId,
            country: selectedCountry
          }
        },
        { 
          query: getCurrentMemberProperties,
          variables: {
            tenantId,
          }
        },
        {
          query: getAllRewardRequests,
          variables: {
            tenantId,
          }
        }
      ];

      if (isAdminMember) {
        queriesToRefetch.push(
          { 
            query: getPendingRewardRequests,
            variables: {
              tenantId,
            }
          }
        );
      }
      return queriesToRefetch;
    },
  });

  useEffect(() => {
    setMemberRewardsCountry({
      variables: {
        tenantId,
        country: selectedCountry
      }
    });
  }, [selectedCountry]);

  useEffect(() => {
    if (requestError) {
      Toast.error(requestError.message);
    }
  }, [requestError]);

  if (isViewHistory) {
    return (
      <RedemptionHistory 
        autoApprove={autoApprove}
        tenantId={tenantId} 
        isAdminMember={isAdminMember}
        onClickBack={() => {
          setSelectedReward();
          onClickBack();
        }} 
        isConfirmModalOpen={isConfirmModalOpen}
        rewardType={selectedReward?.rewardType}
        onCloseModal={() => setIsConfirmModalOpen(false)}
      />
    );
  }

  const onClickRequestReward = async (reward) => {
    const { rewardType } = reward;
    setSelectedReward(reward);

    if (rewardType === REWARD_TYPES.CUSTOM) {
      await requestReward({
        variables: {
          tenantId,
          rewardItemId: reward.id,
          coinsValue: reward.value,
          rewardType: 'custom'
        }
      });
    } else {
      const { rewardItemId, faceValue, coinsValue } = reward;
      await requestReward({
        variables: {
          tenantId,
          rewardItemId,
          faceValue,
          coinsValue,
        }
      });
    }
    setIsRedeemModalOpen(false);
    setIsConfirmModalOpen(true);
    onClickHistory();
  };

  return (
    <>
      {!isRewardsActive ? 
        <RewardsBanner /> :
        <>
          <RewardsFilterBar
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            selectedType={selectedRewardType}
            setSelectedType={setSelectedRewardType}
            disabledRewardTypes={disabledRewardTypes}
          />
          <MobileCoinBalance>
            <>{COIN_BALANCE_TEXT}</>
            <strong>{currentCoinBalance.toLocaleString('en-US')}</strong>
          </MobileCoinBalance>
          <MobileViewRedemptionHistory onClick={onClickHistory}>
            View Redemption History
            <LinkIcon />
          </MobileViewRedemptionHistory>
        </>
      }
      <RewardsList
        tenantId={tenantId}
        selectedCountry={selectedCountry}
        selectedRewardType={selectedRewardType}
        isRewardsActive={isRewardsActive}
        onClickReward={(reward) => {
          setSelectedReward(reward);
          setIsRedeemModalOpen(true);
        }}
      />
      {selectedReward &&
        <RewardsRedeemModal
          isOpen={isRedeemModalOpen}
          isRequestingReward={isRequestingReward}
          selectedReward={selectedReward}
          currentCoinBalance={currentCoinBalance}
          onClickComplete={onClickRequestReward}
          onClickClose={() => {
            setIsRedeemModalOpen(false);
            setSelectedReward();
          }}
        />
      }
    </>
  );
};

RewardsMainColumn.propTypes = {
  rewardSettings: PropTypes.object,
  isViewHistory: PropTypes.bool,
  onClickHistory: PropTypes.func,
  onClickBack: PropTypes.func,
  currentCoinBalance: PropTypes.number,
  memberRewardsCountry: PropTypes.string
};

export default RewardsMainColumn;