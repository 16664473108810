import React from 'react';

export default function ToTheMoon(props) {
  return (
    <img 
      width="200"
      height="120"
      style={{ position: 'absolute', bottom: 0, right: 0 }}
      src="https://mattertest.imgix.net/kudos/3d/tothemoon-200x120.png" 
      alt="To the moon" 
      {...props} 
    />
  );
}
