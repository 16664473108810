import React, { useContext } from 'react';
import { 
  SettingsContainer
} from '../../styles';
import AdminsSettingsHoc from '../../AdminsSettingsHoc';
import UserContext from 'context/UserContext/UserContext';
import { AppLayout } from 'modules';
import { Resources } from '@matterapp/routing';
import MatterTeamDetails from './MatterTeamDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { StyledPaper } from '../../DataProperties/styles';
import SettingsNavigation from '../../SettingsNavigation';
import { useQueryParams } from 'hooks';
import getMatterTeam from 'graphql-queries/queries/matterTeams/getMatterTeam';
import { useQuery } from '@apollo/client';
import { Loader } from '@matterapp/matter-ui';

export default function TeamDetails() {  
  const { currentWorkspaceId: workspaceId, currentTenant, currentWorkspace, isLoadingCurrentUser } = useContext(UserContext);
  const { queryParams } = useQueryParams();
  const settingsRedirectUrl = queryParams.isChannelSetting 
    ? Resources.workspaceSettingsMain.path({ workspaceId }, { isChannelSetting: true }) 
    : Resources.workspaceSettingsMain.path({ workspaceId });
  const params = useParams();
  const { teamId } = params || {};

  const navigate = useNavigate();

  const { data: matterTeamData, refetch: refetchTeam, loading: loadingTeam } = useQuery(getMatterTeam, {
    variables: {
      tenantId: parseInt(currentTenant?.id),
      teamId: parseInt(teamId)
    },
    skip: !currentTenant
  });

  const { matterTeam } = matterTeamData || {};

  const isLoading = !currentWorkspace || isLoadingCurrentUser || loadingTeam;

  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={(
        <AdminsSettingsHoc redirectUrl={settingsRedirectUrl}>
          <SettingsContainer sx={{ maxWidth: '100%!important' }}>
            <SettingsNavigation 
              currentLabel={matterTeam?.name}
              settingsRedirectUrl={settingsRedirectUrl}
              isChannelSetting={queryParams.isChannelSetting}
              childCrumbs={[
                {
                  label: 'Groups',
                  href: Resources.workspaceSettingsTeams.path({ workspaceId })
                }
              ]}
            />
            {isLoading 
              ? <Loader /> 
              : (
                <StyledPaper elevation={0}>
                  <MatterTeamDetails 
                    tenant={currentTenant}
                    workspace={currentWorkspace}
                    isLoading={isLoadingCurrentUser}
                    onClickCancel={() => navigate(settingsRedirectUrl)}
                    matterTeam={matterTeam}
                    refetchTeam={refetchTeam}
                  />
                </StyledPaper>
              )}
          </SettingsContainer>
        </AdminsSettingsHoc>
      )}
    />
  );
}
