import { useState, useEffect } from 'react';

export const useFeedItemRenderProps = () => {
  const [scrollTop, setScrollTop] = useState();
  const [windowHeight, setWindowHeight] = useState();
  const [scrollHeight, setScrollHeight] = useState();
  const handleScroll = () => {
    setScrollTop(window.scrollY);
    setWindowHeight(window.innerHeight);
    setScrollHeight(document.body.scrollHeight);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    scrollTop,
    scrollHeight,
    windowHeight,
  };
};

export const transformInactiveGiver = (inactiveMembers, giver) => {
  if (inactiveMembers.length && giver) {
    inactiveMembers.forEach(member => {
      if (member.person.userId === giver.id) {
        giver.isInactive = true;
      }
    });
  }

  return giver;
};

export const transformInactiveReceivers = (inactiveMembers, receivers) => {
  if (!receivers) {
    return [];
  }
  const inactiveReceivers = [...receivers];
  if (inactiveMembers.length && receivers.length) {
    inactiveMembers.forEach(member => {
      inactiveReceivers.forEach(receiver => {
        if (member.person.userId == receiver.id) {
          receiver.isInactive = true;
        } 
      });
    });
  }
  return inactiveReceivers;
};