import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import ChartComponent, { Line } from 'react-chartjs-2';
import { rgba } from 'polished';
import map from 'lodash/map';
import times from 'lodash/times';
import filter from 'lodash/filter';
import pick from 'lodash/pick';
import defaults from 'lodash/defaults';
import defaultsDeep from 'lodash/defaultsDeep';
import { getSkillPrimaryColor } from 'components/SkillUnit/helpers';
import theme, { skillColorPalettes as colorPalettes } from '@matterapp/matter-theme';
import ChartHover from './ChartHover';

const convertRatingValue = (rating) => rating / 2;

class Chart extends React.PureComponent {
  static defaultProps = {
    height: 225,
    width: 'auto',
    clickable: true,
    showHoverUser: true,
    showLineAtTheEnd: true,
    showLineAtTheStart: true,
    showScatterDataStyle: true
  };

  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static scatterItemSize = 24; // size of data pimple
  static absoluteMaxLabels = 7; // x-axis labels displayed
  static maxDateLabelWidth = 50;

  lineDataStyle = (color) => ({
    baseColor: theme.colors.purple,
    borderColor: theme.colors.purple, // the line stroke
    highlightStroke: theme.colors.purple, // the line stroke when hovered
    pointBorderColor: theme.colors.purple, // the line stroke around the point
    backgroundColor: rgba(color, 0.1), // area under the curve
    barBackgroundColor: rgba(color, 0.1), // area under the curve
    highlightFill: rgba(color, 0.1), // area under the curve hovered
    pointBackgroundColor: 'white',
    pointHoverBackgroundColor: 'white',

    lineTension: 0,
    pointBorderWidth: 2,
    pointHoverBorderWidth: 2,
    pointHitRadius: 8,
    pointRadius: 4,
    pointHoverRadius: 4,
  });

  lineDataStyleNoPoints = (color) => ({
    ...this.lineDataStyle(color),
    pointBorderColor: 'transparent',
    pointBackgroundColor: 'transparent',
    pointHoverBackgroundColor: 'transparent',

    lineTension: 0,
    pointBorderWidth: 0,
    pointHoverBorderWidth: 0,
    pointHitRadius: 0,
    pointRadius: 0,
    pointHoverRadius: 0,
  });

  static scatterDataStyle = (color) => ({
    showLine: false,

    borderColor: 'transparent', // the line stroke
    highlightStroke: 'transparent', // the line stroke when hovered
    pointBorderColor: 'transparent', // the line stroke around the point

    backgroundColor: 'transparent', // area under the curve
    barBackgroundColor: 'transparent', // area under the curve

    highlightFill: rgba(color, 0.5),
    pointBackgroundColor: rgba(color, 0.5),
    pointHoverBackgroundColor: rgba(color, 0.5),

    lineTension: 0,
    pointBorderWidth: 0,
    pointHoverBorderWidth: 0,
    pointHitRadius: Chart.scatterItemSize / 2,
    pointRadius: Chart.scatterItemSize / 2,
    pointHoverRadius: Chart.scatterItemSize / 2,
  });

  static defaultOptions = (maxStepSize, stepSize, yAxisRender = (n) => n, formatXAxis = () => ({
    unit: 'day',
    displayFormats: {
      day: 'MMM D',
    },
  })) => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      showLines: true,
      layout: {
        padding: { top: 24, right: 10 },
      },
      legend: { display: false },
      animation: {
        duration: 500,
        delay: 500
      },
      tooltips: {
        enabled: false,
      },
      hover: {
        intersect: false,
        mode: 'point',
      },
      title: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            id: 'y-axis-0',
            display: true,
            gridLines: {
              display: true,
              lineWidth: 1,
              color: 'rgba(0, 0, 0, 0.1)',
              tickMarkLength: 0,
              drawBorder: false
            },
            ticks: {
              display: true,
              beginAtZero: true,
              padding: 24,
              stepSize: stepSize,
              suggestedMax: maxStepSize,
              callback: yAxisRender,
              fontFamily: 'Inter UI',
              fontColor: theme.colors.blacks[50],
            },
          },
        ],
        xAxes: [
          {
            type: 'time',
            id: 'x-axis-0',
            offset: false,
            time: formatXAxis(),
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              source: 'labels',
              beginAtZero: true,
              display: true,
              maxRotation: 0,
              fontFamily: 'Inter UI',
              fontColor: theme.colors.blacks[50],
              callback: (label) => label.toUpperCase(),
            },
          },
        ],
      },
    };
  }

  getDateForChart = (str) => {
    return moment(str).toDate();
  };

  convertXToDate(arr) {
    return map(arr, (pt) => ({ ...pt, x: this.getDateForChart(pt.x), y: convertRatingValue(pt.y) }));
  }

  static Container = styled.div`
    position: relative;
    cursor: ${({clickable}) => clickable ? 'pointer' : 'default'};
  `;

  constructor(props) {
    super(props);
    this.state = {
      hoverPointIndex: null,
      hoverPoint: null,
      maxLabels: Chart.absoluteMaxLabels,
    };
  }

  componentDidMount() {
    this.updateMaxLabels();
  }

  handleResize = () => {
    this.updateMaxLabels();
  };

  handleHover = (event, activeItems) => {
    let hoverItem = null;

    if (activeItems.length > 0) {
      [hoverItem] = filter(
        activeItems,
        (i) => i._datasetIndex >= 0
      );
    }

    if (hoverItem) {
      if (this.state.hoverPointIndex !== hoverItem._index) {
        this.setState({
          hoverPointIndex: hoverItem._index,
          hoverPoint: pick(hoverItem._model, ['x', 'y']),
        });
      }
    } else if (this.state.hoverPointIndex != null) {
      this.setState({ hoverPointIndex: null });
    }
  };

  handleClick = (event, activeItems) => {
    const { ratings } = this.props.data;
    const { hoverPointIndex } = this.state;

    if (typeof this.state.hoverPointIndex === 'number') {
      if (ratings[hoverPointIndex].onClick) {
        return ratings[hoverPointIndex].onClick();
      }
    }
  }

  updateMaxLabels = () => {
    const width = this.container.offsetWidth;
    const sidePadding = 20;
    const { xAxisMaxLabels } = this.props;
    let maxLabels;
    if (xAxisMaxLabels) {
      maxLabels = xAxisMaxLabels;
    } else {
      maxLabels = Math.min(
        Chart.absoluteMaxLabels,
        Math.floor((width - sidePadding) / Chart.maxDateLabelWidth));
    }
    if (this.state.maxLabels !== maxLabels) {
      this.setState({ maxLabels });
    }
  };

  generateLabels = () => {
    const {
      data: { startDate, endDate },
    } = this.props;
    const { maxLabels } = this.state;
    return this.generateDateLabels(startDate, endDate, maxLabels);
  };

  generateDateLabels = (startDate, endDate, numLabels) => {
    const first = this.getDateForChart(startDate);
    const last = this.getDateForChart(endDate);
    const interval = (last - first) / (numLabels - 1);

    const filteredLabels = [
      ...times(numLabels - 1, (i) =>
        moment(first.getTime() + interval * i).toDate()
      ),
      last,
    ];

    return filteredLabels;
  };

  formatDataStyle = () => {
    const { data, skillName, showLineAtTheEnd, showLineAtTheStart, showScatterDataStyle, generateLabels } = this.props;

    if (!data?.averages?.length) {
      return {
        labels: [],
        datasets: []
      };
    }

    const color = skillName ? getSkillPrimaryColor({ name: skillName }) : (colorPalettes['Purple']).primary;


    const datasets = [
      defaults(
        { label: 'averages' },
        { data: this.convertXToDate(data.averages) },
        this.lineDataStyle(color)
      ),
    ];

    if (showScatterDataStyle) {
      datasets.push(
        defaults(
          { label: 'ratings' },
          { data: this.convertXToDate(data.ratings) },
          Chart.scatterDataStyle(color)
        )
      );
    }

    if (showLineAtTheStart && data.averages[0].x !== data.startDate) {
      datasets.splice(
        1,
        0,
        defaults(
          { label: 'line-start' },
          {
            data: this.convertXToDate([
              { x: data.startDate, y: convertRatingValue(data.averages[0].y) },
              data.averages[0]
            ]),
          },
          this.lineDataStyleNoPoints(color)
        )
      );
    }
    
    const lastIndex = data.averages.length - 1;
    // If the last data point is not on the end date, create a continuing line.	
    if (showLineAtTheEnd && data.averages[lastIndex].x !== data.endDate) {
      datasets.push(	
        defaults(
          { label: 'line-end' },	
          {	
            data: this.convertXToDate([	
              data.averages[lastIndex],	
              { x: data.endDate, y: convertRatingValue(data.averages[0].y) },	
            ]),	
          },	
          this.lineDataStyleNoPoints(color)	
        )	
      );	
    }

    return {
      ...data,
      labels: generateLabels ? generateLabels() : this.generateLabels(),
      datasets,
    };
  };

  render() {
    const { data, yAxisRender, height, width, skillName, showHoverUser, clickable, maxStepSize, stepSize, formatXAxis } = this.props;

    const options = defaultsDeep(
      {
        onResize: this.handleResize,
        onHover: clickable ? this.handleHover : () => null,
        onClick: clickable ? this.handleClick : () => null,
        events: ['mousemove', 'mouseout', 'click'],
      },
      Chart.defaultOptions(maxStepSize || 5, stepSize, yAxisRender, formatXAxis)
    );

    const formattedData = this.formatDataStyle();

    const { hoverPoint, hoverPointIndex } = this.state;

    let hoverUser = null;
    if (showHoverUser && typeof hoverPointIndex === 'number') {
      const skillRating = data.ratings[hoverPointIndex];

      hoverUser = (
        <ChartHover
          point={hoverPoint}
          rating={{ ...skillRating, y: convertRatingValue(skillRating.y) }}
          skillName={skillName}
        />
      );
    }

    return (
      <Chart.Container
        ref={(el) => {
          this.container = el;
        }}
        clickable={ clickable || typeof hoverPointIndex === 'number' && data.ratings[hoverPointIndex].onClick }
      >
        {showHoverUser && hoverUser}
        <Line
          data={formattedData}
          options={options}
          width={width}
          height={height}
        />
      </Chart.Container>
    );
  }
}

// Monkey patch this so the chart doesn't complain. We need to pass it 'auto'...
ChartComponent.propTypes.width = Chart.propTypes.width;
ChartComponent.propTypes.height = Chart.propTypes.height;

export default Chart;
