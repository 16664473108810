import styled from 'styled-components';
import { 
  Box
} from '@mui/material';
import { Modal, Input, Dropdown } from '@matterapp/matter-ui';

export const MainWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    marginBottom: theme.spacing(2)
  }
}));

export const LineItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}));

export const SearchInput = styled(Input)(({ theme }) => ({
  height: theme.spacing.quad,
  marginTop: theme.spacing.half,
  marginBottom: theme.spacing.single,
  width: '100%',
  'input&': {
    '-webkit-appearance': 'none',
    padding: `${theme.spacing.threeQuarters} ${theme.spacing.single}`,
    borderColor: theme.colors.blacks[30],
    borderRadius: theme.borderRadius.M,
    borderWidth: '1px',
    transition: `border ${theme.transitions.times.singleAndHalf}`,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

export const SearchInputWithTags = styled(Dropdown.Email.Tags)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
  'input&': {
    borderColor: theme.palette.blacks[30],
    borderRadius: theme.spacing(1),
    borderWidth: '1px'
  },
  svg: {
    display: 'none'
  }
}));

export const StyledModalBody = styled(Modal.Body)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  overflow: 'visible',
  '& > div:first-child': {
    overflowY: 'visible'
  }
}));

export const StyledModal = styled(Modal)(({ theme }) => ({
  '.ReactModal__Content': {
    overflow: 'visible',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      borderRadius: 0
    }
  }
}));

export const InputWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  width: '100%',
  input: {
    width: '100%',
    [`@media screen and (max-width: ${theme.breakpoints[1]})`]: {
    }
  }
}));
