import React from 'react';
import { Tooltip, Advisors } from '@matterapp/matter-ui';
import {
  SubHeader,
  InputWrapper,
  ResetButton,
  StyledInput,
  AllowanceBlock,
  StyledIcon,
  DropdownContainer,
  AllowanceResetDateContainer
} from './styles';
import { colors } from '@matterapp/matter-theme';
import getNextAllowanceResetDateQuery from 'graphql-queries/queries/tenant/getNextAllowanceResetDate';
import { useQuery } from '@apollo/client';
import { Box, Typography, Divider } from '@mui/material';

export const FREQUENCY_OPTIONS = [
  {
    label: 'Weekly',
    value: 'weekly',
    capitalized: 'Weekly',
    multiplier: 1
  },
  {
    label: 'Bi-Weekly',
    value: 'biweekly',
    capitalized: 'Bi-Weekly',
    multiplier: 2
  },
  {
    label: 'End of every Month',
    value: 'monthly',
    capitalized: 'Monthly',
    multiplier: 4
  },
  {
    label: 'End of every Quarter',
    value: 'quarterly',
    capitalized: 'Quarterly',
    multiplier: 12
  }];

export const MAX_ALLOWED_COINS = 250000;
const DEFAULT_ALLOWED_COINS = 80;

export default function DefaultAllowanceSection(props) {
  const { 
    selectedMaxCoins, 
    allowanceResetFrequency, 
    onError, 
    onChange, 
    hasError,
    nextAllowanceResetDate,
    tenantId
  } = props;
  const selectedFrequency = FREQUENCY_OPTIONS.find(({ value }) => value === allowanceResetFrequency);
  const { data } = useQuery(getNextAllowanceResetDateQuery, {
    variables: {
      tenantId,
      settings: {
        allowanceResetFrequency
      }
    },
    skip: !allowanceResetFrequency || !tenantId
  });

  function onAllowanceChange(_, target) {
    const value = parseInt(target.value, 10);
    onChange({ 
      maxAllowance: value || 0,
      allowanceResetFrequency
    });

    if (value > MAX_ALLOWED_COINS || value < 0) {
      onError();
    }
  }

  const coinAllowanceCopy = (
    <>
      Channel members receive a coin allowance to give with kudos and feedback.<br />
      Coin allowances expire and reset at the time of your choosing below. 
      Coins earned from kudos and feedback do not expire or reset.
      <br />
      <br />
      Use the&nbsp;
      <a href="https://matterapp.com/rewards-calculator" target="_blank" rel="noopener noreferrer">
        Rewards Calculator
      </a> to determine allowance based on your budget.
    </>
  );

  return (
    <Box>
      <Typography variant='h3' sx={{ mb: 3 }}>Coin Allowance</Typography>
      <Typography variant='body1'>{coinAllowanceCopy}</Typography>
      <Divider />
      <SubHeader>
          Default Allowance
        <Tooltip
          dark
          content={`We recommend 80 coins per week to start. This translates to $10 USD/employee/month. Don’t worry, you can always change it.`}
        >
          <StyledIcon 
            name="infoCircle" 
            fill={colors.blacks[30]}
          />
        </Tooltip>
      </SubHeader>
      <Typography variant='body1'>Set default allowances for all channel members.</Typography>
      <AllowanceBlock>
        <div>
          <h5>{selectedFrequency.capitalized} Coins per Channel Member</h5> 
            10 Matter Coins = 1 USD
        </div>
        <InputWrapper>
          <ResetButton 
            onClick={() => onChange({ 
              maxAllowance: DEFAULT_ALLOWED_COINS * selectedFrequency.multiplier,
              allowanceResetFrequency
            })}
          >
            Reset
          </ResetButton>
          <StyledInput
            type="number"
            onChange={onAllowanceChange}
            errorMessage={hasError ? 'Must be between 1-250,000' : null}
            showErrorMessageBelow
            errorMessageClassName="allowanceInputError"
            value={(selectedMaxCoins || 0).toString()} 
          />
        </InputWrapper>
      </AllowanceBlock>
      <SubHeader>
        Allowance Reset Frequency
      </SubHeader>
      <Typography variant='body1'>Use it or lose it. How often the default and custom allowances are reset.</Typography>
      <DropdownContainer>
        <Advisors.RecurringModal.Dropdown
          options={FREQUENCY_OPTIONS}
          selectedOption={selectedFrequency.label}
          handleChangeSelection={(choice) => {
            onChange({ 
              allowanceResetFrequency: choice.value,
              maxAllowance: selectedMaxCoins 
            });
          }}
          width='100%'
          position={'absolute'}
        />
      </DropdownContainer>
      <AllowanceResetDateContainer>
        <Typography variant='body1'>
          Next coin allowance will reset on {data?.nextAllowanceResetDate || nextAllowanceResetDate} at 11:59pm.
        </Typography>
      </AllowanceResetDateContainer>
      <Divider />
    </Box>
  );
}
