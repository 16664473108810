import React, { useContext } from 'react';
import { 
  SettingsContainer
} from '../styles';
import AdminsSettingsHoc from '../AdminsSettingsHoc';
import UserContext from 'context/UserContext/UserContext';
import { AppLayout } from 'modules';
import { Resources } from '@matterapp/routing';
import FeedbackFridaySettingsPage from './FeedbackFridaySettings';
import { useNavigate } from 'react-router-dom';
import SettingsNavigation from '../SettingsNavigation';
import { useQueryParams } from 'hooks';
import { getRitualName } from './helpers';
import styled from 'styled-components';

const StyledSettingsContainer = styled(SettingsContainer)(({ theme }) => ({
  '.MuiPaper-root': {
    padding: theme.spacing(3)
  }
}));

export default function FeedbackFridaySettings() {  
  const { currentWorkspaceId: workspaceId, currentTenant, currentWorkspace, isLoadingCurrentUser } = useContext(UserContext);
  const isLoading = !currentWorkspace || isLoadingCurrentUser;
  const { isEnterprise } = currentTenant || {};
  const { feedbackRitualSettings } = currentWorkspace || {};
  const { customRitualName, dayOfPost } = feedbackRitualSettings || {};
  let ritualName;

  if (feedbackRitualSettings) {
    ritualName = getRitualName(dayOfPost);
  }

  const navigate = useNavigate();
  const { queryParams } = useQueryParams();
  const settingsRedirectUrl = queryParams.isChannelSetting 
    ? Resources.workspaceSettingsMain.path({ workspaceId }, { isChannelSetting: true }) 
    : Resources.workspaceSettingsMain.path({ workspaceId });

  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={(
        <AdminsSettingsHoc redirectUrl={settingsRedirectUrl}>
          <StyledSettingsContainer>
            <SettingsNavigation 
              currentLabel={customRitualName || ritualName}
              settingsRedirectUrl={settingsRedirectUrl} 
              isChannelSetting={isEnterprise}
            />
            {isLoading 
              ? null 
              : (
                <FeedbackFridaySettingsPage 
                  tenant={currentTenant}
                  workspace={currentWorkspace}
                  isLoading={isLoadingCurrentUser}
                  onClickCancel={() => navigate(settingsRedirectUrl)}
                />
              )}
          </StyledSettingsContainer>
        </AdminsSettingsHoc>
      )}
    />
  );
}
