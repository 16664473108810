import React from 'react';
import PropTypes from 'prop-types';
import UpgradeModal from 'modules/workspaces/shared/UpgradeModal';
import PurpleBanner from 'components/PurpleBanner';

const FreeTierBanner = (props) => {
  const { isAdminMember, upgradeModalProps, onClickUpgrade } = props;

  const paymentButtonLabel = isAdminMember ? 'Add Payment Info' : 'Upgrade to Pro';

  return (
    <>
      <UpgradeModal {...upgradeModalProps} />
      <PurpleBanner
        isCollapsable
        id={'free-trial-banner'}
        expandedHeader={'Trial ended'}
        collapasedHeader={'Trial ended'}
        description="Your 14-day free trial has ended and you’re on Matter’s free plan which gives you 5 free kudos per week."
        buttonLabel={paymentButtonLabel}
        onButtonClick={onClickUpgrade}
      />
    </>
  );
};

FreeTierBanner.propTypes = {
  isAdminMember: PropTypes.bool,
  onClickUpgrade: PropTypes.func,
  upgradeModalProps: PropTypes.shape({
    openUpgradeModal: PropTypes.func,
    onClickSendRequestToUpgrade: PropTypes.func,
  }),
};

export default FreeTierBanner;