import { isEqual } from 'lodash';
import { getMemberBirthdayMonthAndDay, getRoleValueFromProps } from './helpers';

export const ACTIONS = {
  UPDATE_DIRECT_MANAGER: 'UPDATE_DIRECT_MANAGER',
  RESET_INITIAL_SETTINGS: 'RESET_INITIAL_SETTINGS',
  UPDATE_TOTAL_COINS: 'UPDATE_TOTAL_COINS',
  UPDATE_BIRTHDAY_VISIBILITY: 'UPDATE_BIRTHDAY_VISIBILITY',
  UPDATE_ANNIVERSARY_VISIBILITY: 'UPDATE_ANNIVERSARY_VISIBILITY',
  UPDATE_HIRED_ON: 'UPDATE_HIRED_ON',
  UPDATE_BIRTHDAY_MONTH: 'UPDATE_BIRTHDAY_MONTH',
  UPDATE_BIRTHDAY_DAY: 'UPDATE_BIRTHDAY_DAY',
  UPDATE_ETHNICITY: 'UPDATE_ETHNICITY',
  UPDATE_GENDER: 'UPDATE_GENDER',
  UPDATE_ROLE: 'UPDATE_ROLE',
  RESET_ROLE: 'RESET_ROLE',
  UPDATE_CUSTOM_HRIS_PROPERTY: 'UPDATE_CUSTOM_HRIS_PROPERTY',
  UPDATE_CAN_SAVE: 'UPDATE_CAN_SAVE',
  UPDATE_CELEBRATION_CHANNEL: 'UPDATE_CELEBRATION_CHANNEL',
  UPDATE_JOB_TITLE: 'UPDATE_JOB_TITLE'
};

function memberPropsChanged(state) {
  const { initialSettings, settings } = state;

  const hasBirthdayChanged = initialSettings.birthMonth !== settings.birthMonth || initialSettings.birthDay !== settings.birthDay;

  const propsChanged = initialSettings.hiredOn !== settings.hiredOn 
  || hasBirthdayChanged
  || initialSettings.birthdayVisibility !== settings.birthdayVisibility
  || initialSettings.anniversaryVisibility !== settings.anniversaryVisibility
  || initialSettings.totalCoins !== settings.totalCoins
  || initialSettings.ethnicity !== settings.ethnicity
  || initialSettings.gender !== settings.gender
  || initialSettings.celebrationChannelId !== settings.celebrationChannelId
  || initialSettings.jobTitle !== settings.jobTitle;

  return propsChanged;
}

export function getInitialState({ member, isChannelPermissions }) {
  const { 
    directManager,
    totalCoins,
    birthdayVisibility,
    anniversaryVisibility,
    hiredOn,
    birthday,
    ethnicity,
    gender,
    workspaceId,
    customProperties,
    celebrationChannelId,
    jobTitle,
    hrisProps
  } = member || {};
  const [birthMonth, birthDay] = getMemberBirthdayMonthAndDay({ birthday });

  const initialSettings = {
    directManager: { 
      id: directManager?.id,
      fullName: directManager?.fullName,
      email: directManager?.email
    },
    totalCoins: totalCoins || 0,
    birthdayVisibility: birthdayVisibility || 'public',
    anniversaryVisibility: anniversaryVisibility || 'public',
    hiredOn,
    birthday,
    birthMonth, 
    birthDay,
    ethnicity,
    gender,
    celebrationChannelId,
    role: getRoleValueFromProps(member, workspaceId, isChannelPermissions),
    customProperties,
    jobTitle,
    hrisProps
  };

  return {
    canSave: false,
    roleChanged: false,
    propsChanged: false,
    customPropsChanged: false,
    managerChanged: false,
    coinsHasError: false,
    settings: { ...initialSettings },
    initialSettings
  };
}

export function getStateTransferObject(state) {
  const { 
    maxAllotedCoinPerRitualPerMember,
    allowanceResetFrequency,
    disabledRewardTypes,
    autoApprove,
    notifyAdmins,
    rewardsFundingSettings
  } = state;

  return { 
    maxAllotedCoinPerRitualPerMember,
    allowanceResetFrequency,
    disabledRewardTypes,
    autoApprove,
    notifyAdmins,
    enableRewardsFunding: !!rewardsFundingSettings.enabled,
    rewardsFundingThreshold: rewardsFundingSettings.threshold,
    rewardsFundingNotifyMembers: rewardsFundingSettings.notifyMembers
  };
}

export function memberPropsReducer(state, action) {
  switch (action.type) {
  case ACTIONS.RESET_INITIAL_SETTINGS: {
    return getInitialState(action.payload);
  }
  case ACTIONS.UPDATE_DIRECT_MANAGER: {
    const { items, value } = action.payload;
    const item = items.find((item) => item.email === value[0]);

    const directManager = {
      id: item?.id,
      fullName: item?.fullName,
      email: item?.email
    };
    
    const newSettings = {
      ...state.settings,
      directManager
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      managerChanged: directManager?.id !== state.initialSettings.directManager?.id,
      settings: newSettings
    };
  }
  case ACTIONS.UPDATE_TOTAL_COINS: {
    let coinsHasError = false;

    if (parseInt(action.payload) < 0) {
      coinsHasError = true;
    }

    const newSettings = {
      ...state.settings,
      totalCoins: parseInt(action.payload)
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      settings: newSettings,
      coinsHasError,
      propsChanged: memberPropsChanged({ initialSettings: state.initialSettings, settings: newSettings })
    };
  }
  case ACTIONS.UPDATE_BIRTHDAY_VISIBILITY: {
    const newSettings = {
      ...state.settings,
      birthdayVisibility: action.payload
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      settings: newSettings,
      propsChanged: memberPropsChanged({ initialSettings: state.initialSettings, settings: newSettings })
    };
  }
  case ACTIONS.UPDATE_ANNIVERSARY_VISIBILITY: {
    const newSettings = {
      ...state.settings,
      anniversaryVisibility: action.payload
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      settings: newSettings,
      propsChanged: memberPropsChanged({ initialSettings: state.initialSettings, settings: newSettings })
    };
  }
  case ACTIONS.UPDATE_HIRED_ON: {
    const newSettings = {
      ...state.settings,
      hiredOn: action.payload
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      settings: newSettings,
      propsChanged: memberPropsChanged({ initialSettings: state.initialSettings, settings: newSettings })
    };
  }
  case ACTIONS.UPDATE_BIRTHDAY_MONTH: {
    const newSettings = {
      ...state.settings,
      birthMonth: action.payload
    };

    const hasBirthday = !!newSettings.birthMonth ? !!newSettings.birthDay : true;

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings) && hasBirthday,
      settings: newSettings,
      propsChanged: memberPropsChanged({ initialSettings: state.initialSettings, settings: newSettings })
    };
  }
  case ACTIONS.UPDATE_BIRTHDAY_DAY: {
    const newSettings = {
      ...state.settings,
      birthDay: action.payload
    };

    const hasBirthday = !!newSettings.birthDay ? !!newSettings.birthMonth : true;

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings) && hasBirthday,
      settings: newSettings,
      propsChanged: memberPropsChanged({ initialSettings: state.initialSettings, settings: newSettings })
    };
  }
  case ACTIONS.UPDATE_ETHNICITY: {
    const newSettings = {
      ...state.settings,
      ethnicity: action.payload
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      settings: newSettings,
      propsChanged: memberPropsChanged({ initialSettings: state.initialSettings, settings: newSettings })
    };
  }
  case ACTIONS.UPDATE_GENDER: {
    const newSettings = {
      ...state.settings,
      gender: action.payload
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      settings: newSettings,
      propsChanged: memberPropsChanged({ initialSettings: state.initialSettings, settings: newSettings })
    };
  }
  case ACTIONS.UPDATE_JOB_TITLE: {
    const newSettings = {
      ...state.settings,
      jobTitle: action.payload
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      settings: newSettings,
      propsChanged: memberPropsChanged({ initialSettings: state.initialSettings, settings: newSettings })
    };
  }
  case ACTIONS.UPDATE_ROLE: {
    const newSettings = {
      ...state.settings,
      role: action.payload
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      roleChanged: newSettings.role !== state.initialSettings.role,
      settings: newSettings
    };
  }
  case ACTIONS.RESET_ROLE: {
    const newSettings = {
      ...state.settings,
      role: state.initialSettings.role
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      roleChanged: false,
      settings: newSettings
    };
  }
  case ACTIONS.UPDATE_CUSTOM_HRIS_PROPERTY: {
    const { id, value } = action.payload;
    const propertyExists = state.settings.customProperties.find((prop) => prop.propertyId === id);
    let newCustomProperties = state.settings.customProperties;

    if (!propertyExists) {
      newCustomProperties = [...newCustomProperties, { propertyId: id, value }];
    } else {
      newCustomProperties = state.settings.customProperties.map((prop) => {
        if (prop.propertyId === id) {
          return { ...prop, value };
        }
  
        return prop;
      });
    }

    const newSettings = {
      ...state.settings,
      customProperties: newCustomProperties
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      settings: newSettings,
      customPropsChanged: !isEqual(newCustomProperties, state.initialSettings.customProperties)
    };
  }
  case ACTIONS.UPDATE_CAN_SAVE: {
    return {
      ...state,
      canSave: action.payload
    };
  }
  case ACTIONS.UPDATE_CELEBRATION_CHANNEL: {
    const newSettings = {
      ...state.settings,
      celebrationChannelId: action.payload
    };

    return {
      ...state,
      canSave: !isEqual(newSettings, state.initialSettings),
      settings: newSettings,
      propsChanged: memberPropsChanged({ initialSettings: state.initialSettings, settings: newSettings })
    };
  }
  default: {
    return {};
  }
  }
}