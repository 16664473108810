import React, { useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { AppLayout } from 'modules';
import RewardsRequestList from './RewardsRequestList/RewardsRequestList';
import PaymentFailedBanner from './RewardsRequestList/PaymentFailedBanner';
import BalanceSection from './Funding/BalanceSection';
import { 
  Paper, 
  Typography
} from '@mui/material';
import FundingHistory from './FundingHistory/FundingHistory';
import { useQuery } from '@apollo/client';
import getRewardsFundCreditsQuery from 'graphql-queries/queries/rewards/getRewardsFundCredits';
import getRewardsFundDebitsQuery from 'graphql-queries/queries/rewards/getRewardsFundDebits';
import TrialBanner from 'modules/composer/TrialBanner';
import FreeTierBanner from 'modules/composer/FreeTierBanner';
import { Resources } from '@matterapp/routing';
import { useUpgradeModal } from 'routes/pages/workspaces/hooks/shared';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const BannerWrapper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: 0,
  '> div': {
    borderRadius: '8px'
  },
  [theme.breakpoints.down('sm')]: {
    '> div.open, div.closed': {
      borderRadius: 0
    },
    marginBottom: 0
  }
}));

export default function RewardsManagerOverview() {
  const { 
    currentTenant, 
    currentWorkspaceId,
    isLoadingCurrentUser,
    currentWorkspaceId: workspaceId,
    currentWorkspace,
    refetchCurrentUser,
    isAdminMember,
    tenantAdmins
  } = useContext(UserContext);
  const { 
    rewardSettings, 
    stripeCustomerId, 
    id: tenantId, 
    rewardsFundingSettings, 
    tier, 
    isFree, 
    isExpired, 
    expiresInDays, 
    isTrial,
    isAdmin, 
    isOwner
  } = currentTenant || {};
  const { isStripePaymentFailed } = rewardSettings || {};
  const { enabled } = rewardsFundingSettings || {};
  const isPro = tier === 'pro';
  const upgradeModalProps = useUpgradeModal(currentWorkspace, tenantAdmins, false);
  const navigate = useNavigate();
  const Banner = isFree 
    ? FreeTierBanner
    : (isTrial && expiresInDays && !isExpired ? TrialBanner : Paper);

  const { data: creditData } = useQuery(getRewardsFundCreditsQuery, { 
    variables: { 
      tenantId
    },
    skip: !tenantId || !enabled
  });

  const { data: debitData, refetch: refetchDebitRows } = useQuery(getRewardsFundDebitsQuery, { 
    variables: { 
      tenantId
    },
    skip: !tenantId || !enabled
  });

  function onClickUpgrade() {
    if (isAdminMember) {
      navigate(Resources.workspaceSettingsBilling.path({ workspaceId: currentWorkspace.id }));
    } else {
      upgradeModalProps.openUpgradeModal();
    }
  };

  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={
        <>
          <PaymentFailedBanner 
            workspaceId={workspaceId}
            stripeCustomerId={stripeCustomerId}
            isStripePaymentFailed={isStripePaymentFailed}
          />
          {!isPro && (
            <BannerWrapper>
              <Banner
                expiresInDays={expiresInDays}
                upgradeModalProps={upgradeModalProps}
                onClickUpgrade={onClickUpgrade}
                isAdminMember={isAdmin || isOwner}
                roundAllCorners
              />
            </BannerWrapper>
          )}
          {isPro && enabled && <BalanceSection />}
          <RewardsRequestList
            tenant={currentTenant} 
            workspaceId={currentWorkspaceId}
            isLoadingCurrentUser={isLoadingCurrentUser}
            refetchCurrentUser={refetchCurrentUser}
            refetchDebitRows={refetchDebitRows}
          />
          {enabled && isPro && (
            <Paper sx={{ mt: 3 }}>
              <Typography variant="h4">Reward Balance</Typography>
              <FundingHistory 
                creditRows={(creditData?.rewardsFundCredits || []).filter(({ status }) => status === 'approved')}
                debitRows={debitData?.rewardsFundDebits || []}
                tz={currentWorkspace?.timezone}
              />
            </Paper>
          )}
        </>
      }
    />
  );
};
