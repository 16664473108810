import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { ResetCoinsButton } from './styles';
import { StyledPaper } from '../styles';
import { Confirm } from '@matterapp/matter-ui';
import { useMutation } from '@apollo/client';
import Toast from 'components/Toast/Toast';
import resetTotalCoins from 'graphql-queries/mutations/rewards/resetWorkspaceTotalCoins';
import getCurrentMemberProperties from 'graphql-queries/queries/rewards/getCurrentMemberProperties';

function ResetCoinsModal({ isOpen, onClose, onConfirm }) {
  return (
    <Confirm
      header='This Can Not Be Undone'
      subHeader={(
        <>
          <b>
             You are about to reset earned coins for all members to 0.
          </b>
          &nbsp;This can not be undone.
        </>
      )}
      isOpen={isOpen}
      onClose={onClose}
      confirmButtonLabel='Yes, Reset'
      confirmButtonIsDestructive
      onClickCancel={onClose}
      onClickConfirm={onConfirm}
    />
  );
}

export default function ResetCoinsSection({ tenantId }) {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [resetCoinsMutation, { loading: resettingCoins, data: resetCoinsData }] = useMutation(resetTotalCoins);

  useEffect(() => {
    if (resetCoinsData && !resettingCoins) {
      Toast.success('You successfully reset earned coins for all members');
    }
  }, [resettingCoins, resetCoinsData]);

  return (
    <StyledPaper>
      <Typography variant='h3' sx={{ mb: 3 }}>Reset Earned Coins</Typography>
      <Typography variant='body1'>
        Earned coins for all channel members will be reset to 0. Often used after trialing Matter to reset earned coins before launching rewards/coins for actual use.
      </Typography>
      <Box>
        <ResetCoinsButton 
          onClick={() => setIsConfirmModalOpen(true)}
        >
          Reset All Earned Coins
        </ResetCoinsButton>
      </Box>
      <ResetCoinsModal 
        isOpen={isConfirmModalOpen}
        onClose={() => {
          setIsConfirmModalOpen(false);
        }}
        onConfirm={() => {
          setIsConfirmModalOpen(false);
          resetCoinsMutation({
            variables: {
              tenantId
            },
            refetchQueries: [
              {
                query: getCurrentMemberProperties,
                variables: {
                  tenantId
                }
              }
            ]
          });
        }}
      />
    </StyledPaper>
  );
}
