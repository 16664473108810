import styled from 'styled-components';
import {
  Tab,
  Tabs,
  Box
} from '@mui/material';
import { Dropdown, Peer, TextArea } from '@matterapp/matter-ui';

export const EmailListArea = styled(TextArea.WithCount)({
  border: 'none',
  height: '140px',
  fontWeight: 400
});

export const UnorderedList = styled.ul(({ theme }) =>({
  paddingInlineStart: '20px',
  marginTop: theme.spacing(1),
  li: {
    lineHeight: '24px'
  }
}));

export const SearchDropdown = styled(Dropdown.Email.Tags)(() => ({
  svg: {
    display: 'none'
  }
}));

export const MainWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2)
  }
}));

export const NameTabWrapper = styled(Box)(({ theme }) => ({
  width: '70%',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  flex: 1,
  maxWidth: 'none',
  color: theme.palette.blacks[60],
  fontWeight: 500,
  fontSize: '17px',
  lineHeight: '32px',
  textTransform: 'none',
  whiteSpace: 'nowrap',
  '&.Mui-selected': {
    color: theme.palette.blues[60],
    borderBottom: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    flex: 'none'
  }
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-indicator': {
    backgroundColor: theme.palette.blues[60],
    height: '4px'
  }
}));

export const DeleteButtonWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'baseline'
});

export const MembersList = styled(Peer.List.WithAddRemove.Email)(({ theme }) => ({
  '> div': {
    margin: 0,
    width: 'auto'
  }
}));

export const ManagerTag = styled.strong(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));
