import { useRef } from 'react';
import theme from '@matterapp/matter-theme';
import { getKudosType } from '../consts';
import { getThemeColor } from '../utils';

/*****************************************************************************/
/* Hook the Mini component.                                                  */
/*****************************************************************************/
export const useMini = (params) => {
  const { minHeight, onClick, type, totalKudosList } = params;
  const kudosFromList = totalKudosList && totalKudosList.find(kudos => kudos.type === type);
  let color;
  let label;
  let backgroundColor;
  let textColor;
  let thumbnail;
  let composerThumbnail;
  let lightBackgroundColor;

  if (kudosFromList?.isCustom) {
    label = kudosFromList.label;
    color = kudosFromList.backgroundSettings.backgroundColor || `${theme.colors.blacks[40]}`;
    backgroundColor = kudosFromList.backgroundSettings.backgroundColor;
    lightBackgroundColor = kudosFromList.backgroundSettings.backgroundColor;
    textColor = `${theme.colors.blacks[70]}`;
  } else {
    const kudosData = getKudosType(type, params);
    color = kudosData.color;
    label = kudosData.label;
    thumbnail = kudosData.thumbnail;
    composerThumbnail = kudosData.composerThumbnail;
    const themeData = getThemeColor(color);
    backgroundColor = themeData.backgroundColor;
    lightBackgroundColor = themeData.lightBackgroundColor;
    textColor = themeData.textColor;
  }

  const highlightRef = useRef();

  const handleClick = (e) => {
    onClick(e, params);
  };

  return {
    ...params,
    backgroundColor: lightBackgroundColor,
    containerProps: {
      backgroundColor,
      borderColor: textColor,
      minHeight,
      onClick: handleClick,
      textColor,
      isCustom: kudosFromList?.isCustom,
    },
    color,
    highlightRef,
    label,
    labelProps: {
      color: textColor,
    },
    Thumbnail: thumbnail,
    ComposerThumbnail: composerThumbnail,
    tooltipContent: label.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),
  };
};
