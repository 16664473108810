import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@matterapp/matter-ui';
import {
  StyledModalBody,
  CustomAmountWrapper,
  InputWrapper
} from './styles';
import {
  StyledInput
} from 'routes/pages/settings/Rewards/styles';
import Toast from 'components/Toast/Toast';
import styled from 'styled-components';
import { Typography } from '@mui/material';

const CustomStyledInput = styled(StyledInput)(({ theme }) => ({
  width: '340px',
  maxWidth: '100%',
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    marginTop: theme.spacing(2)
  }
}));

export default function AnniversaryAddCustomAmountModal({
  isOpen,
  onClose,
  saveCelebrationSettings,
  workspaceId,
  anniversaryCustomAwards
}) {
  const [coinAmount, updateCoinAmount] = useState();
  const [years, updateYears] = useState();

  const yearHasError = years <= 0;
  const coinsHasError = coinAmount < 0 || coinAmount > 1000000;
  const canSave = coinAmount && years && !yearHasError && !coinsHasError;

  function resetAndClose() {
    updateYears();
    updateCoinAmount();
    onClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={resetAndClose}
      size="large"
    >
      <Modal.Header
        header="Add Custom Amount"
        right={<Modal.Buttons.CloseIcon onClick={resetAndClose} />}
      />
      <StyledModalBody>
        <CustomAmountWrapper sx={{ mb: 4 }}>
          <Typography variant='body1'>Year of Service</Typography>
          <InputWrapper>
            <CustomStyledInput
              placeholder="Enter year"
              type="number"
              onChange={(_, target) => updateYears(parseInt(target.value, 10))}
              errorMessage={yearHasError ? 'Must be greater than 0' : null}
              showErrorMessageBelow
              errorMessageClassName="yearInputError"
              value={years ? years.toString() : null}
            />
          </InputWrapper>
        </CustomAmountWrapper>
        <CustomAmountWrapper>
          <Typography variant='body1'>Coin Amount</Typography>
          <InputWrapper>
            <CustomStyledInput
              placeholder="Enter coin amount"
              type="number"
              onChange={(_, target) => updateCoinAmount(parseInt(target.value, 10))}
              errorMessage={coinsHasError ? 'Must be between 0-100,000' : null}
              showErrorMessageBelow
              errorMessageClassName="allowanceInputError"
              value={coinAmount ? coinAmount.toString() : null}
            />
          </InputWrapper>
        </CustomAmountWrapper>
      </StyledModalBody>
      <Modal.Footer.WithCancelSave
        canClickSave={!!canSave}
        onClickCancel={resetAndClose}
        onClickSave={async () => {
          const newAnniversaryCustomAwards = [...anniversaryCustomAwards]
            .filter((record) => record.years !== years)
            .map(({ years, coins }) => ({ years, coins }));

          newAnniversaryCustomAwards.push({ years, coins: coinAmount });

          await saveCelebrationSettings({
            variables: {
              workspaceId,
              settings: {
                anniversaryCustomAwards: newAnniversaryCustomAwards
              }
            }
          });

          Toast.success('You successfully added a custom award!');

          resetAndClose();
        }}
        sendIsLoading={false}
      />
    </Modal>
  );
}

AnniversaryAddCustomAmountModal.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  saveCelebrationSettings: PropTypes.func,
  workspaceId: PropTypes.string,
  anniversaryCustomAwards: PropTypes.array
};

