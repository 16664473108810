import React, { useState, useEffect, useReducer } from 'react';
import { Advisors, Modal, Loader } from '@matterapp/matter-ui';
import updateMemberProperties from 'graphql-queries/mutations/workspaceMembers/updateMemberProperties';
import updateMemberCustomProperties from 'graphql-queries/mutations/workspaceMembers/updateMemberCustomProperties';
import updateDirectManager from 'graphql-queries/mutations/workspaceMembers/updateDirectManager';
import updateMemberPermissions from 'graphql-queries/mutations/workspaceMembers/updateMemberPermissions';
import updateMemberChannelPermissions from 'graphql-queries/mutations/workspaceMembers/updateMemberChannelPermissions';
import transferWorkspaceOwnership from 'graphql-queries/mutations/workspaceMembers/transferWorkspaceOwnership';
import { useMutation, useQuery } from '@apollo/client';
import Toast from 'components/Toast/Toast';
import getMemberById from 'graphql-queries/queries/workspace/getMemberById';
import { 
  MEMBERS_DROPDOWN_ADMIN_ITEM,
  MEMBERS_DROPDOWN_CHANNEL_ADMIN_ITEM,
  CELEBRATION_OPTIONS
} from './helpers';
import { 
  StyledPaper,
  DropdownContainer,
  MemberSettingsFooter
} from './styles';
import { Box, Divider, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getInitialState, memberPropsReducer, ACTIONS } from './reducer';
import MemberBasicProperties from './MemberBasicProperties';
import MemberPropertiesPermissions from './MemberPropertiesPermissions';
import MemberPropertiesSection from './MemberPropertiesSection';
import { useQueryParams } from 'hooks';

export default function MemberProperties(props) {
  const { workspace, tenant } = props;
  const { queryParams } = useQueryParams();
  const { isEnterprise, workspaces } = tenant || {};
  const isAdminSettings = !queryParams.isChannelSetting;

  const params = useParams();
  const { memberId } = params || {}; 
  const { id: workspaceId, celebrationSettings } = workspace;
  let { isBirthdaysActive, isAnniversariesActive } = celebrationSettings || {};

  if (isEnterprise) {
    isBirthdaysActive = workspaces.some(({ celebrationSettings }) => celebrationSettings.isBirthdaysActive);
    isAnniversariesActive = workspaces.some(({ celebrationSettings }) => celebrationSettings.isAnniversariesActive);
  }
  
  const [isTransferConfirmed, setIsTransferConfirmed] = useState(false);
  const [celebrationChannelOptions, setCelebrationChannelOptions] = useState([]);

  const [updateMemberPropertiesMutation, { loading: savingSettings, data: savedMember }] = useMutation(updateMemberProperties);
  const [updateDirectManagerMutation, { loading: savingManager, data: savedManager }] = useMutation(updateDirectManager);
  const [updateMemberPermissionsMutation, { loading: savingPermissions, data: savedPermissions }] = useMutation(updateMemberPermissions);
  const [updateMemberChannelPermissionsMutation, { loading: savingChannelPermissions, data: savedChannelPermissions }] = useMutation(updateMemberChannelPermissions);
  const [updateMemberCustomPropertiesMutation, { loading: savingCustomProps, data: savedCustomProps }] = useMutation(updateMemberCustomProperties);
  const [transferOwnershipMutation, { loading: savingTransferOwnership, data: transferData, error: transferError }] = useMutation(transferWorkspaceOwnership);
  const isChannelPermissions = isEnterprise ? !isAdminSettings : false;

  const [state, dispatch] = useReducer(memberPropsReducer, getInitialState({ isChannelPermissions }));
  const isSaving = savingSettings || savingManager || savingPermissions || savingChannelPermissions || savingCustomProps;
  const saved = savedMember || savedManager || savedPermissions || savedChannelPermissions || savedCustomProps;
  const { canSave, settings, managerChanged, propsChanged, roleChanged, customPropsChanged } = state;
  const { 
    directManager, 
    totalCoins, 
    birthdayVisibility, 
    anniversaryVisibility, 
    hiredOn,
    birthDay, 
    birthMonth,
    ethnicity,
    gender,
    jobTitle,
    role,
    customProperties,
    celebrationChannelId
  } = settings;

  const celebrationChannel = celebrationChannelOptions.find(({ value }) => value === celebrationChannelId) || { label: ' ', value: '' };

  const { data, loading, refetch } = useQuery(getMemberById, {
    variables: {
      workspaceId,
      id: memberId
    },
    fetchPolicy: 'network-only',
    skip: !memberId
  });

  const { workspaceMember: member } = data || {};

  useEffect(() => {
    if (member) {
      dispatch({ type: ACTIONS.RESET_INITIAL_SETTINGS, payload: { member, isChannelPermissions } });

      setIsTransferConfirmed(false);

      if (isEnterprise) {
        const { activeWorkspaceMembership = [] } = member;

        const workspaces = tenant.workspaces.filter(({ id }) => activeWorkspaceMembership.includes(id));  
        const celebrationChannelOptions = workspaces
          .filter(({ celebrationSettings }) => celebrationSettings?.isBirthdaysActive || celebrationSettings?.isAnniversariesActive)
          .map(({ slackWorkspace, teamsWorkspace, name }) => {
            return {
              label: slackWorkspace?.primaryChannel || name,
              value: slackWorkspace?.primaryChannelId || teamsWorkspace?.channelId
            };
          });

        setCelebrationChannelOptions(celebrationChannelOptions);
      }
    }
  }, [member]);

  useEffect(() => {
    if (!isSaving && saved) {
      Toast.success('You successfully updated member\'s settings');
    }
  }, [isSaving, saved]);

  useEffect(() => {
    if (!savingTransferOwnership && transferData && !transferError) {
      Toast.success('You’ve successfully transferred ownership of this channel');
    } else if (transferError) {
      Toast.error(transferError.message);
    }
  }, [savingTransferOwnership, transferData, transferError]);

  if (loading || !member) {
    return (
      <Loader />
    );
  }

  return (
    <Modal.Panel.Main>
      <MemberBasicProperties 
        workspace={workspace}
        member={member}
        tenant={tenant}
        state={state}
        dispatch={dispatch}
      />
      <MemberPropertiesPermissions 
        member={member}
        isAdminSettings={isAdminSettings}
        isEnterprise={isEnterprise}
        setIsTransferConfirmed={setIsTransferConfirmed}
        isTransferConfirmed={isTransferConfirmed}
        state={state}
        dispatch={dispatch}
      />
      {isBirthdaysActive || isAnniversariesActive ? (
        <StyledPaper>
          <Typography variant='h3' sx={{ mb: 3 }}>Celebrations</Typography>
          {isEnterprise && !!celebrationChannelOptions.length && (
            <Box sx={{ mb: 1 }}>
              <Typography variant='h5' sx={{ mb: 1 }}>Celebration Channel</Typography>
              <Typography variant='body1' sx={{ mb: 1 }}>
                If a member belongs to multiple channels and a celebration has been added to be shared publicly, then select the channel where you want to share the celebration.
              </Typography>
              <DropdownContainer>
                <Advisors.RecurringModal.Dropdown
                  options={celebrationChannelOptions}
                  selectedOption={celebrationChannel}
                  handleChangeSelection={(choice) => {
                    dispatch({ type: ACTIONS.UPDATE_CELEBRATION_CHANNEL, payload: choice.value });
                  }}
                  width='100%'
                  position={'absolute'}
                />
              </DropdownContainer>
              <Divider />
            </Box>
          )}
          <Typography variant='h5' sx={{ mb: 1 }}>Visibility</Typography>
          <Typography variant='body1' sx={{ mb: 3 }}>
            If Matter coins are awarded per celebration, they are distributed for only public and private celebrations. 
            Alternatively, you can disable the individual celebration.
          </Typography>

          {isBirthdaysActive && (
            <>
              <Typography variant='body1' sx={{ fontWeight: 500 }}>
                Birthday Visibility
              </Typography>
              <DropdownContainer>
                <Advisors.RecurringModal.Dropdown
                  options={CELEBRATION_OPTIONS}
                  selectedOption={CELEBRATION_OPTIONS.find(({ value }) => value === birthdayVisibility)}
                  handleChangeSelection={(choice) => {
                    dispatch({ type: ACTIONS.UPDATE_BIRTHDAY_VISIBILITY, payload: choice.value });
                  }}
                  width='100%'
                  position={'absolute'}
                />
              </DropdownContainer>
            </>
          )}

          {isAnniversariesActive && (
            <>
              <Typography variant='body1' sx={{ mt: 3, fontWeight: 500 }}>
                Work Anniversary Visibility
              </Typography>
              <DropdownContainer>
                <Advisors.RecurringModal.Dropdown
                  options={CELEBRATION_OPTIONS}
                  selectedOption={CELEBRATION_OPTIONS.find(({ value }) => value === anniversaryVisibility)}
                  handleChangeSelection={(choice) => {
                    dispatch({ type: ACTIONS.UPDATE_ANNIVERSARY_VISIBILITY, payload: choice.value });
                  }}
                  width='100%'
                  position={'absolute'}
                />
              </DropdownContainer>
            </>
          )}
        </StyledPaper>
      ) : null}
      <MemberPropertiesSection 
        state={state}
        dispatch={dispatch}
        tenant={tenant}
        workspace={workspace}
      />
      <MemberSettingsFooter
        primaryLabel={savingSettings || savingCustomProps ? 'Saving...' : 'Save'}
        onClickPrimary={async () => {
          dispatch({ type: ACTIONS.UPDATE_CAN_SAVE, payload: false });

          if (isTransferConfirmed) {
            await transferOwnershipMutation({
              variables: {
                memberId: member.id,
                tenantId: tenant.id
              }
            });
          }

          if (customPropsChanged) {
            await updateMemberCustomPropertiesMutation({
              variables: {
                personId: member?.person?.id,
                tenantId: tenant.id,
                customProperties: customProperties.map(({ propertyId, value }) => ({ propertyId, value }))
              }
            });
          }
            
          if (propsChanged) {
            await updateMemberPropertiesMutation({
              variables: {
                personId: member?.person?.id,
                tenantId: tenant.id,
                memberProperties: {
                  hiredOn: hiredOn || null,
                  birthday: birthMonth && birthDay ? `${birthMonth} ${birthDay}` : null,
                  totalCoins: parseInt(totalCoins),
                  birthdayVisibility,
                  anniversaryVisibility,
                  ethnicity,
                  jobTitle,
                  gender,
                  celebrationChannelId
                }
              }
            });
          }

          if (managerChanged) {
            await updateDirectManagerMutation({
              variables: {
                managerPersonId: directManager?.id,
                employeePersonId: member.person.id,
                tenantId: tenant.id
              }
            });
          }
  
          if (roleChanged) {
            const { id: memberId } = member;
  
            if (isChannelPermissions) {
              const isChannelAdmin = role === MEMBERS_DROPDOWN_CHANNEL_ADMIN_ITEM.value;
  
              await updateMemberChannelPermissionsMutation({ 
                variables: { memberId, isChannelAdmin, workspaceId: workspace.id } 
              });
            } else {
              const isAdmin = role === MEMBERS_DROPDOWN_ADMIN_ITEM.value;
  
              await updateMemberPermissionsMutation({ 
                variables: { memberId, isAdmin, workspaceId: workspace.id } 
              });
            }
          }
  
          refetch({
            workspaceId,
            id: memberId
          });
        }}
        isSaving={savingSettings}
        saveProps={{
          isLoading: savingSettings
        }}
        canClickPrimary={canSave && !savingSettings}
      />
    </Modal.Panel.Main>
  );
}
