import React, { useState } from 'react';
import { Button, Input } from '@matterapp/matter-ui';
import { 
  Typography
} from '@mui/material';
import { useMutation } from '@apollo/client';
import updateMatterTeam from 'graphql-queries/mutations/matterTeams/updateMatterTeam';
import Toast from 'components/Toast/Toast';
import { MainWrapper, NameTabWrapper } from './styles';

export default function NameTab({ matterTeam, tenantId }) {
  const { name } = matterTeam;
  const [newTeamName, setNewTeamName] = useState(name);
  const [updateMatterTeamMutation] = useMutation(updateMatterTeam);
  const hasError = newTeamName?.trim()?.length > 60;

  return (
    <MainWrapper>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>Group Name</Typography>
      <NameTabWrapper>
        <Input
          type="text"
          style={{ width: '100%' }}
          value={newTeamName}
          errorMessage={hasError ? 'Group name must be less than 60 characters' : ''}
          onChange={(event) => {  
            setNewTeamName(event.target.value);
          }}
        />
        <Button.Primary.Vivid
          style={{ width: '100%', marginTop: '24px' }}
          content="Save"
          disabled={hasError || newTeamName?.trim() === name || newTeamName?.trim()?.length === 0}
          onClick={async () => {
            try {
              await updateMatterTeamMutation({
                variables: {
                  teamId: matterTeam.id,
                  tenantId,
                  name: newTeamName.trim()
                }
              });
              Toast.success('Your group name has been updated');
            } catch (error) {
              Toast.error('Failed to update group name');
            }
          }}
        />
      </NameTabWrapper>
    </MainWrapper>
  );
};
