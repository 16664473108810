import styled from 'styled-components';
import { Button } from '@matterapp/matter-ui';

export const UnorderedList = styled.ul(({ theme }) =>({
  paddingInlineStart: '20px',
  marginTop: theme.spacing(1),
  li: {
    lineHeight: '24px',
    fontSize: '17px'
  }
}));

export const OrderedList = styled.ol(({ theme }) =>({
  paddingInlineStart: '20px',
  marginTop: theme.spacing(1),
  li: {
    lineHeight: '24px',
    fontSize: '17px'
  }
}));

export const StyledButton = styled(Button.Primary)(({ theme }) => ({
  whiteSpace: 'nowrap',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  fontSize: '14px',
  lineHeight: '14px',
  minHeight: 0,
  backgroundColor: theme.palette.blues[5],
  marginRight: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(1)
  }
}));
