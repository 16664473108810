import { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  SEND_WORKSPACE_DELETION_LINK_MUTATION,
} from 'graphql-queries/queries';
import updateTenantMutation from 'graphql-queries/mutations/workspaces/updateTenant';
import setGeneralWorkspaceSettings from 'graphql-queries/mutations/workspaces/setGeneralWorkspaceSettings';
import { Toast, TOAST_TEXT } from './toasts';
import getNextFeedbackFriday from 'graphql-queries/queries/slackWorkspace/getNextFeedbackFriday';

const DELETE_WORKSPACE_MESSAGE = 'We need to verify that this is your account before continuing to delete this channel.';
const DELETE_WORKSPACE_LINK = 'Resend delete link.';

/**
 * Hook for defining 'sendWorkspaceDeletionLink' mutation.
 */
export const useSendWorkspaceDeletionLink = () => {
  const [mutation] = useMutation(SEND_WORKSPACE_DELETION_LINK_MUTATION);
  const sendWorkspaceDeletionLink = (workspaceId) => {
    return mutation({ variables: { workspaceId } });
  };
  return { sendWorkspaceDeletionLink };
};

/**
 * Hook state for general panel.
 */
export const useGeneralPanel = (params) => {
  const { workspace, tenant } = params;
  const { id: tenantId } = tenant;
  const [isCheckEmailModalOpen, setIsCheckEmailModalOpen] = useState(false);
  const [saveProps, setSaveProps] = useState({});
  const { sendWorkspaceDeletionLink } = useSendWorkspaceDeletionLink();
  const [saveGeneralSettings, { loading: savingGeneralSettings }] = useMutation(setGeneralWorkspaceSettings);
  const [updateTenant, { loading: savingWorkspace }] = useMutation(updateTenantMutation);

  const [calculateNextFF, { data: { nextFeedbackFriday } = {}, loading: calculatingNextFF }] = useLazyQuery(
    getNextFeedbackFriday,
    { fetchPolicy: 'no-cache' }
  );
  const [isConfirmationModalOpen, setConfirmationModalIsOpen] = useState(false);
  const [ritualDateString, setRitualDateString] = useState(null);

  useEffect(() => {
    if (nextFeedbackFriday) {      
      const { dateString } = nextFeedbackFriday;

      setRitualDateString(dateString);
      setConfirmationModalIsOpen(true);
    }
  }, [nextFeedbackFriday]);

  async function saveSettingsAndShowConfirmation(workspaceId, settings) {
    await saveGeneralSettings({
      variables: {
        workspaceId,
        settings,
      }
    });

    Toast.success(TOAST_TEXT.UPDATE);
  }

  return {
    ...params,
    isSaving: savingWorkspace || savingGeneralSettings || calculatingNextFF,
    onClickSave: async (e, props) => {
      const {
        icon,
        name,
        timezoneSettings,
        workspaceId,
        workspaceUpdated,
        workspaceSettingsUpdated,
        customKudosAdminOnly,
        privateKudosPermission,
        channelCreationPermission,
        kudosAllSettings,
        timezoneChanged,
        emailActivityNotifications
      } = props;

      if (workspaceUpdated) {
        await updateTenant({
          variables: {
            icon,
            name,
            tenantId,
            channelCreationPermission
          }
        });
        Toast.success(TOAST_TEXT.UPDATE);
      }

      if (workspaceSettingsUpdated) {
        const { feedbackRitualSettings } = workspace;
        const { isActive } = feedbackRitualSettings || {};
        
        if (isActive && timezoneChanged) {
          setSaveProps({ 
            workspaceId, 
            timezoneSettings, 
            customKudosAdminOnly, 
            privateKudosPermission, 
            kudosAllSettings
          });

          calculateNextFF({
            variables: {
              workspaceId,
              timezone: timezoneSettings.timezone,
            }
          });
        } else {
          saveSettingsAndShowConfirmation(workspaceId, { 
            ...timezoneSettings, 
            customKudosAdminOnly, 
            privateKudosPermission, 
            kudosAllSettings, 
            emailActivityNotifications
          });
        }
      }
    },
    onClickConfirmSave: async () => {
      const { workspaceId, timezoneSettings, customKudosAdminOnly, privateKudosPermission, kudosAllSettings } = saveProps;

      setConfirmationModalIsOpen(false);
      setRitualDateString(null);
      saveSettingsAndShowConfirmation(workspaceId, { ...timezoneSettings, customKudosAdminOnly, privateKudosPermission, kudosAllSettings });
    },
    onClickCancelSave: () => {
      setConfirmationModalIsOpen(false);
      setRitualDateString(null);
    },
    onClickDelete: () => {
      setIsCheckEmailModalOpen(true);
      sendWorkspaceDeletionLink(workspace.id);
    },
    checkYourEmailModalProps: {
      subHeader: DELETE_WORKSPACE_MESSAGE,
      isOpen: isCheckEmailModalOpen,
      onClickResend: () => {
        sendWorkspaceDeletionLink(workspace.id);
        Toast.success(TOAST_TEXT.RESEND_DELETE_LINK);
      },
      onClose: () => {
        setIsCheckEmailModalOpen(false);
      },
      resendLinkText: DELETE_WORKSPACE_LINK,
    },
    isConfirmationModalOpen,
    ritualDateString,
  };
};
