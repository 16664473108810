import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Form,
  Input
} from '@matterapp/matter-ui';
import { SET_USER_SETTINGS_MUTATION } from 'graphql-queries/queries';
import { useMutation } from '@apollo/client';
import Toast from 'components/Toast';
import theme from '@matterapp/matter-theme';

const ERROR_TOO_LONG = 'Unfortunately, your input is too long.';
const ERROR_CANT_LEAVE_BLANK = "You can't leave this blank.";
const ERROR_PROFILE_INVALID_NAME = 'First name and last name please';

function validateFullName(fullName = '') {
  const fullNameTrimmed = fullName.trim();

  if (fullNameTrimmed.length < 1) {
    return ERROR_CANT_LEAVE_BLANK;
  } else if (!/^\S+\s+[\s+\S+]+$/.test(fullNameTrimmed)) {
    return ERROR_PROFILE_INVALID_NAME;
  } else if (fullNameTrimmed.length >= 255) {
    return ERROR_TOO_LONG;
  }

  return false;
}

const SettingsContainer = styled.div(() => ({
}));

const SaveButton = styled(Button.Primary.Vivid)({
  width: '100%',
});

const FormGroup = styled(Form.Group)(() => ({
  label: {
    fontSize: theme.font.size.L
  }
}));

function UserSettingsForm({ currentUser }) {
  const [updateSettings, { loading, data: updatedUser }] = useMutation(SET_USER_SETTINGS_MUTATION);
  const [name, setName] = useState(currentUser?.fullName);
  const [canSave, updateCanSave] = useState(false);
  const [nameError, setNameError] = useState();

  useEffect(() => {
    const error = validateFullName(name);

    setNameError(error);

    updateCanSave(!error && (
      name !== currentUser.fullName
    ));

  }, [name, updatedUser]);

  return (
    <SettingsContainer>
      <FormGroup label="Name">
        <Input
          placeholder="Enter your name"
          onChange={(e, { value }) => {
            setName(value);
          }}
          errorMessage={nameError}
          value={name}
          text="Name"
          fluid
        />
      </FormGroup>
      <SaveButton
        submitting={loading}
        onClick={() => updateSettings({
          variables: {
            fullName: name
          }
        }).then(() => Toast.success('Successfully updated your settings'))}
        fluid
        size="large"
        disabled={!canSave}
      >
        Save Profile
      </SaveButton>
    </SettingsContainer>
  );
}

UserSettingsForm.propTypes = {
  currentUser: PropTypes.object
};

export default UserSettingsForm;
