import { 
  Paper,
  Box
} from '@mui/material';
import styled from 'styled-components';
import { Form, Modal } from '@matterapp/matter-ui';
import React from 'react';

export const InputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(4)
}));

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2)
}));

export const MobileSettingsHeader = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    color: theme.palette.blacks[60],
  }
}));

export const SeetingsWrapper = styled(Paper)(({ theme }) => ({
  margin: `${theme.spacing(3)} 0`,
  maxWidth: '944px',
  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,
    marginTop: 0
  }
}));

export const SettingsContainer = styled(Box)(({ theme }) => ({
  maxWidth: '760px',
  'nav.MuiBreadcrumbs-root': {
    marginBottom: theme.spacing(3)
  },
  '.MuiPaper-root': {
    paddingBottom: '4px'
  },
  [theme.breakpoints.down('sm')]: {
    '.MuiPaper-root': {
      borderRadius: 0
    }
  }
}));

export const Group = (props) => <Form.Group largerSpacing {...props} />;

export const IconWrapper = styled(Box)(({ theme }) => ({
  minWidth: '32px',
  minHeight: '32px',
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.purples[5],
  padding: 0,
  marginRight: theme.spacing(2),
  svg: {
    width: '16px',
    height: '16px'
  }
}));

export const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}));

export const SubSection = styled(Box)(({ theme }) => ({
  width: '288px',
  height: '120px',
  padding: theme.spacing(3),
  borderRadius: '4px',
  border: `1px solid ${theme.palette.blacks[10]}`,
  color: theme.palette.blacks[50],
  transitionDuration: '0.2s',
  transitionProperty: 'background-color',
  cursor: 'pointer',
  '> p': {
    lineHeight: '20px'
  },
  ':hover':{
    backgroundColor: theme.palette.blacks[5]
  },
  ':active': {
    backgroundColor: theme.palette.purples[5]
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginRight: 0
  }
}));

export const SubSectionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
  flexWrap: 'wrap'
}));

export const SubSectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'space-between',
  color: '#000',
  svg: {
    fill: theme.palette.blacks[30]
  },
  p: {
    maxWidth: '90%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}));

export const SettingsWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    paddingTop: 0
  }
}));

export const MobileBreadCrumb = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 0,
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    color: theme.palette.blacks[60]
  }
}));

export const SettingsFooter = styled(Modal.Footer.WithActionButtons)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  zIndex: 1,
  padding: theme.spacing(3),
  borderTop: 'none',
  borderRadius: '8px'
}));
