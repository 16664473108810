import styled from 'styled-components';
import { 
  Box,
  Button,
  TableCell,
  TableRow,
  Paper
} from '@mui/material';

export const TopWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: 0
  }
}));

export const TopSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginBottom: 0
  }
}));

export const NameWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  svg: {
    marginLeft: theme.spacing(1),
    width: '14px'
  },
  p: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 'auto'
  }
}));

export const EditButton = styled(Button)(({ theme }) => ({
  color: theme.palette.blacks[60],
  borderColor: theme.palette.blacks[10],
  fontSize: '12px',
  padding: '3px 5px',
  minWidth: 'auto',
  marginLeft: theme.spacing(2),
  ':hover': {
    backgroundColor: theme.palette.blacks[10],
    borderColor: theme.palette.blacks[10],
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0
  }
}));

export const MainTableCell = styled(TableCell)(({ theme }) => ({
  maxWidth: '550px',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    maxWidth: '250px'
  }
}));

export const DesktopOnlyCell = styled(MainTableCell)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

export const MobileCell = styled(TableCell)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'table-cell'
  }
}));

export const StyledBodyRow = styled(TableRow)(({ theme }) => ({
  ':hover': {
    backgroundColor: theme.palette.blacks[5],
  },
  ':active': {
    backgroundColor: theme.palette.purple[5]
  },
  position: 'relative'
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderTop: `solid 1px ${theme.palette.blacks[10]}`,
  borderBottom: `solid 1px ${theme.palette.blacks[10]}`
}));

export const HeaderCell = styled(TableCell)
  .withConfig({ shouldForwardProp: (prop) => {
    return prop !== 'visibleOnMobile';
  } })
  (({ theme, visibleOnMobile }) => ({
    border: 'none',
    fontWeight: 'normal',
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    color: theme.palette.blacks[60],
    [theme.breakpoints.down('md')]: {
      display: visibleOnMobile ? 'table-cell' : 'none'
    }
  }));
