export const defaultProperties = [{
  label: 'Date of Birth',
  type: 'date',
  key: 'birthday',
  isMatter: true
},
{
  label: 'Ethnicity',
  key: 'ethnicity',
  type: 'choices',
  isMatter: true
},
{
  label: 'Gender',
  key: 'gender',
  type: 'choices',
  isMatter: true
},
{
  label: 'Hire Date',
  key: 'hiredOn',
  type: 'date',
  isMatter: true
},
{
  label: 'Job Title',
  key: 'jobTitle',
  type: 'string',
  isMatter: true
},
{
  label: 'Direct Manager',
  key: 'manager',
  type: 'choices',
  isMatter: true
}
];
