import moment from 'moment';

export const dateFilters = [
  {
    label: 'Last 30 Days',
    value: 'Last 30 Days',
    startDate: moment().add(-30, 'day'),
    endDate: moment(),
    groupedBy: 'GROUPED BY WEEK',
    groupBy: 'week',
    xAxisResolution: 5,
    visibleOnMobile: true
  },
  {
    label: 'Last 3 Months',
    value: 'Last 3 Months',
    startDate: moment().add(-3, 'month').startOf('month'),
    endDate: moment(),
    groupedBy: 'GROUPED BY MONTH',
    groupBy: 'month',
    xAxisResolution: 5,
    visibleOnMobile: true
  },
  {
    label: 'Last 6 Months',
    value: 'Last 6 Months',
    startDate: moment().add(-6, 'month').startOf('month'),
    endDate: moment(),
    groupedBy: 'GROUPED BY MONTH',
    groupBy: 'month',
    xAxisResolution: 7,
    visibleOnMobile: true
  },
  {
    label: 'Last 12 Months',
    value: 'Last 12 Months',
    startDate: moment().add(-12, 'month').startOf('month'),
    endDate: moment(),
    groupedBy: 'GROUPED BY MONTH',
    groupBy: 'month',
    xAxisResolution: 13,
    visibleOnMobile: false
  }
];
