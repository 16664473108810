import React from 'react';
import { ToggleContainer, ToggleWrapper, ToggleUnit } from 'components/ToggleGroup';
import { REWARD_TYPES } from 'routes/pages/rewards-store/components/consts';
import { Typography } from '@mui/material';
import { StyledPaper } from '../styles';

export default function CategoriesSection(props) {
  const { disabledRewardTypes, onChange } = props;

  return (
    <StyledPaper>
      <Typography variant='h3' sx={{ mb: 3 }}>Categories</Typography>
      <Typography variant='body1'>Enable or disable reward categories based on preference.</Typography>
      <ToggleWrapper>
        <ToggleContainer>
          Gift Cards
          <ToggleUnit 
            checked={!disabledRewardTypes.includes(REWARD_TYPES.GIFT_CARD)}
            onChange={() => onChange(REWARD_TYPES.GIFT_CARD)}
          />
        </ToggleContainer>
        <ToggleContainer>
          Donations
          <ToggleUnit 
            checked={!disabledRewardTypes.includes(REWARD_TYPES.DONATION)}
            onChange={() => onChange(REWARD_TYPES.DONATION)}
          />
        </ToggleContainer>
        <ToggleContainer>
          Prepaid Cards
          <ToggleUnit 
            checked={!disabledRewardTypes.includes(REWARD_TYPES.CASH_EQUIVALENT)}
            onChange={() => onChange(REWARD_TYPES.CASH_EQUIVALENT)}
          />
        </ToggleContainer>
        <ToggleContainer>
          Custom Rewards
          <ToggleUnit 
            checked={!disabledRewardTypes.includes(REWARD_TYPES.CUSTOM)}
            onChange={() => onChange(REWARD_TYPES.CUSTOM)}
          />
        </ToggleContainer>
      </ToggleWrapper>
    </StyledPaper>
  );
}
