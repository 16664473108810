import React from 'react';
import PropTypes from 'prop-types';
import { Confirm } from '@matterapp/matter-ui';
import { FEEDBACK_RITUAL_PANEL_TEXT, CANCEL_BUTTON } from 'routes/pages/settings/sharedConsts';

const FEEDBACK_RITUAL_PANEL_DISABLE_MODAL_TEXT = {
  HEADER: FEEDBACK_RITUAL_PANEL_TEXT.DAY_OF_POST_CONFIRM_HEADER,
  CANCEL_BUTTON,
  DISABLE_BUTTON: FEEDBACK_RITUAL_PANEL_TEXT.DAY_OF_POST_CONFIRM_POST_BUTTON
};

const DisableModal = (props) => {
  const { isOpen, onClickCancel, onClickDisable, ritualName } = props;
  return (
    <Confirm
      header={FEEDBACK_RITUAL_PANEL_DISABLE_MODAL_TEXT.HEADER}
      subHeader={`You and your team will no longer receive ${ritualName} messages and notifications from Matter on Slack or the Web.`}
      cancelButtonLabel={FEEDBACK_RITUAL_PANEL_DISABLE_MODAL_TEXT.CANCEL_BUTTON}
      confirmButtonLabel={
        FEEDBACK_RITUAL_PANEL_DISABLE_MODAL_TEXT.DISABLE_BUTTON
      }
      confirmButtonIsDestructive
      isOpen={isOpen}
      onClickCancel={onClickCancel}
      onClickConfirm={onClickDisable}
    />
  );
};

DisableModal.propTypes = {
  /** If modal is open. */
  isOpen: PropTypes.bool,
  /** Callback when cancel button is clicked. */
  onClickCancel: PropTypes.func,
  /** Callback when disable button is clicked. */
  onClickDisable: PropTypes.func,
};

export default DisableModal;
