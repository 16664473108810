/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { Feed } from '@matterapp/matter-ui';
import { FeedItemTypes } from '../consts';
import { RitualGiveKudosModal, RitualGiveFeedbackModal } from '../RitualModals';
import { transformInactiveGiver, transformInactiveReceivers } from '../hooks';
import { DeleteConfirmModal, RemoveConfirmModal } from '../ConfirmModals';

const WorkspaceFeedItems = ({
  payload,
  feedItemProps,
  deleteProps,
  renderProps,
  footerProps,
  totalKudosList,
}) => {
  const { currentWorkspace: workspace } = useContext(UserContext);
  const { feedItemId, index, canDelete, isWholeChannel, teamNames } = feedItemProps;
  const { name, iconUrl, members = [] } = workspace || {};
  const { onClickDeleteFeedItem, onClickRemoveMention } = deleteProps;
  const [isGiveFeedbackModalOpen, setIsGiveFeedbackModalOpen] = useState(false);
  const [isGiveKudosModalOpen, setIsGiveKudosModalOpen] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [isRemoveConfirmModalOpen, setIsRemoveConfirmModalOpen] = useState(false);

  const onClickDeleteMenu = () => setIsDeleteConfirmModalOpen(true);
  const onClickRemoveMenu = () => setIsRemoveConfirmModalOpen(true);
  const onClickCloseDeleteConfirmModal = () => setIsDeleteConfirmModalOpen(false);
  const onClickCloseRemoveConfirmModal = () => setIsRemoveConfirmModalOpen(false);

  const onClickOpenFeedbackModal = () => setIsGiveFeedbackModalOpen(true);
  const onClickCloseFeedbackModal = () => setIsGiveFeedbackModalOpen(false);
  const onClickOpenKudosModal = () => setIsGiveKudosModalOpen(true);
  const onClickCloseKudosModal = () => setIsGiveKudosModalOpen(false);

  const inactiveMembers = members.filter(member => {
    return !(member.status === 'accepted' || member.status === 'pending');
  });

  let feedItem;
  switch (payload.__typename) {
  case FeedItemTypes.CREATION: {
    const { workspaceCreator } = payload;
    const transformedCreator = transformInactiveGiver(inactiveMembers, workspaceCreator);
    feedItem = (
      <Feed.Workspaces.Events.Creation
        key={feedItemId}
        userFullName={workspaceCreator.fullName}
        isInactive={transformedCreator.isInactive}
        workspaceName={name}
        photoUrl={workspaceCreator.photoUrl}
        footerProps={footerProps}
      />
    );
    break;
  }
  case FeedItemTypes.NEW_MEMBERS: {
    const { membersAdded } = payload;
    const restOfMembersLength = membersAdded.length > 1 ? membersAdded.length - 1 : null;
    const membersWithFullName = membersAdded.filter(members => members && members.member && members.member.fullName);
    const firstMember = membersWithFullName[0] ? membersWithFullName[0].member : membersAdded[0].member;
    const transformedFirstMember = transformInactiveGiver(inactiveMembers, firstMember);
    const { fullName, email, isInactive, photoUrl } = transformedFirstMember;
    feedItem = (
      <Feed.Workspaces.Events.NewMembers
        key={feedItemId}
        firstMemberName={fullName || email}
        isInactive={isInactive}
        restOfMembersLength={restOfMembersLength}
        photoUrl={photoUrl}
        footerProps={footerProps}
      />
    );
    break;
  }
  case FeedItemTypes.KUDOS: {
    const { kudos, receivers, giver } = payload;
    const transformedGiver = transformInactiveGiver(inactiveMembers, giver);
    const transformedReceivers = transformInactiveReceivers(inactiveMembers, receivers);

    feedItem = (
      <Feed.Workspaces.UserPosts.Kudos
        key={feedItemId}
        giver={transformedGiver}
        receivers={transformedReceivers}
        isWholeChannel={isWholeChannel}
        teamNames={teamNames}
        channelName={name}
        kudos={kudos}
        index={index}
        showMenu={canDelete}
        footerProps={footerProps}
        deleteProps={deleteProps}
        renderProps={renderProps}
        feedItemProps={feedItemProps}
        onClickDelete={onClickDeleteMenu}
        onClickRemove={onClickRemoveMenu}
        totalKudosList={totalKudosList}
      />
    );
    break;
  }
  case FeedItemTypes.RITUAL_START: {
    const { mainMessage, secondaryMessage } = payload;
    feedItem = (
      <>
        <Feed.Workspaces.Announcements.RitualStart
          key={feedItemId}
          mainMessage={mainMessage}
          secondaryMessage={secondaryMessage}
          photoUrl={iconUrl}
          footerProps={footerProps}
          onClickFeedback={onClickOpenFeedbackModal}
          onClickKudos={onClickOpenKudosModal}
        />
        <RitualGiveFeedbackModal
          isOpen={isGiveFeedbackModalOpen}
          workspace={workspace}
          closeModal={onClickCloseFeedbackModal}
        />
        <RitualGiveKudosModal 
          isOpen={isGiveKudosModalOpen}
          onClose={onClickCloseKudosModal}
          workspace={workspace}
        />
      </>
    );
    break;
  }
  case FeedItemTypes.RITUAL_PROGRESS: {
    const { mainMessage, secondaryMessage, percentProgress } = payload;
    feedItem = (
      <>
        <Feed.Workspaces.Announcements.RitualProgress
          key={feedItemId}
          mainMessage={mainMessage}
          secondaryMessage={secondaryMessage}
          percentProgress={percentProgress}
          photoUrl={iconUrl}
          footerProps={footerProps}
          onClickFeedback={onClickOpenFeedbackModal}
          onClickKudos={onClickOpenKudosModal}
        />
        <RitualGiveFeedbackModal
          isOpen={isGiveFeedbackModalOpen}
          workspace={workspace}
          closeModal={onClickCloseFeedbackModal}
        />
        <RitualGiveKudosModal 
          isOpen={isGiveKudosModalOpen}
          onClose={onClickCloseKudosModal}
          workspace={workspace}
        />
      </>
    );
    break;
  }
  case FeedItemTypes.RITUAL_RECAP: {
    const { mainMessage, secondaryMessage, feedbackGivers, biggestGivers } = payload;

    feedItem = (
      <Feed.Workspaces.Announcements.RitualRecap
        key={feedItemId}
        mainMessage={mainMessage}
        secondaryMessage={secondaryMessage}
        winners={biggestGivers}
        participants={feedbackGivers}
        photoUrl={iconUrl}
        footerProps={footerProps}
      />
    );
    break;
  }
  default:
    console.error('Unrecognized feed item type');
    feedItem = null;
  }

  return (
    <>
      {feedItem}
      <DeleteConfirmModal 
        isOpen={isDeleteConfirmModalOpen}
        deleteProps={deleteProps}
        isWorkspace={true}
        onClose={onClickCloseDeleteConfirmModal}
        onClickDelete={() => {
          onClickDeleteFeedItem();
          onClickCloseDeleteConfirmModal();
        }}
      />
      <RemoveConfirmModal 
        isOpen={isRemoveConfirmModalOpen}
        onClose={onClickCloseRemoveConfirmModal}
        onClickRemove={() => {
          onClickRemoveMention();
          onClickCloseRemoveConfirmModal();
        }}
      />
    </>
  );
};

export default WorkspaceFeedItems;
