import { defaultProperties } from 'routes/pages/settings/DataProperties/helpers';
import { isEqual } from 'lodash';

export const defaultHrisProperties = defaultProperties
  .filter(({ key }) => key !== 'birthday')
  .map(({ label, key, ...rest }) => ({ ...rest, label, value: key }));

export const RULE_OPTIONS = [{
  label: 'All',
  value: 'all'
},
{
  label: 'Any',
  value: 'any'
}];

export const OPERATORS = {
  IS_EQUAL_TO: 'is equal to',
  IS_NOT_EQUAL_TO: 'is not equal to',
  GREATER_THAN: 'greater than',
  LESSER_THAN: 'lesser than',
  IS_BETWEEN: 'is between',
  IS_EXACTLY: 'is exactly',
  IS_BEFORE: 'is before',
  IS_AFTER: 'is after'
};

const CHOICE_OPERATORS = [
  {
    label: OPERATORS.IS_EQUAL_TO,
    value: OPERATORS.IS_EQUAL_TO
  },
  {
    label: OPERATORS.IS_NOT_EQUAL_TO,
    value: OPERATORS.IS_NOT_EQUAL_TO
  }
];

const NUMERIC_OPERATORS = [
  {
    label: OPERATORS.IS_EQUAL_TO,
    value: OPERATORS.IS_EQUAL_TO
  },
  {
    label: OPERATORS.IS_NOT_EQUAL_TO,
    value: OPERATORS.IS_NOT_EQUAL_TO
  },
  {
    label: OPERATORS.GREATER_THAN,
    value: OPERATORS.GREATER_THAN
  },
  {
    label: OPERATORS.LESSER_THAN,
    value: OPERATORS.LESSER_THAN
  },
  {
    label: OPERATORS.IS_BETWEEN,
    value: OPERATORS.IS_BETWEEN
  }
];

const DATE_OPERATORS = [
  {
    label: OPERATORS.IS_EXACTLY,
    value: OPERATORS.IS_EXACTLY
  },
  {
    label: OPERATORS.IS_BEFORE,
    value: OPERATORS.IS_BEFORE
  },
  {
    label: OPERATORS.IS_AFTER,
    value: OPERATORS.IS_AFTER
  },
  {
    label: OPERATORS.IS_BETWEEN,
    value: OPERATORS.IS_BETWEEN
  }
];


export function getOperators(property) {
  switch (property?.type) {
  case 'choices':
    return CHOICE_OPERATORS;
  case 'date':
    return DATE_OPERATORS;
  case 'numeric':
    return NUMERIC_OPERATORS;
  default:
    return [];
  }
}

export function rulesAreValid(state) {
  return state.settings.rules.every(({ propertyName, operator, values, type }) => {
    if (!values) {
      return false;
    }

    let valuesAreCorrect = values.every(Boolean);

    if (type === 'date') {
      valuesAreCorrect = valuesAreCorrect && values.every(value => {
        const date = new Date(value);

        return date !== 'Invalid Date' || date.getYear() > 1900;
      });
    }

    if (operator === OPERATORS.IS_BETWEEN) {
      return propertyName && operator && values.filter(Boolean).length === 2 && valuesAreCorrect;
    }
    
    return propertyName && operator && values.length;
  });
}

export function getCanSave(state) {
  const isValid = state.settings.name?.trim() && !state.nameHasError && rulesAreValid(state);

  if (state.creatingNew) {
    return !!isValid;
  }

  return isValid && !isEqual(state.settings, state.initialSettings);
}