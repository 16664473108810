import React, { useContext } from 'react';
import { 
  SettingsContainer
} from '../styles';
import AdminsSettingsHoc from '../AdminsSettingsHoc';
import UserContext from 'context/UserContext/UserContext';
import { AppLayout } from 'modules';
import { Resources } from '@matterapp/routing';
import SegmentsPage from './Segments';
import { Paper } from '@mui/material';
import SettingsNavigation from '../SettingsNavigation';
import { useQueryParams } from 'hooks';
import styled from 'styled-components';

const StyledPaper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: 0
  }
}));

export default function SegmentsContainer() {  
  const { currentWorkspaceId: workspaceId, currentWorkspace, isLoadingCurrentUser } = useContext(UserContext);
  const isLoading = !currentWorkspace || isLoadingCurrentUser;
  const { queryParams } = useQueryParams();
  const settingsRedirectUrl = queryParams.isChannelSetting 
    ? Resources.workspaceSettingsMain.path({ workspaceId }, { isChannelSetting: true }) 
    : Resources.workspaceSettingsMain.path({ workspaceId });

  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={(
        <AdminsSettingsHoc redirectUrl={settingsRedirectUrl}>
          <SettingsContainer sx={{ maxWidth: '100%!important' }}>
            <SettingsNavigation 
              currentLabel='Segments'
              settingsRedirectUrl={settingsRedirectUrl}
            />
            {isLoading 
              ? null 
              : (
                <StyledPaper elevation={0}>
                  <SegmentsPage />
                </StyledPaper>
              )}
          </SettingsContainer>
        </AdminsSettingsHoc>
      )}
    />
  );
}
