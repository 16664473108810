import React, { useState, useEffect } from 'react';
import { Modal, Input } from '@matterapp/matter-ui';
import { Typography } from '@mui/material';
import searchWorkspaceMembers from 'graphql-queries/queries/workspace/searchWorkspaceMembers';
import createMatterTeam from 'graphql-queries/mutations/matterTeams/createMatterTeam';
import getMatterTeams from 'graphql-queries/queries/matterTeams/getMatterTeams';
import { useMutation, useLazyQuery } from '@apollo/client';
import Toast from 'components/Toast/Toast';
import { 
  StyledModalBody, 
  LineItem, 
  StyledModal, 
  InputWrapper,
  SearchInputWithTags
} from './styles';
import { debounce } from 'lodash';

const debouncedSearch = debounce((searchString, tenantId, query) => {
  query({
    variables: {
      tenantId,
      searchString,
      excludeCurrentUser: false
    },
    fetchPolicy: 'network-only'
  });
}, 300);

export default function CreateTeamModal(props) {
  const { isOpen, onClose, tenant, teams } = props;
  const { id: tenantId } = tenant;
  const [teamName, setTeamName] = useState('');
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [createTeamMutation, { loading: savingTeam, data: savedTeam, error }] = useMutation(createMatterTeam);
  const [searchMembers, { data: searchMembersData }] = useLazyQuery(searchWorkspaceMembers);
  const nameHasError = teamName?.trim().length > 60;

  let memberItems = searchMembersData?.searchMembers || [];

  memberItems = [...memberItems.map((member) => {
    return {
      label: member.person.fullName,
      value: member.person.id,
      subLabel: member.person.email
    };
  })];

  const teamsItems = teams.map((team) => ({
    label: team.name,
    value: `team-${team.id}`
  }));

  const canSave = teamName?.trim().length > 1 && !nameHasError;
  
  function clearAndClose() {
    setTeamName('');
    setSelectedManagers([]);
    setSelectedMembers([]);

    onClose();
  }

  useEffect(() => { 
    if (error) {
      Toast.error(error?.message);
    }
  }, [error]);

  useEffect(() => {
    if (!savingTeam && savedTeam) {
      Toast.success('Your group has been created');

      clearAndClose();
    }
  }, [savingTeam, savedTeam]);

  return (
    <StyledModal 
      isOpen={isOpen} 
      onClose={clearAndClose}
      size="large"
    >
      <Modal.Header
        header='Create Group'
        right={<Modal.Buttons.CloseIcon onClick={clearAndClose} />}
      />
      <StyledModalBody>
        <LineItem>
          <Typography variant='body1' sx={{ fontWeight: 'bold', mb: 1 }}>
            Group Name
          </Typography>
          <InputWrapper>
            <Input
              type="text"
              placeholder="e.g. Finance group"
              onChange={(e) => setTeamName(e.target.value)}
              value={teamName} 
              errorMessage={nameHasError ? 'Group name must be less than 60 characters' : ''}
              showErrorMessageBelow
            />
          </InputWrapper>
        </LineItem>
        <LineItem>
          <Typography variant='body1' sx={{ fontWeight: 'bold', mb: 1 }}>
            Group Manager(s)
          </Typography>
          <InputWrapper>
            <SearchInputWithTags
              filterItems
              formatTagLabel={(value) => {
                const label = [...selectedManagers.filter(({ label }) => !!label)].find((item) => item.value === value)?.label;

                return label;
              }}
              items={memberItems}
              isValidValue={() => true}
              onChange={(_, { value: updatedList }) => {
                if (updatedList.length < selectedManagers.length) {
                  const updatedManagerList = selectedManagers.filter((manager) => updatedList.includes(manager.value));

                  setSelectedManagers(updatedManagerList);
                }
              }}
              onChangeInput={(e, { value }) => { debouncedSearch(value, tenantId, searchMembers); }}
              onSelect={(_, item ) => {
                if (!item?.value.map) {
                  setSelectedManagers([...selectedManagers, item.item]);
                }
              }}
              fluid
              showValuesAsSubLabels={false}
              value={selectedManagers.map(({ value }) => value)}
              placeholder="e.g. Sara"
            />
          </InputWrapper>
        </LineItem>
        <LineItem>
          <Typography variant='body1' sx={{ fontWeight: 'bold', mb: 1 }}>
            Member(s) and Sub-Group(s)
          </Typography>
          <InputWrapper>
            <SearchInputWithTags
              filterItems
              formatTagLabel={(value) => {
                const label = [...selectedMembers.filter(({ label }) => !!label)].find((item) => item.value === value)?.label;

                return label;
              }}
              items={[...teamsItems, ...memberItems.filter((member) => !selectedMembers.find((selectedMember) => selectedMember.value === member.value))]}
              isValidValue={() => true}
              onChange={(_, { value: updatedList }) => {
                if (updatedList.length < selectedMembers.length) {
                  const updatedMemberList = selectedMembers.filter((member) => updatedList.includes(member.value));

                  setSelectedMembers(updatedMemberList);
                }
              }}
              onChangeInput={(e, { value }) => { debouncedSearch(value, tenantId, searchMembers); }}
              onSelect={(_, item ) => {
                if (!item?.value.map) {
                  setSelectedMembers([...selectedMembers, item.item]);
                }
              }}
              fluid
              showValuesAsSubLabels={false}
              value={selectedMembers.map(({ value }) => value)}
              placeholder="e.g. Finance group or Sara"
            />
          </InputWrapper>
        </LineItem>
      </StyledModalBody>
      <Modal.Footer.WithCancelSave
        canClickSave={canSave && !savingTeam}
        onClickCancel={clearAndClose}
        onClickSave={() => {
          createTeamMutation({
            variables: {
              tenantId,
              name: teamName,
              managers: selectedManagers.map(({ value }) => value),
              subteams: selectedMembers
                .filter(({ value }) => value.startsWith('team-'))
                .map(({ value }) => value.split('-')[1]),
              members: selectedMembers
                .filter(({ value }) => !value.startsWith('team-'))
                .map(({ value }) => value)
            },
            update(cache, { data: { createMatterTeam } }) {
              cache.writeQuery({
                query: getMatterTeams,
                variables: {
                  tenantId: parseInt(tenantId)
                },
                data: {
                  matterTeams: [...(cache.readQuery({ query: getMatterTeams, variables: { tenantId: parseInt(tenantId) } })?.matterTeams || []), createMatterTeam]
                }
              });
            }
          });
        }}
        sendIsLoading={savingTeam}
      />
    </StyledModal>
  );
}
