import React, { useState, useEffect } from 'react';
import { Modal } from '@matterapp/matter-ui';
import addMembersToMatterTeam from 'graphql-queries/mutations/matterTeams/addMembersToMatterTeam';
import { useMutation } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { EmailListArea, UnorderedList } from './styles';

const MAX_EMAILS = 5000;

function validateEmails(emailList) {
  if (!emailList || emailList?.length === 0) {
    return { isValid: false };
  }
  
  const emails = emailList.split(/,|\s/).filter(email => email.trim() !== '');

  return {
    isValid: emails.length && emails.length <= MAX_EMAILS,
    error: emails.length > MAX_EMAILS ? 'You can not add more than 5,000 members per import.' : null
  };
}

export default function BulkAddMembers(props) {
  const { isOpen, onClose, tenantId, teamId, resetData } = props;
  const [emailList, updateEmailList] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [addMembersToMatterTeamMutation, { loading: savingMembers, data: savedMembers }] = useMutation(addMembersToMatterTeam);

  const { isValid: canSave, error } = validateEmails(emailList);

  function clearAndClose() {
    updateEmailList([]);
    setShowSummary(false);

    onClose();
  }

  useEffect(() => {
    if (!savingMembers && savedMembers) {
      setShowSummary(true);
      resetData();
    }
  }, [savedMembers, savingMembers]);

  if (showSummary) {
    const { added, skippedEmails, total } = savedMembers.addMembersToMatterTeam;

    return (
      <Modal 
        isOpen={showSummary}
        onClose={clearAndClose}
        size="large"
      >
        <Modal.Header
          header='Bulk Add Members by Email'
          right={<Modal.Buttons.CloseIcon onClick={clearAndClose} />}
        />
        <Box sx={{ p: 3 }}>
          <Typography variant='body1'>
            <b>{total} Emails Processed</b>
            <UnorderedList>
              <li>{added} Added</li>
              {!!skippedEmails.length && <li>{skippedEmails.length} Skipped (not found in Matter or invalid input).</li>}
            </UnorderedList>
            {!!skippedEmails.length && (
              <>
                <Typography variant='body2'><b>{skippedEmails.length} {skippedEmails.length === 1 ? 'Email' : 'Emails'} Skipped:</b></Typography>
                <Box sx={{ maxHeight: 200, overflowY: 'auto', mt: 1 }}>
                  <Typography variant='body1'>{skippedEmails.join(', ')}</Typography>
                </Box>
              </>
            )}
          </Typography>

        </Box>
        <Modal.Footer.WithClose
          onClickClose={clearAndClose}
        />
      </Modal>
    );
  }

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={clearAndClose}
      size="large"
    >
      <Modal.Header
        header='Bulk Add Members by Email'
        right={<Modal.Buttons.CloseIcon onClick={clearAndClose} />}
      />
      <Box sx={{ p: 3 }}>
        <Typography variant='body1'>
          Enter your list of emails addresses (comma or space separated) below to add members to your group.
        </Typography>
        <Typography variant='body1' sx={{ mt: 3, mb: 2 }}><b>Emails</b></Typography>
        <EmailListArea
          showBottomBorder={false}
          onChange={(_, { value }) => {
            updateEmailList(value);
          }}
          allowErrorBeforeBlur
          showCharacterCountOnIncorrectLength
          value={emailList}
          placeholder='Type or paste your list of emails here'
          error={error}
        />
        {error && <Typography variant='body2' color='error'>{error}</Typography>}
        <Typography variant='body1' sx={{ mt: 2, mb: 1 }}><b>Important Notes:</b></Typography>
        <UnorderedList>
          <li>Users must already exist in Matter — this will not create new users.</li>
          <li>Emails that do not match any users will be skipped.</li>
          <li>Maximum of 5,000 members per import.</li>
        </UnorderedList>
      </Box>
      <Modal.Footer.WithCancelSave
        canClickSave={canSave && !savingMembers}
        onClickCancel={clearAndClose}
        saveLabel={savingMembers ? 'Adding Members...' : 'Add Members'}
        onClickSave={() => {
          addMembersToMatterTeamMutation({
            variables: {
              tenantId,
              teamId: parseInt(teamId),
              emails: emailList.split(/,|\s/).filter(email => email.trim() !== '').join(',')
            }
          });
        }}
        sendIsLoading={savingMembers}
      />
    </Modal>
  );
}
