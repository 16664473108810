import React from 'react';
import { ToggleContainer, ToggleWrapper, ToggleUnit } from 'components/ToggleGroup';
import { StyledPaper } from '../styles';
import { Typography } from '@mui/material';

export default function AddOnCoinsSection(props) {
  const { addOnCoins, onChange } = props;

  return (
    <StyledPaper>
      <Typography variant='h3' sx={{ mb: 3 }}>Adding On Coins</Typography>
      <Typography variant='body1'>
        By default, members can add coins to existing Matter posts like kudos.
      </Typography> 
      <ToggleWrapper>
        <ToggleContainer>
            Allow Members to Add Coins
          <ToggleUnit 
            checked={addOnCoins}
            onChange={onChange}
          />
        </ToggleContainer>
      </ToggleWrapper>
    </StyledPaper>
  );
}
