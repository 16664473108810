import React, { useState, createRef, useEffect, useContext } from 'react';
import { Button as MatterButton } from '@matterapp/matter-ui';
import UserContext from 'context/UserContext/UserContext';
import { 
  Box,
  Typography,
  TableHead,
  TableSortLabel,
  TableBody,
  Table
} from '@mui/material';
import EditSegmentModal from './EditSegmentModal';
import getSegments from 'graphql-queries/queries/hris/getSegments';
import getCustomHrisProperties from 'graphql-queries/queries/hris/getCustomHrisProperties';
import { useQuery } from '@apollo/client';
import {
  TopWrapper,
  TopSection,
  NameWrapper,
  StyledTableRow,
  HeaderCell,
  StyledBodyRow,
  MainTableCell,
  DesktopOnlyCell,
  MobileCell,
  EditButton
} from '../DataProperties/styles';
import { Caption, NoComments } from 'components/Table/styles';
import { Resources } from '@matterapp/routing';
import { useNavigate } from 'react-router-dom';

export default function Segments() {
  const { currentTenant: tenant, currentWorkspace: workspace } = useContext(UserContext);
  const [order, setOrder] = useState('desc');
  const [editingSegment, setEditingSegment] = useState(null);
  const [buttonText, updateButtonText] = useState('Create Segment');
  const navigate = useNavigate();
  const { data: segmentsData, loading } = useQuery(getSegments, {
    variables: {
      tenantId: tenant.id
    },
    fetchPolicy: 'network-only'
  });

  const { data: customHrisPropsData } = useQuery(getCustomHrisProperties, {
    variables: {
      tenantId: tenant.id
    }
  });

  const ref = createRef();
  const { segments = [] } = segmentsData || {};
  let { customHrisProperties = [] } = customHrisPropsData || {};
  customHrisProperties = customHrisProperties?.filter(({ type }) => type !== 'text');

  useEffect(() => {
    if (ref?.current?.offsetWidth < 600) {
      updateButtonText('Create');
    }
  }, [ref]);

  return (
    <Box ref={ref}>
      <TopSection>
        <Typography variant='h4'>
          Segments
        </Typography>
        <MatterButton 
          size={'S'} 
          onClick={() => setEditingSegment({})}
        >
          {buttonText}
        </MatterButton>
      </TopSection>
      <TopWrapper>
        <EditSegmentModal 
          workspace={workspace}
          tenant={tenant}
          isOpen={!!editingSegment}
          segment={editingSegment}
          customHrisProperties={customHrisProperties}
          onClose={() => setEditingSegment(null)}
        />
        <Typography variant='body1' sx={{ mt: -2, mb: 3 }}>
          Create a segment to group members based on member properties to get more specific data and insights about your organization.
          Segments are updated every 24 hours.  
          To learn more, visit the <a href='https://help.matterapp.com/en/articles/10309837-member-segments' target='_blank' rel='noopener noreferrer'> Help Center</a>.
        </Typography>
      </TopWrapper>
      <Table size='large' sx={{ mb: 9 }}>
        {!segments?.length ? (
          loading || !tenant ? (
            <Caption>
              <NoComments variant='body1' component='p'>
                &nbsp;
              </NoComments>
            </Caption>
          ) : (
            <Caption>
              <NoComments variant='h6' component='p'>
                <b>No segments yet</b>
              </NoComments>
              <NoComments variant='body1' component='p'>
                Use your <a style={{ cursor: 'pointer' }} onClick={() => navigate(Resources.workspaceSettingsDataProps.path({ workspaceId: workspace.id }))}>member properties</a> to create segments.
              </NoComments>
            </Caption>
          )
        ) : null}
        <TableHead>
          <StyledTableRow>
            <HeaderCell visibleOnMobile>
              <TableSortLabel
                active
                direction={order}
                onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
              >
                Segment
              </TableSortLabel>
            </HeaderCell>
            <HeaderCell>Members</HeaderCell>
            <HeaderCell>Created by</HeaderCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {segments && [...segments]
            .sort((a, b) => {
              if (order === 'asc') {
                return a.name > b.name ? -1 : 1;
              } 
        
              return b.name > a.name ? -1 : 1;
            })
            .map((property) => {
              const { id, name, memberCount, createdBy } = property;

              return (
                <StyledBodyRow 
                  sx={{ cursor: 'pointer' }}
                  key={id} 
                  onClick={() => {
                    setEditingSegment(property);
                  }}
                >
                  <MainTableCell>
                    <NameWrapper>
                      <Typography variant='body2' sx={{ fontWeight: 'bold', width: '100%', textOverflow: 'ellipsis' }}>
                        {name}
                      </Typography>
                    </NameWrapper>
                  </MainTableCell>
                  <DesktopOnlyCell>
                    <Typography variant='body2' color='blacks.90'>
                      {memberCount}
                    </Typography>
                  </DesktopOnlyCell>
                  <DesktopOnlyCell sx={{ maxWidth: '25%' }}>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}>
                      <Typography variant='body2' color='blacks.90' sx={{ textWrap: 'nowrap' }}>
                        {createdBy?.fullName || 'Matter'}
                      </Typography>
                      <EditButton 
                        variant='outlined'
                      >
                        EDIT
                      </EditButton>
                    </Box>
                  </DesktopOnlyCell>
                  <MobileCell>
                    <EditButton 
                      variant='outlined'
                    >
                      EDIT
                    </EditButton>
                  </MobileCell>
                </StyledBodyRow>
              );
            })}
        </TableBody>
      </Table>
    </Box>
  );
};
