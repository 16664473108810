
import { animated } from 'react-spring';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { getPalette } from '../skill-color-palettes';

export const unitHeight = '32px';
export const unitWidth = '10px';

export const getBarColorPalette = ({ color }) => {
  const palette = getPalette(color);
  return palette.tint;
};

export const getPrimaryBarColorPalette = ({ color }) => {
  const palette = getPalette(color);
  return palette.primary;
};

export const SkillBadge = styled.div``;

export const SkillBadgeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing.half};
`;

export const SkillInfo = styled.div`
  width: 100%;
  margin: 0;
`;

export const SkillInfoContainer = styled.div
  .withConfig({ shouldForwardProp: (prop) => {
    return prop !== 'showScore';
  }})
`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  height: ${({ showScore }) => showScore ? '112px' : 'auto'};
`;

export const SkillScore = styled(animated.div)
  .withConfig({ shouldForwardProp: (prop) => {
    return prop !== 'showScore';
  }})
`
  border-bottom: 1px dashed ${({ showScore, ...props}) => showScore ? getPrimaryBarColorPalette(props) : 'transparent'};
  color: ${getPrimaryBarColorPalette};
  display: inline-block;
  font-weight: ${theme.fontWeight.bold};
  margin: 0 0 ${theme.spacing.threeQuarters};
  transition: border ${theme.transitions.times.default} ${theme.transitions.times.default};
  cursor: default;
`;

export const SkillName = styled.div`
  color: ${theme.colors.blacks[70]};
  font-size: ${theme.spacing.base};
  font-weight: ${theme.fontWeight.bold};
  display: -webkit-box;
  hyphens: auto;
  margin: 0 auto;
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines || 2};
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  ${theme.media.S`
    font-size: ${theme.spacing.single};
  `}
`;

export const Container = styled.div
  .withConfig({ shouldForwardProp: (prop) => {
    return prop !== 'showScore';
  }})
`
  ${({ showScore }) => {
  const badgeSize = showScore
    ? theme.spacing.double
    : theme.spacing.doubleAndHalf;
  return `
      line-height: 1;
      text-align: ${showScore ? 'left' : 'center'};
      width: 100%;
      & ${SkillBadge} {
        margin: ${!showScore ? `${theme.spacing.threeQuarters} auto ${theme.spacing.threeQuarters}` : ''};
        max-width: ${badgeSize};
        height: ${badgeSize};
      }
      & ${SkillInfo} {
        text-align: ${showScore ? 'left' : 'center'};
        margin-top: ${showScore ? 0 : `-${theme.spacing.single}`};
        margin-bottom: ${showScore ? theme.spacing.quarter: theme.spacing.threeQuarters};
        padding-top: ${showScore ? 0 : theme.spacing.single};
        & + button {
          display: inline-block;
          cursor: default;
        }
      }
    `;
}}
`;

export const BarContainer = styled('div')
  .withConfig({ shouldForwardProp: (prop) => {
    return prop !== 'showScore';
  }})`
  position: relative;
  background: ${getBarColorPalette};
  margin: 0 -1px;
  min-height: ${unitHeight};
  display: flex;
  flex: 1;
  cursor: default;
  overflow: hidden;
`;

export const Bar = styled(animated.div)`
  position: absolute;
  z-index: 0;
  background: ${getPrimaryBarColorPalette};
  min-height: ${unitHeight};
  width: 100%;
  transform-origin: left;
`;

export const UnitContainer = styled.div`
  display: flex;
  width: 100%;
  z-index: 0;

  & > button {
    display: flex;
    flex: 1 1 auto;
    cursor: default;
    min-width: ${unitWidth};
    
    & > div {
      height: 100%;
      min-height: ${unitHeight};
      min-width: unset;
    }
    &:last-child > div > div:last-child {
      background: ${theme.colors.white};
    }
  }
`;

export const TooltipContainer = styled.div`
  padding: ${theme.spacing.half} ${theme.spacing.threeQuarters};

  & > span:first-child:not(:only-child) {
    font-weight: bold;
  }
`;