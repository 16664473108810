import moment from 'moment-timezone';
import { WEEKDAY_LIST } from 'libs/prop-types/weekday';
import {
  FEEDBACK_RITUAL_PANEL_TEXT,
  TextReplace,
} from '../sharedConsts';
import { feedbackFridayUtils } from '@matterapp/utils';

export const RITUAL_STAGES = {
  KICKOFF: 'kickoff',
  REMINDER: 'reminder',
  RECAP: 'recap'
};

export function getTimezoneCode(timezone) {
  const timezoneText = timezone
    ? `${moment.tz(new Date(), timezone).format('z')}`
    : '';

  return timezoneText;
}

export function getRitualCustomTimes(stage, prevStageStartsAt) {
  let minHour = 0;
  let minMinutes = '00';
  let maxEndHour = 23;

  if (prevStageStartsAt) {
    const prevStage = prevStageStartsAt.split(':');
    minHour = parseInt(prevStage[0], 10);
    minMinutes = prevStage[1];

    // Next stage can start in 30 mins
    if (minMinutes === '30') {
      minHour += 1;
      minMinutes = '00';
    } else {
      minMinutes = '30';
    }
  }

  if (stage === RITUAL_STAGES.KICKOFF) {
    maxEndHour = 21;
  } else if (stage === RITUAL_STAGES.REMINDER) {
    maxEndHour = 22;
  }


  let hour = minHour;
  let minutes = minMinutes;
  const result = [];

  while (hour < maxEndHour ) {
    result.push(`${hour}:${minutes}`);
  
    if (minutes === '00') {
      minutes = '30';
    } else {
      hour++;
      minutes = '00';
    }
  }

  result.push(`${hour}:${minutes}`); 

  return result;
}

export function formatDropdownOptions(options) {
  return options.map(value => ({ label: `${feedbackFridayUtils.getUSFormattedTime(value)}`, value }));
}

export function getLastCallTime(recapTime) {
  let [hours, minutes] = recapTime.split(':');

  if (parseInt(minutes, 10) === 30) {
    minutes = 15;
  } else {
    minutes = 45;
    hours = hours - 1;
  }

  return `${hours}:${minutes}`;
}

export function getDefaultKickoffMessage(dayOfPost, customRitualName) {
  const ritualName = getRitualName(dayOfPost);

  return FEEDBACK_RITUAL_PANEL_TEXT.KICKOFF_DEFAULT_MESSAGE.replace(
    TextReplace.replaceText,
    customRitualName || ritualName
  );
}

export function getRitualName(dayOfPost) {
  const ritualName = `Feedback ${WEEKDAY_LIST[dayOfPost].name}`;

  const dayOfPostToRitualName = {
    1: 'Motivation Monday',
    2: 'Thank You Tuesday',
    3: 'Winning Wednesday',
    4: 'Thankful Thursday',
    5: 'Feedback Friday',
    6: 'Shoutout Saturday',
    7: 'Shoutout Sunday'
  };

  return dayOfPostToRitualName[dayOfPost] || ritualName;
}

export function isKickoffMessageCustom(kickoffMessage, previousCustomRitualName) {
  for (let i = 1; i < 8; i++) {
    const dayOfPost = i;

    const defaultKickoffMessage = getDefaultKickoffMessage(dayOfPost, previousCustomRitualName);

    if (kickoffMessage === defaultKickoffMessage) {
      return false;
    }
  }

  return true;
}