import React, { useEffect, useContext } from 'react';
import { Paper, Box, Typography } from '@mui/material';
import { Button as MatterButton } from '@matterapp/matter-ui';
import styled from 'styled-components';
import UserContext from 'context/UserContext/UserContext';
import { useUpgradeModal } from 'routes/pages/workspaces/hooks/shared';
import UpgradeModal from 'modules/workspaces/shared/UpgradeModal';
import { Resources } from '@matterapp/routing';
import { useNavigate } from 'react-router-dom';

const VideoWrapper = styled(Box)(({ theme }) => ({
  width: '440px',
  minWidth: '440px',
  marginRight: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    minWidth: '100%',
    margin: 0,
    marginTop: theme.spacing(2)
  }
}));

const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex', 
  flexDirection: 'row', 
  marginTop: theme.spacing(4)
}));

const UpgradeButton = styled(MatterButton.Simple.Primary.Vivid)(({ theme }) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    marginBottom: theme.spacing(2)
  }
}));

const StyledMatterButton = styled(MatterButton)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    marginBottom: theme.spacing(2)
  }
}));

const TextWrapper = styled(Box)(({ theme }) => ({
  alignSelf: 'flex-start'
}));

const Wrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    padding: theme.spacing(3)
  }
}));

export default function UpgradeView() {
  const { isAdminMember, currentWorkspace, tenantAdmins, scheduleCallParams } = useContext(UserContext);
  const upgradeModalProps = useUpgradeModal(currentWorkspace, tenantAdmins, false);
  const { teamsWorkspace, slackWorkspace } = currentWorkspace || {};
  const [wistiaId, setWistiaId] = React.useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentWorkspace) {
      if (teamsWorkspace) {
        const script1 = document.createElement('script');
        const script2 = document.createElement('script');
        script1.src = 'https://fast.wistia.com/embed/medias/fc3il68wir.jsonp';
        script1.async = true;
        script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
        script2.async = true;
    
        document.body.appendChild(script1);
        document.body.appendChild(script2);

        setWistiaId('fc3il68wir');
      } else if (slackWorkspace) {
        const script1 = document.createElement('script');
        const script2 = document.createElement('script');
        script1.src = 'https://fast.wistia.com/embed/medias/e59b3lirvu.jsonp';
        script1.async = true;
        script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
        script2.async = true;
    
        document.body.appendChild(script1);
        document.body.appendChild(script2);

        setWistiaId('e59b3lirvu');
      }
    }
  }, [currentWorkspace]);

  if (!wistiaId) {
    return null;
  }

  return (
    <Wrapper>
      <VideoWrapper>
        <Box className="wistia_responsive_padding" sx={{ padding: '62.29% 0 0 0', position: 'relative' }}>
          <Box className="wistia_responsive_wrapper" sx={{ height: '100%', left:0, position: 'absolute', top:0, width: '100%' }}>
            <Box className={`wistia_embed wistia_async_${wistiaId} seo=false videoFoam=true autoPlay=false`}
              sx={{ height: '100%', position: 'relative', width: '100%' }}>
              <Box 
                className="wistia_swatch"
                sx={{
                  height: '100%',
                  left: 0, 
                  opacity:0,
                  overflow: 'hidden',
                  position: 'absolute',
                  top: 0,
                  transition: 'opacity 200ms',
                  width: '100%'
                }}>
                <img 
                  src={`https://fast.wistia.com/embed/medias/${wistiaId}/swatch`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    filter: 'blur(5px)'
                  }}
                  onLoad="this.parentNode.style.opacity=1;" 
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </VideoWrapper>
      <TextWrapper>
        <Typography variant='h2' sx={{ mb: 1 }}>10x your response rate with custom surveys</Typography>
        <Typography variant='body1'>
          Get instant feedback and powerful analytics with custom surveys. Schedule and automate surveys, plus offer rewards for survey completions.
        </Typography>
        <Typography variant='body1' sx={{ mt: 2, mb: 2, color: 'blues.50' }}>
          <a 
            href={teamsWorkspace ? 'https://matterapp.com/training/custom-surveys-teams' : 'https://matterapp.com/training/employee-surveys-slack'} 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Learn more →
          </a>
        </Typography>
        <ButtonsWrapper>
          <UpgradeButton
            size='M'
            color='blue'
            onClick={() => {
              if (isAdminMember) {
                navigate(Resources.workspaceSettingsBilling.path({ workspaceId: currentWorkspace.id }, { pulseUpgrade: true }));
              } else {
                upgradeModalProps.openUpgradeModal();
              }
            }}
          >
            Upgrade Now
          </UpgradeButton> 
          <StyledMatterButton 
            size='M'
            color='black'
            onClick={() => {
              window.open(`https://schedule.matterapp.com/campaign/free-trial?${scheduleCallParams}`, '_blank');
            }}
          >
            Schedule a Call
          </StyledMatterButton> 
        </ButtonsWrapper>
      </TextWrapper>
      <UpgradeModal {...upgradeModalProps} isSurveys />
    </Wrapper>
  );
}
