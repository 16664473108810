import React, { useReducer, useEffect, useContext, useState } from 'react';
import UserContext from 'context/UserContext/UserContext';
import Toast from 'components/Toast/Toast';
import updatePulseSettings from 'graphql-queries/mutations/pulse/updatePulseSettings';
import pulseSampleMutation from 'graphql-queries/mutations/pulse/pulseSampleQuery';
import sendPulseDigest from 'graphql-queries/mutations/pulse/sendPulseDigest';
import launchPulseMutation from 'graphql-queries/mutations/pulse/launchPulse';
import getTenantMembersByPersonIds from 'graphql-queries/queries/workspace/getTenantMembersByPersonIds';
import getNextPulseDate from 'graphql-queries/queries/pulse/getNextPulseDate';
import { useMutation, useQuery } from '@apollo/client';
import { Modal, Button as MatterButton, Advisors, Checkbox, Input, Message } from '@matterapp/matter-ui';
import { Box, Typography } from '@mui/material';
import DisabledState from './DisabledState';
import { FormattedGroup } from 'components/Group';
import { StyledPaper } from '../styles';
import {
  ToggleContainer,
  SubHeader,
  AllowanceBlock,
  InputWrapper,
  ResetButton,
  FrequencyWrapper,
  EnpsFrequencyWrapper,
} from './styles';
import { FREQUENCY_OPTIONS, ENPS_FREQUENCY_OPTIONS } from './helpers';
import { getInitialState, pulseReducer, ACTIONS, getSettings } from './state';
import AddMembersModal, { MemberListItem, RemoveMemberButton } from 'components/AddMembersModal/AddMembersModal';
import BlueBanner from 'components/BlueBanner/BlueBanner';
import PulseUpgradeModal from 'modules/workspaces/shared/PulseUpgradeModal/PulseUpgradeModal';
import AudienceSection from './AudienceSection';
import { SettingsFooter } from '../styles';
import SettingsHeader from '../SettingsHeader';

const { IS_PROD } = WEBPACK_ENV_VARS;

const QUESTION_COUNT_OPTIONS = [{
  label: '1 Question',
  value: 1
}];

for (let i = 2; i <= 20; i++) {
  QUESTION_COUNT_OPTIONS.push({
    label: `${i} Questions`,
    value: i
  });
}

export default function PulsePanel() {
  const { currentTenant: tenant, refetchCurrentUser, currentWorkspace, currentUser, scheduleCallParams } = useContext(UserContext);
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);
  const [isExcludeModalOpen, setExcludeModalOpen] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const { id: tenantId, pulseSettings, rewardSettings, hasPulse } = tenant;
  const { isRewardsActive } = rewardSettings || {};
  const [state, dispatch] = useReducer(pulseReducer, getInitialState(pulseSettings));
  const { canSave, settings } = state;
  const { slackWorkspace } = currentWorkspace || {};
  const { 
    frequency, 
    questionCount,
    eNpsEnabled, 
    eNpsFrequency, 
    adminNotifications, 
    coinsPerSurvey, 
    coinsError, 
    launchSurveysInstantly, 
    isPulseActive,
    isPulseEnabled,
    notifyMembers,
    excludeMembers,
    recipients,
    rewardsActive,
    sendReminders
  } = settings;

  const { data: nextPulseData } = useQuery(getNextPulseDate, {
    variables: {
      tenantId,
      frequency
    },
    skip: !settings?.frequency
  });
 
  const selectedFrequency = FREQUENCY_OPTIONS.find(({ value }) => value === frequency);
  const selectedQuestionCount = QUESTION_COUNT_OPTIONS.find(({ value }) => value === questionCount);
  const selectedEnpsFrequency = ENPS_FREQUENCY_OPTIONS.find(({ value }) => value === eNpsFrequency);
  const [savePulseSettings, { loading: savingPulseSettings, data }] = useMutation(updatePulseSettings);
  const [sendDigest] = useMutation(sendPulseDigest);
  const [sendPulseSample] = useMutation(pulseSampleMutation);
  const [launchPulse, { loading: launchingPulse }] = useMutation(launchPulseMutation);
  const notificationCopy = slackWorkspace 
    ? 'Configure admin notifications for Pulse Survey activity. All notifications are sent by @MatterBot on Slack.'
    : 'Configure admin notifications for Pulse Survey activity. All notifications are sent by Matter on Teams.';
  const personIds = new Set();
  const trainingLink = slackWorkspace 
    ? <a href='https://matterapp.com/training/pulse-surveys-slack' target='_blank' rel='noopener noreferrer'>Training Video</a>
    : <a href='https://matterapp.com/training/pulse-surveys-teams' target='_blank' rel='noopener noreferrer'>Training Video</a>;
  
  [
    ...notifyMembers, 
    ...excludeMembers, 
    ...recipients
      .map(({ value }) => parseInt(value, 10))
      .filter(Boolean)
  ]
    .forEach((personId) => personIds.add(personId));
    
  const { data: membersData } = useQuery(getTenantMembersByPersonIds, {
    variables: {
      tenantId,
      personIds: Array.from(personIds)
    },
    skip: !personIds.size
  });

  const members = membersData?.tenantMembersByPersonIds || [];

  const saveSettings = async (settings) => {
    await savePulseSettings({
      variables: {
        tenantId,
        settings,
      }
    });

    refetchCurrentUser();
  };

  useEffect(() => {
    if (data && !savingPulseSettings) {
      Toast.success('Your settings have been saved');

      const { setPulseSettings } = data;
      const { pulseSettings } = setPulseSettings;

      dispatch({ type: ACTIONS.RESET_INITIAL_SETTINGS, payload: pulseSettings });
    }
  }, [savingPulseSettings, data]);

  if (!tenant) {
    return null;
  }

  return (
    <Modal.Panel.Main>
      <SettingsHeader 
        headerText='Pulse Survey'
        isLoading={savingPulseSettings}
        onDisable={() => {
          dispatch({ type: ACTIONS.TOGGLE_PULSE });
          saveSettings({ isPulseEnabled: false });
        }}
        disabled={!isPulseEnabled}
        disabledComponent={(
          <DisabledState 
            saveSettings={saveSettings}
            savingPulseSettings={savingPulseSettings}
            isSlack={!!slackWorkspace}
          />
        )}
        text='Configure Feedback Friday settings for your organization.'
        trainingLink={trainingLink}
        helpCenterLink='https://help.matterapp.com/en/collections/8234608-pulse-surveys'
      />
      {isPulseEnabled ? (
        <>
          <AddMembersModal 
            onSave={async (members) => {
              let settingsToSave = {};

              if (isNotifyModalOpen) {
                settingsToSave = { notifyMembers: Array.from(new Set([...members.map(({ person }) => person.id), ...pulseSettings.notifyMembers])) };
              } else if (isExcludeModalOpen) {
                settingsToSave = { excludeMembers: Array.from(new Set([...members.map(({ person }) => person.id), ...pulseSettings.excludeMembers])) };
              }

              await saveSettings({
                ...settingsToSave
              });
            }}
            tenantId={tenant.id}
            header={isNotifyModalOpen ? 'Notify Members' : 'Exclude Members'}
            isOpen={isNotifyModalOpen || isExcludeModalOpen}
            onClose={() => {
              setIsNotifyModalOpen(false);
              setExcludeModalOpen(false);
            }}
          />
          <StyledPaper>
            <FormattedGroup marginBottom='0px'>
              <BlueBanner 
                showButton={!isPulseActive}
                text={isPulseActive ? '🎉 Woohoo! Your Pulse Survey is launched! 👏' : '👉 Your Pulse Survey is Ready to Launch!'}
                buttonProps={{
                  isDisabled: savingPulseSettings,
                  onClick: async () => {
                    if (hasPulse) {
                      await saveSettings({
                        notifyMembers: Array.from(new Set([...notifyMembers, currentUser.personId])),
                      });

                      await launchPulse({
                        variables: {
                          tenantId
                        }
                      });

                      dispatch({ type: ACTIONS.LAUNCH_PULSE });

                      Toast.success('Your pulse survey has been launched!');

                      refetchCurrentUser();
                    } else {
                      setUpgradeModalOpen(true);
                    }
                  },
                  text: launchingPulse ? 'Launching' : 'Launch'
                }}
              />
              <Typography variant='body1' component='p' sx={{ mb: 2 }}>
                Pulse surveys will only be sent Monday through Thursday during business hours (9 am to 5 pm local time). 
                After launching, your first pulse survey will be sent on the following applicable day.
              </Typography>
              <MatterButton 
                onClick={async () => {
                  await sendPulseSample({
                    variables: {
                      tenantId
                    }
                  });
    
                  Toast.success('Preview sent');
                }}
                size='S'
              >
                Preview Pulse Survey
              </MatterButton>
              <Box sx={{ mt: 3 }}>
                <ToggleContainer>
                  <Typography variant='body1' component='p' >
                    Send First Pulse Survey Instantly (On Launch)
                  </Typography>
                  <Checkbox.Toggle
                    size='S' 
                    disabled={isPulseActive}
                    checked={launchSurveysInstantly}
                    onChange={() => {
                      dispatch({ type: ACTIONS.TOGGLE_LAUNCH_INSTANTLY });
                    }}
                  />
                </ToggleContainer>
              </Box>
            </FormattedGroup>
          </StyledPaper>
          <AudienceSection 
            settings={state.settings}
            dispatch={dispatch}
            members={members}
            tenant={tenant}
            currentWorkspace={currentWorkspace}
            setExcludeModalOpen={setExcludeModalOpen}
            saveSettings={saveSettings}
          />
          <StyledPaper>
            <FormattedGroup header="Frequency" marginBottom='0px'>
              <Typography variant='body1' component='p'>
                Every employee will receive 1 pulse per frequency period.
              </Typography>
              <FrequencyWrapper>
                <Advisors.RecurringModal.Dropdown
                  options={FREQUENCY_OPTIONS}
                  selectedOption={selectedFrequency.label}
                  handleChangeSelection={(choice) => {
                    dispatch({ type: ACTIONS.FREQUENCY_CHANGE, payload: choice.value });
                  }}
                  width='100%'
                  position='absolute'
                />
              </FrequencyWrapper>
              <Typography variant='body2' component='p' sx={{ mt: 2 }}>
              Your next Pulse Survey sends on {nextPulseData?.nextPulseDate}
              </Typography>
            </FormattedGroup>
          </StyledPaper>
          <StyledPaper>
            <FormattedGroup header="Question Count" marginBottom='0px'>
              <Typography variant='body1' component='p'>
              Specify how many questions to include per survey.
              </Typography>
              <FrequencyWrapper>
                <Advisors.RecurringModal.Dropdown
                  options={QUESTION_COUNT_OPTIONS}
                  selectedOption={selectedQuestionCount.label}
                  handleChangeSelection={(choice) => {
                    dispatch({ type: ACTIONS.QUESTION_COUNT_CHANGE, payload: choice.value });
                  }}
                  width='100%'
                  position='absolute'
                />
              </FrequencyWrapper>
            </FormattedGroup>
          </StyledPaper>
          <StyledPaper>
            <FormattedGroup 
              header="Employee Net Promoter Score (eNPS)"
              marginBottom='0px'
              headerActions={
                <Checkbox.Toggle
                  size={Checkbox.Toggle.sizes.S}
                  checked={eNpsEnabled}
                  onChange={() => {
                    dispatch({ type: ACTIONS.TOGGLE_ENPS });
                  }}
                />
              }
            >
              {eNpsEnabled ? (
                <>
                  <Typography variant='body1' component='p'>
                  This question is always asked first, while other questions are randomized.
                  </Typography>
                  <Typography variant='h5' component='p' sx={{ mt: 2, mb: 2 }}>
                  eNPS Question Frequency
                  </Typography>
                  <Typography variant='body1' component='p'>
                  This question is typically asked less frequently than other questions.
                  </Typography>
                  <EnpsFrequencyWrapper>
                    <Advisors.RecurringModal.Dropdown
                      options={ENPS_FREQUENCY_OPTIONS}
                      selectedOption={selectedEnpsFrequency.label}
                      handleChangeSelection={(choice) => {
                        dispatch({ type: ACTIONS.ENPS_FREQUENCY_CHANGE, payload: choice.value });
                      }}
                      width='100%'
                      position='absolute'
                    />
                  </EnpsFrequencyWrapper>
                </>
              ) : null}
            </FormattedGroup>
          </StyledPaper>
          <StyledPaper>
            <FormattedGroup 
              marginBottom='0px'
              header="Notifications"
            >
              <Typography variant='body1' component='p'>
                {notificationCopy}
              </Typography>
              <Box sx={{ mt: 1, mb: 1 }}>
                <ToggleContainer>
                  <Typography variant='body1' component='p' >
                  New Feedback
                  </Typography>
                  <Checkbox.Toggle
                    size='S' 
                    checked={adminNotifications.newFeedback}
                    onChange={() => {
                      dispatch({ type: ACTIONS.TOGGLE_NEW_FEEDBACK });
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Typography variant='body1' component='p'>
                  Weekly Digest
                  </Typography>
                  <Checkbox.Toggle 
                    size='S' 
                    checked={adminNotifications.digest}
                    onChange={() => {
                      dispatch({ type: ACTIONS.TOGGLE_DIGEST });
                    }}
                  />
                </ToggleContainer>
              </Box>
              <SubHeader>
                <Typography variant='h6' component='p'>
                Notified Members
                </Typography>
                <MatterButton 
                  onClick={() => setIsNotifyModalOpen(true)}
                  size='S'
                >
                Add member
                </MatterButton>
              </SubHeader>
              <Typography variant='body1' component='p' sx={{ mb: 3 }}>
                The admin who first launches the pulse survey will be automatically added. 
                Optionally, you can add additional admins.
              </Typography>
              {members
                .filter(({ person }) => notifyMembers.includes(String(person.id)))
                .map(({ person }) => (
                  <MemberListItem
                    key={person.id}
                    peer={person}
                  >
                    <RemoveMemberButton size='S' color='black' onClick={() => {
                      const newMembers = notifyMembers.filter((personId) => personId !== person.id);
                      saveSettings({ notifyMembers: newMembers });
                    }}>
                      REMOVE
                    </RemoveMemberButton>
                  </MemberListItem>
                ))}
            </FormattedGroup>
          </StyledPaper>
          <StyledPaper>
            <FormattedGroup 
              marginBottom='0px'
              header='Members Notifications'
            >
              <Typography variant='body1' component='p' sx={{ mb: 2 }}>
              Increase participation by enabling reminders.
              </Typography>
              <Box sx={{ mt: 3 }}>
                <ToggleContainer>
                  <Typography variant='body1' component='p' >
                  Reminders to Recipients
                  </Typography>
                  <Checkbox.Toggle
                    size='S' 
                    checked={sendReminders}
                    onChange={() => {
                      dispatch({ type: ACTIONS.TOGGLE_REMINDERS });
                    }}
                  />
                </ToggleContainer>
              </Box>
              <Typography variant='body2' component='p' sx={{ mt: 2, color: 'blacks.50' }}>
              Matter will send smart reminders at optimal times per participant.
              </Typography>
            </FormattedGroup>
          </StyledPaper>
          {isRewardsActive ? (
            <StyledPaper>
              <FormattedGroup 
                header="Rewards"
                marginBottom='0px'
                headerActions={
                  <Checkbox.Toggle
                    size={Checkbox.Toggle.sizes.S}
                    checked={rewardsActive}
                    onChange={() => {
                      dispatch({ type: ACTIONS.TOGGLE_REWARDS });
                    }}
                  />
                }
              >
                {rewardsActive ? (
                  <>
                    <Typography variant='body1' component='p'>
                    Optionally, give Matter coins to members for completing a pulse survey. 
                    This has been shown to increase participation rates and response times by 35%.
                    </Typography>
                    <AllowanceBlock>
                      <Box sx={{ mt: 5, mb: 2 }}>
                        <Typography variant='h6' component='p'>
                        Coins per Member per Survey Completion
                        </Typography> 
                        {coinsPerSurvey} Matter Coins = {coinsPerSurvey / 10} USD
                      </Box>
                      <InputWrapper>
                        <ResetButton 
                          onClick={() => {
                            dispatch({ type: ACTIONS.RESET_COINS });
                          }}
                        >
                        Reset
                        </ResetButton>
                        <Input
                          type="number"
                          onChange={(event) => {
                            dispatch({ type: ACTIONS.COINS_PER_SURVEY_CHANGE, payload: event.target.value });
                          }}
                          errorMessage={coinsError ? 'Must be between 1-100,000' : null}
                          showErrorMessageBelow
                          errorMessageClassName="allowanceInputError"
                          value={(coinsPerSurvey || 0).toString()} 
                        />
                      </InputWrapper>
                    </AllowanceBlock>
                  </>
                ) : (
                  <Message.Warning
                    showColonAfterHeader
                    header='Rewards off'
                    content='Enable rewards, giving Matter coins to members for completing a pulse survey. This has been shown to increase participation rates and response times by 35%.'
                  />
                )}
              </FormattedGroup>
            </StyledPaper>
          ) : null}

          <PulseUpgradeModal 
            currentWorkspace={currentWorkspace}
            isOpen={upgradeModalOpen} 
            onClose={() => setUpgradeModalOpen(false)} 
            scheduleCallParams={scheduleCallParams}
          />
          {IS_PROD ? null : (
            <StyledPaper>
              <FormattedGroup 
                marginBottom='0px'
                header="Advanced"
              >
                <Box sx={{ mt: 2}}>
                  <MatterButton 
                    disabled={!notifyMembers.length}
                    onClick={async () => {
                      await sendDigest({
                        variables: {
                          tenantId
                        }
                      });
            
                      Toast.success('Digest is sent!');
                    }}
                    size='S'
                  >
                  Send digest
                  </MatterButton>
                </Box>
              </FormattedGroup>
            </StyledPaper>
          )}
          {
            isPulseEnabled ? 
              (
                <SettingsFooter
                  primaryLabel='Save'
                  onClickPrimary={() => saveSettings(getSettings(state))}
                  canClickPrimary={canSave}
                />
              ) : null
          }
        </>
      ) : null}
    </Modal.Panel.Main>
  );
};
