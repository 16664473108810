import React, { useState, useContext, useEffect } from 'react';
import { Button, Confirm } from '@matterapp/matter-ui';
import { 
  MainWrapper
} from '../styles';
import {
  StyledTabs,
  StyledTab
} from './styles';
import UserContext from 'context/UserContext/UserContext';
import { useMutation } from '@apollo/client';
import deleteMatterTeam from 'graphql-queries/mutations/matterTeams/deleteMatterTeam';
import getMatterTeams from 'graphql-queries/queries/matterTeams/getMatterTeams';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from 'hooks';
import Toast from 'components/Toast/Toast';
import MembersTab from './MembersTab';
import SubTeamsTab from './SubTeamsTab';
import ManagersTab from './ManagersTab';
import NameTab from './NameTab';
import { 
  Box,
  Typography
} from '@mui/material';
import { TopSection } from '../../DataProperties/styles';
import { Resources } from '@matterapp/routing';

function CurrentTab(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

export default function MatterTeamDetails({ 
  matterTeam, 
  MAX_MEMBERS_PER_PAGE, 
  refetchTeam
}) {
  const { currentTenant: tenant, currentWorkspace: workspace } = useContext(UserContext);
  const [tabValue, setTabValue] = useState(0);
  const [deletingTeam, setDeletingTeam] = useState(false);
  const { isEnterprise, hrisSettings } = tenant;
  const { teamsMapping } = hrisSettings || {};
  const { queryParams, setQueryParams } = useQueryParams();
  const isAdminSettings = !queryParams.isChannelSetting;
  const navigate = useNavigate();
  const { id: workspaceId, tenantId } = workspace || {};
  const variables = { limit: MAX_MEMBERS_PER_PAGE };
  const [deleteMatterTeamMutation] = useMutation(deleteMatterTeam);
  const isSynced = teamsMapping?.find(({ matterTeamId }) => matterTeamId === matterTeam?.id);
  const tabParams = ['members', 'sub-groups', 'managers', 'name'];

  if (isAdminSettings && isEnterprise) {
    variables.tenantId = tenantId;
  } else {
    variables.workspaceId = workspaceId;
  }

  useEffect(() => {
    if (queryParams.tab) {
      setTabValue(tabParams.indexOf(queryParams.tab));
    } else {
      setQueryParams({ tab: tabParams[0] });
    }
  }, []);

  function handleChange(_, newValue) {
    setTabValue(newValue);

    setQueryParams({ tab: tabParams[newValue] });
  };

  return (
    <Box>
      <TopSection>
        <Typography variant='h4'>
          {matterTeam?.name}
        </Typography>
        <Button 
          size={'S'}
          color={'red'}
          onClick={() => setDeletingTeam(true)}
        >
          Delete
        </Button>
        <Confirm
          isOpen={deletingTeam}
          onClose={() => setDeletingTeam(false)}
          header='Are you sure?'
          subHeader='Once you remove a group, it cannot be restored. Member data will not be affected.'
          confirmButtonLabel='Yes, Delete'
          onClickConfirm={async () => {
            setDeletingTeam(false);

            try {
              await deleteMatterTeamMutation({
                variables: {
                  tenantId,
                  teamId: matterTeam.id
                },
                refetchQueries: [
                  {
                    query: getMatterTeams,
                    variables: {
                      tenantId: parseInt(tenantId)
                    }
                  }
                ]
              });
  
              Toast.success('Your group has been deleted');
  
              navigate(Resources.workspaceSettingsTeams.path({ workspaceId }));
            } catch (error) {
              Toast.error(error.message);
            }
          }}
          onClickCancel={() => setDeletingTeam(false)}
        />
      </TopSection>
      <MainWrapper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <StyledTabs
            value={tabValue} 
            onChange={handleChange}
            allowScrollButtonsMobile
            variant="scrollable"
          >
            <StyledTab label='Members' disableRipple />
            <StyledTab label='Sub-Groups' disableRipple />
            <StyledTab label='Group Managers' disableRipple />
            <StyledTab label='Name' disableRipple />
          </StyledTabs>
        </Box>
        <CurrentTab value={tabValue} index={0}>
          <MembersTab 
            matterTeam={matterTeam}
            tenantId={tenantId}
            isSynced={isSynced}
            refetchTeam={refetchTeam}
          />
        </CurrentTab>
        <CurrentTab value={tabValue} index={1}>
          <SubTeamsTab 
            isSynced={isSynced}
            matterTeam={matterTeam}
            workspaceId={workspace.id}
            tenantId={tenantId}
          />
        </CurrentTab>
        <CurrentTab value={tabValue} index={2}>
          <ManagersTab 
            isSynced={isSynced}
            matterTeam={matterTeam}
            tenantId={tenantId}
            refetchTeam={refetchTeam}
          />
        </CurrentTab>
        <CurrentTab value={tabValue} index={3}>
          <NameTab 
            matterTeam={matterTeam}
            tenantId={tenantId}
          />
        </CurrentTab>
      </MainWrapper>
    </Box>
  );
};
