import React, { useState } from 'react';
import { 
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Table,
  Box,
  Typography
} from '@mui/material';
import ScoreDistribution from './ScoreDistribution';
import { useNavigate, useParams } from 'react-router-dom';
import { Resources } from '@matterapp/routing';
import styled from 'styled-components';
import { CategoryPaper } from '../styles';

const HeaderCell = styled(TableCell)(({ theme }) => ({
  fontSize: '17px',
  lineHeight: '32px',
  fontWeight: 'bold',
  padding: theme.spacing(1.5, 3)
}));

const ColumnCell = styled(TableCell)(({ theme }) => ({
  fontSize: '17px',
  lineHeight: '24px',
  padding: theme.spacing(1.5, 3)
}));

const DistributionCell = styled(HeaderCell)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  ':hover': {
    backgroundColor: theme.palette.blacks[5]
  },
  ':active': {
    backgroundColor: theme.palette.purple[5]
  },
  '&:last-child > td': {
    borderBottom: 'none'
  }
}));

function QuestionMeta({ question }) {
  const { commentsCount, lastAsked, responsesCount } = question || {};
  const responsesCountText = responsesCount === 1 ? '1 response' : `${responsesCount} responses`;
  const commentsCountText = commentsCount === 1 ? '1 comment' : `${commentsCount} comments`;
  
  return (
    <Box sx={{ textTransform: 'uppercase', mt: 1, textAlign: 'right', whiteSpace: 'nowrap' }}>
      <Typography sx={{ fontSize: '12px', lineHeight: '20px', color: '#99999B' }}>
        {responsesCountText} • Last Asked {lastAsked} • {commentsCountText}
      </Typography>
    </Box>
  );
}

export default function CategoryQuestionsBreakdown({ 
  questions,
  workspaceId
}) {
  const [order, setOrder] = useState('desc');
  const navigate = useNavigate();
  const params = useParams();
  const { categoryId } = params || {};
  const ordered = questions.sort((a, b) => {
    if (a.score == undefined) {
      return 1;
    }

    if (b.score == undefined) {
      return -1;
    }

    if (order === 'asc') {
      return a.score - b.score;
    } 

    return b.score - a.score;
  });

  return (
    <CategoryPaper elevation={1} sx={{ padding: 0 }}>
      <Table size='large'>
        <TableHead>
          <TableRow>
            <HeaderCell sx={{ fontWeight: 'bold' }}>Question</HeaderCell>
            <HeaderCell>
              <TableSortLabel
                active
                direction={order}
                onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                sx={{ fontWeight: 'bold' }}
              >
                Score
              </TableSortLabel>
            </HeaderCell>
            <DistributionCell sx={{ fontWeight: 'bold' }}>Score Distribution</DistributionCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ordered.map(({ id, question, score, distribution = [], commentsCount, responsesCount, lastAsked }) => (
            <StyledTableRow 
              key={id} 
              sx={{ cursor: 'pointer' }} 
              onClick={() => navigate(
                Resources.pulseCategoryDetails.path({ workspaceId, categoryId }, { questionId: id })
              )}
            >
              <ColumnCell>{question}</ColumnCell>
              <ColumnCell>{score || score == 0 ? `${score}%` : '-'}</ColumnCell>
              <DistributionCell>
                <ScoreDistribution distribution={distribution} />
                <QuestionMeta question={{ commentsCount, lastAsked, responsesCount }}/>
              </DistributionCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </CategoryPaper>
  );
}
