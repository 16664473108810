import React from 'react';
import { Modal } from '@matterapp/matter-ui'; 
import {
  MessageContainer,
  ContextMessageContainer,
  UpgradeButton,
  Container
} from './styles';

function OutOfKudosModal(props) {
  const { isOpen, onClose, isAdminMember, onClickUpgrade } = props;

  const buttonText = isAdminMember ? 'Add Payment Info' : 'Request Upgrade';
  const bodyText = isAdminMember 
    ? 'Your channel has already used all your 5 free weekly kudos. To send unlimited kudos, add in your payment information. 👇 💜'
    : '😱 You’re on the Free plan which gives you 5 free kudos per week. Upgrade to the Pro plan to send unlimited kudos.'; 
  
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onClickCancel={onClose}
      confirmButtonLabel={null}
      cancelButtonLabel={null}
      header={'You\'re Out of Kudos'}
    >
      <Modal.Body>
        <Container>
          <MessageContainer>
            {bodyText}
          </MessageContainer>
          <ContextMessageContainer>
            Need shelp? <a href="https://schedule.matterapp.com/campaign/free-trial" target="_blank" rel="noopener noreferrer">Schedule a time</a> or <a href="mailto:hello@matterapp.com" target="_blank" rel="noopener noreferrer">contact us</a>
          </ContextMessageContainer>
          <UpgradeButton 
            onClick={() => {
              onClickUpgrade(null, null, true); 
              onClose();
            }}
          >
            {buttonText}
          </UpgradeButton>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default OutOfKudosModal;
