import React, { useState, useContext } from 'react';
import { Modal, Menu, Tooltip } from '@matterapp/matter-ui';
import AddKudosModal from './AddKudosModal/AddKudosModal';
import { AddKudosButton, CustomKudosList, MenuContainer, CustomAvatar, LockIcon, IconContainer } from './styles';
import ConfirmDeleteKudos from './ConfirmDeleteKudos';
import { formatToPeersList } from './helpers';
import { GET_ALL_CUSTOM_KUDOS_FOR_WORKSPACE } from 'graphql-queries';
import { useQuery } from '@apollo/client';
import UserContext from 'context/UserContext/UserContext';
import { useQueryParams } from 'hooks';

export default function KudosSettings(kudosPanelProps) {
  const { currentUser, currentTenant } = useContext(UserContext);
  const { isAdmin, isOwner } = currentTenant;
  const { workspace, tenant } = kudosPanelProps;
  const { queryParams } = useQueryParams();

  if (!workspace) {
    return null;
  }

  const { id: workspaceId, generalSettings, slackWorkspace } = workspace;
  const customKudosAdminOnly = generalSettings?.customKudosAdminOnly;
  const openKudosModal = queryParams.kudosModal === 'open';
  const [isAddKudosModalOpen, setIsAddKudosModalOpen] = useState(openKudosModal);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [editCustomKudosId, setEditCustomKudosId] = useState(null);
  const [deleteCustomKudosId, setDeleteCustomKudosId] = useState(null);

  const trainingLink = slackWorkspace 
    ? <a href='https://matterapp.com/training/kudos-slack' target='_blank' rel='noopener noreferrer'>Training Video</a>
    : <a href='https://matterapp.com/training/kudos-teams' target='_blank' rel='noopener noreferrer'>Training Video</a>;


  const getAllCustomKudosQuery = useQuery(GET_ALL_CUSTOM_KUDOS_FOR_WORKSPACE, {
    variables: {
      workspaceId,
    },
  });

  if (getAllCustomKudosQuery.loading) {
    return null;
  }

  const customKudosList = formatToPeersList({
    list: getAllCustomKudosQuery.data?.customKudos,
    tenant,
    currentUser,
    skipCaseFormat: true
  });

  let showAddKudosButton = false;

  if (customKudosAdminOnly) {
    if (customKudosList.length <= 100 && (isAdmin || isOwner)) {
      showAddKudosButton = true;
    }
  } else {
    showAddKudosButton = true;
  }

  const handleDeleteConfirmModalOpen = ({ id }) => {
    setIsDeleteConfirmModalOpen(true);
    setDeleteCustomKudosId(id);
  };

  const renderSubHeader = (renderProps) => {
    const { peer } = renderProps;
    const {creatorName, createdTime, isCustom } = peer;
    return isCustom ? `By: ${creatorName} on ${createdTime}` : 'By: Matter';
  };

  const renderPeerActions = (renderProps) => {
    const menuItemProps = [];
    const { canEdit, canDelete, id } = renderProps;

    if (canEdit) {
      menuItemProps.push({
        menuLabel: 'Edit Kudos',
        rcLabel: 'menu-item-edit-kudos',
        handleAction: () => {
          setEditCustomKudosId(id);
          setIsAddKudosModalOpen(true);
        }
      });
    }
    if (canDelete) {
      menuItemProps.push({
        menuLabel: 'Delete Kudos',
        rcLabel: 'menu-item-remove-kudos',
        handleAction: () => handleDeleteConfirmModalOpen(renderProps),
      });
    }

    return !!menuItemProps.length ? (
      <MenuContainer displayInSlideMenu>
        <Menu.Dark menuMap={menuItemProps} />
      </MenuContainer>
    ) : <Tooltip dark content="Cannot remove this Kudos type">
      <IconContainer> <LockIcon /> </IconContainer>
    </Tooltip>;
  };

  return (
    <Modal.Panel.Main
      header='Kudos'
      headerActions={showAddKudosButton ? <AddKudosButton onClick={() => setIsAddKudosModalOpen(true)} /> : null}
      subHeader={
        <>
          Configure Kudos settings for your organization. To learn more, watch the {trainingLink} or visit the <a href='https://help.matterapp.com/en/collections/2053509-kudos-custom-kudos' target='_blank' rel='noopener noreferrer'>Help Center</a>.
        </>
      }
    >
      <CustomKudosList
        peers={customKudosList}
        renderSubHeader={renderSubHeader}
        renderPeerActions={renderPeerActions}
        squared
        customAvatar={(
          <CustomAvatar>NO IMAGE</CustomAvatar>
        )}
      />
      <ConfirmDeleteKudos 
        isOpen={isDeleteConfirmModalOpen}
        onClose={() => setIsDeleteConfirmModalOpen(false)}
        customKudosId={deleteCustomKudosId}
        workspaceId={workspaceId}
      />
      <AddKudosModal 
        workspaceId={workspaceId}
        isOpen={isAddKudosModalOpen}
        onClose={() => {
          setIsAddKudosModalOpen(false);
          setEditCustomKudosId(null);
        }}
        editCustomKudosId={editCustomKudosId}
      />
    </Modal.Panel.Main>
  );
};
