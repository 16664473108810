const monthToDays = {
  'January': 31,
  'February': 29,
  'March': 31,
  'April': 30,
  'May': 31,
  'June': 30,
  'July': 31,
  'August': 31,
  'September': 30,
  'October': 31,
  'November': 30,
  'December': 31
};

export const monthsOptions = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const months = Object.keys(monthToDays);

export const monthsMap = months.map(name => ({ label: name, value: name }));

export function getDaysInMonthList(month) {
  const days = [];

  for (let i = 1; i < monthToDays[month || 'January'] + 1; i++) {
    days.push({ label: String(i), value: String(i) });
  }

  return days;
}

export function getMemberBirthdayMonthAndDay(member) {
  const [month, day] = (member.birthday || '').split(' ');

  return [month, day];
}

export function getYearsList() {
  const years = [];
  const thisYear = new Date().getFullYear();

  for (let i = thisYear; i >= 1950; i--) {
    years.push({ label: String(i), value: String(i) });
  }

  return years;
}

export function getMemberAnniversaryMonthDayAndYear(member) {
  const [year, month, day] = (member.hiredOn || '').split('-');

  if (year && month && day) {
    const monthName = months[parseInt(month) - 1];
    // removing leading zero
    const dayNumber = `${parseInt(day)}`;

    return [year, monthName, dayNumber];
  }
  
  return [year, month, day];
}

export const MEMBERS_DROPDOWN_OWNER_ITEM = { 
  label: 'Owner', 
  value: 'owner',
  sublabel: 'All Admin permissions + ability to delete Matter channel'
};

export const MEMBERS_DROPDOWN_ADMIN_ITEM = { 
  label: 'Admin', 
  value: 'admin',
  sublabel: 'Full access to all settings, analytics, rewards, and billing'
};

export const MEMBERS_DROPDOWN_CHANNEL_ADMIN_ITEM = { 
  label: 'Channel Admin', 
  value: 'channel_admin',
  sublabel: 'Full access to channel settings'
};

export const MEMBERS_DROPDOWN_MEMBER_ITEM = {
  label: 'Member',
  value: 'member',
  sublabel: 'Access based on whether they\'re currently in Matter channel'
};

export const getRoleValueFromProps = (member, workspaceId, channelSettings) => {
  if (!member?.permissions) {
    return MEMBERS_DROPDOWN_MEMBER_ITEM.value; 
  }

  const { permissions } = member;
  const { isAdmin, isOwner, channelAdminWorkspaces = [] } = permissions;

  if (channelSettings && channelAdminWorkspaces.includes(String(workspaceId))) {
    return MEMBERS_DROPDOWN_CHANNEL_ADMIN_ITEM.value;
  } else if (channelSettings) {
    return MEMBERS_DROPDOWN_MEMBER_ITEM.value;
  }

  if (isOwner) {
    return MEMBERS_DROPDOWN_OWNER_ITEM.value;
  } else if (isAdmin) {
    return MEMBERS_DROPDOWN_ADMIN_ITEM.value;
  } 
  
  return MEMBERS_DROPDOWN_MEMBER_ITEM.value;
};

export const ETHNICITY_OPTIONS = [
  {
    label: 'American Indian Or Alaska Native', 
    value: 'american_indian_or_alaska_native'
  }, 
  {
    label: 'Hispanic Or Latino', 
    value: 'hispanic_or_latino'
  }, 
  {
    label: 'Black Or African American', 
    value: 'black_or_african_american'
  },
  {
    label: 'Native Hawaiian Or Pacific Islander', 
    value: 'native_hawaiian_or_pacific_islander'
  },
  {
    label: 'Decline To Specify', 
    value: 'decline_to_specify'
  },
  {
    label: 'Two Or More Races', 
    value: 'two_or_more_races'
  },
  {
    label: 'White', 
    value: 'white'
  }
];

export const GENDER_OPTIONS = [
  {
    label: 'Male',
    value: 'male'
  },
  {
    label: 'Female',
    value: 'female'
  },
  {
    label: 'Other',
    value: 'other'
  },
  {
    label: 'Decline To Specify',
    value: 'decline_to_specify'
  }
];

export const CELEBRATION_OPTIONS = [{
  label: 'Celebrate Publicly (Recommended)',
  value: 'public'
},
{
  label: 'Celebrate Privately',
  value: 'private'
},
{
  label: 'Do Not Celebrate',
  value: 'disabled'
}];