import React, { useState } from 'react';
import styled from 'styled-components';
import { 
  Box, 
  Tabs, 
  Tab,
  TableHead,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Pagination
} from '@mui/material';
import { 
  NoResultsText
} from 'components/TableStyles';
import moment from 'moment-timezone';

const Caption = styled.caption({
  height: '50px',
  textAlign: 'center',
  'p:first-child': {
    marginTop: '10px'
  }
});

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-indicator': {
    backgroundColor: theme.palette.blues[60],
    height: '4px'
  }
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  flex: 1,
  maxWidth: 'none',
  color: theme.palette.blacks[60],
  fontWeight: 500,
  fontSize: '17px',
  lineHeight: '32px',
  textTransform: 'none',
  '&.Mui-selected': {
    color: theme.palette.blues[60],
    borderBottom: 'none'
  }
}));

function CurrentTable(props) {
  const { children, value, index, itemsPerPage, itemsCount, page, setPage, ...other } = props;
  const pageCount = Math.ceil(itemsCount / itemsPerPage);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      {itemsPerPage && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination 
            sx={{ mt: 3 }}
            count={pageCount} 
            page={page} 
            color='blue'
            onChange={(_, value) => {
              setPage(value);
            }} 
          />
        </Box>
      )}
    </div>
  );
}

function AdminHeaders() {
  return (
    <>
      <TableCell sx={{ pl: 0 }}>Timestamp</TableCell>
      <TableCell sx={{ pl: 0 }}>Credit/Debit</TableCell>
      <TableCell sx={{ pl: 0 }}>Type</TableCell>
      <TableCell sx={{ pl: 0 }}>Note</TableCell>
      <TableCell sx={{ pl: 0 }}>Funded By</TableCell>
      <TableCell sx={{ pl: 0 }}>Approved By</TableCell>
      <TableCell sx={{ pl: 0 }}>Stripe ID</TableCell>
      <TableCell sx={{ pl: 0 }}>Amount</TableCell>
      <TableCell sx={{ pl: 0 }}>Balance</TableCell>
    </>
  );
}

function AdminRows({ id, amount, newBalance, paymentId, createdAt, addedBy, approvedBy, tangoTransaction, type, note, tz }) {
  let transactionType, rewardNote;

  if (type === 'adjustment') {
    transactionType = 'Account Adjustment';
  } else if (type === 'manual' || type === 'wire') {
    transactionType = 'Account Funding';
  }

  if (tangoTransaction) {
    const { rewardRequest, amountCharged } = tangoTransaction;
    const { requesterUser, rewardItem, coinsValue } = rewardRequest;
    
    rewardNote = `${requesterUser.fullName} redeemed a reward "${rewardItem.name}" for $${amountCharged?.total} USD (${coinsValue} coins)`;
  }

  return (
    <TableRow key={id}>
      <TableCell sx={{ pl: 0, textWrap: 'nowrap' }}>
        {moment(createdAt).tz(tz || 'UTC').format('MMM DD, YYYY h:mm a z')}
      </TableCell>
      <TableCell sx={{ pl: 0, color: addedBy ? 'greens.50' : 'reds.50' }}>
        <b>{addedBy ? 'Credit' : 'Debit'}</b>
      </TableCell>
      <TableCell sx={{ pl: 0 }}>
        {transactionType ? transactionType : `Order #${tangoTransaction?.referenceOrderId || 'N/A'}`}
      </TableCell>
      <TableCell sx={{ pl: 0 }}>
        {note || rewardNote}
      </TableCell>
      <TableCell sx={{ pl: 0 }}>
        {addedBy?.fullName}
      </TableCell>
      <TableCell sx={{ pl: 0 }}>
        {approvedBy?.fullName}
      </TableCell>
      <TableCell sx={{ pl: 0 }}>
        {paymentId}
      </TableCell>
      <TableCell sx={{ pl: 0, textWrap: 'nowrap' }}>
        ${(amount / 100).toLocaleString()} USD
      </TableCell>
      <TableCell sx={{ pl: 0, textWrap: 'nowrap' }}>
        ${(newBalance / 100).toLocaleString()} USD
      </TableCell>
    </TableRow>
  );
}

function UserHeaders() {
  return (
    <>
      <TableCell sx={{ pl: 0 }}>Timestamp</TableCell>
      <TableCell sx={{ pl: 0 }}>Credit/Debit</TableCell>
      <TableCell sx={{ pl: 0 }}>Type</TableCell>
      <TableCell sx={{ pl: 0 }}>Note</TableCell>
      <TableCell sx={{ pl: 0 }}>Amount</TableCell>
      <TableCell sx={{ pl: 0 }}>Balance</TableCell>
    </>
  );
}

function UserRows({ id, amount, newBalance, createdAt, addedBy, tangoTransaction, type, note, tz }) {
  let transactionType, userNote;

  if (type === 'adjustment') {
    transactionType = 'Account Adjustment';
    userNote = note;
  } else if (type === 'manual' || type === 'wire') {
    transactionType = 'Account Funding';
  }

  if (tangoTransaction) {
    const { rewardRequest, amountCharged } = tangoTransaction;
    const { requesterUser, rewardItem, coinsValue } = rewardRequest;

    userNote = `${requesterUser.fullName} redeemed a reward "${rewardItem.name}" for $${amountCharged?.total} USD (${coinsValue} coins)`;
  }

  return (
    <TableRow key={id}>
      <TableCell sx={{ pl: 0, textWrap: 'nowrap' }}>
        {moment(createdAt).tz(tz || 'UTC').format('MMM DD, YYYY h:mm a z')}
      </TableCell>
      <TableCell sx={{ pl: 0, color: addedBy ? 'greens.50' : 'reds.50' }}>
        <b>{addedBy ? 'Credit' : 'Debit'}</b>
      </TableCell>
      <TableCell sx={{ pl: 0 }}>
        {transactionType ? transactionType : `Order #${tangoTransaction?.referenceOrderId || 'N/A'}`}
      </TableCell>
      <TableCell sx={{ pl: 0 }}>
        {userNote}
      </TableCell>
      <TableCell sx={{ pl: 0, textWrap: 'nowrap' }}>
        ${(amount / 100).toLocaleString()} USD
      </TableCell>
      <TableCell sx={{ pl: 0, textWrap: 'nowrap' }}>
        ${(newBalance / 100).toLocaleString()} USD
      </TableCell>
    </TableRow>
  );
}

function sortByDate(a, b) {
  if (a.createdAt > b.createdAt) {
    return -1;
  }

  return 1;
}

export default function FundingHistory({ creditRows, debitRows, isAdminView, tz }) {
  const [tabvalue, setTabValue] = useState(0);
  const [page, setPage] = useState(1);
  const rows = [...creditRows, ...debitRows].sort(sortByDate).map((row) => ({ ...row, tz }));
  const itemsPerPage = 5;

  const AllTableHeaders = isAdminView ? AdminHeaders : UserHeaders;
  const AllBodyRows = isAdminView ? AdminRows : UserRows;
  
  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
        <StyledTabs value={tabvalue} onChange={(_, newValue) => {
          setTabValue(newValue);
          setPage(1);
        }}>
          <StyledTab label='All Transactions' disableRipple />
          <StyledTab label='Debits' disableRipple />
          <StyledTab label='Credits' disableRipple />
        </StyledTabs>
      </Box>
      <CurrentTable 
        value={tabvalue} 
        index={0}
        itemsPerPage={itemsPerPage}
        itemsCount={rows.length}
        page={page}
        setPage={setPage}
      >
        <Table size='large' sx={{ mt: 2 }}>
          {!rows.length ? (
            <Caption>
              <NoResultsText variant='h6' component='p'>
                No records
              </NoResultsText>
            </Caption>
          ) : null}
          <TableHead>
            <TableRow>
              <AllTableHeaders />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice((page - 1) * itemsPerPage, page * itemsPerPage).map(AllBodyRows)}
          </TableBody>
        </Table>
      </CurrentTable>
      <CurrentTable 
        value={tabvalue} 
        index={1}
        page={page}
        itemsPerPage={itemsPerPage}
        itemsCount={debitRows.length}
        setPage={setPage}
      >
        <Table size='large' sx={{ mt: 2 }}>
          {!debitRows.length ? (
            <Caption>
              <NoResultsText variant='h6' component='p'>
                No records
              </NoResultsText>
            </Caption>
          ) : null}
          <TableHead>
            <TableRow>
              <TableCell sx={{ pl: 0 }}>Timestamp</TableCell>
              <TableCell sx={{ pl: 0 }}>Type</TableCell>
              <TableCell sx={{ pl: 0 }}>Amount</TableCell>
              <TableCell sx={{ pl: 0 }}>Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...debitRows]
              .sort(sortByDate)
              .slice((page - 1) * itemsPerPage, page * itemsPerPage)
              .map(({ id, amount, newBalance, createdAt, tangoTransaction }) => {
                return (
                  <TableRow key={id}>
                    <TableCell sx={{ pl: 0, textWrap: 'nowrap' }}>
                      {moment(createdAt).tz(tz || 'UTC').format('MMM DD, YYYY h:mm a z')}
                    </TableCell>
                    <TableCell sx={{ pl: 0 }}>
                      {`Order #${tangoTransaction?.referenceOrderId || 'N/A'}`}
                    </TableCell>
                    <TableCell sx={{ pl: 0, textWrap: 'nowrap' }}>
                      ${(amount / 100).toLocaleString()} USD
                    </TableCell>
                    <TableCell sx={{ pl: 0, textWrap: 'nowrap' }}>
                      ${(newBalance / 100).toLocaleString()} USD
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </CurrentTable>
      <CurrentTable 
        value={tabvalue} 
        index={2}
        page={page}
        setPage={setPage}
        itemsPerPage={itemsPerPage}
        itemsCount={creditRows.length}
      >
        <Table size='large' sx={{ mt: 2 }}>
          {!creditRows.length ? (
            <Caption>
              <NoResultsText variant='h6' component='p'>
                No records
              </NoResultsText>
            </Caption>
          ) : null}
          <TableHead>
            <TableRow>
              <TableCell sx={{ pl: 0 }}>Timestamp</TableCell>
              {isAdminView ? <TableCell sx={{ pl: 0 }}>Note</TableCell> : null}
              <TableCell sx={{ pl: 0 }}>Type</TableCell>
              {isAdminView ? (
                <>
                  <TableCell sx={{ pl: 0 }}>Funded By</TableCell>
                  <TableCell sx={{ pl: 0 }}>Approved By</TableCell>
                </>
              ) : null}
              <TableCell sx={{ pl: 0 }}>Amount</TableCell>
              <TableCell sx={{ pl: 0 }}>Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...creditRows]
              .sort(sortByDate)
              .slice((page - 1) * itemsPerPage, page * itemsPerPage)
              .map(({ id, amount, note, createdAt, newBalance, addedBy, approvedBy }) => {
                return (
                  <TableRow key={id}>
                    <TableCell sx={{ pl: 0, textWrap: 'nowrap' }}>
                      {moment(createdAt).tz(tz || 'UTC').format('MMM DD, YYYY h:mm a z')}
                    </TableCell>
                    {isAdminView ? (
                      <TableCell sx={{ pl: 0 }}>
                        {note}
                      </TableCell>
                    ) : null}
                    <TableCell sx={{ pl: 0 }}>
                      Account Funding
                    </TableCell>
                    {isAdminView ? (
                      <>
                        <TableCell sx={{ pl: 0 }}>
                          {approvedBy?.fullName}
                        </TableCell>
                        <TableCell sx={{ pl: 0 }}>
                          {addedBy?.fullName}
                        </TableCell>
                      </>
                    ) : null}
                    <TableCell sx={{ pl: 0 }}>
                      ${(amount / 100).toLocaleString()} USD
                    </TableCell>
                    <TableCell sx={{ pl: 0 }}>
                      ${(newBalance / 100).toLocaleString()} USD
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </CurrentTable>
    </Box>
  );
}