import React from 'react';
import styled from 'styled-components';
import Chart from './Chart/Chart';
import { 
  Typography
} from '@mui/material';
import theme from '@matterapp/matter-theme';
import CustomCircularProgress from 'components/CircularProgress/CircularProgress';
import { formatAveragesToDataPoints } from './dateFilters';
import { ChartsWrapper, ChartWrapper, ProgressWrapper, CategoryPaper } from './styles';

const GroupedByTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing.half};
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.XS};
  line-height: ${theme.lineHeight.XS};
  color: ${theme.colors.blacks[50]};
`;

const SKILL_NAME_WITH_PURPLE_COLOR = "Hiring";

export default function ParticipationRate({ 
  analyticsData,
  xAxisMaxLabels, 
  groupedBy, 
  groupBy,
  rollingScore = [], 
  startDate, 
  endDate,
  datePoints,
  isLoadingData
}) {
  const { 
    participationScore,
    participationExplanation,
    totalSurveys,
    uniqueRespondedPeopleCount
  } = analyticsData || {};

  const hasScore = participationScore != null;
  const data = formatAveragesToDataPoints(rollingScore, startDate, endDate, groupBy);

  return (
    <CategoryPaper elevation={1} sx={{ mt: 3 }}>
      <Typography variant='h4' component='p' sx={{mb: '5px' }}>Participation Rate</Typography>
      <Typography variant='body1' component='p' sx={{ color: 'blacks.50' }}>
        The participation rate is calculated over a 14-day period and shows the number of Active Members in your account. 
        Active Members are those who have answered at least one survey question in the last 14-days.
      </Typography>
      <ChartsWrapper>
        <ProgressWrapper>
          <CustomCircularProgress 
            thickness={2}
            size={264}
            value={participationScore}
          >
            <Typography 
              sx={{
                fontSize: hasScore || isLoadingData ? '48px' : '24px',
                fontWeight: 'bold',
                lineHeight: '48px',
                mb: '4px'
              }}
              component="span" 
              color="black"
            >
              {hasScore ? `${participationScore}%` : isLoadingData ? '' : 'No score yet'}
            </Typography>
            {totalSurveys || isLoadingData ? (
              <Typography variant='body2' color='blacks.50' sx={{ mb: '4px' }}>
                {isLoadingData ? ' ' : `${uniqueRespondedPeopleCount} out of ${totalSurveys}`}
              </Typography>
            ) : null}
            <Typography variant='h2' sx={{ fontSize: '24px', lineHeight: '30px' }}>
              {participationExplanation}
            </Typography>
          </CustomCircularProgress>
        </ProgressWrapper>
        <ChartWrapper>
          <Chart
            height='312px'
            data={data}
            showLineAtTheEnd={false}
            showLineAtTheStart={false}
            showScatterDataStyle={false}
            skillName={SKILL_NAME_WITH_PURPLE_COLOR}
            xAxisMaxLabels={xAxisMaxLabels}
            generateLabels={() => datePoints}
            formatXAxis={() => {
              if (groupBy === 'month') {
                return {
                  unit: 'month',
                  displayFormats: {
                    month: 'MMM',
                  }
                };
              }  

              return {
                unit: 'day',
                displayFormats: {
                  day: 'MMM D',
                }
              };
            }}
            maxStepSize={100}
            stepSize={20}
            yAxisRender={(value) => `${value}%`}
          />      
          {hasScore ? (
            <GroupedByTitle> {groupedBy} </GroupedByTitle>
          ) : null}
        </ChartWrapper>
      </ChartsWrapper>
    </CategoryPaper>
  );
}
