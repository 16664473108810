import React, { useState } from 'react';
import PulseFiltersContext from './PulseFiltersContext';
import { dateFilters } from 'components/DateFilterBar/defaultFilters';

export default function PulseFiltersProvider({ children }) {
  const [selectedFilter, setSelectedFilter] = useState(dateFilters[0]);
  const [xAxisMaxLabels, setXAxisMaxLabels] = useState(dateFilters[0].xAxisResolution);
  const [segmentIds, setSegmentIds] = useState([]);
  const [teamIds, setTeamIds] = useState([]);
  const [workspaceIds, setWorkspaceIds] = useState([]);

  function handleSegmentChange(segment) {
    let newSegmentIds;

    if (segmentIds.includes(segment.id)) {
      newSegmentIds = segmentIds.filter((id) => id !== segment.id);
    } else {
      newSegmentIds = [...(segmentIds || []), segment.id];
    }

    setSegmentIds(newSegmentIds);
  };

  function handleTeamChange(team) {
    let newTeamIds;

    if (teamIds.includes(team.id)) {
      newTeamIds = teamIds.filter((id) => id !== team.id);
    } else {
      newTeamIds = [...(teamIds || []), team.id];
    }

    setTeamIds(newTeamIds);
  };

  function handleWorkspaceChange(workspaceId, workspaces) {
    let newWorkspaceIds;

    if (workspaceIds.includes(workspaceId)) {
      newWorkspaceIds = workspaceIds.filter((id) => id !== workspaceId);
    } else {
      newWorkspaceIds = [...(workspaceIds || []), workspaceId];
    }
    // case for initial workspaceIds being selected for manager analytics
    if (Array.isArray(workspaces)) {
      newWorkspaceIds = workspaces;
    }

    setWorkspaceIds(newWorkspaceIds);
  };

  return (
    <PulseFiltersContext.Provider
      value={{
        selectedFilter,
        setSelectedFilter,
        xAxisMaxLabels,
        setXAxisMaxLabels,
        segmentIds, 
        handleSegmentChange,
        teamIds,
        handleTeamChange,
        handleWorkspaceChange,
        selectedWorkspaceIds: workspaceIds,
        resetFilters: (customDateFilters) => {
          setSelectedFilter(customDateFilters ? customDateFilters[0] : dateFilters[0]);
          setXAxisMaxLabels(dateFilters[0].xAxisResolution);
          setSegmentIds([]);
          setTeamIds([]);
          setWorkspaceIds([]);
        }
      }}
    >
      {children}
    </PulseFiltersContext.Provider>
  );
}
