import React, { useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { 
  Typography, 
  Box
} from '@mui/material';
import { ButtonsWrapper, SettingsButton, Wrapper } from './styles';
import { Button as MatterButton } from '@matterapp/matter-ui';
import { Resources } from '@matterapp/routing';
import { useNavigate } from 'react-router-dom';
import BalanceSection from './BalanceSection';

export default function FundingPage() {
  const { 
    currentTenant,
    currentWorkspaceId,
    scheduleCallParams
  } = useContext(UserContext);
  const { rewardsFundingSettings } = currentTenant || {};
  const { enabled } = rewardsFundingSettings || {};
  const navigate = useNavigate();

  if (!enabled) {
    return (
      <Box>
        <Wrapper sx={{ mb: 3 }}>
          <Typography variant='h2' sx={{ mb: 1 }}>Prepay for Rewards</Typography>
          <Typography variant='body1'>
            By default, approved reward requests are charged to your credit card, which includes a 3% processing fee. To avoid this fee and reduce frequent credit card transactions, you can prepay for rewards. Invoicing options (e.g., ACH/wire) are available with prepay.
          </Typography>
          <Typography variant='body1' sx={{ mt: 2, mb: 2, color: 'blues.50' }}>
            <a 
              href={'https://help.matterapp.com/en/articles/9712551-how-the-rewards-prepay-feature-works'} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Learn more →
            </a>
          </Typography>
          <ButtonsWrapper>
            <SettingsButton
              size='M'
              color='blue'
              onClick={() => {
                navigate(Resources.workspaceSettingsRewards.path({ workspaceId: currentWorkspaceId }));
              }}
            >
              Reward Settings
            </SettingsButton> 
            <MatterButton 
              size='M'
              color='black'
              onClick={() => {
                window.open(`https://schedule.matterapp.com/campaign/free-trial?${scheduleCallParams}`, '_blank');
              }}
            >
              Schedule a Call
            </MatterButton> 
          </ButtonsWrapper>
        </Wrapper>
        <BalanceSection />
      </Box>
    );
  }

  return (
    <Box>
      <BalanceSection />
    </Box>
  );
};
