import React, { useContext, useState } from 'react';
import UserContext from 'context/UserContext/UserContext';
import PulseFiltersContext from 'context/PulseFiltersContext/PulseFiltersContext';
import { useQuery } from '@apollo/client';
import { AppLayout } from 'modules';
import getPulseCategoryDetails from 'graphql-queries/queries/analytics/getPulseCategoryDetails';
import getPulseQuestionDetails from 'graphql-queries/queries/analytics/getPulseQuestionDetails';
import { 
  Box,
  Typography
} from '@mui/material';
import CategoryDetails from './CategoryDetails';
import CategoryQuestionsBreakdown from './CategoryQuestionsBreakdown';
import { Loader } from '@matterapp/matter-ui';
import { LoaderContainer } from '../styles';
import { useParams } from 'react-router-dom';
import QuestionDetails from './QuestionDetails';
import QuestionComments from './QuestionComments';
import { useQueryParams } from 'hooks';
import { dateFilters } from 'components/DateFilterBar/defaultFilters';
import DateFilterBar, { buildCustomDateRange } from 'components/DateFilterBar/DateFilterBar';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import { Resources } from '@matterapp/routing';

export default function CategoryDetailPage() {
  const params = useParams();
  const { categoryId } = params || {};
  const { queryParams } = useQueryParams();
  const { questionId } = queryParams || {};
  const { 
    selectedFilter, 
    setSelectedFilter, 
    xAxisMaxLabels, 
    setXAxisMaxLabels, 
    segmentIds,
    handleSegmentChange,
    teamIds,
    handleTeamChange,
    handleWorkspaceChange,
    selectedWorkspaceIds
  } = useContext(PulseFiltersContext);
  const { startDate, endDate, groupBy } = selectedFilter;

  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const { currentTenant, currentWorkspaceId: workspaceId } = useContext(UserContext);

  const { data: categoryData, loading: isLoadingData } = useQuery(getPulseCategoryDetails, {
    variables: {
      tenantId: currentTenant?.id,
      startDate: startDate.format('YYYY-MM-DD'), 
      endDate: endDate.format('YYYY-MM-DD'),
      groupBy,
      categoryId,
      subcategories: selectedSubcategories,
      segmentIds,
      teamIds,
      workspaceIds: selectedWorkspaceIds
    },
    fetchPolicy: 'network-only',
    skip: !currentTenant || !categoryId
  });

  const { data: questionData } = useQuery(getPulseQuestionDetails, {
    variables: {
      tenantId: currentTenant?.id,
      startDate: startDate.format('YYYY-MM-DD'), 
      endDate: endDate.format('YYYY-MM-DD'),
      groupBy,
      questionId,
      segmentIds
    },
    skip: !currentTenant || !questionId
  });

  const { pulseCategoryDetails } = categoryData || {};
  const isLoading = !currentTenant;

  const questions = (pulseCategoryDetails?.questions || []).filter(({ subcategory }) => {
    if (selectedSubcategories.length) {
      return selectedSubcategories.includes(subcategory?.name);
    }

    return true;
  });

  const handleChangeFilter = (value, isCustom) => {
    let selectedValue;

    if (isCustom) {
      selectedValue = buildCustomDateRange(value.from, value.to);
    } else {
      selectedValue = dateFilters.find((filter) => filter.value === value);
    }
    
    if (startDate.format('YYYY-MM-DD') === selectedValue.startDate.format('YYYY-MM-DD') && endDate.format('YYYY-MM-DD') === selectedValue.endDate.format('YYYY-MM-DD')) {
      return;
    }

    setSelectedFilter(selectedValue);
    setXAxisMaxLabels(selectedValue.xAxisResolution);
  };

  const { name = '' } = pulseCategoryDetails || {};

  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={
        isLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <Box sx={{ maxWidth: '950px' }}>
            {questionId ? (
              <>
                <BreadCrumbs 
                  sx={{ mb: 3 }}
                  crumbs={[
                    {
                      label: 'Pulse Survey',
                      href: Resources.pulseDashboard.path({ workspaceId })
                    },
                    {
                      label: name,
                      href: Resources.pulseCategoryDetails.path({ workspaceId, categoryId })
                    },
                    {
                      label: 'Question Detail'
                    }
                  ]}
                />
                <DateFilterBar 
                  startDate={selectedFilter.startDate}
                  endDate={selectedFilter.endDate}
                  value={selectedFilter.value}
                  onChange={handleChangeFilter}
                  groupedBy={selectedFilter.groupBy}
                  onSegmentChange={handleSegmentChange}
                  selectedSegmentIds={segmentIds}
                  onTeamChange={handleTeamChange}
                  selectedTeamIds={teamIds}
                  onChannelChange={handleWorkspaceChange}
                  selectedWorkspaceIds={selectedWorkspaceIds}
                  disclaimer={(
                    <Typography variant='body2' component='p' sx={{ color: 'blacks.50' }}>
                      Scores calculated on a 30-day rolling time window (<a href='https://help.matterapp.com/en/articles/8828295-pulse-survey-guide' target='_blank' rel='noopener noreferrer'>learn more</a>).
                    </Typography>
                  )}
                />
                <QuestionDetails 
                  category={pulseCategoryDetails?.name}
                  questionData={questionData?.pulseQuestionDetails || {}}
                  xAxisMaxLabels={xAxisMaxLabels}
                  startDate={selectedFilter.startDate.format('YYYY-MM-DD')}
                  endDate={selectedFilter.endDate.format('YYYY-MM-DD')}
                  groupedBy={selectedFilter.groupedBy}
                  groupBy={selectedFilter.groupBy}
                  isLoading={isLoading}
                />
                <QuestionComments
                  comments={questionData?.pulseQuestionDetails?.comments || []}
                />
              </>
            ) : (
              <>
                <BreadCrumbs 
                  sx={{ mb: 3 }}
                  crumbs={[
                    {
                      label: 'Pulse Survey',
                      href: Resources.pulseDashboard.path({ workspaceId })
                    },
                    {
                      label: name,
                      href: null
                    }
                  ]}
                />
                <DateFilterBar 
                  startDate={selectedFilter.startDate}
                  endDate={selectedFilter.endDate}
                  value={selectedFilter.value}
                  onChange={handleChangeFilter}
                  groupedBy={selectedFilter.groupBy}
                  onSegmentChange={handleSegmentChange}
                  selectedSegmentIds={segmentIds}
                  onTeamChange={handleTeamChange}
                  selectedTeamIds={teamIds}
                  onChannelChange={handleWorkspaceChange}
                  selectedWorkspaceIds={selectedWorkspaceIds}
                  disclaimer={(
                    <Typography variant='body2' component='p' sx={{ color: 'blacks.50' }}>
                      Scores calculated on a 30-day rolling time window (<a href='https://help.matterapp.com/en/articles/8828295-pulse-survey-guide' target='_blank' rel='noopener noreferrer'>learn more</a>).
                    </Typography>
                  )}
                />
                <CategoryDetails 
                  selectedSubcategories={selectedSubcategories}
                  setSelectedSubcategories={setSelectedSubcategories}
                  categoryData={pulseCategoryDetails}
                  xAxisMaxLabels={xAxisMaxLabels}
                  startDate={selectedFilter.startDate.format('YYYY-MM-DD')}
                  endDate={selectedFilter.endDate.format('YYYY-MM-DD')}
                  groupedBy={selectedFilter.groupedBy}
                  groupBy={selectedFilter.groupBy}
                  isLoadingData={isLoadingData}
                />
                <CategoryQuestionsBreakdown 
                  questions={questions}
                  workspaceId={workspaceId}
                />
              </>
            )}
          </Box>
        )
      }
    />
  );
}
