import React, { useEffect, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import IS_USER_AN_ADMIN from 'graphql-queries/queries/admin/isUserAnAdmin';
import { Loader } from '@matterapp/matter-ui';
import styled from 'styled-components';
import WorkspaceTierForm from './WorkspaceTierForm';
import MemberPromoteAdmin from './MemberPromoteAdmin';
import RewardsFunding from './RewardsFunding';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const Wrapper = styled.div({
  padding: '2rem'
});

const LoaderContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

function AdminPage() {
  const { currentUser, isLoadingCurrentUser} = useContext(UserContext);
  const params = useParams();
  const { workspaceId } = params || {};
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(IS_USER_AN_ADMIN);

  useEffect(() => {
    if (!isLoadingCurrentUser && !currentUser) {
      navigate('/404');
    }
  }, [isLoadingCurrentUser, currentUser]);

  if (loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  if (error) {
    navigate('/404');
  }

  if (!data?.isUserAnAdmin) {
    navigate('/404');
  }

  if (workspaceId) {
    return (
      <Wrapper>
        <WorkspaceTierForm workspaceId={workspaceId} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <WorkspaceTierForm />
      <MemberPromoteAdmin />
      <RewardsFunding />
    </Wrapper>
  );
};

AdminPage.propTypes = {
  currentUserStatus: PropTypes.shape({
    isLoggedIn: PropTypes.bool.isRequired,
  }),
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  })
};

export default AdminPage;
