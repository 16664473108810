import React from 'react';
import { Resources } from '@matterapp/routing';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import PulseDashboardPage from 'routes/pages/pulse/PulseDashboardPage';
import EnpsDetailPage from 'routes/pages/pulse/CategoryDetail/EnpsDetailPage';
import CategoryDetailPage from 'routes/pages/pulse/CategoryDetail/CategoryDetailPage';
import QuestionLibraryPage from 'routes/pages/pulse/QuestionLibrary/QuestionLibraryPage';
import { Route } from 'react-router-dom';

export const PulseDashboard = (
  <Route
    exact
    path={Resources.pulseDashboard.pattern}
    element={(
      <PrivateRoute adminsOnly managersOnly>
        <PulseDashboardPage />
      </PrivateRoute>    
    )}
  />
);

export const PulseCategoryDetails = (
  <Route
    exact
    path={Resources.pulseCategoryDetails.pattern}
    element={(
      <PrivateRoute adminsOnly managersOnly>
        <CategoryDetailPage />
      </PrivateRoute>    
    )}
  />
);

export const PulseEnpsDetails = (
  <Route
    exact
    path={Resources.pulseEnpsDetails.pattern}
    element={(
      <PrivateRoute adminsOnly managersOnly>
        <EnpsDetailPage />
      </PrivateRoute>    
    )}
  />
);

export const PulseQuestionLibrary = (
  <Route
    exact
    path={Resources.pulseQuestionLibrary.pattern}
    element={(
      <PrivateRoute adminsOnly managersOnly>
        <QuestionLibraryPage />
      </PrivateRoute>    
    )}
  />
);
