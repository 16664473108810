import { useEffect } from 'react';
import { useNavigate, useResolvedPath, useParams } from 'react-router-dom';
import { Resources } from '@matterapp/routing';
import { useUserMetadata, useQueryParams } from 'hooks';
import { useWorkspaceLocalStoage, CURRENT_WORKSPACE } from 'hooks/workspaces';
import {
  isHomePage,
  isWorkspacesPage,
  isSettingsPage,
  getActiveTab,
  getActiveWorkspaces,
  sortWorkspaces,
  workspacesPathsToTabs,
} from './utils';
import Toast from 'components/Toast/Toast';

const IS_FROM_LOGIN = 'login';

export const useActiveWorkspaceFromMetadataAndLocalStorage = ({
  isLoadingCurrentUser,
  workspaces = [],
  showZeroWorkspaces,
}) => {
  const navigate = useNavigate();
  const { 
    queryParams, 
    setQueryParams 
  } = useQueryParams();

  const { pathname: path } = useResolvedPath();
  const { loading, metadata, setMetadata } = useUserMetadata();
  const { setWorkspaceId, getWorkspaceId } = useWorkspaceLocalStoage();
  const params = useParams();
  const { workspaceId } = params;
  const hasWorkspaces = !!workspaces.length;
  const shouldRedirectToWorkspace = queryParams[IS_FROM_LOGIN];
  let activeWorkspaceId = workspaceId || getWorkspaceId();

  if (activeWorkspaceId === 'undefined') {
    activeWorkspaceId = undefined;
  }
  
  const activeWorkspace = workspaces.find(
    (workspace) => workspace.id === activeWorkspaceId
  );

  const setCurrentWorkspace = (workspaceId) => {
    setMetadata({ [CURRENT_WORKSPACE]: workspaceId });
    setWorkspaceId(workspaceId);
  };

  useEffect(() => {
    const metadataWorkspaceId = metadata[CURRENT_WORKSPACE];
    const isOnHomePage = isHomePage(path) && !loading && !isLoadingCurrentUser;
    if (workspaceId) {
      setWorkspaceId(workspaceId);
    }

    if (activeWorkspace?.isExpired) {
      const isOnWorkspacesHomeTab = isWorkspacesPage(path);
      const isOnSettingsPage = isSettingsPage(path);

      if (!isOnWorkspacesHomeTab && !isOnSettingsPage) {
        navigate(
          Resources.workspacesActivityFeed.path({
            workspaceId: metadataWorkspaceId,
          })
        );
        Toast.error('Your free 14-day trial has ended, upgrade to Pro plan to view this page');
      }
    }

    if (isOnHomePage) {
      if (!showZeroWorkspaces && hasWorkspaces) {
        if (shouldRedirectToWorkspace && metadataWorkspaceId) {
          //  On login, use metadata to set workspace.
          setWorkspaceId(metadataWorkspaceId);
          navigate(
            Resources.workspacesActivityFeed.path({
              workspaceId: metadataWorkspaceId,
            })
          );
        } else if (activeWorkspaceId && activeWorkspace) {
          // If there is an active workspace id in the url param or localStorage.
          setWorkspaceId(activeWorkspaceId);
          navigate(
            Resources.workspacesActivityFeed.path({
              workspaceId: activeWorkspaceId,
            })
          );
        } else if (hasWorkspaces) {
          // We are now hiding home page to everyone. When they leave their activeWorkspace, 
          // they would get redirected to home page. To avoid that, we are redirecting them to the first workspace 
          // in their list if they have another workspace. If they have no other workspaces, they would then 
          // get redirected to home.
          const firstWorkspaceId = workspaces[0].id;
          setWorkspaceId(firstWorkspaceId);
          navigate(
            Resources.workspacesActivityFeed.path({
              workspaceId: firstWorkspaceId,
            })
          );
        }
      } else {
        setCurrentWorkspace(null);
        setQueryParams({});
      }
    } else {
      if (showZeroWorkspaces) {
        setCurrentWorkspace(null);
        setQueryParams({});
        if (isWorkspacesPage(path)) {
          navigate(Resources.homeMain.path());
        }
      }
    }
  }, [loading, isLoadingCurrentUser]);

  return {
    activeWorkspace,
    activeWorkspaceId,
    setCurrentWorkspace,
    loading,
    clearCurrentWorkspace: () => setCurrentWorkspace(null),
  };
};

export const useActiveWorkspace = (params) => {
  const { path, workspaces, showZeroWorkspaces } = params;
  const hasWorkspaces = !!workspaces && !!workspaces.length;

  const currentWorkspaces = hasWorkspaces ? getActiveWorkspaces(workspaces) : [];

  const {
    activeWorkspace,
    activeWorkspaceId,
    clearCurrentWorkspace,
    setCurrentWorkspace,
    loading,
  } = useActiveWorkspaceFromMetadataAndLocalStorage({
    isLoadingCurrentUser: params.loading,
    workspaces: currentWorkspaces,
    showZeroWorkspaces,
  });

  const activeWorkspaceTab = !activeWorkspace
    ? null
    : getActiveTab(path, workspacesPathsToTabs);

  return {
    activeWorkspace,
    activeWorkspaceId,
    activeWorkspaceTab,
    currentWorkspaces: sortWorkspaces(currentWorkspaces),
    clearCurrentWorkspace,
    hasWorkspaces,
    isWorkspacesLoading: loading,
    onChangeWorkspace: (e, { home, workspaceId }) => {
      if (home) {
        setCurrentWorkspace(null);
      } else {
        setCurrentWorkspace(workspaceId);
      }
    },
  };
};

export default useActiveWorkspace;
