import React, { useState, useEffect } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Dropdown, Form, Modal } from '@matterapp/matter-ui';
import theme, { border, colors, lineHeight, font, spacing } from '@matterapp/matter-theme';
import { VALUE_TYPE } from '../consts';
import { duplicateCountriesList, duplicateBrands } from '../../data/duplicates';
import { Typography } from '@mui/material';
import {
  ModalBody,
  RewardContainer,
  RewardImage,
  RewardDetailsContainer,
  RewardName,
  RewardDescription,
  OrderCostSection,
  DropdownErrorMessage,
  MODAL_IMAGE_WIDTH,
} from './sharedStyles';
import { getRemainingCoins } from './helpers';

const REWARD_INPUT = 'reward-variable-input';
const INPUT_ERROR = 'reward-input-error';

const AmountDropdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${font.size.S2};
  line-height: ${lineHeight.M};
`;

const AmountDropdown = styled(Dropdown).attrs(() => ({
  size: Dropdown.sizes.M,
}))`
  width: 200px;

  & + [data-role="menu"] {
    position: fixed;
    max-width: 200px;
  }
`;

const InputLabel = styled.div`
  margin-top: ${spacing.single};
  margin-bottom: ${spacing.quarter};
`;

const InputContainer = styled.div`
  display: flex;
  .${REWARD_INPUT} {
    border-left: none !important;
    border-radius: 0 ${border.radius.S} ${border.radius.S} 0 !important;
  }

  .${INPUT_ERROR} {
    margin-left: -${spacing.singleAndThreeQuarters};
  }
`;

const InputSymbol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${spacing.triple};
  width: ${spacing.singleAndThreeQuarters};
  font-size: ${font.size.S2};
  border: 1px solid ${colors.blacks[40]};
  border-end-start-radius: ${border.radius.S};
  border-start-start-radius: ${border.radius.S};
  background: ${colors.blacks[1]};
  color: ${colors.blacks[60]};

  ${({ focused }) => focused ? `
    border-color: ${theme.inputs.borderActiveColor};
    box-shadow: 0px 2px 4px rgba(52, 55, 153, 0.2);
  ` : ''}

  ${({ error }) => error ? `
    background: ${colors.reds[5]};
    color: ${colors.reds[40]};
    border-color: ${colors.reds[40]};
  `: ''};
`;

const Disclaimer = styled.div`
  font-size: ${font.size.XXS};
  margin-bottom: ${spacing.single};
`;

const CompleteOrderNotice = styled.div`
  font-size: ${font.size.XXS};
  line-height: 14px;
  color: ${colors.blacks[70]};
  margin-top: ${spacing.singleAndHalf};
`;

const getRewardCostOptions = (selectedRewardItems, currencySymbol) => {
  return selectedRewardItems.map(item => {
    return {
      label: `${currencySymbol}${item.faceValue} (${item.coinsValue} coins)`,
      value: `${item.coinsValue}`,
      disabled: !item.isItemRedeemable,
      selectedItem: item
    };
  });
};

// This function adds a target=_blank into the string provided by tango for disclaimers/descriptions
const transformUrl = (string) => {
  const aTagString = '<a';
  const targetBlankString = 'target="_blank"';
  const indexOfATag = string.indexOf(aTagString);
  let transformedString = string;

  if (indexOfATag > -1) {
    const split = string.split(' ');
    const newArrayOfStrings = split.map(word => {
      if (word.includes(aTagString)) {
        word = word + ' ' + targetBlankString;
        return word;
      }
      return word;
    });
    transformedString = newArrayOfStrings.join(' ');
  }
  return transformedString;
};

const TangoRewardRedeemModal = ({
  isOpen,
  selectedReward,
  currentCoinBalance,
  onClickClose,
  onClickComplete,
  isRequestingReward
}) => {
  const { image, items, description, disclaimer, currencyCode, valueType, exchangeRate, country, name } = selectedReward;
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedCoinAmount, setSelectedCoinAmount] = useState();
  const [enteredAmount, setEnteredAmount] = useState();
  const [isInputFocused, setIsInputFocused] = useState();
  const [errorMessage, setErrorMessage] = useState();

  let brandName = selectedItem.name;
  if (duplicateBrands.includes(name)) {
    brandName = `${selectedItem.name} ${duplicateCountriesList[country]}`;
  }

  const currencySymbol = getSymbolFromCurrency(currencyCode);
  const rewardCostList = getRewardCostOptions(items, currencySymbol);
  const remainingCoinAmount = getRemainingCoins(selectedCoinAmount, currentCoinBalance);

  const transformedDescription = transformUrl(description);
  const transformedDisclaimer = transformUrl(disclaimer);

  const firstItem = items[0];
  const minValue = firstItem.minValue;
  const maxValue = firstItem.maxValue;

  const isVariableValue = valueType === VALUE_TYPE.VARIABLE;
  const isInteger = enteredAmount % 1 === 0;
  const currencyInUSD = enteredAmount / exchangeRate;
  const coinAmount = Math.ceil(currencyInUSD * 10) || 0;

  const isVariableAmountValid = coinAmount > 0 && coinAmount <= currentCoinBalance && enteredAmount >= minValue && isInteger && enteredAmount <= maxValue;
  const isFixedAmountValid = selectedItem.isItemRedeemable;
  const canClickContinueButton = isVariableValue ? isVariableAmountValid : isFixedAmountValid;
  const canClickCompleteButton = remainingCoinAmount >= 0;

  const onBlur = () => setIsInputFocused(false);
  const onClickCancel = () => {
    setSelectedCoinAmount();
    onClickClose();
  };
  const onClickSave = () => {
    onClickComplete({
      rewardItemId: selectedItem.id,
      faceValue: isVariableValue ? Number(enteredAmount) : selectedItem.faceValue,
      coinsValue: isVariableValue ? selectedCoinAmount : selectedItem.coinsValue,
    });
    onClickCancel();
  };

  useEffect(() => {
    setSelectedItem(firstItem);
    if (!isVariableValue && firstItem.isItemRedeemable) {
      setSelectedCoinAmount(rewardCostList[0].value);
    }
  }, []);

  useEffect(() => {
    if (enteredAmount == 0) {
      setErrorMessage('Amount cannot be 0');
    } else if (enteredAmount < minValue) {
      setErrorMessage('Amount must be greater than minimum value');
    } else {
      setErrorMessage(null);
    }
  }, [isInputFocused]);

  useEffect(() => {
    if (coinAmount > currentCoinBalance) {
      setErrorMessage('You do not have enough coins');
    } else if (!isInteger) {
      setErrorMessage('Amount must be a whole number');
    } else if ((coinAmount / 10) > maxValue) {
      setErrorMessage('Exceeds limit');
    } else {
      setErrorMessage(null);
    }
  }, [enteredAmount]);

  return (
    <Modal.Flow
      isOpen={isOpen}
      fullScreenMobile
      onClose={onClickCancel}
      size={Modal.sizes.XL}
      steps={[
        {
          title: 'Select Amount',
          renderTopRight: () => 
            <Button.Icon
              iconName="close"
              onClick={onClickCancel}
            />,
          renderBody: () => (
            <ModalBody>
              <RewardContainer isVariableValue={isVariableValue}>
                <RewardImage 
                  src={image}
                  width={MODAL_IMAGE_WIDTH}
                />
                <RewardDetailsContainer isVariableValue={isVariableValue}>
                  <RewardName>{brandName}</RewardName>
                  {isVariableValue ? 
                    <>
                      <OrderCostSection>Current Balance: <strong>{currentCoinBalance.toLocaleString('en-US')} coins</strong></OrderCostSection>
                      <OrderCostSection>Coin Cost: <strong>{coinAmount.toLocaleString('en-US')} coins</strong></OrderCostSection>
                      <InputLabel>Enter Amount ({currencyCode}): {currencySymbol}{minValue.toLocaleString('en')} - {currencySymbol}{maxValue.toLocaleString('en')}</InputLabel>
                      <InputContainer>
                        <InputSymbol focused={isInputFocused} error={errorMessage}>{currencySymbol}</InputSymbol>
                        <Form.Input
                          autoFocus
                          size="M"
                          type="number"
                          errorMessageClassName={INPUT_ERROR}
                          className={REWARD_INPUT}
                          value={enteredAmount}
                          onFocus={() => setIsInputFocused(true)}
                          onBlur={onBlur}
                          onChange={(e, { value }) => setEnteredAmount(value)}
                          errorMessage={errorMessage}
                          showErrorMessageBelow
                          placeholder={`${minValue} - ${maxValue}`}
                        />
                      </InputContainer>
                    </>
                    : 
                    <>
                      <AmountDropdownContainer>
                          Select amount
                        <AmountDropdown 
                          items={rewardCostList}
                          value={selectedCoinAmount}
                          onChange={(e, { value, item }) => {
                            setSelectedCoinAmount(value);
                            setSelectedItem(item.selectedItem);
                          }}
                          placeholder={rewardCostList[0].label}
                        />
                      </AmountDropdownContainer>
                      {!isFixedAmountValid && <DropdownErrorMessage>You do not have enough coins for this reward</DropdownErrorMessage>}
                    </>
                  }
                </RewardDetailsContainer>
              </RewardContainer>
              <RewardDescription dangerouslySetInnerHTML={{ __html: transformedDescription }} />
            </ModalBody>
          ),
          renderFooter:(renderProps) => (
            <Modal.Footer.WithActionButtons
              primaryLabel='Continue'
              secondaryLabel='Cancel'
              canClickPrimary={canClickContinueButton}
              onClickPrimary={() => {
                if (isVariableValue) {
                  setSelectedCoinAmount(coinAmount);
                }
                renderProps.incrementStep();
              }}
              onClickSecondary={onClickCancel}
            /> 
          ),
        },
        {
          title: 'Your Order',
          renderTopRight: (renderProps) => 
            <Button.Icon
              iconName={'close'}
              onClick={() => {
                renderProps.decrementStep();
                onClickCancel();
              }}
            />,
          renderBody: () => (
            <ModalBody>
              <RewardContainer>
                <RewardImage 
                  src={image}
                  width={MODAL_IMAGE_WIDTH}
                />
                <RewardDetailsContainer>
                  <RewardName>{isVariableValue ? `${selectedItem.name} ${currencySymbol}${enteredAmount}` : selectedItem.name}</RewardName>
                  <OrderCostSection>Current Balance: <strong>{currentCoinBalance.toLocaleString('en-US')} coins</strong></OrderCostSection>
                  <OrderCostSection>
                    Coin Cost: <strong>{selectedCoinAmount.toLocaleString('en-US')} coins</strong>
                  </OrderCostSection>
                  <OrderCostSection>
                    Remaining Coins: <strong>{remainingCoinAmount.toLocaleString('en-US')} coins</strong>
                  </OrderCostSection>
                </RewardDetailsContainer>
              </RewardContainer>
              <CompleteOrderNotice>
                <Disclaimer dangerouslySetInnerHTML={{ __html: transformedDisclaimer }}/>
              </CompleteOrderNotice>
              <Typography variant='body2'>
                By clicking <b>Complete Order</b>, I understand that this virtual reward/card is subject to the terms and conditions stated by the merchant/vendor on their website, not valid for other payment, and Matter is unable to provide returns or refunds.
              </Typography>
              <Typography variant='body2' sx={{ mt: 2 }}>
                For additional support, contact the merchant/vendor.
              </Typography>
            </ModalBody>
          ),
          renderFooter:(renderProps) => (
            <Modal.Footer.WithCancelSave
              saveLabel='Complete Order'
              cancelLabel='Back'
              canClickSave={canClickCompleteButton}
              onClickSave={!isRequestingReward && onClickSave}
              onClickCancel={renderProps.decrementStep}
            /> 
          ),
          onGoBack: (renderProps) => renderProps.decrementStep(),
        }
      ]}
    >
    </Modal.Flow>
  );
};

TangoRewardRedeemModal.propTypes = {
  isOpen: PropTypes.bool,
  selectedReward: PropTypes.object,
  currentCoinBalance: PropTypes.number,
  onClickClose: PropTypes.func,
  onClickComplete: PropTypes.func,
  isRequestingReward: PropTypes.bool,
};

export default TangoRewardRedeemModal;