import React, { useState, createRef, useEffect, useContext } from 'react';
import { Button as MatterButton, Tooltip } from '@matterapp/matter-ui';
import UserContext from 'context/UserContext/UserContext';
import { 
  Box,
  Typography,
  TableHead,
  TableSortLabel,
  TableBody,
  Table
} from '@mui/material';
import { defaultProperties } from './helpers';
import LockIcon from '../icons/lock';
import EditPropertyModal from './EditPropertyModal';
import getSegments from 'graphql-queries/queries/hris/getSegments';
import getCustomHrisProperties from 'graphql-queries/queries/hris/getCustomHrisProperties';
import { useQuery } from '@apollo/client';
import {
  TopWrapper,
  TopSection,
  NameWrapper,
  StyledTableRow,
  HeaderCell,
  StyledBodyRow,
  MainTableCell,
  DesktopOnlyCell,
  EditButton,
  MobileCell
} from './styles';

export default function DataProperties() {
  const { currentTenant: tenant, currentWorkspace: workspace } = useContext(UserContext);
  const { hrisSettings } = tenant;
  const { customFieldsMapping = [] } = hrisSettings || {};
  const [order, setOrder] = useState('desc');
  const [editingProperty, setEditingProperty] = useState(null);
  const [buttonText, updateButtonText] = useState('Create Property');
  const isEditingHrisProperty = customFieldsMapping?.some(({ customPropertyId }) => editingProperty?.id == customPropertyId);
  const { data: customHrisPropsData } = useQuery(getCustomHrisProperties, {
    variables: {
      tenantId: tenant.id
    }
  });

  const ref = createRef();
  const { customHrisProperties = [] } = customHrisPropsData || {};

  const { data: segmentsData } = useQuery(getSegments, {
    variables: {
      tenantId: tenant.id
    }
  });
  const { segments = [] } = segmentsData || {};
  const customPropertyIdsInSegments = new Set();
  
  segments.forEach(segment => {
    const { rules } = segment;
    
    rules
      .forEach((rule) => {
        if (rule.customPropertyId) {
          customPropertyIdsInSegments.add(parseInt(rule.customPropertyId));
        }
      });
  });
    
  useEffect(() => {
    if (ref?.current?.offsetWidth < 600) {
      updateButtonText('Create');
    }
  }, [ref]);

  return (
    <Box ref={ref}>
      <TopSection>
        <Typography variant='h4'>
          Member Properties
        </Typography>
        <MatterButton 
          size={'S'} 
          onClick={() => setEditingProperty({})}
        >
          {buttonText}
        </MatterButton>
      </TopSection>
      <TopWrapper>
        <EditPropertyModal 
          workspace={workspace}
          tenant={tenant}
          isOpen={!!editingProperty}
          property={editingProperty}
          onClose={() => setEditingProperty(null)}
          canDelete={!customPropertyIdsInSegments.has(parseInt(editingProperty?.id)) && !isEditingHrisProperty }
        />
        <Typography variant='body1' sx={{ mt: -2, mb: 3 }}>
          Member properties allow you to segment the members of your organization regardless of their teams. 
          Segments are commonly used for filtering in analytics. To learn more, visit the <a href='https://help.matterapp.com/en/articles/10305822-member-properties' target='_blank' rel='noopener noreferrer'> Help Center</a>.
        </Typography>
      </TopWrapper>
      <Table size='large' sx={{ mb: 9 }}>
        <TableHead>
          <StyledTableRow>
            <HeaderCell visibleOnMobile>
              <TableSortLabel
                active
                direction={order}
                onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
              >
                Property
              </TableSortLabel>
            </HeaderCell>
            <HeaderCell>Type</HeaderCell>
            <HeaderCell>Created by</HeaderCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {[...defaultProperties, ...customHrisProperties]
            .sort((a, b) => {
              if (order === 'asc') {
                return a.label > b.label ? -1 : 1;
              } 
        
              return b.label > a.label ? -1 : 1;
            })
            .map((property) => {
              const { key, label, type, isMatter, createdBy } = property;

              return (
                <StyledBodyRow 
                  sx={{ cursor: isMatter ? 'auto' : 'pointer' }}
                  key={key} 
                  onClick={() => {
                    if (!isMatter) {
                      setEditingProperty(property);
                    }
                  }}
                >
                  <MainTableCell sx={{ width: '60%' }}>
                    <NameWrapper>
                      <Typography variant='body2' sx={{ fontWeight: 'bold', width: '100%', textOverflow: 'ellipsis' }}>
                        {label}
                      </Typography>
                      {isMatter ? (
                        <Tooltip
                          dark
                          content='Default Matter property. Can not be deleted.'
                        >
                          <LockIcon />
                        </Tooltip>
                      ) : null}
                    </NameWrapper>
                  </MainTableCell>
                  <DesktopOnlyCell>
                    <Typography variant='body2' color='blacks.90' sx={{ textTransform: 'capitalize' }}>
                      {type}
                    </Typography>
                  </DesktopOnlyCell>
                  <DesktopOnlyCell sx={{ width: '100%' }}>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}>
                      <Typography variant='body2' color='blacks.90'>
                        {createdBy?.fullName || 'Matter'}
                      </Typography>
                      {isMatter ? null : (
                        <EditButton 
                          variant='outlined'
                        >
                          EDIT
                        </EditButton>
                      )}
                    </Box>
                  </DesktopOnlyCell>
                  <MobileCell>
                    {isMatter ? null : (
                      <EditButton 
                        variant='outlined'
                      >
                        EDIT
                      </EditButton>
                    )}
                  </MobileCell>
                </StyledBodyRow>
              );
            })}
        </TableBody>
      </Table>
    </Box>
  );
};
