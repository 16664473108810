import React, { useEffect, useState } from 'react';
import { Button, Confirm } from '@matterapp/matter-ui';
import { 
  Box,
  Typography,
  Divider,
  TableHead,
  TableSortLabel,
  TableBody,
  Table,
  TableRow,
  TableCell
} from '@mui/material';
import { 
  Caption, 
  NoComments,
  StyledBodyRow,
  HeaderCell,
  DesktopOnlyCell
} from 'components/Table/styles';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';
import searchTeams from 'graphql-queries/queries/matterTeams/searchMatterTeams';
import addSubteamToMatterTeam from 'graphql-queries/mutations/matterTeams/addSubteamToMatterTeam';
import deleteMatterSubteam from 'graphql-queries/mutations/matterTeams/deleteMatterSubteam';
import getMatterTeam from 'graphql-queries/queries/matterTeams/getMatterTeam';
import Toast from 'components/Toast/Toast';
import { Resources } from '@matterapp/routing';
import { SearchDropdown, MainWrapper } from './styles';

const debouncedSearch = debounce((searchString, tenantId, query) => {
  query({
    variables: {
      tenantId,
      searchString
    }
  });
}, 300);

export default function SubTeamsTab({ matterTeam, tenantId, workspaceId }) {
  let { subteams } = matterTeam;
  subteams = subteams || [];
  const [deletingSubteam, setDeletingSubteam] = useState(null);
  const [searchMatterTeams, { data: searchTeamsData }] = useLazyQuery(searchTeams);
  const [addSubteamToMatterTeamMutation, { error }] = useMutation(addSubteamToMatterTeam);
  const [deleteMatterSubteamMutation] = useMutation(deleteMatterSubteam);
  const [order, setOrder] = useState('asc');
  const navigate = useNavigate();

  let teamItems = (searchTeamsData?.searchMatterTeams || [])
    .filter((team) => !subteams.find(({ id }) => id == team.id) && team.id != matterTeam.id);
    
  teamItems = teamItems.map((team) => {
    return {
      label: team.name,
      value: team.id
    };
  });

  useEffect(() => {
    if (error) {
      Toast.error(error.message);
    }
  }, [error]);

  return (
    <MainWrapper>
      <Confirm
        isOpen={!!deletingSubteam}
        onClose={() => setDeletingSubteam(null)}
        header='Are you sure?'
        subHeader='Once you remove a sub-group, you can always add it back. Member data will not be affected.'
        confirmButtonLabel='Yes, Delete'
        onClickConfirm={async () => {
          const subteamId = deletingSubteam;

          setDeletingSubteam(null);

          try {
            await deleteMatterSubteamMutation({
              variables: {
                tenantId,
                teamId: matterTeam.id,
                subteamId
              },
              update(cache) {
                const variables = {
                  tenantId: parseInt(tenantId),
                  teamId: parseInt(matterTeam.id),
                  memberLimit: 50
                };

                const cached = cache.readQuery({ 
                  query: getMatterTeam,
                  variables
                })?.matterTeam || {};
    
                cache.writeQuery({
                  query: getMatterTeam,
                  variables,
                  data: {
                    matterTeam: {
                      ...cached,
                      subteams: [
                        ...(cached.subteams || [])
                      ].filter((team) => team.id !== subteamId)
                    }
                  }
                });
              }
            });
  
            Toast.success('The member has been removed');
          } catch (error) {
            Toast.error(error.message);
          }
        }}
        onClickCancel={() => setDeletingSubteam(null)}
      />
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        Add Sub-Groups
      </Typography>
      <Box>
        <SearchDropdown
          filterItems
          items={teamItems}
          isValidValue={() => true}
          onChangeInput={(e, { value }) => { 
            if (value?.trim()) {
              debouncedSearch(value.trim(), tenantId, searchMatterTeams);  
            }
          }}
          onSelect={(_, item ) => {
            if (item.item) {
              addSubteamToMatterTeamMutation({
                variables: {
                  teamId: matterTeam.id,
                  tenantId,
                  subteamId: item.item.value
                },
                refetchQueries: [
                  {
                    query: getMatterTeam,
                    variables: {
                      tenantId: parseInt(tenantId),
                      teamId: parseInt(matterTeam.id)
                    }
                  }
                ]
              });
            }
          }}
          onChange={() => {}}
          fluid
          showValuesAsSubLabels={false}
          value={[]}
          placeholder="Enter group name"
        />
      </Box>
      <Typography variant='body1' sx={{ mt: 3 }}>This group has {subteams?.length} {subteams?.length === 1 ? 'sub-group' : 'sub-groups'}.</Typography>
      <Divider />

      <Table size='large'>
        {!subteams.length ? (
          <Caption>
            <NoComments variant='h6' component='p'>
              <b>No sub-groups yet</b>
            </NoComments>
            <NoComments variant='body1' component='p'>
              Add sub-groups to the group.
            </NoComments>
          </Caption>
        ) : null}
        <TableHead>
          <TableRow sx={{ borderBottom: '1px solid #E6E6E7' }}>
            <HeaderCell visibleOnMobile>
              <TableSortLabel
                active
                direction={order}
                onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
              >
                Name
              </TableSortLabel>
            </HeaderCell>
            <HeaderCell visibleOnMobile>Members</HeaderCell>
            <HeaderCell>Managers</HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...subteams]
            .sort((a, b) => {
              if (order === 'asc') {
                return a.name.localeCompare(b.name);
              } else {
                return b.name.localeCompare(a.name);
              }
            })
            .map((team) => {
              const { id, name, managers = [], ownMemberCount } = team;

              return (
                <StyledBodyRow 
                  onClick={(e) => {
                    navigate(
                      Resources.workspaceSettingsTeamPage.path({ workspaceId, teamId: id })
                    );
                  }}
                  key={id} 
                >
                  <TableCell>
                    <Typography variant='body2'>
                      <b>{name}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2'>
                      {ownMemberCount || 0}
                    </Typography>
                  </TableCell>
                  <DesktopOnlyCell>
                    <Typography variant='body2'>
                      {managers?.length || 0}
                    </Typography>
                  </DesktopOnlyCell>
                  <DesktopOnlyCell>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      width: '100%'
                    }}>
                      <Button 
                        color={'red'}
                        size={'S'}
                        onClick={(e) => {
                          e.stopPropagation();
                            
                          setDeletingSubteam(id);
                        }}
                      >
                        Remove
                      </Button>
                    </Box>
                  </DesktopOnlyCell>
                </StyledBodyRow>
              );
            })}
        </TableBody>
      </Table>
    </MainWrapper>
  );
};
