import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateIntercom } from 'libs/tracking/intercom';
import { Resources } from '@matterapp/routing';
import UserContext from 'context/UserContext/UserContext';

function getRedirectPath(props) {
  const { customRedirect } = props;

  if (customRedirect) {
    const customRedirectPath = customRedirect(props);

    if (customRedirectPath) { 
      return customRedirectPath;
    }
  }

  return Resources.authRequestVerification.path();
}

function PrivateRoute(props) {
  const { 
    children, 
    showIntercomMessenger,
    adminsOnly,
    managersOnly
  } = props;
  const navigate = useNavigate();
  const { 
    currentUser, 
    isLoadingCurrentUser,
    isAuthenticating,
    isAdminMember,
    loadedTeamsManagers,
    isTeamsManager,
    currentTenant
  } = useContext(UserContext);

  useEffect(() => {
    if (!currentUser && !isLoadingCurrentUser && !isAuthenticating) {
      const redirectPath = getRedirectPath({ ...props, currentUser });

      navigate(redirectPath);
    }

    if (currentUser && currentTenant) {
      let hasAccess = adminsOnly ? isAdminMember : true;

      if (!hasAccess && managersOnly) {
        hasAccess = hasAccess || isTeamsManager;
      }

      if (!hasAccess && loadedTeamsManagers) {
        navigate(Resources.homeMain.path());
      }
      
      updateIntercom(currentUser, { showIntercomMessenger });
    }
  }, [currentUser, isLoadingCurrentUser, loadedTeamsManagers, isAuthenticating, currentTenant]);

  if (isAuthenticating || !currentUser || !loadedTeamsManagers) {
    return null;
  }
  
  return children;
}

export default PrivateRoute;
