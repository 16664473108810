import React, { useEffect, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { Helmet } from 'react-helmet';
import { AppLayout } from 'modules';
import FeedItemsList from 'modules/feed/FeedItemsList';
import { FEEDBACK_RECEIVED_QUERY } from 'graphql-queries/queries';
import { useSettingsPanelState } from 'routes/pages/workspaces/hooks/shared';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Toast from 'components/Toast/Toast';

export default function FeedbackReceivedViewFeedbackPage(props) {
  const params = useParams();
  const { feedbackReceivedId } = params || {};
  const { data, error } = useQuery(FEEDBACK_RECEIVED_QUERY, {
    variables: {
      id: feedbackReceivedId,
    }
  });
  const { 
    currentWorkspace: workspace
  } = useContext(UserContext);

  const { feedbackFeedItemByFeedbackId } = data || {};

  let feedItemsList = feedbackFeedItemByFeedbackId ? [feedbackFeedItemByFeedbackId] : [];
  feedItemsList = feedItemsList.map((feedItem) => ({
    ...feedItem,
    payload: {
      ...feedItem.payload,
      isWholeChannel: feedItem.isWholeChannel,
    }
  }));

  const {
    headerProps
  } = useSettingsPanelState(workspace || {});

  useEffect(() => {
    if (error) {
      Toast.error('Oops! That feedback has been deleted');
    }
  }, [error]);

  if (!data || error) {
    return null;
  }

  return (
    <>
      <Helmet title="View Feedback" />
      <AppLayout
        headerProps={headerProps}
        layout={AppLayout.Layouts.Main}
        mainColumn={
          <FeedItemsList
            feedItemsList={feedItemsList}
            isLoading={!feedbackFeedItemByFeedbackId}
            feedItemsProps={{ expanded: true, showMenu: false }}
            feedItemFooterProps={{ expanded: true, autoFocus: false }}
          />
        }
      />
    </>
  );
}
