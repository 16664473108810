import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import {
  Text,
  Label
} from '@matterapp/matter-ui';
import { AppLayout } from 'modules';
import { 
  UPLOAD_USER_PHOTO_MUTATION,
  REMOVE_USER_PHOTO_MUTATION
} from 'graphql-queries/queries';
import ContainerNavigation from './ContainerNavigation';
import { IMAGE_FILE_UPLOAD_ERROR_DETAILS } from 'routes/pages/settings/Kudos/AddKudosModal/consts';
import ImageUpload from 'modules/workspaces/shared/ImageUpload/ImageUpload';
import getCurrentUser from 'graphql-queries/queries/user/getCurrentUser';
import { useQuery, useMutation } from '@apollo/client';
import { Loader } from '@matterapp/matter-ui';
import getS3PresignedUrl from 'graphql-queries/mutations/getS3PresignedUrl';
import UserSettingsForm from './UserSettingsForm';

const ImageContainer = styled.div`
  margin-bottom: ${theme.spacing.singleAndHalf};
  margin-top: ${theme.spacing.singleAndHalf};
`;

function UserSettingsMainPage() {
  const [photoUrl, setPhotoUrl] = useState(null);
  const { loading: loadingUser, data: userData } = useQuery(getCurrentUser);
  const [s3PresignedUrlMutation] = useMutation(getS3PresignedUrl);
  const [savePhoto] = useMutation(UPLOAD_USER_PHOTO_MUTATION);
  const [removePhoto] = useMutation(REMOVE_USER_PHOTO_MUTATION);

  const { currentUser } = userData || {};

  useEffect(() => {
    if (userData) {
      setPhotoUrl(userData.currentUser?.photoUrl);
    }
  }, [userData]);

  if (loadingUser) {
    return (
      <Loader />
    );
  }

  if (!currentUser) { 
    return null; 
  }

  async function onSaveIcon(_, file) {
    const presignedUrlData = await s3PresignedUrlMutation({
      variables: {
        prefix: 'profile'
      },
    });
    
    const { presignedUrl, imgixPath } = presignedUrlData.data.getS3PresignedUrl;

    await fetch(presignedUrl, {
      method: 'PUT',
      headers: { 
        'Content-Type': 'image/png'
      },
      body: file
    });
    
    savePhoto({
      variables: {
        photoUrl: imgixPath
      }
    });
  }

  async function onRemoveIcon() {
    removePhoto();
  }

  return (
    <>
      <AppLayout
        layout={AppLayout.Layouts.TwoColumnFixedLeft}
        oldLayoutVersion={1}
        containerNavigation={<ContainerNavigation />}
      >
        <Text color="black" textStyle={500} marginBottom={24} lineHeight={32} block bold>
          General
        </Text>
        <Label text="Profile Photo">
          <Text
            as="p"
            textAlign="left"
            color="black"
            fontSize={17}
            marginBottom={24}
          >
            Update your photo below.
          </Text>
        </Label>
        <ImageContainer>
          <ImageUpload
            url={photoUrl}
            onSaveIcon={onSaveIcon}
            onRemoveIcon={onRemoveIcon}
            {...IMAGE_FILE_UPLOAD_ERROR_DETAILS}
          />
        </ImageContainer>
        <UserSettingsForm currentUser={currentUser} />
      </AppLayout>
    </>
  );
}

UserSettingsMainPage.defaultProps = {
  iconRemoved: false,
};

export default UserSettingsMainPage;
