import { 
  Box,
  Typography,
  Paper,
  TableCell,
  TableRow
} from '@mui/material';
import styled from 'styled-components';
import DarkMenu from 'components/Menu/Dark/Menu';

export const StyledBodyRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.palette.blacks[5],
  },
  ':active': {
    backgroundColor: theme.palette.purple[5]
  },
  position: 'relative'
}));

export const StyledDarkMenu = styled(DarkMenu)(({ theme }) => ({
  width: '40px',
  height: '40px'
}));

export const NoResultsText = styled(Typography)(({ theme }) => ({
  color: theme.palette.blacks[60], 
  textAlign: 'center'
}));

export const MainSection = styled(Paper)(({ theme }) => ({
  padding: 0,
  minHeight: '466px',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    borderRadius: 0
  }
}));

export const TopSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    margin: '20px'
  }
}));

export const Caption = styled.caption({
  height: '320px',
  textAlign: 'center',
  'p:first-child': {
    marginTop: '120px'
  }
});

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderTop: `solid 1px ${theme.palette.blacks[10]}`,
  borderBottom: `solid 1px ${theme.palette.blacks[10]}`
}));

export const HeaderCell = styled(TableCell)
  .withConfig({ shouldForwardProp: (prop) => {
    return prop !== 'visibleOnMobile';
  } })
  (({ theme, visibleOnMobile }) => ({
    border: 'none',
    fontWeight: 'normal',
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    color: theme.palette.blacks[60],
    [theme.breakpoints.down('md')]: {
      display: visibleOnMobile ? 'table-cell' : 'none'
    }
  }));

export const TitleHeaderCell = styled(HeaderCell)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}));

export const StyledTableCell = styled(TableCell)
  .withConfig({ shouldForwardProp: (prop) => {
    return !['hideOnDesktop', 'hideOnMobile'].includes(prop);
  } })(({ theme, hideOnMobile, hideOnDesktop }) => ({
    display: hideOnDesktop ? 'none' : 'table-cell',
    [theme.breakpoints.down('md')]: {
      display: hideOnMobile ? 'none' : 'table-cell'
    }
  }));

export const MainTableCell = styled(TableCell)(({ theme }) => ({
  width: '420px',
  maxWidth: '420px',
  p: {
    lineHeight: '20px'
  },
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  [theme.breakpoints.down('sm')]: {
    width: '90%'
  }
}));

export const DesktopOnlyCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

export const MenuTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  width: '40px',
  maxWidth: '40px'
}));
