import React, { useState, useEffect, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import { Modal, Dropdown } from '@matterapp/matter-ui';
import { Box, Typography } from '@mui/material';
import DayOfPostPicker from './DayOfPostPicker';
import DisableModal from './DisableModal';
import Messages from './Messages';
import { Resources } from '@matterapp/routing';
import {
  FEEDBACK_RITUAL_PANEL_TEXT,
  FEEDBACK_RITUAL_KICKOFF_MESSAGE
} from 'routes/pages/settings/sharedConsts';
import { 
  AllowanceBlock,
  InputWrapper,
  ResetButton,
  StyledInput
} from 'routes/pages/settings/Rewards/DefaultAllowanceSection/styles';
import ChangeRitualFrequencyConfirmModal from './ChangeRitualFrequencyConfirmModal';
import {
  EnableButtonsContainer,
  MidDayContainer,
  SubHeader,
  SubText,
  EnableButton,
  ExampleLink,
  KickoffMessageArea,
  KickoffMessageContainer,
  KickoffMessageHeader,
  KickoffMessageResetButton,
  KickoffMessageSentByHeader,
  Toggle,
  SlackNotificationsCheckmark,
  SlackNotificationsContainer,
  SlackNotificationsNotifyText,
  ChangeTimezoneLink,
  HowItWorksButton,
  RitualNameWrapper
} from './styles';
import { FormattedGroup } from 'components/Group';
import { 
  getRitualCustomTimes, 
  RITUAL_STAGES,
  formatDropdownOptions,
  getLastCallTime,
  getDefaultKickoffMessage,
  getRitualName,
  isKickoffMessageCustom
} from './helpers';
import { StyledPaper } from '../styles';
import { ToggleContainer, ToggleWrapper, ToggleUnit } from 'components/ToggleGroup';
import TimezoneTooltip from 'components/TimezoneTooltip/TimezoneTooltip';
import { useLazyQuery, useMutation } from '@apollo/client';
import getNextFeedbackFridayQuery from 'graphql-queries/queries/slackWorkspace/getNextFeedbackFriday';
import setFeedbackRitualWorkspaceSettings from 'graphql-queries/mutations/slackWorkspace/setFeedbackRitualSettings';
import Toast from 'components/Toast';
import { SEND_FEEDBACK_RITUAL_WORKSPACE_MESSAGE_SAMPLE } from 'graphql-queries/queries';
import timezones from 'timezones-list';
import { feedbackFridayUtils } from '@matterapp/utils';
import InlineCheckbox from 'components/Forms/InlineCheckbox';
import ExcludeFromMVPSection from './ExcludeFromMVPSection/ExcludeFromMVPSection';
import { SettingsFooter } from '../styles';
import { useNavigate } from 'react-router-dom';
import SettingsHeader from '../SettingsHeader';

const FeedbackRitualMessageType = {
  kickoff: 'kickoff',
  reminder: 'reminder',
  recap: 'recap',
  lastCall: 'lastCall',
  stats: 'stats'
};

export default function FeedbackFridaySettings() {
  const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);
  const [isFrequencyConfirmationModalOpen, setIsFrequencyConfirmationModalOpen] = useState(false);
  const { currentTenant: tenant, currentWorkspace: workspace } = useContext(UserContext);
  const [changesConfirmed, updateChangesConfirmed] = useState(false);
  const [getNextFeedbackFriday, { data: nextFeedbackFridayData, loading: loadingNextFF }] = useLazyQuery(getNextFeedbackFridayQuery, {
    fetchPolicy: 'network-only'
  });
  const [saveFeedbackRitualSettings, { loading: isSaving, data: savedData }] = useMutation(setFeedbackRitualWorkspaceSettings);
  const [sendExample, { loading: isSendingExample }] = useMutation(SEND_FEEDBACK_RITUAL_WORKSPACE_MESSAGE_SAMPLE);
  const navigate = useNavigate();

  const { feedbackRitualSettings, teamsWorkspace, timezone, slackWorkspace } = workspace;
  const { primaryChannel } = slackWorkspace || {};
  const { rewardSettings } = tenant;
  const { isRewardsActive } = rewardSettings || {};

  const { 
    isActive,
    frequency,
    dayOfPost,
    kickOffTime,
    reminderTime,
    lastCallTime,
    recapTime,
    kickoffMessage
  } = feedbackRitualSettings || {};
  const isSlackConnected = slackWorkspace && primaryChannel;

  const [{
    selectedFrequency,
    selectedDayOfPost,
    newKickOffTime,
    newReminderTime,
    newLastCallTime,
    newRecapTime,
    newKickoffMessage,
    notifyEntireTeamForKickoff,
    notifyEntireTeamForLastCall,
    isStatsMessageActive,
    isRecapActive,
    isLastCallReminderActive,
    isReminderActive,
    mpvCoinsAwarded,
    customRitualName,
    ritualName,
    useGifs = true,
    isMvpEnabled = true
  }, updateSettings] = useState({ 
    notifyEntireTeamForKickoff: feedbackRitualSettings?.notifyEntireTeamForKickoff,
    notifyEntireTeamForLastCall: feedbackRitualSettings?.notifyEntireTeamForLastCall,
    selectedFrequency: frequency,
    selectedDayOfPost: dayOfPost,
    newKickOffTime: kickOffTime,
    newReminderTime: reminderTime,
    newLastCallTime: lastCallTime,
    newRecapTime: recapTime,
    newKickoffMessage: kickoffMessage,
    isStatsMessageActive: feedbackRitualSettings?.isStatsMessageActive,
    isLastCallReminderActive: feedbackRitualSettings?.isLastCallReminderActive,
    isRecapActive: feedbackRitualSettings?.isRecapActive,
    isReminderActive: feedbackRitualSettings?.isReminderActive,
    mpvCoinsAwarded: feedbackRitualSettings?.mpvCoinsAwarded,
    customRitualName: feedbackRitualSettings?.customRitualName,
    useGifs: feedbackRitualSettings?.useGifs,
    isMvpEnabled: feedbackRitualSettings?.isMvpEnabled,
    ritualName: getRitualName(dayOfPost)
  });
  const mergeSettings = (updatedState) => updateSettings((prevState) => ({ ...prevState, ...updatedState })); 
  const mpvCoinsHasError = mpvCoinsAwarded < 0 || mpvCoinsAwarded > 100000;
  const customRitualNameHasError = customRitualName?.length > 30;

  const ritualTimeSettingsChanged = selectedFrequency !== frequency 
  || selectedDayOfPost !== dayOfPost 
  || newKickOffTime !== kickOffTime
  || newReminderTime !== reminderTime
  || newLastCallTime !== lastCallTime
  || newRecapTime !== recapTime;

  const kickoffMessageIsValid = newKickoffMessage?.length >= FEEDBACK_RITUAL_KICKOFF_MESSAGE.MIN_LENGTH && newKickoffMessage?.length < FEEDBACK_RITUAL_KICKOFF_MESSAGE.MAX_LENGTH;

  const settingsChanged = (newKickoffMessage !== kickoffMessage && kickoffMessageIsValid) 
  || notifyEntireTeamForKickoff !== feedbackRitualSettings?.notifyEntireTeamForKickoff
  || notifyEntireTeamForLastCall !== feedbackRitualSettings?.notifyEntireTeamForLastCall
  || isStatsMessageActive !== feedbackRitualSettings?.isStatsMessageActive
  || isRecapActive !== feedbackRitualSettings?.isRecapActive
  || isLastCallReminderActive !== feedbackRitualSettings?.isLastCallReminderActive
  || isReminderActive !== feedbackRitualSettings?.isReminderActive
  || mpvCoinsAwarded != feedbackRitualSettings?.mpvCoinsAwarded
  || (customRitualName !== feedbackRitualSettings?.customRitualName && !customRitualNameHasError)
  || useGifs !== feedbackRitualSettings?.useGifs
  || isMvpEnabled !== feedbackRitualSettings?.isMvpEnabled;

  const { nextFeedbackFriday } = nextFeedbackFridayData || {};

  const trainingLink = slackWorkspace 
    ? <a href='https://matterapp.com/training/feedback-friday-slack' target='_blank' rel='noopener noreferrer'>Training Video</a>
    : <a href='https://matterapp.com/training/feedback-friday-teams' target='_blank' rel='noopener noreferrer'>Training Video</a>;
    
  useEffect(() => {
    if (changesConfirmed) {
      saveFeedbackRitualSettings({
        variables: {
          workspaceId: workspace.id,
          settings: {
            isActive: true,
            frequency: selectedFrequency,
            dayOfPost: selectedDayOfPost,
            kickOffTime: newKickOffTime,
            reminderTime: newReminderTime,
            lastCallTime: newLastCallTime,
            recapTime: newRecapTime,
            kickoffMessage: newKickoffMessage,
            notifyEntireTeamForKickoff,
            notifyEntireTeamForLastCall,
            isStatsMessageActive,
            isRecapActive,
            isLastCallReminderActive,
            isReminderActive,
            mpvCoinsAwarded,
            customRitualName,
            useGifs,
            isMvpEnabled
          }
        }
      });

      updateChangesConfirmed(false);
    }
  }, [changesConfirmed]);

  useEffect(() => {
    if (isSendingExample) {
      Toast.info('We sent you a message on Slack!');
    }
  }, [isSendingExample]);

  useEffect(() => {
    if (!isSaving && savedData) {
      const { isActive } = savedData?.setFeedbackRitualWorkspaceSettings?.feedbackRitualSettings;

      if (!isActive) {
        Toast.success(`You’ve successfully disabled ${customRitualName || ritualName}`);
      } else {
        Toast.success(`You’ve successfully updated ${customRitualName || ritualName}`);
      }
    }
  }, [savedData]);

  useEffect(() => {
    if (nextFeedbackFriday && !loadingNextFF) {
      setIsFrequencyConfirmationModalOpen(true);
    }
  }, [nextFeedbackFriday, loadingNextFF]);

  const canClickSave = settingsChanged || ritualTimeSettingsChanged;

  const sharedPanelProps = {
    subHeader: FEEDBACK_RITUAL_PANEL_TEXT.SUB_HEADER,
  };

  const howItWorksUrl = slackWorkspace
    ? 'https://matterapp.com/training/feedback-friday-slack'
    : 'https://matterapp.com/training/feedback-friday-teams';

  if (!isActive) {
    return (
      <Modal.Panel.Main>
        <SettingsHeader 
          disabled
          disabledComponent={(
            <>
              <Typography variant='body1' sx={{ mb: 2 }}>
                Encourage weekly team feedback
              </Typography>
              {!isSlackConnected && !teamsWorkspace && (
                <Messages.SlackRequiredMessage />
              )}
              <EnableButtonsContainer>
                <EnableButton onClick={() => {
                  saveFeedbackRitualSettings({
                    variables: {
                      workspaceId: workspace.id,
                      settings: {
                        isActive: true
                      }
                    }
                  });
                }} />
                <HowItWorksButton
                  href={howItWorksUrl} 
                  target="_blank"
                >
                  See How It Works
                </HowItWorksButton>
              </EnableButtonsContainer>
            </>
          )}
          headerText={customRitualName || ritualName}
          isLoading={isSaving}
          onDisable={() => setIsDisableModalOpen(true)}
          text='Configure Feedback Friday settings for your organization.'
          trainingLink={trainingLink}
          helpCenterLink='https://help.matterapp.com/en/collections/3355839-feedback-friday'
        />
      </Modal.Panel.Main>
    );
  }
  return (
    <Modal.Panel.Main
      {...sharedPanelProps}
      subHeader={null}
    >
      <SettingsHeader 
        headerText={customRitualName || ritualName}
        isLoading={isSaving}
        onDisable={() => setIsDisableModalOpen(true)}
        text='Configure Feedback Friday settings for your organization.'
        trainingLink={trainingLink}
        helpCenterLink='https://help.matterapp.com/en/collections/3355839-feedback-friday'
      />
      <StyledPaper>
        <Typography variant='h3' sx={{ mb: 2 }}>Frequency & Day</Typography>
        <Typography variant='body1'>
          Send a recognition ritual kickoff, reminder, and recap on this day.
        </Typography>
        <DayOfPostPicker
          dayOfPost={selectedDayOfPost}
          frequency={selectedFrequency}
          onChangeDayOfPost={({ value }) => {
            mergeSettings({ 
              selectedDayOfPost: value,
              ritualName: getRitualName(value),
              newKickoffMessage: newKickoffMessage === getDefaultKickoffMessage(selectedDayOfPost) 
                ? getDefaultKickoffMessage(value)
                : newKickoffMessage
            });
          }}
          onChangeFrequency={({ value }) => mergeSettings({ selectedFrequency: value })}
        />
        <br />
        <p>
          Timezone: {timezones.find(({ tzCode }) => tzCode === timezone)?.name || timezone}. 
          <ChangeTimezoneLink 
            onClick={() => {
              navigate(Resources.workspaceSettingsGeneral.path({ workspaceId: workspace.id }));
            }}
          >
            &nbsp;Change
          </ChangeTimezoneLink>
        </p>
      </StyledPaper>
      <StyledPaper>
        <FormattedGroup
          header='Kickoff'
          marginBottom='0px'
          subHeader={
            <b>Message Delivery Time: </b>
          }
          subHeaderActions={teamsWorkspace ? null : <ExampleLink onClick={() => sendExample({
            variables: {
              type: FeedbackRitualMessageType.kickoff, 
              workspaceId: workspace.id, 
              settings: {
                dayOfPost: selectedDayOfPost,
                kickoffMessage: newKickoffMessage
              }
            }
          })} />}
        >
          <div>
            <Dropdown
              value={newKickOffTime}
              items={formatDropdownOptions(getRitualCustomTimes(RITUAL_STAGES.KICKOFF))}
              onChange={(_, { value: updatedKickOffTime}) => {
                const newReminderOptions = getRitualCustomTimes(RITUAL_STAGES.REMINDER, updatedKickOffTime);
                let updatedReminderTime = newReminderTime;
                let updatedRecapTime = newRecapTime;

                if (newReminderOptions.indexOf(updatedReminderTime) === -1) {
                  updatedReminderTime = newReminderOptions[0];
                }

                const newRecapOptions = getRitualCustomTimes(RITUAL_STAGES.RECAP, updatedReminderTime);

                if (newRecapOptions.indexOf(recapTime) === -1) {
                  updatedRecapTime = newRecapOptions[0];
                }

                mergeSettings({ 
                  newKickOffTime: updatedKickOffTime,
                  newReminderTime: updatedReminderTime,
                  newLastCallTime: getLastCallTime(updatedRecapTime),
                  newRecapTime: updatedRecapTime
                });
              }}
              placeholder="Kickoff"
              size={Dropdown.sizes.M}
              showSelectedItemInMenu
            />
            <TimezoneTooltip />
          </div>
          <KickoffMessageContainer>
            <KickoffMessageResetButton onClick={() => {
              mergeSettings({ newKickoffMessage: getDefaultKickoffMessage(selectedDayOfPost, customRitualName) });
            }}>
            Reset to default
            </KickoffMessageResetButton>
            <KickoffMessageArea
              label={
                <KickoffMessageHeader>
                Kickoff Message:
                </KickoffMessageHeader>
              }
              allowErrorBeforeBlur
              onChange={(_, { value }) => {
                mergeSettings(({ newKickoffMessage: value }));
              }}
              showCharacterCountOnIncorrectLength
              minLength={FEEDBACK_RITUAL_KICKOFF_MESSAGE.MIN_LENGTH}
              maxLength={FEEDBACK_RITUAL_KICKOFF_MESSAGE.MAX_LENGTH}
              value={newKickoffMessage}
            />
            {teamsWorkspace ? null : (
              <>
                <KickoffMessageSentByHeader>
                  {FEEDBACK_RITUAL_PANEL_TEXT.SLACK_NOTIFICATIONS_HEADER}
                </KickoffMessageSentByHeader>
                <InlineCheckbox 
                  text={FEEDBACK_RITUAL_PANEL_TEXT.SLACK_NOTIFICATIONS_NOTIFY_TEXT}
                  onChange={() => mergeSettings({ notifyEntireTeamForKickoff: !notifyEntireTeamForKickoff })}
                  isChecked={notifyEntireTeamForKickoff}
                  checkboxName='kickoff'
                />
              </>
            )}
          </KickoffMessageContainer>
        </FormattedGroup>
      </StyledPaper>
      <StyledPaper>
        <FormattedGroup
          isOff={!isReminderActive}
          marginBottom='0px'
          header='Mid Day Reminder'
          headerActions={
            <Toggle
              checked={isReminderActive}
              onChange={() => mergeSettings({ isReminderActive: !isReminderActive })}
            />
          }
        >
          <SubText>An encouraging reminder to help reach 100% participation.</SubText>
          <MidDayContainer>
            <SubHeader>Message Delivery Time:</SubHeader>
            {teamsWorkspace ? null : <ExampleLink onClick={() => sendExample({
              variables: {
                type: FeedbackRitualMessageType.reminder, 
                workspaceId: workspace.id, 
                settings: {
                  dayOfPost: selectedDayOfPost,
                  kickoffMessage: newKickoffMessage
                }
              }
            })} />}
          </MidDayContainer>
          {!isReminderActive && <Messages.ReminderOffMessage />}
          {isReminderActive ? (
            <>
              <Dropdown
                value={newReminderTime}
                items={formatDropdownOptions(getRitualCustomTimes(RITUAL_STAGES.REMINDER, newKickOffTime))}
                onChange={(_, { value: updatedReminderTime}) => {
                  let updatedRecapTime = newRecapTime;

                  const newRecapOptions = getRitualCustomTimes(RITUAL_STAGES.RECAP, updatedReminderTime);

                  if (newRecapOptions.indexOf(updatedRecapTime) === -1) {
                    updatedRecapTime = newRecapOptions[0];
                  }

                  mergeSettings({ 
                    newReminderTime: updatedReminderTime,
                    newLastCallTime: getLastCallTime(updatedRecapTime),
                    newRecapTime: updatedRecapTime
                  });
                }}
                placeholder="Kickoff"
                size={Dropdown.sizes.M}
                showSelectedItemInMenu
              />
              <TimezoneTooltip />
            </>
          ) : null
          }
        </FormattedGroup>
      </StyledPaper>
      <StyledPaper>
        <FormattedGroup
          isOff={!isLastCallReminderActive}
          marginBottom='0px'
          header='Last Call'
          headerActions={
            <Toggle
              checked={isLastCallReminderActive}
              onChange={() => mergeSettings({ isLastCallReminderActive: !isLastCallReminderActive })}
            />
          }
          subHeader={
            <p>
            Will be sent at {feedbackFridayUtils.getUSFormattedTime(newLastCallTime)}
            </p>
          }
          subHeaderActions={teamsWorkspace ? null : <ExampleLink onClick={() => sendExample({
            variables: {
              type: FeedbackRitualMessageType.lastCall, 
              workspaceId: workspace.id, 
              settings: {
                dayOfPost: selectedDayOfPost,
                kickoffMessage: newKickoffMessage
              }
            }
          })} />}
        >
          {teamsWorkspace ? null : (
            <>
              <KickoffMessageSentByHeader>
                {FEEDBACK_RITUAL_PANEL_TEXT.SLACK_NOTIFICATIONS_HEADER}
              </KickoffMessageSentByHeader>
              <SlackNotificationsContainer>
                <SlackNotificationsNotifyText
                  disabled={!isLastCallReminderActive}
                >
                  {FEEDBACK_RITUAL_PANEL_TEXT.SLACK_NOTIFICATIONS_NOTIFY_TEXT}
                </SlackNotificationsNotifyText>
                <SlackNotificationsCheckmark
                  onChange={() => mergeSettings({ notifyEntireTeamForLastCall: !notifyEntireTeamForLastCall })}
                  checked={notifyEntireTeamForLastCall}
                  iconSize={30}
                  checkmarkSize={24}
                  name='last-call'
                  light
                  isCheckboxV2
                  showAnimation={false}
                  disabled={!isLastCallReminderActive}
                />
              </SlackNotificationsContainer>
            </>
          )}
        </FormattedGroup>
      </StyledPaper>
      <StyledPaper>
        <FormattedGroup
          isOff={!isRecapActive}
          marginBottom='0px'
          header='Recap'
          headerActions={
            <Toggle
              checked={isRecapActive}
              onChange={() => mergeSettings({ isRecapActive: !isRecapActive })}
            />
          }
          subHeader={`End ${customRitualName || ritualName} with a recap to celebrate everyone who participated.`}
          subHeaderActions={
            teamsWorkspace ? null : <ExampleLink onClick={() => sendExample({
              variables: {
                type: FeedbackRitualMessageType.recap, 
                workspaceId: workspace.id, 
                settings: {
                  dayOfPost: selectedDayOfPost,
                  kickoffMessage: newKickoffMessage,
                  mpvCoinsAwarded
                }
              }
            })} />
          }
        >
          {!isRecapActive && <Messages.RitualRecapOffMessage />}
          {isRecapActive ? (
            <>
              <Dropdown
                value={newRecapTime}
                items={formatDropdownOptions(getRitualCustomTimes(RITUAL_STAGES.RECAP, newReminderTime))}
                onChange={(_, { value: updatedRecapTime}) => {
                  mergeSettings({ 
                    newLastCallTime: getLastCallTime(updatedRecapTime),
                    newRecapTime: updatedRecapTime
                  });
                }}
                placeholder="Recap"
                size={Dropdown.sizes.M}
                showSelectedItemInMenu
              />
              <TimezoneTooltip />
              <ExcludeFromMVPSection 
                workspace={workspace}
              />
              {isRewardsActive && (
                <AllowanceBlock>
                  <Box>
                    <Typography variant='body1'><b>Coins awarded to Most Valuable Participant</b></Typography> 
                    {mpvCoinsAwarded || 0} Matter Coins = {(mpvCoinsAwarded || 0) / 10} USD
                  </Box>
                  <InputWrapper>
                    <ResetButton 
                      onClick={() => mergeSettings({ mpvCoinsAwarded: 50 })}
                    >
                    Reset
                    </ResetButton>
                    <StyledInput
                      type="number"
                      onChange={(_, target) => mergeSettings({ mpvCoinsAwarded: parseInt(target.value) })}
                      errorMessage={mpvCoinsHasError ? 'Must be between 0-100,000' : null}
                      showErrorMessageBelow
                      errorMessageClassName="allowanceInputError"
                      value={(mpvCoinsAwarded || 0).toString()} 
                    />
                  </InputWrapper>
                </AllowanceBlock>
              )}
            </>
          ): null }
        </FormattedGroup>
      </StyledPaper>
      {teamsWorkspace ? null : (
        <StyledPaper>
          <FormattedGroup
            isOff={!isStatsMessageActive}
            marginBottom='0px'
            header='Stats Message'
            headerActions={
              <Toggle
                checked={isStatsMessageActive}
                onChange={() => mergeSettings({ isStatsMessageActive: !isStatsMessageActive })}
              />
            }
            subHeader={(
              <>
              Recap of the kudos with the most emoji reactions. <br />
              Will be sent next business day (Mon - Fri) at 10:00 am.
              </>
            )}
            subHeaderActions={
              <ExampleLink onClick={() => sendExample({
                variables: {
                  type: FeedbackRitualMessageType.stats, 
                  workspaceId: workspace.id, 
                  settings: {
                    dayOfPost: selectedDayOfPost,
                    kickoffMessage: newKickoffMessage
                  }
                }
              })} />
            }
          >
          </FormattedGroup>
        </StyledPaper>
      )}
      <StyledPaper>
        <FormattedGroup
          header='Advanced Settings'
          marginBottom='0px'
        >
          <Typography variant='h5'>Ritual Name</Typography>
          <Typography variant='body1' sx={{ mt: 2 }}>This name will be used throughout your Matter experience.</Typography>
          <RitualNameWrapper>
            <StyledInput
              type='text'
              onChange={(_, target) => {
                const newSettings = { customRitualName: target.value };

                if (!isKickoffMessageCustom(feedbackRitualSettings.kickoffMessage, feedbackRitualSettings.customRitualName)) {
                  newSettings.newKickoffMessage = getDefaultKickoffMessage(selectedDayOfPost, target.value);
                }

                mergeSettings(newSettings);
              }}
              errorMessage={customRitualNameHasError ? 'Must be less or equal to 30 characters' : null}
              showErrorMessageBelow
              value={customRitualName || ritualName} 
            />
          </RitualNameWrapper>
          <Box sx={{ mt: 3 }}>
            <Typography variant='h5'>Notification Options</Typography>
            <ToggleWrapper>
              <ToggleContainer>
              Animated GIFs with Kickoff & Last Call Notifications
                <ToggleUnit 
                  checked={useGifs}
                  onChange={() => mergeSettings({ useGifs: !useGifs })}
                />
              </ToggleContainer>
              <ToggleContainer>
              Most Valuable Participant for Recap Notification
                <ToggleUnit 
                  checked={isMvpEnabled}
                  onChange={() => mergeSettings({ isMvpEnabled: !isMvpEnabled })}
                />
              </ToggleContainer>
            </ToggleWrapper>
          </Box>
        </FormattedGroup>
      </StyledPaper>
      <DisableModal
        isOpen={isDisableModalOpen}
        ritualName={customRitualName || ritualName}
        onClickCancel={() => setIsDisableModalOpen(false)}
        onClickDisable={() => {
          saveFeedbackRitualSettings({
            variables: {
              workspaceId: workspace.id,
              settings: {
                isActive: false
              }
            }
          });
          
          setIsDisableModalOpen(false);
        }}
      />
      <ChangeRitualFrequencyConfirmModal
        ritualDateString={nextFeedbackFriday?.dateString}
        ritualName={customRitualName || ritualName}
        isOpen={isFrequencyConfirmationModalOpen}
        onClickConfirm={() => {
          updateChangesConfirmed(true);
          setIsFrequencyConfirmationModalOpen(false);
        }}
        onClickCancel={() => {
          setIsFrequencyConfirmationModalOpen(false);
        }}
      />
      <SettingsFooter
        primaryLabel='Save'
        onClickPrimary={(e) => {
          if (ritualTimeSettingsChanged) {
            getNextFeedbackFriday({
              variables: {
                workspaceId: workspace.id,
                settings: {
                  frequency: selectedFrequency,
                  dayOfPost: selectedDayOfPost,
                  kickOffTime: newKickOffTime
                }
              }
            });
          } else {
            updateChangesConfirmed(true);
          }
        }}
        isSaving={isSaving || loadingNextFF}
        saveProps={{
          isLoading: isSaving || loadingNextFF
        }}
        canClickPrimary={canClickSave}
      />
    </Modal.Panel.Main>
  );
};
