import React, { useContext, useState, useEffect } from 'react';
import UserContext from 'context/UserContext/UserContext';
import styled from 'styled-components';
import { 
  Paper,
  Typography,
  Box
} from '@mui/material';
import imageSrc from './pulseLaunch.png';
import launchPulseMutation from 'graphql-queries/mutations/pulse/launchPulse';
import pulseSampleMutation from 'graphql-queries/mutations/pulse/pulseSampleQuery';
import getPulseProgress from 'graphql-queries/queries/analytics/getPulseProgress';
import { useMutation, useQuery } from '@apollo/client';
import { Button as MatterButton } from '@matterapp/matter-ui';
import Toast from 'components/Toast/Toast';
import ProgressLine from 'components/ProgressLine/ProgressLine';
import PulseUpgradeModal from 'modules/workspaces/shared/PulseUpgradeModal/PulseUpgradeModal';
import { useQueryParams } from 'hooks';

const LaunchImage = styled.img(({ theme }) => ({
  maxWidth: '900px',
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}));

const InactivePulseWrapper = styled(Paper)(({ theme }) => ({
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(1),
    marginBottom: '10px'
  }
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `0 ${theme.spacing(6)}`,
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    padding: `0 ${theme.spacing(2)}`
  }
}));

const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex', 
  flexDirection: 'row', 
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    '> button': {
      width: '100%',
      padding: theme.spacing(1)
    }
  }
}));

const LaunchButton = styled(MatterButton.Simple.Primary.Vivid)(({ theme }) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    marginBottom: theme.spacing(2)
  }
}));

const StyledMatterButton = styled(MatterButton)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    marginBottom: theme.spacing(2)
  }
}));

export default function SurveyLauncher() {
  const { currentTenant, refetchCurrentUser, currentWorkspace, scheduleCallParams } = useContext(UserContext);
  const { queryParams, setQueryParams } = useQueryParams();
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const { pulseSettings, hasPulse } = currentTenant || {};
  const { isPulseActive, isPulseUnlocked } = pulseSettings || {};
  const { data: pulseProgressData } = useQuery(getPulseProgress, {
    variables: {
      tenantId: currentTenant?.id
    },
    skip: isPulseUnlocked || !currentTenant?.id
  });
  const progress = pulseProgressData?.pulseProgress || 0;
  const [sendPulseSample] = useMutation(pulseSampleMutation);
  const [launchPulse, { loading: launchingPulse }] = useMutation(launchPulseMutation);

  useEffect(() => {
    if (queryParams?.launchPulse && currentTenant) {
      onLaunchPulse();
      setQueryParams({}, null, true);
    }
  }, [queryParams, currentTenant]);

  if (isPulseUnlocked) {
    return null;
  }

  async function onLaunchPulse() {
    if (hasPulse) {
      await launchPulse({
        variables: {
          tenantId: currentTenant.id
        }
      });

      refetchCurrentUser();

      Toast.success('Your pulse survey has been launched!');
    } else {
      setUpgradeModalOpen(true);
    }
  }

  if (!isPulseActive) {
    return (
      <InactivePulseWrapper elevation={1}>
        <Wrapper>
          <Typography variant='h4' component='p' sx={{ mb: 2 }}>Your Pulse Survey is Ready to Launch!</Typography>
          <Typography variant='body1' component='p'>
            Matter&apos;s science-driven Pulse Surveys gather feedback to measure employee sentiment and engagement. Receive weekly insights on company culture.
          </Typography>
        </Wrapper>
        <LaunchImage src={imageSrc} />
        <ButtonsWrapper>
          <LaunchButton
            size='M'
            color='blue'
            disabled={launchingPulse} 
            onClick={onLaunchPulse}
          >
            Launch Pulse Survey
          </LaunchButton> 
          <StyledMatterButton 
            size='M'
            color='black'
            onClick={async () => {
              await sendPulseSample({
                variables: {
                  tenantId: currentTenant.id
                }
              });

              Toast.success('Preview sent');
            }}
          >
            Preview Survey
          </StyledMatterButton> 
        </ButtonsWrapper>
        <PulseUpgradeModal 
          currentWorkspace={currentWorkspace}
          isOpen={upgradeModalOpen} 
          onClose={() => setUpgradeModalOpen(false)} 
          scheduleCallParams={scheduleCallParams}
        />
      </InactivePulseWrapper>
    );
  }
  
  return (
    <Paper elevation={1} sx={{ mb: 2, backgroundColor: '#4f52b1' }}>
      <Typography variant='h4' component='p' color='white.main' sx={{ mb: '5px' }}>Pulse Survey Launched!</Typography>
      <Typography variant='body1' component='p' color='white.main'>
        You can access your Pulse Survey scores once 3 members have completed the survey.
      </Typography>
      <Typography variant='body1' component='p' color='white.main' sx={{ mt: 2 }}>
        <i>Wondering Why?</i>
      </Typography>
      <Typography variant='body1' component='p' color='white.main'>
        Anonymity in Pulse Surveys make employees feel they can be honest with their responses. This will provide you with more meaningful and actionable insights to improve your organization&apos;s culture.
      </Typography>
      <ProgressLine 
        progress={progress}
        total={3}
        text='responses so far'
      />
    </Paper>
  );
}
