import React, { useState, useEffect } from 'react';
import { Advisors, Modal, Checkbox, Loader } from '@matterapp/matter-ui';
import { Group } from '../sharedConsts';
import { useMutation, useQuery } from '@apollo/client';
import updateCelebrationSettings from 'graphql-queries/mutations/celebrations/updateCelebrationSettings';
import sendCelebrationMessageSample from 'graphql-queries/mutations/celebrations/sendCelebrationMessageSample';
import getCelebrationSpreadsheet from 'graphql-queries/mutations/celebrations/getCelebrationSpreadsheet';
import sendCelebrationSpreadsheet from 'graphql-queries/mutations/celebrations/sendCelebrationSpreadsheet';
import getCelebrationSummary from 'graphql-queries/queries/celebrations/getCelebrationSummary';
import requestCelebrationEntry from 'graphql-queries/mutations/celebrations/requestCelebrationEntry';
import getMembers from 'graphql-queries/queries/workspace/getMembers';
import Toast from 'components/Toast/Toast';
import BirthdaySection from './BirthdaySection';
import AnniversariesSection from './AnniversariesSection';
import InlineCheckbox from 'components/Forms/InlineCheckbox';
import { 
  Example,
  LineWrapper,
  MainText,
  DropdownContainer
} from './styles';
import { 
  isCelebrationCoinsAmountValid,
  getInitialCoinsAmount
} from './helpers';
import moment from 'moment-timezone';
import { Typography, Box } from '@mui/material';
import { SettingsFooter } from '../styles';
import { FormattedGroup } from 'components/Group';

const SPREADSHEET_ID = 'spreadsheet';

async function downloadSpreadsheet(base64) {
  const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = 'celebrations.xlsx';

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(url);
}

const defaultBirthdayMessage = '🎉 Help me in celebrating {user}’s birthday! ({date}) 🎂🎈';
const defaultAnniversaryMessage = '🎉 Help me in celebrating {user}’s {number} year work anniversary! 🎈🏆';

const DELIVERY_OPTIONS = [{
  label: 'Send on Weekdays',
  value: false
},
{
  label: 'Send on Weekends (actual day)',
  value: true
}];

export default function CelebrationsSettings(props) {
  const { workspace, tenant } = props;
  const { id: workspaceId, celebrationSettings, slackWorkspace, tenantId, timezone } = workspace;
  const { rewardSettings } = tenant;
  const { isRewardsActive } = rewardSettings;
  const [isBirthdaysActive, updateBirthdays] = useState(celebrationSettings.isBirthdaysActive);
  const [autoCollectBirthdays, updateAutoCollectBirthdays] = useState(!!celebrationSettings.autoCollectBirthdays);
  const [autoCollectAnniversaries, updateAutoCollectAnniversaries] = useState(!!celebrationSettings.autoCollectAnniversaries);
  const [birthdayMessage, updateBirthdayMessage] = useState(celebrationSettings.birthdayMessage || defaultBirthdayMessage);
  const [anniversaryMessage, updateAnniversaryMessage] = useState(celebrationSettings.anniversaryMessage || defaultAnniversaryMessage);
  const [birthdayCoinsAwarded, updateBirthdayCoinsAwarded] = useState(getInitialCoinsAmount(celebrationSettings.birthdayCoinsAwarded));
  const [isAnniversariesActive, updateAnniversaries] = useState(celebrationSettings.isAnniversariesActive);
  const [isAnniversariesNotifyAll, updateAnniversariesNotifyAll] = useState(celebrationSettings.isAnniversariesNotifyAll);
  const [usersCanModifyCelebrationDates, updateUsersCanModifyCelebrationDates] = useState(celebrationSettings.usersCanModifyCelebrationDates);
  const [isBirthdaysNotifyAll, updateBirthdaysNotifyAll] = useState(celebrationSettings.isBirthdaysNotifyAll);
  const [anniversaryCoinsAwarded, updateAnniversaryCoinsAwarded] = useState(getInitialCoinsAmount(celebrationSettings.anniversaryCoinsAwarded));
  const [sendOnWeekendDays, updateSendOnWeekDays] = useState(!!celebrationSettings.sendOnWeekendDays);
  const coinsValuesAreValid = isCelebrationCoinsAmountValid(birthdayCoinsAwarded) && isCelebrationCoinsAmountValid(anniversaryCoinsAwarded);
  const autoCollectBirthdaysStartDate = moment(celebrationSettings.autoCollectBirthdaysStartDate || new Date()).format('MMMM DD YYYY');
  const autoCollectAnniversariesStartDate = moment(celebrationSettings.autoCollectAnniversariesStartDate || new Date()).format('MMMM DD YYYY');

  const hasChanges = isBirthdaysNotifyAll !== celebrationSettings.isBirthdaysNotifyAll
    || isAnniversariesNotifyAll !== celebrationSettings.isAnniversariesNotifyAll
    || anniversaryMessage !== celebrationSettings.anniversaryMessage
    || birthdayMessage !== celebrationSettings.birthdayMessage
    || autoCollectAnniversaries !== !!celebrationSettings.autoCollectAnniversaries
    || autoCollectBirthdays !== !!celebrationSettings.autoCollectBirthdays
    || isBirthdaysActive !== celebrationSettings.isBirthdaysActive 
    || isAnniversariesActive !== celebrationSettings.isAnniversariesActive
    || birthdayCoinsAwarded != celebrationSettings.birthdayCoinsAwarded
    || anniversaryCoinsAwarded != celebrationSettings.anniversaryCoinsAwarded
    || sendOnWeekendDays !== !!celebrationSettings.sendOnWeekendDays
    || usersCanModifyCelebrationDates !== celebrationSettings.usersCanModifyCelebrationDates;

  const canSave = coinsValuesAreValid && hasChanges;

  const [requestEntry, { data: entryData, loading: requestingEntry }] = useMutation(requestCelebrationEntry);
  const [requestCelebrationSpreadsheet, { data: spreadsheetData, loading: requestingSpreadsheet }] = useMutation(getCelebrationSpreadsheet);
  const [uploadCelebrationSpreadsheet, { data: uploadResult, loading: uploadingSpreadsheet }] = useMutation(sendCelebrationSpreadsheet);
  const [saveCelebrationSettings, { loading: savingCelebrationSettings, data }] = useMutation(updateCelebrationSettings);
  const [sendMessageSample] = useMutation(sendCelebrationMessageSample);
  const { data: celebrationData, loading: isLoadingSummary } = useQuery(getCelebrationSummary, {
    variables: {
      workspaceId
    },
    fetchPolicy: 'network-only'
  });

  const { workspace: workspaceWithCelebrationData } = celebrationData || {};

  useEffect(() => {
    if (data && !savingCelebrationSettings) {
      Toast.success('You successfully updated your Celebrations settings');
    }
  }, [savingCelebrationSettings, data]);

  useEffect(() => {
    if (spreadsheetData && !requestingSpreadsheet) {
      downloadSpreadsheet(spreadsheetData.getCelebrationSpreadsheet);
      Toast.success('Check downloaded spreadsheet for instructions.');
    }
  }, [requestingSpreadsheet, spreadsheetData]);

  useEffect(() => {
    if (uploadResult && !uploadingSpreadsheet) {
      const { processed = 0, ok, errorMessage } = uploadResult.sendCelebrationSpreadsheet || {};
      
      if (ok) {
        let message = 'All members are up-to-date';
        if (processed === 1) {
          message = `One member was updated 🥳`;
        } else if (processed > 1) {
          message = `${uploadResult.sendCelebrationSpreadsheet.processed} members have been updated 🥳`;
        }
  
        Toast.success(message);
      } else {
        Toast.error(errorMessage);
      }
    }
  }, [uploadingSpreadsheet, uploadResult]);

  useEffect(() => {
    if (entryData && !requestingEntry) {
      const { requestCelebrationEntry } = entryData;
      let successMessage = requestCelebrationEntry === 1 
        ? `Success! ${requestCelebrationEntry} member has been asked.`
        : `Success! ${requestCelebrationEntry} members have been asked.` ;

      if (requestCelebrationEntry === 0) {
        successMessage = 'There\'s no one left to ask.';
      }

      Toast.success(successMessage);
    }
  }, [entryData, requestingEntry]);

  if (!workspace) {
    return null;
  }

  if (isLoadingSummary) {
    return <Loader />;
  }

  function readFile() {
    const file = document.getElementById(SPREADSHEET_ID).files[0];
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      uploadCelebrationSpreadsheet(({
        variables: {
          workspaceId,
          data: reader.result
        },
        refetchQueries: [{
          query: getCelebrationSummary,
          variables: { workspaceId }
        },
        {
          query: getMembers,
          variables: { tenantId }
        }
        ]
      }));
    }, false);
  
    if (file) {
      reader.readAsDataURL(file);
      document.getElementById(SPREADSHEET_ID).value = null;
    }
  }

  function onUploadClick() {
    const input = document.getElementById(SPREADSHEET_ID);
    input.click();
  }

  const traningLink = slackWorkspace 
    ? <a href='https://matterapp.com/training/celebrations-slack' target='_blank' rel='noopener noreferrer'>Training Video</a>
    : <a href='https://matterapp.com/training/celebrations-teams' target='_blank' rel='noopener noreferrer'>Training Video</a>;

  return (
    <Modal.Panel.Main
      header='Celebrations'
      footer={
        <SettingsFooter
          primaryLabel="Save"
          onClickPrimary={() => {
            saveCelebrationSettings({
              variables: {
                workspaceId,
                settings: {
                  isBirthdaysActive,
                  birthdayCoinsAwarded: parseInt(birthdayCoinsAwarded) || 0,
                  anniversaryCoinsAwarded: parseInt(anniversaryCoinsAwarded) || 0,
                  isAnniversariesActive,
                  autoCollectBirthdays,
                  autoCollectAnniversaries,
                  birthdayMessage,
                  anniversaryMessage,
                  isBirthdaysNotifyAll,
                  isAnniversariesNotifyAll,
                  sendOnWeekendDays,
                  usersCanModifyCelebrationDates
                }
              }
            });
          }}
          canClickPrimary={canSave && !savingCelebrationSettings}
        />
      }
    >
      <Typography variant='body1' sx={{ mb: 3, mt: -2 }}>
        Configure Celebrations settings for your organization. To learn more, watch the {traningLink} or visit the
        <a href='https://help.matterapp.com/en/collections/3984355-celebrations' target='_blank' rel='noopener noreferrer'> Help Center</a>.
      </Typography>
      <Group 
        dividerBelow 
        header='General Settings'
      >
        <Typography variant='body1' sx={{ mb: 3, mt: '-10px' }}>
          These settings apply to Birthdays and Work Anniversaries.
        </Typography>
        <Box>
          <Typography variant='h5' component='p'>
            Celebrations Delivery
          </Typography>
          <Typography variant='body2' component='p' sx={{ mt: 1 }}>
            Shared at 9:00 am, {moment(new Date()).tz(timezone).format('z')}.
          </Typography>
          <DropdownContainer>
            <Advisors.RecurringModal.Dropdown
              options={DELIVERY_OPTIONS}
              selectedOption={sendOnWeekendDays ? DELIVERY_OPTIONS[1].label : DELIVERY_OPTIONS[0].label}
              handleChangeSelection={(choice) => {
                updateSendOnWeekDays(choice.value);
              }}
              width='100%'
              position={'absolute'}
            />
          </DropdownContainer>
          <Typography variant='body2' component='p' sx={{ mt: 2 }}>
            {sendOnWeekendDays 
              ? 'Celebrations that occur on a weekend will be shared on the actual day.'
              : 'Celebrations that occur on a weekend will be shared earlier, on Friday.'
            }
          </Typography>
        </Box>
      </Group>
      <FormattedGroup 
        dividerBelow 
        header='Birthdays'
        headerActions={
          <Checkbox.Toggle
            size={Checkbox.Toggle.sizes.S}
            checked={isBirthdaysActive}
            onChange={() => {
              updateBirthdays(!isBirthdaysActive);
            }}
          />
        }
      >
        <LineWrapper>
          {isBirthdaysActive ? (
            <MainText>Shared at 9:00 am.</MainText>
          ) : (
            <MainText>Your channel does not have birthdays enabled. Enable birthdays to celebrate your team members&apos; special day. <span role="img" aria-label="birthday cake">🎂</span></MainText>
          )}
          <Example onClick={async () => {
            await sendMessageSample({
              variables: {
                workspaceId,
                type: 'birthday'
              }
            });

            Toast.success('Preview sent to your channel!');
          }}>See preview</Example>
        </LineWrapper>
        <BirthdaySection 
          isActive={isBirthdaysActive} 
          isRewardsActive={isRewardsActive}
          celebrationSummary={workspaceWithCelebrationData} 
          birthdayCoinsAwarded={birthdayCoinsAwarded} 
          updateBirthdayCoinsAwarded={updateBirthdayCoinsAwarded}
          hasError={!isCelebrationCoinsAmountValid(birthdayCoinsAwarded)}
          autoCollectBirthdays={autoCollectBirthdays} 
          autoCollectBirthdaysStartDate={autoCollectBirthdaysStartDate}
          updateAutoCollectBirthdays={updateAutoCollectBirthdays}
          defaultBirthdayMessage={defaultBirthdayMessage}
          birthdayMessage={birthdayMessage}
          updateBirthdayMessage={updateBirthdayMessage}
          isSlack={!!slackWorkspace}
          isBirthdaysNotifyAll={isBirthdaysNotifyAll}
          updateBirthdaysNotifyAll={updateBirthdaysNotifyAll}
          requestBirthdayEntry={() => {
            requestEntry({
              variables: {
                workspaceId,
                type: 'birthday'
              }
            });
          }}
          onUploadClick={onUploadClick}
          requestCelebrationSpreadsheet={() => {
            requestCelebrationSpreadsheet({
              variables: {
                workspaceId
              }
            });
          }}
        />
      </FormattedGroup>
      <FormattedGroup 
        header='Work Anniversaries'
        headerActions={
          <Checkbox.Toggle
            size={Checkbox.Toggle.sizes.S}
            checked={isAnniversariesActive}
            onChange={() => {
              updateAnniversaries(!isAnniversariesActive);
            }}
          />
        }
      >
        <LineWrapper>
          {isAnniversariesActive ? (
            <MainText>Shared at 9:00 am.</MainText>
          ) : (
            <MainText>Your channel does not have work anniversaries enabled. Enable work anniversaries to celebrate your team members&apos; milestone. <span role="img" aria-label="trophy">🏆</span></MainText>
          )}
          <Example onClick={async () => {
            await sendMessageSample({
              variables: {
                workspaceId,
                type: 'anniversary'
              }
            });
            
            Toast.success('Preview sent to your channel!');
          }}>See preview</Example>
        </LineWrapper>
        <AnniversariesSection 
          isActive={isAnniversariesActive} 
          isRewardsActive={isRewardsActive}
          celebrationSummary={workspaceWithCelebrationData}
          anniversaryCoinsAwarded={anniversaryCoinsAwarded} 
          updateAnniversaryCoinsAwarded={updateAnniversaryCoinsAwarded}
          hasError={!isCelebrationCoinsAmountValid(anniversaryCoinsAwarded)}
          updateAutoCollectAnniversaries={updateAutoCollectAnniversaries}
          autoCollectAnniversaries={autoCollectAnniversaries}
          defaultAnniversaryMessage={defaultAnniversaryMessage}
          anniversaryMessage={anniversaryMessage} 
          updateAnniversaryMessage={updateAnniversaryMessage}
          isSlack={!!slackWorkspace}
          isAnniversariesNotifyAll={isAnniversariesNotifyAll}
          updateAnniversariesNotifyAll={updateAnniversariesNotifyAll}
          autoCollectAnniversariesStartDate={autoCollectAnniversariesStartDate}
          saveCelebrationSettings={saveCelebrationSettings}
          anniversaryCustomAwards={celebrationSettings.anniversaryCustomAwards}
          workspaceId={workspaceId}
          requestAnniversaryEntry={() => {
            requestEntry({
              variables: {
                workspaceId,
                type: 'anniversary'
              }
            });
          }} 
          onUploadClick={onUploadClick}
          requestCelebrationSpreadsheet={() => {
            requestCelebrationSpreadsheet({
              variables: {
                workspaceId
              }
            });
          }}
        />
      </FormattedGroup>

      <FormattedGroup 
        dividerBelow 
        header='Advanced Settings'
      >
        <Typography variant='body1' sx={{ mb: 3 }}>
          These settings apply to Birthdays and Work Anniversaries.
        </Typography>
        <LineWrapper>
          <InlineCheckbox 
            text={'Allow members to modify their celebration dates'}
            onChange={() => updateUsersCanModifyCelebrationDates(!usersCanModifyCelebrationDates)}
            isChecked={usersCanModifyCelebrationDates}
            checkboxName='usersCanModifyCelebrationDates'
          />
        </LineWrapper>
      </FormattedGroup>
      
      <input 
        type="file"
        style={{ display: 'none' }}
        id={SPREADSHEET_ID} 
        name="celebrations"
        onChange={readFile}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
    </Modal.Panel.Main>
  );
};
