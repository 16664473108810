import React from 'react';
import { Advisors, Input } from '@matterapp/matter-ui';
import { Divider, Typography, Box } from '@mui/material';
import { ACTIONS } from './reducer';
import { DropdownWrapper, InputWrapper, DividerWrapper, LogicOperator } from './styles';
import { 
  ETHNICITY_OPTIONS,
  GENDER_OPTIONS
} from 'routes/pages/settings/MemberProperties/helpers';
import { OPERATORS } from './helpers';
import IsBetweenInput from './IsBetweenInput';

function getValueOptions(property) {
  const { value, options } = property || {};

  if (options) {
    return options.filter(Boolean).map((option) => ({
      label: option,
      value: option
    }));
  }
  
  if (value === 'ethnicity') {
    return ETHNICITY_OPTIONS;
  } else if (value === 'gender') {
    return GENDER_OPTIONS;
  }

  return [];
}

export default function RuleBreakdown({ 
  rule, 
  rules,
  showDivider, 
  index, 
  operators,
  properties,
  dispatch,
  applyRules
}) {
  const { propertyName, operator, values } = rule;
  const selectedProperty = properties.find(({ value }) => value === propertyName);
  const canDelete = rules.length > 1;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography variant='body1' sx={{ fontWeight: 'bold', mb: '8px!important' }}>
          Rule #{index + 1}
        </Typography>
        {canDelete && (
          <Typography 
            variant='body2' 
            color='reds.60' 
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch({
                type: ACTIONS.DELETE_RULE,
                payload: { index }
              });
            }}
          >
            Delete
          </Typography>
        )}
      </Box>
      <DropdownWrapper>
        <Advisors.RecurringModal.Dropdown
          options={[...properties].sort((a, b) => a.label.localeCompare(b.label))}
          selectedOption={selectedProperty || {
            label: 'Choose Property',
            value: ''
          }}
          handleChangeSelection={(choice) => {
            dispatch({ type: ACTIONS.UPDATE_PROPERTY_NAME, payload: { index, propertyName: choice.value } });
          }}
          width='100%'
          position={'absolute'}
        />
      </DropdownWrapper>
      <DropdownWrapper>
        <Advisors.RecurringModal.Dropdown
          options={operators}
          selectedOption={operators.find(({ value }) => value === operator) || {
            label: 'Choose a Condition',
            value: ''
          }}
          handleChangeSelection={(choice) => {
            dispatch({ type: ACTIONS.UPDATE_OPERATOR, payload: { index, operator: choice.value } });
          }}
          disabled={!propertyName}
          width='100%'
          position={'absolute'}
        />
      </DropdownWrapper>
      {!selectedProperty || selectedProperty.type === 'choices' ? (
        <DropdownWrapper>
          <Advisors.RecurringModal.Dropdown
            options={getValueOptions(selectedProperty)}
            selectedOption={getValueOptions(selectedProperty).find(({ value }) => values.includes(value)) || {
              label: 'Choose a Value',
              value: ''
            }}
            handleChangeSelection={(choice) => {
              dispatch({ type: ACTIONS.UPDATE_SELECTED_VALUES, payload: { index, values: [choice.value] } });
            }}
            disabled={!operator}
            width='100%'
            position={'absolute'}
          />
        </DropdownWrapper>
      ) : null}
      {selectedProperty?.type === 'numeric' && operator !== OPERATORS.IS_BETWEEN && (
        <InputWrapper>
          <Input
            type="number"
            onChange={(e) => dispatch({ type: ACTIONS.UPDATE_SELECTED_VALUES, payload: { index, values: [e.target.value] } })}
            value={values[0]} 
            disabled={!operator}
          />
        </InputWrapper>
      )}
      {selectedProperty?.type === 'date' && operator !== OPERATORS.IS_BETWEEN && (
        <InputWrapper>
          <Input
            type="date"
            onChange={(e) => dispatch({ type: ACTIONS.UPDATE_SELECTED_VALUES, payload: { index, values: [e.target.value] } })}
            value={values[0]} 
            disabled={!operator}
          />
        </InputWrapper>
      )}
      {operator === OPERATORS.IS_BETWEEN && (
        <IsBetweenInput 
          rule={rule}
          index={index}
          type={selectedProperty?.type}
          dispatch={dispatch}
        />
      )}
      {showDivider && (
        <DividerWrapper>
          <Divider />
          <LogicOperator>
            {applyRules === 'all' ? 'AND' : 'OR'}
          </LogicOperator>
        </DividerWrapper>
      )}
    </Box>
  );
}
