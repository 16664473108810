import React, { useEffect, useState, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import MobileMenuContext from 'context/MobileMenuContext/MobileMenuContext';
import { useQuery } from '@apollo/client';
import { AppLayout } from 'modules';
import MainColumn from './MainColumn';
import { dateFilters } from 'components/DateFilterBar/defaultFilters';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';
import moment from 'moment';
import getWorkspaceAnalytics from 'graphql-queries/queries/analytics/getWorkspaceAnalytics';
import getWorkspaceMemberAnalytics from 'graphql-queries/queries/analytics/getWorkspaceMemberAnalytics';
import PulseFiltersContext from 'context/PulseFiltersContext/PulseFiltersContext';

const LIMIT = 20;

function buildCustomDateRange(from, to) {
  const fromDate = moment(from);
  const toDate = moment(to);
  const diff = toDate.diff(fromDate, 'days');
  let groupedBy = 'GROUPED BY DAY';
  let groupBy = 'day';
  let xAxisResolution = 7;

  if (diff > 14 && diff < 60) {
    groupedBy = 'GROUPED BY WEEK';
    groupBy = 'week';
    xAxisResolution = 8;
  } else if (diff >= 60 && diff < 90) {
    groupedBy = 'GROUPED BY WEEK';
    groupBy = 'week';
    xAxisResolution = 10;
  } else if (diff >= 90) {
    groupedBy = 'GROUPED BY MONTH';
    groupBy = 'month';
    xAxisResolution = 10;
  }

  return {
    label: 'custom',
    value: 'custom',
    startDate: fromDate,
    endDate: toDate,
    groupedBy,
    groupBy,
    xAxisResolution
  };
}

function AnalyticsPage() {
  const navigate = useNavigate();
  const { currentWorkspaceId: workspaceId, currentTenant } = useContext(UserContext);
  const { setTopNavItem } = useContext(MobileMenuContext);
  const [offset, setOffset] = useState(0);
  const [memberRows, setMemberRows] = useState([]);

  const { 
    selectedFilter, 
    setSelectedFilter, 
    xAxisMaxLabels, 
    setXAxisMaxLabels, 
    segmentIds, 
    handleSegmentChange,
    teamIds,
    handleTeamChange,
    selectedWorkspaceIds,
    handleWorkspaceChange,
    resetFilters
  } = useContext(PulseFiltersContext);

  const { startDate, endDate, groupBy } = selectedFilter;

  const { loading: workspaceMemberAnalyticsLoading, data } = useQuery(getWorkspaceMemberAnalytics, {
    variables: {
      workspaceIds: selectedWorkspaceIds,
      tenantId: currentTenant?.id,
      startDate: startDate.format('YYYY-MM-DD'), 
      endDate: endDate.format('YYYY-MM-DD'),
      offset,
      limit: LIMIT,
      segmentIds,
      teamIds
    },
    skip: !currentTenant
  });
  
  const workspaceMemberAnalyticsData = data?.workspaceMemberAnalytics || [];

  const { data: workspaceAnalyticsData } = useQuery(getWorkspaceAnalytics, {
    variables: {
      workspaceIds: selectedWorkspaceIds,
      tenantId: currentTenant?.id,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      groupBy,
      segmentIds,
      teamIds
    },
    skip: !currentTenant
  });

  const hasMoreMembers = workspaceMemberAnalyticsData.length === LIMIT;

  function onLoadMore() {
    if (hasMoreMembers) {
      setOffset(memberRows.length);
    }
  }

  useEffect(() => {
    setTopNavItem(null);
    resetFilters();
  }, []);

  useEffect(() => {
    if (data?.workspaceMemberAnalytics && !workspaceMemberAnalyticsLoading) {
      if (offset === 0) {
        setMemberRows(data?.workspaceMemberAnalytics);
      } else {
        setMemberRows([...memberRows, ...data?.workspaceMemberAnalytics]);
      }
    }
  }, [data, workspaceMemberAnalyticsLoading]);

  const handleChangeFilter = (value, isCustom) => {
    let selectedValue;

    if (isCustom) {
      selectedValue = buildCustomDateRange(value.from, value.to);
    } else {
      selectedValue = dateFilters.find((filter) => filter.value === value);
    }
    
    if (startDate.format('YYYY-MM-DD') === selectedValue.startDate.format('YYYY-MM-DD') && endDate.format('YYYY-MM-DD') === selectedValue.endDate.format('YYYY-MM-DD')) {
      return;
    }

    setMemberRows([]);
    setOffset(0);
    setSelectedFilter(selectedValue);
    setXAxisMaxLabels(selectedValue.xAxisResolution);
  };

  return (
    <AppLayout
      headerProps={{
        onClickUpgrade: () => {
          navigate(
            Resources.workspaceDashboard.path({ workspaceId }, {
              settings: 'show',
              section: 'billing',
            }), { replace: true }
          );
        }
      }}
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={
        <MainColumn
          tenantId={currentTenant?.id}
          workspaceAnalyticsData={workspaceAnalyticsData?.workspaceAnalytics || {}}
          workspaceMemberAnalyticsData={memberRows}
          showSpinner={workspaceMemberAnalyticsLoading}
          onLoadMore={onLoadMore}
          selectedFilter={selectedFilter}
          xAxisMaxLabels={xAxisMaxLabels}
          handleChangeFilter={handleChangeFilter}
          handleSegmentChange={handleSegmentChange}
          selectedSegmentIds={segmentIds}
          isLoading={!currentTenant}
          workspaceId={workspaceId}
          handleTeamChange={handleTeamChange}
          selectedTeamIds={teamIds}
          handleChannelChange={handleWorkspaceChange}
          selectedWorkspaceIds={selectedWorkspaceIds}
        />
      }
    />
  );
}

export default AnalyticsPage;
