import React from 'react';
import styled from 'styled-components';
import { 
  Typography,
  Box
} from '@mui/material';
import { Pie } from 'react-chartjs-2';
import theme from '@matterapp/matter-theme';
import { CategoryPaper } from './styles';
import { 
  Button
} from '@matterapp/matter-ui'; 
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';

const PieWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '184px',
  marginLeft: theme.spacing(4),
  [theme.breakpoints.down('lg')]: {
    marginLeft: 0,
    marginTop: theme.spacing(4),
    flexDirection: 'column',
    height: 'auto'
  }
}));

const BlankPie = styled(Box)(({ theme }) => ({
  width: '184px',
  height: '184px',
  borderRadius: '100%',
  backgroundColor: theme.palette.blacks[20]
}));

const CanvasWrapper = styled(Box)(({ theme }) => ({
  padding: 0,
  marginLeft: theme.spacing(5),
  marginRight: theme.spacing(4),
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: 0
  }
}));

const DataWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(6),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column'
  }
}));

const Legend = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4)
  }
}));

const LegendRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '200px',
  marginBottom: '8px'
});

const LegendColorBox = styled(Box)({
  width: '8px',
  height: '40px',
  borderRadius: '8px',
  marginRight: '8px'
}); 

const LegendText = styled(Typography)
  .withConfig({ shouldForwardProp: (prop) => {
    return prop !== 'hasScore';
  } })
  (({ theme, hasScore }) => ({
    fontWeight: 'bold', 
    color: hasScore ? theme.palette.blacks[80] : theme.palette.blacks[20],
    flexBasis: '80%'
  }));

const EnpsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
  textAlign: 'center',
  width: '300px',
  [theme.breakpoints.down('lg')]:{
    width: '100%'
  }
}));

const DetailsButton = styled(Button)(({ theme }) => ({
  alignSelf: 'flex-end',
  marginTop: theme.spacing(7),
  width: '300px',
  [theme.breakpoints.down('lg')]:{
    alignSelf: 'center',
  }
}));


export default function EnpsScore({ 
  analyticsData,
  workspaceId,
  sx = {},
  showDetailsButton
}) {
  const { 
    enpsScore,
    enpsExplanation,
    enpsDetails
  } = analyticsData || {};
  const {
    promoters,
    detractors,
    passives,
    skipped
  } = enpsDetails || {};
  const navigate = useNavigate();

  const hasScore = enpsScore !== null;
  const pieData = hasScore 
    ? [
      promoters,
      detractors,
      passives,
      skipped
    ] 
    : [100];

  const backgroundColor = hasScore 
    ? [
      theme.colors.greens[40],
      theme.colors.reds[40],
      theme.colors.yellows[40],
      theme.colors.blacks[40]
    ] : [
      theme.colors.blacks[20],
      theme.colors.blacks[20],
      theme.colors.blacks[20],
      theme.colors.blacks[20]
    ];

  const labels = hasScore 
    ? [
      'Promoters',
      'Detractors',
      'Passives',
      'Skipped'
    ] : [
      'No score yet'
    ];

  return (
    <CategoryPaper elevation={1} sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
      <Typography variant='h4' component='p' sx={{mb: '5px' }}>Employee Net Promoter Score (eNPS)</Typography>
      <Typography variant='body1' component='p' sx={{ color: 'blacks.50' }}>
        How likely people are to recommend your organization as a place to work.
      </Typography>
      <DataWrapper>
        <EnpsWrapper>
          <Typography variant='body1' color="grey.light">
            eNPS score
          </Typography>
          <Typography 
            sx={{
              fontSize: '64px',
              fontWeight: 'bold',
              mt: 4.5,
              mb: 4.5
            }}
            component="span" 
            color={hasScore ? 'black' : 'blacks.50'}
          >
            {hasScore ? enpsScore : '-'}
          </Typography>
          <Typography 
            sx={{
              fontSize: '32px',
              lineHeight: '32px',
              fontWeight: 'bold'
            }}
            component="span" 
            color="black"
          >
            {enpsExplanation || 'No score yet'}
          </Typography>
        </EnpsWrapper>
        <PieWrapper>
          <CanvasWrapper>
            {hasScore ? (
              <Pie
                width={184}
                height={184}
                options={{
                  legend: {
                    display: false
                  }
                }}
                data={{  
                  labels,
                  datasets: [{
                    label: 'eNps score',
                    data: pieData,
                    backgroundColor,
                    hoverOffset: 4
                  }]
                }}
              />
            ): <BlankPie /> }
          </CanvasWrapper>
          <Legend>
            <LegendRow>
              <LegendColorBox sx={{ backgroundColor: backgroundColor[0] }} />
              <LegendText hasScore={hasScore} variant='body2'>
                Promoters
              </LegendText>
              <Typography variant='body2' sx={{ color: 'blacks.80' }}>
                {promoters}
              </Typography>
            </LegendRow>
            <LegendRow>
              <LegendColorBox sx={{ backgroundColor: backgroundColor[1] }} />
              <LegendText variant='body2' hasScore={hasScore}>
                Detractors
              </LegendText>
              <Typography variant='body2' sx={{ color: 'blacks.80' }}>
                {detractors}
              </Typography>
            </LegendRow>
            <LegendRow>
              <LegendColorBox sx={{ backgroundColor: backgroundColor[2] }} />
              <LegendText variant='body2' hasScore={hasScore}>
                Passives
              </LegendText>
              <Typography variant='body2' sx={{ color: 'blacks.80' }}>
                {passives}
              </Typography>
            </LegendRow>
            <LegendRow>
              <LegendColorBox sx={{ backgroundColor: backgroundColor[3] }} />
              <LegendText variant='body2' hasScore={hasScore}>
                Skipped
              </LegendText>
              <Typography variant='body2' sx={{ color: 'blacks.80' }}>
                {skipped}
              </Typography>
            </LegendRow>
          </Legend>
        </PieWrapper>
      </DataWrapper>
      {showDetailsButton && hasScore && (
        <DetailsButton onClick={() => {
          navigate(Resources.pulseEnpsDetails.path({ workspaceId }));
        }}>
          View Details
        </DetailsButton>
      )}
    </CategoryPaper>
  );
}
