import React, { useContext } from 'react';
import { 
  SettingsContainer
} from '../styles';
import AdminsSettingsHoc from '../AdminsSettingsHoc';
import UserContext from 'context/UserContext/UserContext';
import { AppLayout } from 'modules';
import { Resources } from '@matterapp/routing';
import DataPropertiesPage from './DataProperties';
import SettingsNavigation from '../SettingsNavigation';
import { useQueryParams } from 'hooks';
import { StyledPaper } from './styles';

export default function DataProperties() {  
  const { currentWorkspaceId: workspaceId, currentWorkspace, isLoadingCurrentUser } = useContext(UserContext);
  const isLoading = !currentWorkspace || isLoadingCurrentUser;
  const { queryParams } = useQueryParams();
  const settingsRedirectUrl = queryParams.isChannelSetting 
    ? Resources.workspaceSettingsMain.path({ workspaceId }, { isChannelSetting: true }) 
    : Resources.workspaceSettingsMain.path({ workspaceId });

  return (
    <AppLayout
      layout={AppLayout.Layouts.Main}
      showRightSideColumn={false}
      mainColumn={(
        <AdminsSettingsHoc redirectUrl={settingsRedirectUrl}>
          <SettingsContainer sx={{ maxWidth: '100%!important' }}>
            <SettingsNavigation 
              currentLabel='Member Properties'
              settingsRedirectUrl={settingsRedirectUrl}
            />
            {isLoading 
              ? null 
              : (
                <StyledPaper elevation={0}>
                  <DataPropertiesPage />
                </StyledPaper>
              )}
          </SettingsContainer>
        </AdminsSettingsHoc>
      )}
    />
  );
}
