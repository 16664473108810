import React from 'react';
import { StyledPaper, Header } from './styles';
import { Typography } from '@mui/material';
import { Button } from '@matterapp/matter-ui';

export default function SettingsHeader({
  headerText,
  isLoading,
  onDisable,
  text,
  trainingLink,
  helpCenterLink,
  disabled,
  disabledComponent
}) {
  
  if (disabled && disabledComponent) {
    return (
      <StyledPaper>
        <Header>
          <Typography variant='h3'>{headerText}</Typography>
        </Header>
        {disabledComponent}
      </StyledPaper>
    );
  }

  return (
    <StyledPaper>
      <Header>
        <Typography variant='h3'>{headerText}</Typography>
        <Button 
          size={'S'} 
          color={'black'} 
          loading={isLoading} 
          onClick={onDisable}
        >
          Disable
        </Button>
      </Header>
      <Typography variant='body1'>
        {text}
        {' '}To learn more, watch the {trainingLink} or visit the <a href={helpCenterLink} target='_blank' rel='noopener noreferrer'>Help Center</a>.
      </Typography>
    </StyledPaper>
  );
}
