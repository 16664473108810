import { StyledTableRow } from '../styles';
import styled from 'styled-components';
import { Button } from '@matterapp/matter-ui'; 
import { 
  Box,
  TableCell,
} from '@mui/material';

export const StyledTableCell = styled(TableCell)
  .withConfig({ shouldForwardProp: (prop) => {
    return prop !== 'visibleOnMobile';
  } })
  (({ theme, visibleOnMobile }) => ({
    [theme.breakpoints.down('md')]: {
      display: visibleOnMobile ? 'table-cell' : 'none'
    }
  }));

export const TableRow = styled(StyledTableRow)(({ theme }) => ({
  'th, td': {
    padding: theme.spacing(1.5),
    '&:first-of-type': {
      width: '80px',
      padding: 0
    },
    '&:nth-child(2)': {
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '80%'
      }
    },
    '&:nth-child(3)': {
      width: '20%'
    },
    '&:last-of-type': {
      width: '70px',
      padding: 0
    },
  }
}));

export const HeaderTableRow = styled(TableRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.blacks[20]}`,
  th: {
    color: theme.palette.blacks[60],
    '&:nth-child(2)': {
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  minHeight: 'auto',
  fontSize: '14px'
}));

export const CategoryHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
    marginBottom: 0,
    padding: theme.spacing(2)
  }
}));

export const ToggleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.blacks[5],
  width: '100%',
  padding: theme.spacing(2),
  margin: `${theme.spacing(2)} 0`
}));
