import React, { useReducer, useState, useEffect, useRef } from 'react';
import { Box, Icon, Typography, Fade } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import saveSurveyMutation from 'graphql-queries/mutations/surveys/saveCustomSurvey';
import sendCustomSurveySampleMutation from 'graphql-queries/mutations/surveys/sendCustomSurveySample';
import launchCustomSurveyMutation from 'graphql-queries/mutations/surveys/launchCustomSurvey';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import EditIcon from './EditIcon';
import ScheduleIcon from '@mui/icons-material/CalendarMonth';
import BuilderIcon from './BuilderIcon';
import AudienceIcon from './AudienceIcon';
import WrapUpIcon from '@mui/icons-material/RocketLaunch';
import reducer, { getInitialState, ACTIONS, getStateTransferObject } from './reducer';
import { debounce, once } from 'lodash';
import { useMutation } from '@apollo/client';
import { SCREEN_NAMES, SCREENS, isBuilderScreenCompleted } from './consts';
import Toast from 'components/Toast/Toast';
import { Tooltip } from '@matterapp/matter-ui';
import { useParams, useNavigate } from 'react-router-dom';
import {
  StyledModal,
  SaveNotification,
  Content,
  Header,
  Footer,
  ProgressBar,
  MobileProgressBar,
  ProgressElement,
  CloseButton,
  Body,
  BodyInternalWrapper,
  ContinueButton,
  PreviewButton,
  SaveWrapper
} from './styles';
import { Resources } from '@matterapp/routing';
import { FILTERS } from '../useSurveyMenu';
import moment from 'moment-timezone';
import { useQueryParams } from 'hooks';

function getNextScreenName(currentScreenName) {
  const screenNames = Object.keys(SCREENS);
  const currentIndex = screenNames.indexOf(currentScreenName);

  if (currentIndex === screenNames.length - 1) {
    return null;
  }

  const nextIndex = currentIndex + 1;

  return screenNames[nextIndex];
}

function getLastSaveTimeText(lastSaveTime) {
  let saveDiff = moment().diff(lastSaveTime, 'seconds');
  let lastSaveTimeText = 'seconds ago';

  if (saveDiff > 60) {
    saveDiff = moment().diff(lastSaveTime, 'minutes');
    lastSaveTimeText = `${saveDiff} minute${saveDiff > 1 ? 's' : ''} ago`;
  }

  return lastSaveTimeText;
}

function SaveIndicator({ isSaving }) {
  const [showSaving, setShowSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [lastSaveTime, setLastSaveTime] = useState(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useEffect(() => {
    if (isSaving) {
      setShowSaving(true);
      setSaved(true);
      setLastSaveTime(new Date());
    } else {
      setTimeout(() => {
        setShowSaving(false);
      }, 1000);
    }
  }, [isSaving]);

  return (
    <Tooltip
      style={{ left: '120px' }}
      isOpen={isTooltipOpen}
      onClickOutside={() => setIsTooltipOpen(false)}
      triggerProps={{
        onBlur: () => setIsTooltipOpen(false),
        onClick: () => setIsTooltipOpen(!isTooltipOpen),
        onMouseEnter: () => setIsTooltipOpen(true),
        onMouseLeave: () => setIsTooltipOpen(false),
      }}
      content={
        <Box sx={{ padding: 2 }}>
          <Typography variant='body1'>
            All changes are saved automatically. {lastSaveTime && `Last edit was ${getLastSaveTimeText(lastSaveTime)}.`}
          </Typography>
        </Box>
      }
    >
      <SaveWrapper>
        <Fade in={saved && !showSaving} sx={{ position: 'absolute' }}>
          <SaveNotification 
            variant='body1' 
            component='p'
            timeout={1000}
            data-rc='save-notification'
          >
            All changes saved
          </SaveNotification>
        </Fade>
        <Fade in={showSaving} sx={{ position: 'absolute' }}>
          <SaveNotification 
            variant='body1' 
            component='p'
            timeout={1000}
          >
            Saving...
          </SaveNotification>
        </Fade>
      </SaveWrapper>
    </Tooltip>
  );
}

const debouncedSave = debounce(async (variables, query, callback) => {
  await query({
    variables
  });

  callback();
}, 250); 

let handleFirstSave;

export default function SurveyEditorModal(props) {
  const { isOpen, onClose, tenantId, survey, workspaceId, timezone, isRewardsActive, isAdminMember, isSlack, matterTeams } = props;
  const rewardsActive = isRewardsActive && isAdminMember;
  const params = useParams();
  const { queryParams } = useQueryParams();
  const { step: currentStep } = params;

  const [state, dispatch] = useReducer(reducer, getInitialState(survey || {}, survey?.timezone || timezone, rewardsActive, currentStep));
  const [saveSurvey, { loading: savingQuestion, data: savedSurveyData }] = useMutation(saveSurveyMutation);
  const [launchCustomSurvey, { loading: launchingSurvey }] = useMutation(launchCustomSurveyMutation);
  const [sendCustomSurveySample] = useMutation(sendCustomSurveySampleMutation);
  const { surveyId, isEditing, completedScreens, currentScreenName, step, currentScreenCompleted } = state;
  const navigate = useNavigate();
  const CurrentScreen = SCREENS[currentScreenName];
  const nextScreenName = getNextScreenName(currentScreenName);
  const completeButtonLabel = !survey || survey?.status === 'draft' ? 'Launch Survey' : 'Update Survey';
  const containerRef = useRef(null);

  useEffect(() => {
    handleFirstSave = once(function (variables, query) {
      query({
        variables
      });
    });
  }, []);

  useEffect(() => {
    if (surveyId && !queryParams.surveyId) {
      navigate(Resources.createSurveys.path({ workspaceId, step }, { surveyId }));
    }
  }, [surveyId]);

  useEffect(() => {
    if (currentScreenCompleted) {
      navigate(Resources.createSurveys.path({ workspaceId, step: parseInt(currentStep) + 1 }, { surveyId }));
    }
  }, [currentScreenCompleted]);

  useEffect(() => {
    if (step !== currentStep) {
      const screenName = Object.keys(SCREENS)[currentStep - 1];

      dispatch({
        type: ACTIONS.MOVE_TO_SCREEN,
        payload: screenName
      });
    }
  }, [currentStep]);

  useEffect(() => {
    if (state.autosave) {
      if (!surveyId) {
        handleFirstSave({ tenantId, survey: getStateTransferObject(state) }, saveSurvey);
      } else if (surveyId) {
        debouncedSave({ tenantId, id: surveyId, survey: getStateTransferObject(state) }, saveSurvey, () => dispatch({
          type: ACTIONS.UPDATE_AUTOSAVE
        }));
      }
    }
  }, [state, surveyId]);

  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [CurrentScreen]);

  useEffect(() => {
    if (savedSurveyData?.saveCustomSurvey && !surveyId) {
      dispatch({
        type: ACTIONS.ADD_SURVEY_ID,
        payload: savedSurveyData.saveCustomSurvey.id
      });
    }
  }, [savedSurveyData]);

  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
    >
      <Content>
        <Header>
          <ProgressBar>
            <ProgressElement 
              onClick={() => {
                navigate(Resources.createSurveys.path({ workspaceId, step: 1 }, { surveyId }));
              }}
              isActive={currentScreenName === SCREEN_NAMES.CREATE}
              canBeClicked
            >
              {isEditing ? (
                <>
                  <Icon component={EditIcon} />
                  <Typography variant='body1'>
                    Edit
                  </Typography>
                </>
              ) : (
                <>
                  <Icon component={AddIcon} />
                  <Typography variant='body1'>
                    Create
                  </Typography>
                </>
              )}
            </ProgressElement>
            <ProgressElement 
              onClick={() => {
                if (completedScreens.includes(SCREEN_NAMES.CREATE)) {
                  navigate(Resources.createSurveys.path({ workspaceId, step: 2 }, { surveyId }));
                }
              }}
              isActive={currentScreenName === SCREEN_NAMES.BUILDER}
              canBeClicked={completedScreens.includes(SCREEN_NAMES.CREATE)}
            >
              <Icon component={ArrowForwardIcon} />
              <Icon component={BuilderIcon} />
              <Typography variant='body1'>
                Builder
              </Typography>
            </ProgressElement>
            <ProgressElement
              onClick={() => {
                if (completedScreens.includes(SCREEN_NAMES.BUILDER)) {
                  navigate(Resources.createSurveys.path({ workspaceId, step: 3 }, { surveyId }));
                }
              }}
              isActive={currentScreenName === SCREEN_NAMES.AUDIENCE}
              canBeClicked={completedScreens.includes(SCREEN_NAMES.BUILDER)}
            >
              <Icon component={ArrowForwardIcon} />
              <Icon component={AudienceIcon} />
              <Typography variant='body1'>
                Audience
              </Typography>
            </ProgressElement>
            <ProgressElement
              onClick={() => {
                if (completedScreens.includes(SCREEN_NAMES.AUDIENCE)) {
                  navigate(Resources.createSurveys.path({ workspaceId, step: 4 }, { surveyId }));
                }
              }}
              isActive={currentScreenName === SCREEN_NAMES.SCHEDULE}
              canBeClicked={completedScreens.includes(SCREEN_NAMES.AUDIENCE)}
            >
              <Icon component={ArrowForwardIcon} />
              <Icon component={ScheduleIcon} />
              <Typography variant='body1'>
                Schedule
              </Typography>
            </ProgressElement>
            <ProgressElement
              onClick={() => {
                if (completedScreens.includes(SCREEN_NAMES.SCHEDULE) && completedScreens.includes(SCREEN_NAMES.AUDIENCE)) {
                  navigate(Resources.createSurveys.path({ workspaceId, step: 5 }, { surveyId }));
                }
              }}
              isActive={currentScreenName === SCREEN_NAMES.WRAP_UP}
              canBeClicked={completedScreens.includes(SCREEN_NAMES.SCHEDULE) && completedScreens.includes(SCREEN_NAMES.AUDIENCE)}
            >
              <Icon component={ArrowForwardIcon} />
              <Icon component={WrapUpIcon} />
              <Typography variant='body1'>
                Wrap Up
              </Typography>
            </ProgressElement>
          </ProgressBar>
          <MobileProgressBar>
            <Typography variant='body1'>
              <b>Step {step} of 5</b>
            </Typography>
          </MobileProgressBar>
          <CloseButton 
            component={CloseIcon}
            onClick={onClose}
          />
        </Header>
        <Body ref={containerRef}>
          <BodyInternalWrapper>
            <CurrentScreen 
              state={state} 
              matterTeams={matterTeams}
              dispatch={dispatch}
            />
          </BodyInternalWrapper>
        </Body>
        <Footer>
          <SaveIndicator 
            isSaving={savingQuestion}
          />
          <Box>
            {currentScreenName !== SCREEN_NAMES.CREATE && (
              <PreviewButton 
                size='M'
                color='black'
                isSlack={isSlack}
                disabled={!isBuilderScreenCompleted(state)}
                onClick={async () => {
                  await sendCustomSurveySample({
                    variables: {
                      surveyId,
                      tenantId
                    }
                  });

                  Toast.success('Preview sent');
                }}
              />
            )}
            <ContinueButton
              size='M'
              color='blue'
              rcLabel='continue-button'
              disabled={launchingSurvey}
              onClick={async () => {
                if (nextScreenName !== null) {
                  dispatch({
                    type: ACTIONS.CAN_MOVE_TO_NEXT_SCREEN,
                    payload: nextScreenName
                  });
                } else {
                  await launchCustomSurvey({
                    variables: {
                      tenantId,
                      surveyId
                    }
                  });
                  
                  if (!survey || survey.status === 'draft') {
                    Toast.success('Your survey has been launched');
                  } else {
                    Toast.success('Your survey has been updated');
                  }

                  navigate(
                    Resources.surveys.path({ workspaceId }, { activeTab: FILTERS.ACTIVE })
                  );

                  onClose();
                }
              }}
            >
              {nextScreenName ? 'Continue': completeButtonLabel}
            </ContinueButton>
          </Box>
        </Footer>
      </Content>
    </StyledModal>
  );
};

