import styled from 'styled-components';
import theme from '@matterapp/matter-theme';
import { IconNew as Icon } from '@matterapp/matter-ui';

export const MainColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NonFilterContainer = styled.div`
  display: none;
  ${theme.media.S`
    display: flex;
  `}
`;

export const NonFilterCardForMobile = styled.div`
  display: flex;
  flex-direction: column;
  ${theme.media.S`
    display: none;
  `}
`;

export const KudosCard = styled.div`
  height: 104px;
  background: ${theme.colors.white};
  box-shadow: none;
  margin-right: 0;
  border-radius: 0;
  width: 100%;
  ${theme.media.S`
    border-radius: ${theme.borderRadius.L};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
    width: 192px;
    height: 136px;
    margin-right: ${theme.spacing.single};
  `}
`;

export const ToolTipContainer = styled.div`
  display: none;
  ${theme.media.S`
    display: flex;
  `}
`;

export const MobileDividerForKudosCards = styled.div`
  display: block;
  height: 104px;
  width: 1px;
  background: ${theme.colors.blacks[20]};
  ${theme.media.S`
    display: none;
  `}
`;

export const MobileDividerForParticipationCard = styled.div`
  display: inline;
  width: 100%;
  border: 1px solid #E4E4E4;
  ${theme.media.S`
    display: none;
  `}
`;

export const KudosAndMembersContainer = styled.div`
  display: flex;
  font-family: ${theme.fontFamily.marketing};
  border-bottom: 1px solid #E4E4E4;
  ${theme.media.S`
    border-bottom: none;
  `}
`;

export const RightSideColumn = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fontFamily.marketing};
  width: 100%;
`;

export const ParticipationCard = styled.div`
  height: 136px;
  background: ${theme.colors.white};
  margin-bottom: none;
  border-radius: 0;
  box-shadow: none;
  ${theme.media.S`
    border-radius: ${theme.borderRadius.L};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
    margin-bottom: ${theme.spacing.singleAndHalf};
  `}
`;

export const RecognitionCard = styled.div`
  background: ${theme.colors.white};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  margin: 0 0 ${theme.spacing.singleAndHalf};
  border-radius: 0;
  box-shadow: none;
  ${theme.media.S`
    border-radius: ${theme.borderRadius.L};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  `}
`;

export const KudosContentContainer = styled.div`
  margin: ${theme.spacing.single} 0 ${theme.spacing.single} ${theme.spacing.single};
  ${theme.media.S`
  margin: ${theme.spacing.singleAndHalf} 0 ${theme.spacing.singleAndHalf} ${theme.spacing.singleAndHalf};
  `}
`;

export const KudosCountRow = styled.div`
  display: flex;
  margin-bottom: ${theme.spacing.half};
  margin-right: ${theme.spacing.singleAndHalf};
  align-items: center;
  justify-content: space-between;
  ${theme.media.S`
    margin-bottom: ${theme.spacing.singleAndQuarter};
  `}
  max-height: ${theme.spacing.triple}
`;

export const HideIcon = styled.div`
display: ${({ length }) => (length > 3 ? 'none' : 'inline-block')};
${theme.media.S`
display: ${({ length }) => (length > 6 ? 'none' : 'inline-block')};
  `}
`;

export const KudosSentIcon = styled(Icon).attrs({ name: 'kudosSent' })``;

export const KudosReceivedIcon = styled(Icon).attrs({ name: 'kudosReceived' })``;

export const MembersIcon = styled(Icon).attrs({ name: 'members' })``;

export const ParticipationIcon = styled(Icon).attrs({ name: 'participation' })``;

export const RecognitionIcon = styled(Icon).attrs({ name: 'recognition' })``;

export const KudosTextRow = styled.div`
  display: flex;
  align-items: center;
`;

export const TeamRecognitionTextRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${theme.spacing.singleAndHalf} ${theme.spacing.singleAndQuarter};
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.XL};
  line-height: ${theme.lineHeight.XXL};
  color: ${theme.colors.blacks[80]};
`;

export const TeamRecognition = styled.div``;

export const KudosCountText = styled.div`
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.M};
  line-height: ${theme.lineHeight.XXL};
  display: flex;
  align-items: center;
  color: ${theme.colors.blacks[80]};
  margin: 0 ${theme.spacing.half} 0 0;
`;

export const KudosTitle = styled.div`
  font-weight: ${theme.fontWeight.bold};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : theme.fontSize.XS)};
  line-height: ${theme.lineHeight.M};
  display: flex;
  align-items: center;
  color: ${theme.colors.blacks[80]};
  ${theme.media.S`
    font-size: ${theme.fontSize.S};
  `}
`;

export const TeamRecTitle = styled.div`
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  display: flex;
  align-items: center;
  color: ${theme.colors.blacks[80]};
`;

export const KudosInfoIcon = styled(Icon).attrs({ name: 'info' })``;

export const TopFeebackText = styled.div`
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.S};
  color: ${theme.colors.blacks[60]};
  font-weight: ${theme.fontWeight.bold};
  margin: ${theme.spacing.half} ${theme.spacing.singleAndQuarter};
  ${theme.media.S`
    margin: ${theme.spacing.half} ${theme.spacing.singleAndHalf};
  `}
`;

export const StyledAvatar = styled.div`
  display: inline;
  margin-right: ${theme.spacing.half};
  width: ${theme.spacing.singleAndHalf};
  height: ${theme.spacing.singleAndHalf};
`;

export const FeebackerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${theme.fontFamily.default};
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.M};
  margin: ${theme.spacing.half} ${theme.spacing.singleAndQuarter};
  ${theme.media.S`
    margin: ${theme.spacing.half} ${theme.spacing.singleAndHalf};
  `}
`;

export const FeedbackerName = styled.div`
  color: ${theme.colors.blacks[50]};
`;

export const FeedbackerScore = styled.div`
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.colors.blacks[50]};
`;

export const Divider = styled.div`
  width: 100%;
  border: 0.5px solid #E4E4E4;
`;

export const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Spacer40 = styled.div`
  height: ${theme.spacing.single};
  width: 100%;
  ${theme.media.S`
    height: ${theme.spacing.doubleAndHalf}
  `}
`;

export const Spacer16 = styled.div`
  height: ${theme.spacing.single};
  width: 100%;
`;

export const LeftSideColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftSideColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const KudosSentChartContainer = styled.div`
  margin-bottom: 0;
  width: 100%;
  ${theme.media.S`
    width: 608px;
    margin: ${theme.spacing.singleAndHalf} 22px 0 0;
  `}
`;

export const MembersListContainer = styled.div`
  width: 100%;
  margin: 0 22px 0 0;
  box-shadow: none;
  border-radius: 0;
  background: ${theme.colors.white};
  ${theme.media.S`
    width: 608px;
    border-radius: ${theme.borderRadius.L};
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  `}
`;

export const MembersListTitle = styled.div`
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.L};
  color: ${theme.colors.blacks[80]};
  margin: ${theme.spacing.singleAndHalf};
`;

export const MembersListHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${theme.spacing.threeQuarters} ${theme.spacing.singleAndHalf};
`;

export const MemberHeaderIconContainer = styled.div`
  width: ${theme.spacing.triple};
  display: none;
  align-items: center;
  justify-content: center;
  ${theme.media.S`
    display: flex;
  `}
`;

export const MemberHeaderNameContainer = styled.div`
  display: none;
  align-items: center;
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.L};
  color: ${theme.colors.blacks[60]};
  font-weight: ${theme.fontWeight.medium};
  padding-left: ${theme.spacing.single};
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${theme.media.S`
    width: 207px;
    display: flex;
  `}
`;

export const MemberHeaderKudosSentContainer = styled.div`
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.L};
  color: ${theme.colors.blacks[60]};
  font-weight: ${theme.fontWeight.medium};
  display: none;
  align-items: center;
  justify-content: center;
  width: 96px;
  ${theme.media.S`
    width: 151px;
    display: flex;
  `}
`;

export const MemberHeaderKudosReceivedContainer = styled.div`
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.L};
  color: ${theme.colors.blacks[60]};
  font-weight: ${theme.fontWeight.medium};
  
  align-items: center;
  justify-content: center;
  display: none;
  ${theme.media.S`
    width: 178px;
    display: flex;
  `}
`;

export const MemberHeaderNameMobileContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.L};
  color: ${theme.colors.blacks[60]};
  font-weight: ${theme.fontWeight.medium};
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${theme.media.S`
    display: none
  `}
`;

export const MemberHeaderKudosSentMobileContainer = styled.div`
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.L};
  color: ${theme.colors.blacks[60]};
  font-weight: ${theme.fontWeight.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  ${theme.media.S`
    display: none;
  `}
`;

export const MemberHeaderKudosReceivedMobileContainer = styled.div`
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.L};
  color: ${theme.colors.blacks[60]};
  font-weight: ${theme.fontWeight.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  ${theme.media.S`
    display: none;
  `}
`;

export const MemberNameContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.MemberIconContainer};
  color: ${theme.colors.blacks[90]};
  font-weight: ${theme.fontWeight.semiBold};
  margin: ${theme.spacing.singleAndThreeQuarters} 0;
  width: 160px;
  ${theme.media.S`
    width: 207px;
  `}
`;

export const MemberKudosSentContainer = styled.div`
  width: 151px;
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[90]};
  font-weight: ${theme.fontWeight.semiBold};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${theme.spacing.singleAndThreeQuarters} 0;
  width: 96px;
  ${theme.media.S`
    width: 151px;
  `}
`;

export const MemberKudosReceivedContainer = styled.div`
  font-size: ${theme.fontSize.S};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[90]};
  font-weight: ${theme.fontWeight.semiBold};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${theme.spacing.singleAndThreeQuarters} 0;
  width: 96px;
  ${theme.media.S`
    width: 178px;
  `}
`;

export const MemberListContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 ${theme.spacing.singleAndHalf};
`;


export const StyledMemberListAvatar = styled.div`
  display: none;
  ${theme.media.S`
    display: inline;
    padding-right: ${theme.spacing.single};
  `}
`;

export const MembersListIcon = styled(Icon).attrs({ name: 'analyticsMemberList' })``;

export const SpinnerIconContainer = styled.div`
  display: flex;
  alignitems: center;
  justify-content: center;
  height: ${theme.spacing.tripleAndHalf};
  @keyframes spin { 100% { transform:rotate(360deg) } }
  margin-bottom: 16px;
  svg {
    display: block;
    height: ${theme.spacing.doubleAndHalf};
    width: ${theme.spacing.doubleAndHalf};
    animation: spin 4s linear infinite;
  }
`;

export const IconSpinner = styled(Icon).attrs(() => ({
  name: 'spinner',
}))``;

export const LoaderContainer = styled.div`
  padding: ${theme.spacing.singleAndHalf};
  position: relative;
  min-height: 160px;
  background: white;
  max-width: 944px;
  border-bottom: 1px solid ${theme.colors.blacks[10]};
`;

export const NoDataContainer = styled.div`
  font-size: ${theme.fontSize.base};
  line-height: ${theme.lineHeight.M};
  color: ${theme.colors.blacks[30]};
  font-weight: ${theme.fontWeight.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${theme.spacing.half} 0;
`;

export const Container = styled.div``;