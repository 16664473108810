import React, { useReducer, useEffect, useContext } from 'react';
import UserContext from 'context/UserContext/UserContext';
import Toast from 'components/Toast/Toast';
import updateRewardsSettings from 'graphql-queries/mutations/rewards/updateRewardsSettings';
import getCurrentMemberProperties from 'graphql-queries/queries/rewards/getCurrentMemberProperties';
import { useMutation } from '@apollo/client';
import { Button, Modal } from '@matterapp/matter-ui';
import {
  REWARDS_PANEL_TEXT,
} from '../sharedConsts';
import DefaultAllowanceSection from './DefaultAllowanceSection/DefaultAllowanceSection';
import CustomAllowanceSection from './CustomAllowanceSection/CustomAllowanceSection';
import CategoriesSection from './CategoriesSection';
import ApprovalSection from './ApprovalSection';
import AddOnCoinsSection from './AddOnCoinsSection';
import CoinMonitoringSection from './CoinMonitoringSection';
import ResetCoinsSection from './ResetCoinsSection';
import { Typography } from '@mui/material';
import { SecondaryButton, RewardsSettingsFooter } from './styles';
import { StyledPaper } from '../styles';
import FundingSection from './FundingSection';
import { rewardsReducer, getInitialState, ACTIONS, getStateTransferObject } from './reducer';
import SettingsHeader from '../SettingsHeader';

export default function RewardsSettings() {
  const { currentTenant: tenant, currentWorkspace: workspace } = useContext(UserContext);
  const { slackWorkspace } = workspace || {};
  const { id: tenantId, tier, rewardSettings } = tenant;
  const { isRewardsActive, nextAllowanceResetDate } = rewardSettings || {};
  const [state, dispatch] = useReducer(rewardsReducer, getInitialState(rewardSettings));
  const { canSave, settings, initialized } = state;
  const { rewardsFundingSettings } = settings;
  const trainingLink = slackWorkspace 
    ? <a href='https://matterapp.com/training/rewards-slack' target='_blank' rel='noopener noreferrer'>Training Video</a>
    : <a href='https://matterapp.com/training/rewards-teams' target='_blank' rel='noopener noreferrer'>Training Video</a>;

  const howItWorksLink = slackWorkspace 
    ? 'https://matterapp.com/training/rewards-slack'
    : 'https://matterapp.com/training/rewards-teams';

  const [saveRewardsSettings, { loading: savingRewardsSettings, data }] = useMutation(updateRewardsSettings, {
    options: {
      refetchQueries: () => [{ 
        query: getCurrentMemberProperties,
        variables: { tenantId } 
      }],
    }
  });

  const saveSettings = async (settings) => {
    await saveRewardsSettings({
      variables: {
        tenantId,
        settings,
      }
    });
  };

  useEffect(() => {
    if (data && !savingRewardsSettings) {
      Toast.success('You successfully updated your Rewards settings');
    }
  }, [savingRewardsSettings, data]);

  useEffect(() => {
    if (rewardSettings) {
      const canSave = rewardSettings.monitorCoins !== settings.monitorCoins 
        || rewardSettings.monitorCoinsThreshold != settings.monitorCoinsThreshold
        || rewardSettings.monitorCoinsFrequency !== settings.monitorCoinsFrequency;

      const threshold = tenant.rewardsFundingSettings.threshold == null ? 5000 : tenant.rewardsFundingSettings.threshold;
      // We need to keep some unsaved settings that get overwritten in case 
      const mergeState = initialized ? {
        monitorCoins: state.settings.monitorCoins,
        monitorCoinsThreshold: state.settings.monitorCoinsThreshold,
        monitorCoinsFrequency: state.settings.monitorCoinsFrequency,
        canSave
      } : {};
      
      dispatch({ type: ACTIONS.RESET_INITIAL_SETTINGS, payload: {
        ...rewardSettings,
        ...mergeState,
        rewardsFundingSettings: {
          ...tenant.rewardsFundingSettings,
          threshold
        }
      }});
    }
  }, [tenant]);

  if (!tenant || !initialized) {
    return null;
  }

  return (
    <Modal.Panel.Main>
      {isRewardsActive ? (
        <>
          <SettingsHeader 
            headerText='Rewards'
            isLoading={savingRewardsSettings}
            onDisable={() => saveSettings({ isRewardsActive: false })}
            trainingLink={trainingLink}
            helpCenterLink='https://help.matterapp.com/en/collections/3594355-rewards'
            text='Configure Rewards settings for your organization.'
          />
          <FundingSection 
            rewardsFundingSettings={rewardsFundingSettings}
            dispatch={dispatch}
          />
          <CategoriesSection
            disabledRewardTypes={state.settings.disabledRewardTypes}
            onChange={(selectedMaxCoins) => dispatch({ 
              type: ACTIONS.REWARD_TYPES_NEW_PROPS, 
              payload: selectedMaxCoins 
            })}
          />
          <StyledPaper>
            <DefaultAllowanceSection 
              onError={() => dispatch({ type: ACTIONS.DEFAULT_SETTINGS_HAS_ERROR })}
              onChange={(payload) => dispatch({ type: ACTIONS.DEFAULT_SETTINGS_NEW_PROPS, payload })}
              hasError={state.defaultSettingsHasError}
              selectedMaxCoins={state.settings.maxAllotedCoinPerRitualPerMember}
              allowanceResetFrequency={state.settings.allowanceResetFrequency}
              nextAllowanceResetDate={nextAllowanceResetDate}
              tenantId={tenantId}
            />
            <CustomAllowanceSection 
              tenant={tenant}
              workspace={workspace}
              allowanceResetFrequency={state.settings.allowanceResetFrequency}
            />
          </StyledPaper>
          {tier === 'pro' ? (
            <ApprovalSection
              autoApprove={state.settings.autoApprove}
              notifyAdmins={state.settings.notifyAdmins}
              onChange={(autoApprove, notifyAdmins) => dispatch({ 
                type: ACTIONS.UPDATE_APPROVAL_PROCESS, 
                payload: { autoApprove, notifyAdmins } 
              })}
            />
          ) : null}
          <AddOnCoinsSection
            addOnCoins={state.settings.addOnCoins}
            onChange={() => dispatch({ 
              type: ACTIONS.TOGGLE_ADD_ON_COINS,
            })}
          />
          <CoinMonitoringSection
            tenantId={tenantId}
            monitorCoins={state.settings.monitorCoins}
            onChange={() => dispatch({ 
              type: ACTIONS.TOGGLE_MONITOR_COINS,
            })}
            onCoinsAmountChange={(value) => dispatch({
              type: ACTIONS.UPDATE_MONITOR_COINS_THRESHOLD,
              payload: value
            })}
            onPeriodChange={(value) => dispatch({
              type: ACTIONS.UPDATE_MONITOR_COINS_FREQUENCY,
              payload: value
            })}
            monitorCoinsFrequency={state.settings.monitorCoinsFrequency}
            monitorCoinsThreshold={state.settings.monitorCoinsThreshold}
            monitorCoinsNotifyMembers={state.settings.monitorCoinsNotifyMembers}
            saveSettings={saveSettings}
          />
          <ResetCoinsSection tenantId={tenant?.id}/>
        </>
      ):(
        <StyledPaper>
          <Typography variant='h3' sx={{ mb: 3 }}>Rewards</Typography>
          {REWARDS_PANEL_TEXT.DISABLED_TEXT}
          <br/> <br/>
          {REWARDS_PANEL_TEXT.LEARN_MORE_TEXT}
          <div style={{ marginTop: 24, paddingBottom: 24, display: 'flex' }}>
            <Button.Primary.Vivid
              color={'blue'}
              style={{ marginRight: 16 }}
              loading={savingRewardsSettings}
              onClick={() => saveSettings({ isRewardsActive: true })}
            >
              Enable Feature
            </Button.Primary.Vivid>
            <SecondaryButton 
              color={'black'} 
              target='_blank'
              href={howItWorksLink}
            >
              See How It Works
            </SecondaryButton>
          </div>
        </StyledPaper>
      )}
      {isRewardsActive ? (
        <RewardsSettingsFooter
          primaryLabel='Save'
          onClickPrimary={() => saveSettings(getStateTransferObject(state.settings))}
          canClickPrimary={canSave}
        />
      ) : null}
    </Modal.Panel.Main>
  );
};
