import React from 'react';
import { Routes } from 'react-router-dom';
import {
  SettingsMain,
  SettingsGeneral,
  SettingsFeedbackFriday,
  SettingsKudos,
  SettingsBilling,
  SettingsHris,
  SettingsRewards,
  SettingsCelebrations,
  SettingsPulse,
  SettingsMembers,
  SettingsDelete,
  SettingsMemberProperties,
  SettingsDataProperties,
  SettingsSegments,
  SettingsTeams,
  SettingsTeamPage,
  SettingsMembersBulkUploadProps
} from './bindings/workspaceSettings';

export default () => (
  <Routes>
    {SettingsMain}
    {SettingsGeneral}
    {SettingsFeedbackFriday}
    {SettingsKudos}
    {SettingsBilling}
    {SettingsHris}
    {SettingsRewards}
    {SettingsCelebrations}
    {SettingsPulse}
    {SettingsMembers}
    {SettingsMembersBulkUploadProps}
    {SettingsTeams}
    {SettingsTeamPage}
    {SettingsMemberProperties}
    {SettingsDataProperties}
    {SettingsSegments}
    {SettingsDelete}
  </Routes>
);
