import { Text } from '@matterapp/matter-ui';
import Toast from 'components/Toast';

const TextReplacement = Text.WithReplacement;

export const TOAST_TEXT = {
  DELETE: 'You’ve successfully deleted your channel',
  EMAIL_CONFIRMED: 'Email confirmed, please continue above',
  INVITE_SINGLE: `You’ve successfully sent ${TextReplacement.replaceText} channel invite`,
  INVITE: `You’ve successfully sent ${TextReplacement.replaceText} channel invites`,
  MUST_CONNECT_TO_SLACK: 'You must connect to Slack in order to enable this feature',
  REVOKE: 'You’ve successfully revoked a channel invite',
  RESEND_DELETE_LINK: 'An email is on the way!',
  SEE_EXAMPLE: 'We sent you a message on Slack!',
  SUCCESS_SLACK_SETUP: 'You’ve successfully set up Slack',
  SUCCESS_CHANGE_CHANNEL: 'You’ve successfully updated the Slack channel',
  UPDATE: 'You’ve successfully updated your channel settings',
  SLACK_ERROR_INVALID_CODE: 'Your Slack authentication code was invalid. Please try again',
  SLACK_ERROR_TAKEN: 'That Slack channel is already associated with another Matter channel. Please try again with a different Slack channel',
  SLACK_ERROR_UNKNOWN: 'Something went wrong. Please try again',
};

export const CONNECT_SLACK_ERROR = {
  taken: 'slack_error_taken',
  invalidCode: 'slack_error_invalid_code',
  unknown: 'slack_error_unknown',
};

export { TextReplacement, Toast };
