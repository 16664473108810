import React from 'react';
import { Button } from '@matterapp/matter-ui';
import { Typography, Box } from '@mui/material';
import { FormattedGroup } from 'components/Group';

export default function DisabledState({ savingPulseSettings, saveSettings, isSlack }) {
  return (
    <FormattedGroup marginBottom='0px'>
      <Typography variant='body1'>
        Matter&apos;s science-driven Pulse Surveys gathers feedback to measure employee sentiment and engagement. 
        Receive weekly insights on company culture.
      </Typography>
      <Typography variant='body1' sx={{ mt: '8px' }}>
        5 Reasons You’ll Love Pulse Surveys 💜 
      </Typography>
      <Box sx={{ mt: '8px', pl: '15px' }}>
        <Typography variant='body1' sx={{ pb: '5px '}}> 
          ✅ Fully automated in {isSlack ? 'Slack' : 'Teams' }
        </Typography>
        <Typography variant='body1' sx={{ pb: '5px '}}> 
          ✅ Measure 10 key engagement categories
        </Typography>
        <Typography variant='body1' sx={{ pb: '5px '}}> 
          ✅ Measure employee net promoter score (eNPS)
        </Typography>
        <Typography variant='body1' sx={{ pb: '5px '}}> 
          ✅ Reward survey completions with Matter Coins
        </Typography>
        <Typography variant='body1' sx={{ pb: '5px '}}> 
          ✅ Receive weekly insights and real-time feedback
        </Typography>
      </Box>
      <div style={{ marginTop: 24, display: 'flex' }}>
        <Button.Primary.Vivid
          color={'blue'}
          style={{ marginRight: 16 }}
          loading={savingPulseSettings}
          onClick={() => saveSettings({ isPulseEnabled: true })}
        >
            Enable Pulse Survey
        </Button.Primary.Vivid>
      </div>
    </FormattedGroup>
  );
};
