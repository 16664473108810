import React, { useState } from 'react';
import { 
  Paper,
  TableHead,
  TableCell,
  TableSortLabel,
  TableBody,
  Table,
  Typography
} from '@mui/material';
import { StyledTableRow } from '../styles';
import styled from 'styled-components';

const Caption = styled.caption({
  height: '264px',
  textAlign: 'center',
  'p:first-child, p:last-child': {
    margin: '110px 0'
  }
});

const SubmittedCell = styled(TableCell)(({ theme }) => ({
  width: '200px',
  [theme.breakpoints.down('sm')]: {
    width: '150px'
  }
}));

const NoComments = styled(Typography)(({
  color: '#C6C6C7', 
  textAlign: 'center'
}));

export default function QuestionComments({ 
  comments
}) {
  const [order, setOrder] = useState('desc');

  const ordered = [...comments].sort((a, b) => {
    if (order === 'asc') {
      return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
    } 

    return new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1;
  });

  return (
    <Paper elevation={1} sx={{ padding: 0 }}>
      <Table size='large'>
        {!ordered.length ? (
          <Caption>
            <NoComments variant='h6' component='p'>
              NO COMMENTS
            </NoComments>
          </Caption>
        ) : null}
        <TableHead>
          <StyledTableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Comments ({comments.length})</TableCell>
            <SubmittedCell>
              <TableSortLabel
                active
                direction={order}
                onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                sx={{ fontWeight: 'bold' }}
              >
                Submitted At
              </TableSortLabel>
            </SubmittedCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {ordered.map(({ text, createdAt }) => (
            <StyledTableRow 
              key={text} 
            >
              <TableCell>{text}</TableCell>
              <TableCell>{createdAt}</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
