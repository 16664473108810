import Animation from './Animation';
import Thumbnail from './Thumbnail';
import { ColorPalettes as Colors } from '@matterapp/matter-theme/dist/colors';

const KUDOS_TYPE = {
  amazingJob: 'amazing_job',
  teamPlayer:'team_player',
  thankYou:'thank_you',
  cheers: 'cheers',
  impressive: 'impressive',
  inclusive: 'inclusive',
  meowow: 'meowow',
  keepItUp: 'keep_it_up',
  thoughtful: 'thoughtful',
  toTheMoon: 'to_the_moon'
};

const KUDOS_DETAILS_MAP_INTERNAL = {
  amazingJob: {
    type: KUDOS_TYPE.amazingJob,
    label: 'Amazing job',
    isBase: true,
    color: Colors.Orange,
  },
  thankYou: {
    color: Colors.Purple,
    type: KUDOS_TYPE.thankYou,
    label: 'Thank you',
    isBase: true,
  },
  teamPlayer: {
    color: Colors.Blue,
    type: KUDOS_TYPE.teamPlayer,
    label: 'Team player',
    isBase: true,
  },
  cheers: {
    type: KUDOS_TYPE.cheers,
    label: 'Cheers',
    isBase: false,
    color: Colors.Blue,
  },
  impressive: {
    type: KUDOS_TYPE.impressive,
    label: 'Impressive',
    isBase: false,
    color: Colors.Red,
  },
  inclusive: {
    type: KUDOS_TYPE.inclusive,
    label: 'Inclusive',
    isBase: false,
    color: Colors.Purple,
  },
  meowow: {
    type: KUDOS_TYPE.meowow,
    label: 'Meowow',
    isBase: false,
    color: Colors.Red,
  },
  keepItUp: {
    type: KUDOS_TYPE.keepItUp,
    label: 'Keep it up',
    isBase: false,
    color: Colors.Blue,
  },
  thoughtful: {
    type: KUDOS_TYPE.thoughtful,
    label: 'Thoughtful',
    isBase: false,
    color: Colors.Green,
  },
  toTheMoon: {
    type: KUDOS_TYPE.toTheMoon,
    label: 'To the moon',
    isBase: false,
    isLight: true,
    color: Colors.Purple,
  },
};


export const KUDOS_DETAILS_MAP = (() => {
  const result = {};
  Object.values(KUDOS_DETAILS_MAP_INTERNAL).forEach((currentKudos) => {
    result[currentKudos.type] = currentKudos;
  });
  return result;
})();

export const AMAZING_JOB = {
  ...KUDOS_DETAILS_MAP[KUDOS_TYPE.amazingJob],
  animation: Animation.AmazingJob,
  thumbnail: Thumbnail.AmazingJob,
};

export const THANK_YOU = {
  ...KUDOS_DETAILS_MAP[KUDOS_TYPE.thankYou],
  animation: Animation.ThankYou,
  thumbnail: Thumbnail.ThankYou,
};

export const TEAM_PLAYER = {
  ...KUDOS_DETAILS_MAP[KUDOS_TYPE.teamPlayer],
  animation: Animation.TeamPlayer,
  thumbnail: Thumbnail.TeamPlayer,
};

export const IMPRESSIVE = {
  ...KUDOS_DETAILS_MAP[KUDOS_TYPE.impressive],
  animation: Animation.Impressive,
  thumbnail: Thumbnail.Impressive,
};

export const KEEP_IT_UP = {
  ...KUDOS_DETAILS_MAP[KUDOS_TYPE.keepItUp],
  animation: Animation.KeepItUp,
  thumbnail: Thumbnail.KeepItUp,
};

export const THOUGHTFUL = {
  ...KUDOS_DETAILS_MAP[KUDOS_TYPE.thoughtful],
  animation: Animation.Thoughtful,
  thumbnail: Thumbnail.Thoughtful,
};

export const INCLUSIVE = {
  ...KUDOS_DETAILS_MAP[KUDOS_TYPE.inclusive],
  animation: Animation.Inclusive,
  thumbnail: Thumbnail.Inclusive,
};

export const MEOWOW = {
  ...KUDOS_DETAILS_MAP[KUDOS_TYPE.meowow],
  animation: Animation.Meowow,
  thumbnail: Thumbnail.Meowow,
};

export const CHEERS = {
  ...KUDOS_DETAILS_MAP[KUDOS_TYPE.cheers],
  animation: Animation.Cheers,
  thumbnail: Thumbnail.Cheers,
};

export const TO_THE_MOON = {
  ...KUDOS_DETAILS_MAP[KUDOS_TYPE.toTheMoon],
  animation: Thumbnail.ToTheMoon,
  thumbnail: Thumbnail.ToTheMoon,
  composerThumbnail: Thumbnail.ToTheMoonComposer,
};
