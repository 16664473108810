import React from 'react';
import styled from 'styled-components';
import { 
  Typography,
  Breadcrumbs
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const StyledBreadCrumbs = styled(
  Breadcrumbs
).withConfig({ shouldForwardProp: (prop) => {
  return prop !== 'hideOnMobile';
} })
(({ theme, hideOnMobile }) => ({
  color: theme.palette.blacks[60],
  'p': {
    cursor: 'pointer',
    ':hover': {
      color: theme.palette.blacks[90],
      textDecoration: 'none'
    },
    ':active': {
      textDecoration: 'underline'
    }
  },
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(3)
  },
  [theme.breakpoints.down('sm')]: {
    display: hideOnMobile ? 'none' : 'flex',
    marginTop: 0,
    paddingLeft: theme.spacing(2),
    height: '56px',
    alignItems: 'center'
  }
}));

export default function CustomBreadCrumbs({ crumbs, sx = {}, hideOnMobile }) {
  const navigate = useNavigate();

  return (
    <StyledBreadCrumbs sx={{ ...sx }} hideOnMobile={hideOnMobile}>
      {crumbs.map(({ label, href, onClick = () => {}}) => (
        <Typography 
          key={label} 
          variant='body1'
          onClick={() => href ? navigate(href) : onClick()}
        >
          {label}
        </Typography>
      ))}
    </StyledBreadCrumbs>
  );
}