import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import UserContext from 'context/UserContext/UserContext';
import { Pane, Container, ToggleUnit, Text, Message } from '@matterapp/matter-ui';
import { AppLayout } from 'modules';
import ContainerNavigation from './ContainerNavigation';

const allSettings = {
  feedbackReceived: 'feedbackReceived',
  newFeedbackComments: 'newFeedbackComments',
  thankReceived: 'thankReceived',
  productUpdates: 'productUpdates',
  requestFeedback: 'requestFeedback',
  requestFeedbackReminderDigest: 'requestFeedbackReminderDigest',
  feedbackRequestStatusUpdate: 'feedbackRequestStatusUpdate',
  failedFeedbackRequests: 'failedFeedbackRequests',
};
  
const settingsMappings = {
  [allSettings.feedbackReceived]: {
    copy: 'You’ve received new feedback',
    shouldShow: () => true,
  },
  [allSettings.thankReceived]: {
    copy: 'You’ve received thanks from an Advisor',
    shouldShow: () => false,
  },
  [allSettings.productUpdates]: {
    copy: 'News about Matter product updates',
    shouldShow: () => true,
  },
  [allSettings.requestFeedback]: {
    copy: 'You’ve received a feedback request',
    shouldShow: () => true,
  },
  [allSettings.requestFeedbackReminderDigest]: {
    copy: 'You’ve received feedback reminders',
    shouldShow: ({ isSet }) => isSet,
  },
  [allSettings.feedbackRequestStatusUpdate]: {
    copy: 'Your feedback request status digest',
    shouldShow: () => false,
  },
  [allSettings.failedFeedbackRequests]: {
    copy: 'Alerts on feedback request failures',
    shouldShow: () => true,
  },
  [allSettings.newFeedbackComments]: {
    copy: 'You’ve received comments on your feedback',
    shouldShow: () => true,
  },
};

function getSettings(notificationSettings) {
  return notificationSettings.map(({ label, value, isSet }) => ({
    label,
    text: settingsMappings[label].copy,
    name: `email-${label}`,
    checked: value,
    shouldShow: settingsMappings[label].shouldShow({ isSet }),
    value,
    isSet
  }));
}

function UserSettingsNotificationsPage({ setNotificationSettingMutation }) {
  const { currentUser, currentWorkspace } = useContext(UserContext);
  const [settingsList, updateSettingsList] = useState(getSettings(currentUser?.notificationSettings.email));
  const groupTitle = 'Email';
  const settingsToShow = settingsList.filter(({ shouldShow }) => shouldShow);
  const { generalSettings } = currentWorkspace || {};
  const { emailActivityNotifications } = generalSettings || {};

  async function onChange(e, label) {
    const newValue = e.target.checked;
    const newCategorySettings = settingsList.map((setting) => {
      if (setting.label === label) {
        return {
          ...setting,
          value: newValue,
        };
      }
      return setting;
    });

    updateSettingsList(getSettings(newCategorySettings));

    setNotificationSettingMutation({
      variables: {
        category: 'email',
        label,
        value: newValue,
      }
    });
  };

  if (!currentWorkspace) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <AppLayout
        layout={AppLayout.Layouts.TwoColumnFixedLeft}
        oldLayoutVersion={1}
        containerNavigation={<ContainerNavigation />}
      >
        <Text color="black" textStyle={500} marginBottom={8} block bold>
          Email Notifications
        </Text>
        <Text color="black" textStyle={400} marginBottom={32} block>
          Control when and how Matter contacts you.
        </Text>
        {emailActivityNotifications ? (
          <>
            <Container marginBottom={32} style={{width: '100%'}}>
              <Text color="black" textStyle={500} bold block marginBottom={8}>
                {groupTitle}
              </Text>
              <Pane
                style={{
                  paddingTop: 24,
                  paddingBottom: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
              >
                {settingsToShow.map((setting, index) => (
                  <ToggleUnit
                    key={setting.label}
                    name={setting.label}
                    text={setting.text}
                    checked={setting.checked}
                    onChange={(e) => onChange(e, setting.label)}
                    isLast={index === settingsToShow.length - 1}
                  />
                ))}
              </Pane>
            </Container>
          </>
        ) : (
          <Message.Warning
            showColonAfterHeader
            content='Email notifications have been disabled by your Matter admin. Contact your Matter admin to enable them in your workspace.'
          />
        )}
      </AppLayout>
    </>
  );
};

export default UserSettingsNotificationsPage;
