import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from '@matterapp/matter-ui';
import getMemberBulkUploadSpreadsheet from 'graphql-queries/mutations/workspaces/getMemberBulkUploadSpreadsheet';
import sendMemberPropertiesSpreadsheet from 'graphql-queries/mutations/workspaces/sendMemberPropertiesSpreadsheet';
import theme from '@matterapp/matter-theme';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';
import { Box, Typography } from '@mui/material';
import { UnorderedList, OrderedList, StyledButton } from './styles';
import Toast from 'components/Toast/Toast';

const SubHeader = styled.div(() => ({
  marginTop: '-24px',
  marginBottom: theme.spacing.single
}));

const SPREADSHEET_ID = 'spreadsheet';

function onUploadClick() {
  const input = document.getElementById(SPREADSHEET_ID);
  input.click();
}

async function downloadSpreadsheet(base64) {
  const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = 'memberProperties.xlsx';

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(url);
}

export default function MembersBulkUploadProps(props) {
  const {
    workspace
  } = props;
  const navigate = useNavigate();
  const { id: workspaceId, tenantId } = workspace || {};
  const [requestMemberBulkUploadSpreadsheet, { data: spreadsheetData, loading: requestingSpreadsheet }] = useMutation(getMemberBulkUploadSpreadsheet);
  const [uploadMemberPropertiesSpreadsheet, { data: uploadResult, loading: uploadingSpreadsheet }] = useMutation(sendMemberPropertiesSpreadsheet);

  useEffect(() => {
    if (spreadsheetData && !requestingSpreadsheet) {
      downloadSpreadsheet(spreadsheetData.memberBulkUploadSpreadsheet, 'pulseSurveyReport');
      Toast.success('Template downloaded. Edit and re-upload.');
    }
  }, [requestingSpreadsheet, spreadsheetData]);

  useEffect(() => {
    if (uploadResult && !uploadingSpreadsheet) {
      const { processed = 0, ok, errorMessage } = uploadResult.sendMemberPropertiesSpreadsheet || {};
      
      if (ok) {
        let message = 'All members are up-to-date';
        if (processed === 1) {
          message = `One member was updated 🥳`;
        } else if (processed > 1) {
          message = `${uploadResult.sendMemberPropertiesSpreadsheet.processed} members have been updated 🥳`;
        }
  
        Toast.success(message);
      } else {
        Toast.error(errorMessage);
      }
    }
  }, [uploadingSpreadsheet, uploadResult]);

  function readFile() {
    const file = document.getElementById(SPREADSHEET_ID).files[0];
    const reader = new FileReader();
  
    reader.addEventListener('load', () => {
      uploadMemberPropertiesSpreadsheet(({
        variables: {
          tenantId,
          data: reader.result
        }
      }));
    }, false);
  
    if (file) {
      reader.readAsDataURL(file);
      document.getElementById(SPREADSHEET_ID).value = null;
    }
  }

  return (
    <Modal.Panel.Main
      header='Bulk Update Member Properties'
    >
      <input 
        type="file"
        style={{ display: 'none' }}
        id={SPREADSHEET_ID} 
        name="memberProperties"
        onChange={readFile}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <SubHeader>
        <Typography variant='body1'>
          Update the values of <a onClick={() => navigate(Resources.workspaceSettingsDataProps.path({ workspaceId }))} style={{ cursor: 'pointer' }}>Member Properties</a> for each Matter user. You can update default member properties such as hire date, birthday, ethnicity, gender, coin balance, and any custom member properties you&apos;ve created.
        </Typography>
      </SubHeader>
      <Box sx={{ mb: 3, mt: 3 }}>
        <StyledButton 
          variant='outlined'
          disabled={requestingSpreadsheet}
          onClick={() => {
            requestMemberBulkUploadSpreadsheet({
              variables: {
                tenantId
              }
            });
          }
          }
        >
          Download Template
        </StyledButton>
        <StyledButton 
          variant='outlined'
          onClick={onUploadClick}
        >
          Upload Completed Template
        </StyledButton>
      </Box>
      <Box>
        <Typography variant='body1' sx={{ mt: 2, mb: 1 }}><b>How to Update:</b></Typography>
        <OrderedList>
          <li>Download the template.</li>
          <li>Fill out the template.</li>
          <li>Upload the completed template.</li>
        </OrderedList>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant='body1' sx={{ mt: 2, mb: 1 }}><b>Important Notes:</b></Typography>
        <UnorderedList>
          <li>Do not modify the UserID, Email Address, and Full Name columns.</li>
          <li>Custom properties will appear as additional columns in the template.</li>
          <li>If the HRIS sync is enabled, the HRIS values will be the source of truth and will overwrite for all properties.</li>
        </UnorderedList>
      </Box>
    </Modal.Panel.Main>
  );
};
