import { createTheme } from '@mui/material/styles';
import theme from '@matterapp/matter-theme';

const defaultTheme = createTheme();

const newTheme = {
  ...theme,
  legacySpacing: theme.spacing
};

delete newTheme.shadows;
delete newTheme.spacing;

export default createTheme({
  ...newTheme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 860,
      lg: 1080,
      xl: 1280,
    },
  },
  palette: {
    ...theme.colors,
    primary: {
      main: '#353799',
      light: '#ADAFDE',
      lighter: '#3466F2',
    },
    error: {
      main: '#E01E5A'
    },
    white: {
      main: '#FFF'
    },
    grey: {
      main: '#828285',
      darker: '#5E5E5E',
      light: '#6B6B6F',
      lighter: '#F6F6F6',
      10: theme.colors.blacks[10],
      90: theme.colors.blacks[90]
    },
    black: {
      main: '#000',
      lighter: 'rgb(62, 62, 66)'
    },
    green: {
      main: '#007A5A',
      lighter: '#07BB87'
    },
    purple: {
      main: '#5B5FC7',
      darker: '#4F52B2',
      dark: '#444791',
      lighter: '#7C7FF2',
      60: '#25277A',
      50: '#343799',
      40: '#4F52B1',
      5: '#E9E9F6'
    },
    blue: {
      main: theme.colors.blues[50],
      lighter: '#F3F8FF'
    },
    disableButton: {
      main: theme.colors.blacks[30]
    }
  },
  typography: {
    fontFamily: ['Inter UI', 'sans-serif'].join(','),
    fontSize: 14,
    h1: {
      fontSize: '48px',
      fontWeight: 700
    },
    h2: {
      fontSize: '32px',
      fontWeight: 700
    },
    h3: {
      fontSize: '24px',
      fontWeight: 700
    },
    h4: {
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: '32px'
    },
    h5: {
      fontWeight: 700,
      fontSize: '17px',
      lineHeight: '24px',
      textTransform: 'none',
      color: theme.colors.blacks[80]
    },
    h6: {
      fontWeight: 500,
      fontSize: '17px',
      lineHeight: '24px'
    },
    h7: {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '22px'
    },
    body1: {
      fontSize: '17px',
      lineHeight: '24px'
    },
    body2: {
      fontSize: '14px',
      lineHeight: '24px'
    },
    body3: {
      fontSize: '10px',
      lineHeight: '10px'
    }
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .Mui-error': {
            backgroundColor: '#FFE7EE',
            borderColor: '#FF6588',
            color: '#FF6588'
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          border: `1px solid #99999b`,
          padding: '11px',
          paddingBottom: '10px',
          '&.Mui-error': {
            backgroundColor: '#FFE7EE',
            borderColor: '#FF6588',
            color: '#FF6588'
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.colors.blacks[10],
          margin: `${defaultTheme.spacing(3)} 0`
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(3),
          borderRadius: '12px'
        },
        elevation1: {
          boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 0px'
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '.MuiTableRow-root': {
            ':hover': {
              backgroundColor: '#F6F6F6'
            },
            ':active': {
              backgroundColor: '#E6E6E7'
            }
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px',
          color: 'white',
          fontWeight: 'bold',
          backgroundColor: newTheme.colors.blacks[90],
          padding: '5px 20px',
          borderRadius: '8px'
        },
        arrow: {
          color: newTheme.colors.blacks[90]
        }
      }
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          '.MuiPaginationItem-circular': {
            border: `1px solid ${newTheme.colors.blacks[10]}`,
            '&.MuiPaginationItem-ellipsis': {
              border: 'none'
            }
          },
          '.Mui-selected': {
            color: 'white'
          }
        }
      }
    }
  }
});
