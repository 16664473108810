import React from 'react';
import { Advisors, Tooltip, Dropdown } from '@matterapp/matter-ui';
import { colors } from '@matterapp/matter-theme';
import { 
  monthsMap, 
  getDaysInMonthList, 
  ETHNICITY_OPTIONS,
  GENDER_OPTIONS
} from './helpers';
import { 
  InputWrapper,
  BirthdayWrapper,
  StyledInput,
  ClearButton,
  StyledPaper,
  IconWrapper,
  StyledIcon
} from './styles';
import { Typography, Box } from '@mui/material';
import { ACTIONS } from './reducer';
import getCustomHrisProperties from 'graphql-queries/queries/hris/getCustomHrisProperties';
import { useQuery, useLazyQuery } from '@apollo/client';
import styled from 'styled-components';
import ContinuousIcon from '../icons/continuous';
import XButton from 'components/ClearButton/ClearButton';
import searchWorkspaceMembers from 'graphql-queries/queries/workspace/searchWorkspaceMembers';

const LineWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
  justifyContent: 'flex-start',
  marginBottom: theme.spacing(3),
  width: '100%',
  button: {
    alignSelf: 'center'
  },
  'div[disabled]': {
    border: '#99999B solid 1px',
    borderRadius: '4px'
  }
}));

function RenderTextProperty({ value, onChange, disabled, type }) {
  return (
    <StyledInput
      disabled={disabled}
      type={type === 'numeric' ? 'number' : 'text'}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}

function RenderChoicesProperty({ value, options, onChange, disabled }) {
  const parsedOptions = options.map((label) => ({ value: label, label }));

  return (
    <Advisors.RecurringModal.Dropdown
      disabled={disabled}
      options={parsedOptions}
      selectedOption={parsedOptions.find(({ value: optionValue }) => optionValue === value) || { label: 'Select option', value: '' }}
      handleChangeSelection={(choice) => {
        onChange(choice.value);
      }}
      width='100%'
      position={'absolute'}
    />
  );
}

function RenderDateProperty({ value, onChange, id, disabled }) {
  const elementId = `${id}_date_picker`;

  return (
    <InputWrapper>
      <StyledInput
        type='date'
        disabled={disabled}
        value={new Date(value) ? value : null} 
        id={elementId}
        onClick={() => {
          const el = document.getElementById(elementId);
          el.showPicker();
        }}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      {value && !disabled ? (
        <ClearButton>
          <Tooltip
            dark
            content='Reset field to default state'
          >
            <XButton onClick={() => onChange('')} />
          </Tooltip>
        </ClearButton>
      ) : null}
    </InputWrapper>
  );
}

function formatMembers(members) {
  return members.map((member) => {
    return {
      ...member.person,
      value: member.person.email
    };
  });
}

export default function MemberPropertiesSection(props) {
  const { state, dispatch, tenant, workspace } = props;
  const { id: tenantId, hrisSettings } = tenant;
  const { customFieldsMapping = [], syncingProps = [], status } = hrisSettings;
  const hrisSyncIsActive = status === 'active';
  const { id: workspaceId } = workspace || {};
  const { 
    hiredOn,
    birthDay, 
    birthMonth,
    ethnicity,
    gender,
    jobTitle,
    hrisProps,
    customProperties,
    directManager
  } = state?.settings;
  const { department } = hrisProps || {};

  const [searchMembers, { loading: isLoadingResults, data: searchMembersData }] = useLazyQuery(searchWorkspaceMembers);
  const { data: customHrisPropsData } = useQuery(getCustomHrisProperties, {
    variables: {
      tenantId
    }
  });

  const { customHrisProperties = [] } = customHrisPropsData || {};

  async function onSearch(value) {
    if (value) {
      searchMembers({
        variables: {
          workspaceId,
          searchString: value
        }
      });
    }
  }
    
  return (
    <StyledPaper>
      <Typography variant='h3' sx={{ mb: 3 }}>Member Properties</Typography>
      <LineWrapper>
        <IconWrapper>
          <Typography variant='body1' sx={{ fontWeight: 500 }}>
            Hired on
          </Typography>
          {syncingProps.includes('hired_on') && (
            <Tooltip
              dark
              content='This property automatically syncs with your HRIS.'
              style={{ marginLeft: '4px' }}
            >
              <ContinuousIcon />
            </Tooltip>
          )}
        </IconWrapper>
        <InputWrapper>
          <StyledInput
            disabled={syncingProps.includes('hired_on')}
            type="date"
            value={hiredOn} 
            id="hired_picker"
            onClick={() => {
              const el = document.getElementById('hired_picker');
              el.showPicker();
            }}
            onChange={(e) => {
              dispatch({ type: ACTIONS.UPDATE_HIRED_ON, payload: e.target.value });
            }}
          />
          {hiredOn && !syncingProps.includes('hired_on') ? (
            <ClearButton onClick={() => dispatch({ type: ACTIONS.UPDATE_HIRED_ON, payload: '' })}>
              <Tooltip
                dark
                content='Reset field to default state'
              >
                <XButton>
                  X
                </XButton>
              </Tooltip>
            </ClearButton>
          ) : null}
        </InputWrapper>
      </LineWrapper>
      <LineWrapper>
        <IconWrapper>
          <Typography variant='body1' sx={{ fontWeight: 500 }}>
            Date of Birth
          </Typography>
          {syncingProps.includes('birthday') && (
            <Tooltip
              dark
              content='This property automatically syncs with your HRIS.'
              style={{ marginLeft: '4px' }}
            >
              <ContinuousIcon />
            </Tooltip>
          )}
        </IconWrapper>
        <BirthdayWrapper>
          <Advisors.RecurringModal.Dropdown
            selectedOption={monthsMap.find(({ value }) => value === birthMonth) || { label: 'Month', value: '' }}
            options={monthsMap}
            handleChangeSelection={({ value }) => {
              dispatch({ type: ACTIONS.UPDATE_BIRTHDAY_MONTH, payload: value });
            }}
            placeholder="Month"
            disabled={syncingProps.includes('birthday')}
            size='medium'
          />
          <Advisors.RecurringModal.Dropdown
            selectedOption={birthDay ? { label: birthDay, value: birthDay } : { label: 'Day', value: '' }}
            options={getDaysInMonthList(birthMonth)}
            disabled={syncingProps.includes('birthday')}
            handleChangeSelection={({ value }) => {
              dispatch({ type: ACTIONS.UPDATE_BIRTHDAY_DAY, payload: value });
            }}
            placeholder="Day"
            size='medium'
          />
          {birthMonth && birthDay && !syncingProps.includes('birthday') ? (
            <ClearButton onClick={() => {
              dispatch({ type: ACTIONS.UPDATE_BIRTHDAY_MONTH, payload: '' });
              dispatch({ type: ACTIONS.UPDATE_BIRTHDAY_DAY, payload: '' });
            }}>
              <Tooltip
                dark
                content='Reset field to default state'
              >
                <XButton>
                X
                </XButton>
              </Tooltip>
            </ClearButton>
          ) : null}
        </BirthdayWrapper>
      </LineWrapper>
      <LineWrapper>
        <IconWrapper>
          <Typography variant='body1' sx={{ mt: 3, fontWeight: 500 }}>
            Ethnicity
          </Typography>
          {syncingProps.includes('ethnicity') && (
            <Tooltip
              dark
              content='This property automatically syncs with your HRIS.'
              style={{ marginLeft: '4px' }}
            >
              <ContinuousIcon />
            </Tooltip>
          )}
        </IconWrapper>
        <Box sx={{ mt: 0.5, width: '100%' }}>
          <Advisors.RecurringModal.Dropdown
            disabled={syncingProps.includes('ethnicity')}
            options={ETHNICITY_OPTIONS.sort((a, b) => a.label.localeCompare(b.label))}
            selectedOption={ETHNICITY_OPTIONS.find(({ value }) => value === ethnicity) || { label: 'Select option', value: '' }}
            handleChangeSelection={(choice) => {
              dispatch({ type: ACTIONS.UPDATE_ETHNICITY, payload: choice.value });
            }}
            width='100%'
            position={'absolute'}
          />
        </Box>
      </LineWrapper>
      <LineWrapper>
        <IconWrapper>
          <Typography variant='body1' sx={{ mt: 3, fontWeight: 500 }}>
            Gender
          </Typography>
          {syncingProps.includes('gender') && (
            <Tooltip
              dark
              content='This property automatically syncs with your HRIS.'
              style={{ marginLeft: '4px' }}
            >
              <ContinuousIcon />
            </Tooltip>
          )}
        </IconWrapper>
        <Box sx={{ mt: 0.5, width: '100%' }}>
          <Advisors.RecurringModal.Dropdown
            disabled={syncingProps.includes('gender')}
            options={GENDER_OPTIONS}
            selectedOption={GENDER_OPTIONS.find(({ value }) => value === gender) || { label: 'Select option', value: '' }}
            handleChangeSelection={(choice) => {
              dispatch({ type: ACTIONS.UPDATE_GENDER, payload: choice.value });
            }}
            width='100%'
            position={'absolute'}
          />
        </Box>
      </LineWrapper>
      <LineWrapper>
        <IconWrapper>
          <Typography variant='body1' sx={{ mt: 3, fontWeight: 500 }}>
            Job Title
          </Typography>
          {syncingProps.includes('job_title') && (
            <Tooltip
              dark
              content='This property automatically syncs with your HRIS.'
              style={{ marginLeft: '4px' }}
            >
              <ContinuousIcon />
            </Tooltip>
          )}
        </IconWrapper>
        <Box sx={{ mt: 0.5, width: '100%' }}>
          <StyledInput
            type="text"
            disabled={syncingProps.includes('job_title')}
            onChange={(e) => dispatch({ type: ACTIONS.UPDATE_JOB_TITLE, payload: e.target.value })}
            value={jobTitle} 
          />
        </Box>
      </LineWrapper>
      <LineWrapper>
        <IconWrapper>
          <Typography variant='body1' sx={{ fontWeight: 500 }}>
            Direct Manager
          </Typography>
          {syncingProps.includes('manager') ? (
            <Tooltip
              dark
              content='This property automatically syncs with your HRIS.'
              style={{ marginLeft: '4px' }}
            >
              <ContinuousIcon />
            </Tooltip>
          ) : (
            <Tooltip
              dark
              content='Selecting a Direct Manager does not impact team structure in Matter.'
              style={{ marginLeft: '4px' }}
            >
              <StyledIcon 
                name="infoCircle" 
                fill={colors.blacks[30]}
              />
            </Tooltip>
          )}
        </IconWrapper>
        <Box sx={{ mt: 0.5, width: '100%' }}>
          {syncingProps.includes('manager') ? (
            <StyledInput
              type="text"
              disabled
              value={directManager?.id ? directManager.email : null}
            />
          ) : (
            <Dropdown.Email.Tags
              filterItems
              items={formatMembers(searchMembersData?.searchMembers || [])}
              isValidVEmail={() => true}
              singleValue
              hasItem={directManager}
              onChange={(e, props) => {
                dispatch({ type: ACTIONS.UPDATE_DIRECT_MANAGER, payload: props });
              }}
              formatTagLabel={(value) => {
                return directManager?.fullName || value;
              }}
              value={directManager?.id ? [directManager.email] : null}
              onSearch={onSearch}
              isLoadingResults={isLoadingResults}
              emptyMenuMessageNoItems='No matches'
              emptyMenuMessage='Type the name of a member'
              placeholder='Select a member'
              fluid
            />
          )}
        </Box>
      </LineWrapper>
      {hrisSyncIsActive && (
        <Typography variant='body2' sx={{ color: 'blacks.60', mb: 3 }}>
          HRIS PROPERTIES
        </Typography>
      )}
      {hrisSyncIsActive && (
        <LineWrapper>
          <IconWrapper>
            <Typography variant='body1' sx={{ mt: 3, fontWeight: 500 }}>
              Department
            </Typography>
            <Tooltip
              dark
              content='This property automatically syncs with your HRIS.'
              style={{ marginLeft: '4px' }}
            >
              <ContinuousIcon />
            </Tooltip>
          </IconWrapper>
          <Box sx={{ mt: 0.5, width: '100%' }}>
            <StyledInput
              type="text"
              disabled
              value={department} 
            />
          </Box>
        </LineWrapper>
      )}
      {customHrisProperties.length > 0 && (
        <Typography variant='body2' sx={{ color: 'blacks.60', mb: 3 }}>
          CUSTOM PROPERTIES
        </Typography>
      )}
      {[...customHrisProperties]
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((property) => {
          const { id, label, type, options } = property;
          const value = customProperties?.find((prop) => prop.propertyId === id)?.value;
          const disabled = !!customFieldsMapping.find((field) => field.customPropertyId == id);
 
          const onChange = (value) => {
            dispatch({ type: ACTIONS.UPDATE_CUSTOM_HRIS_PROPERTY, payload: { id, value } });
          };

          let Component = RenderTextProperty;

          if (type === 'date') {
            Component = RenderDateProperty;
          } else if (type === 'choices') {
            Component = RenderChoicesProperty;
          }
          
          return (
            <LineWrapper key={id}>
              <IconWrapper>
                <Typography variant='body1' sx={{ mt: 3, fontWeight: 500 }}>
                  {label}
                </Typography>
                {disabled && (
                  <Tooltip
                    dark
                    content='This property automatically syncs with your HRIS.'
                    style={{ marginLeft: '4px' }}
                  >
                    <ContinuousIcon />
                  </Tooltip>
                )}
              </IconWrapper>
              <Box sx={{ mt: 0.5, width: '100%' }}>
                <Component 
                  id={id}
                  onChange={onChange} 
                  options={options}
                  value={value}
                  disabled={disabled}
                  type={type}
                />
              </Box>
            </LineWrapper>
          );
        })}
    </StyledPaper>
  );
}
