import { v4 as uuidv4 } from 'uuid';
import { merge } from 'lodash';
import { 
  getCompletedScreens, 
  isQuestionCompleted,
  QUESTION_DETAILS,
  SCREEN_NAMES,
  SCREENS_ORDER,
  getfrequencyConfig,
  parseFrequencyConfig
} from './consts';

export function getInitialState(survey, timezone, rewardsActive, step) {
  const {
    id: surveyId = null,
    name = '',
    description = '',
    questions = [],
    sendOnMyBehalf = false,
    sendReminder = true,
    recipients = [],
    frequency = 'once',
    closingDate,
    sendDate = '',
    coins = rewardsActive ? 20 : 0,
    notifications,
    isAnonymous = true,
    hasRewards = true,
    frequencyConfig
  } = merge({}, survey);
  let [date, time] = (sendDate || '').split(':');

  if (!sendDate) {
    time = 9;
    date = null;
  }

  const currentScreenName = step ? SCREENS_ORDER[step - 1] : SCREEN_NAMES.CREATE;

  const state = {
    surveyId,
    step,
    currentScreenCompleted: false,
    isEditing: !!surveyId,
    completedScreens: [],
    currentScreenName,
    autosave: false,
    isReady: false,
    name,
    coins,
    description,
    sendOnMyBehalf,
    sendReminder,
    recipients: recipients.map((recipient) => ({ label: null, value: recipient })),
    frequency,
    timezone,
    isAnonymous,
    hasRewards,
    frequencyConfig: parseFrequencyConfig(frequencyConfig, frequency),
    notifications: notifications || {
      response: true,
      digest: true
    },
    closeSurveyOnDate: !!closingDate,
    closingDate,
    sendDate: date,
    sendTime: `${time}:00`,
    sendNow: !sendDate,
    reorderingQuestions: false,
    questions: questions.map((question) => {
      const { type, question: text, answerOptions, canSkip, hasComment } = question;

      return { 
        ...QUESTION_DETAILS[type],
        id: question.id, 
        type, 
        question: text, 
        answerOptions, 
        required: !canSkip, 
        comments: hasComment
      };
    })
  };

  if (!state.frequencyConfig) {
    state.frequencyConfig = getfrequencyConfig(state);
  }
  
  const completedScreens = getCompletedScreens(state);

  return { ...state, completedScreens }; 
}

export function getStateTransferObject(state) {
  const { 
    name, 
    description, 
    questions,     
    sendOnMyBehalf,
    sendReminder,
    recipients,
    sendNow,
    sendDate,
    sendTime,
    frequency,
    closeSurveyOnDate,
    closingDate,
    coins,
    notifications,
    timezone,
    isAnonymous,
    hasRewards,
    frequencyConfig
  } = state;

  let sendDateValue = sendNow ? null : `${sendDate}:${sendTime}`;

  if (!sendDate) {
    sendDateValue = null;
  }
  
  const { sendOnDays, repeatValue, sendOnDay } = frequencyConfig;

  return { 
    name, 
    description,
    sendOnMyBehalf,
    sendReminder,
    recipients: recipients.map(({ value }) => value),
    sendDate: sendDateValue,
    timezone,
    notifications: {
      response: notifications.response,
      digest: notifications.digest
    },
    frequencyConfig: {
      sendOnDays, 
      repeatValue, 
      sendOnDay: {
        value: sendOnDay?.value,
        number: sendOnDay?.number,
        weekday: sendOnDay?.weekday
      }
    },
    isAnonymous,
    hasRewards,
    frequency,
    closingDate: closeSurveyOnDate ? closingDate : null,
    coins: coins ? parseInt(coins, 10) : 0,
    questions: questions.map(({ id, type, question, answerOptions, required, comments, category }) => {

      return { 
        id, 
        type, 
        category,
        question, 
        answerOptions: answerOptions?.map(({ label }) => ({ label })), 
        canSkip: !required, 
        hasComment: comments 
      };
    })
  };
}

export const ACTIONS = {
  UPDATE_TITLE: 'UPDATE_TITLE',
  UPDATE_DESCRIPTION: 'UPDATE_DESCRIPTION',
  UPDATE_AUTOSAVE: 'UPDATE_AUTOSAVE',
  ADD_QUESTION: 'ADD_QUESTION',
  UPDATE_QUESTION_TYPE: 'UPDATE_QUESTION_TYPE',
  UPDATE_QUESTION_TEXT: 'UPDATE_QUESTION_TEXT',
  TOGGLE_QUESTION_REQUIRED: 'TOGGLE_QUESTION_REQUIRED',
  DELETE_QUESTION: 'DELETE_QUESTION',
  UPDATE_QUESTION_OPTION: 'UPDATE_QUESTION_OPTION',
  ADD_QUESTION_OPTION: 'ADD_QUESTION_OPTION',
  DELETE_QUESTION_OPTION: 'DELETE_QUESTION_OPTION',
  TOGGLE_ENABLE_COMMENTS: 'TOGGLE_ENABLE_COMMENTS',
  ADD_SURVEY_ID: 'ADD_SURVEY_ID',
  TOGGLE_SENDER: 'TOGGLE_SENDER',
  TOGGLE_REMINDER: 'TOGGLE_REMINDER',
  ADD_COMPLETED_SCREEN: 'ADD_COMPLETED_SCREEN',
  ADD_RECIPIENT: 'ADD_RECIPIENT',
  REMOVE_RECIPIENT: 'REMOVE_RECIPIENT',
  MOVE_TO_SCREEN: 'MOVE_TO_SCREEN',
  UPDATE_SEND_DATE: 'UPDATE_SEND_DATE',
  UPDATE_SEND_TIME: 'UPDATE_SEND_TIME',
  UPDATE_SEND_NOW: 'UPDATE_SEND_NOW',
  UPDATE_FREQUENCY: 'UPDATE_FREQUENCY',
  UPDATE_CLOSE_ON_DATE: 'UPDATE_CLOSE_ON_DATE',
  UPDATE_CLOSING_DATE: 'UPDATE_CLOSING_DATE',
  UPDATE_COINS_PER_SURVEY: 'UPDATE_COINS_PER_SURVEY',
  TOGGLE_COMMENTS: 'TOGGLE_COMMENTS',
  TOGGLE_DIGEST: 'TOGGLE_DIGEST',
  TOGGLE_REORDER_QUESTIONS: 'TOGGLE_REORDER_QUESTIONS',
  REORDER_QUESTIONS: 'REORDER_QUESTIONS',
  CAN_MOVE_TO_NEXT_SCREEN: 'CAN_MOVE_TO_NEXT_SCREEN',
  TOGGLE_ANONYMITY: 'TOGGLE_ANONYMITY',
  TOGGLE_REWARDS: 'TOGGLE_REWARDS',
  // frequencyConfig
  UPDATE_SEND_ON_DATES: 'UPDATE_SEND_ON_DATES',
  UPDATE_REPEAT_FREQUENCY: 'UPDATE_REPEAT_FREQUENCY',
  UPDATE_SEND_ON_DAY: 'UPDATE_SEND_ON_DAY',
  UPDATE_TIMEZONE: 'UPDATE_TIMEZONE'
};

export default function reducer(state, action) {
  switch (action.type) {

  case ACTIONS.ADD_COMPLETED_SCREEN: {
    return {
      ...state,
      completedScreens: [...state.completedScreens, action.payload]
    };
  }

  case ACTIONS.MOVE_TO_SCREEN: {
    const completedScreens = getCompletedScreens(state);
    const isPreviousScreen = SCREENS_ORDER.indexOf(state.currentScreenName) > SCREENS_ORDER.indexOf(action.payload);
    
    let currentScreenName = action.payload; 
    
    if (!completedScreens.includes(state.currentScreenName)) {
      currentScreenName = state.currentScreenName;
    }

    if (isPreviousScreen) {
      currentScreenName = action.payload;
    }

    const step = SCREENS_ORDER.indexOf(currentScreenName) + 1;

    return {
      ...state,
      step,
      currentScreenName,
      currentScreenCompleted: false,
      completedScreens,
      showErrors: false
    };
  }

  case ACTIONS.UPDATE_TITLE: {
    const len = action.payload?.length;
    const isReady = len >= 5;

    return {
      ...state,
      name: action.payload,
      autosave: isReady
    };
  }

  case ACTIONS.UPDATE_DESCRIPTION: {
    const len = action.payload?.length;
    const isReady = len >= 25 && len <= 400;

    return {
      ...state,
      description: action.payload,
      autosave: isReady && state.name.length >= 5
    };
  }

  case ACTIONS.UPDATE_AUTOSAVE: {
    return {
      ...state,
      autosave: false
    };
  }

  case ACTIONS.ADD_QUESTION: {
    return {
      ...state,
      autosave: true,
      questions: [...state.questions, { id: uuidv4(), ...QUESTION_DETAILS.text }]
    };
  }

  case ACTIONS.UPDATE_QUESTION_TYPE: {
    const { id, type } = action.payload;
    const index = state.questions.findIndex((question) => question.id === id);
    const newQuestions = [...state.questions];
    const { answerOptions, question, required, comments } = newQuestions[index];

    newQuestions[index] = { 
      ...QUESTION_DETAILS[type], 
      question: question || QUESTION_DETAILS[type].question, 
      required,
      comments,
      answerOptions: [...(answerOptions || QUESTION_DETAILS[type].answerOptions || [])], 
      id
    };
    newQuestions[index].completed = isQuestionCompleted(newQuestions[index]);
    
    return {
      ...state,
      autosave: true,
      questions: newQuestions
    };
  }

  case ACTIONS.UPDATE_QUESTION_TEXT: {
    const { id, question } = action.payload;
    const index = state.questions.findIndex((question) => question.id === id);
    const newQuestions = [...state.questions];
    
    newQuestions[index] = { 
      ...newQuestions[index], 
      question, 
      errors: newQuestions[index].errors
        .filter((error) => error.field !== 'question')
    };
    newQuestions[index].completed = isQuestionCompleted(newQuestions[index]);

    return {
      ...state,
      autosave: true,
      questions: newQuestions
    };
  }

  case ACTIONS.TOGGLE_QUESTION_REQUIRED: {
    const { id } = action.payload;
    const index = state.questions.findIndex((question) => question.id === id);
    const newQuestions = [...state.questions];
    newQuestions[index] = { ...newQuestions[index], required: !newQuestions[index].required };

    return {
      ...state,
      autosave: true,
      questions: newQuestions
    };
  }

  case ACTIONS.UPDATE_QUESTION_OPTION: {
    const { id, index, option } = action.payload;
    const questionIndex = state.questions.findIndex((question) => question.id === id);
    const newQuestions = [...state.questions];
    const errors = newQuestions[questionIndex].errors
      .filter((error) => error.field !== `option[${index}]`);

    if (option?.trim().length > 65) {
      errors.push({
        message: 'An option cannot be longer than 65 characters',
        field: `option[${index}]`
      });
    }

    newQuestions[questionIndex] = { 
      ...newQuestions[questionIndex], 
      errors
    };

    newQuestions[questionIndex].answerOptions[index] = { label: option };
    newQuestions[questionIndex].completed = isQuestionCompleted(newQuestions[questionIndex]);
    
    return {
      ...state,
      autosave: true,
      questions: newQuestions
    };
  }

  case ACTIONS.ADD_QUESTION_OPTION: {
    const { id } = action.payload;
    const questionIndex = state.questions.findIndex((question) => question.id === id);
    const newQuestions = [...state.questions];

    newQuestions[questionIndex] = { 
      ...newQuestions[questionIndex], 
      errors: newQuestions[questionIndex].errors
        .filter((error) => error.field !== 'options')
    };

    newQuestions[questionIndex].answerOptions.push({ label: '' });
    newQuestions[questionIndex].completed = isQuestionCompleted(newQuestions[questionIndex]);
    
    return {
      ...state,
      autosave: true,
      questions: newQuestions
    };
  }

  case ACTIONS.DELETE_QUESTION_OPTION: {
    const { id, index } = action.payload;
    const questionIndex = state.questions.findIndex((question) => question.id === id);
    const newQuestions = [...state.questions];
    newQuestions[questionIndex].answerOptions.splice(index, 1);
    newQuestions[questionIndex].completed = isQuestionCompleted(newQuestions[questionIndex]);

    return {
      ...state,
      autosave: true,
      questions: newQuestions
    };
  }

  case ACTIONS.TOGGLE_ENABLE_COMMENTS: {
    const { id } = action.payload;
    const index = state.questions.findIndex((question) => question.id === id);
    const newQuestions = [...state.questions];
    newQuestions[index] = { ...newQuestions[index], comments: !newQuestions[index].comments };

    return {
      ...state,
      autosave: true,
      questions: newQuestions
    };
  }

  case ACTIONS.DELETE_QUESTION: {
    const { id } = action.payload;
    const newQuestions = state.questions.filter((question) => question.id !== id);

    return {
      ...state,
      autosave: true,
      questions: newQuestions
    };
  }

  case ACTIONS.ADD_SURVEY_ID: {
    return {
      ...state,
      surveyId: action.payload
    };
  }

  case ACTIONS.TOGGLE_SENDER: {
    return {
      ...state,
      autosave: true,
      sendOnMyBehalf: !state.sendOnMyBehalf
    };
  }

  case ACTIONS.TOGGLE_REMINDER: {
    return {
      ...state,
      autosave: true,
      sendReminder: !state.sendReminder
    };
  }

  case ACTIONS.ADD_RECIPIENT: {
    return {
      ...state,
      autosave: true,
      showErrors: false,
      recipients: action.payload.value === 'ALL' ? [action.payload] : [...state.recipients, action.payload]
    };
  }

  case ACTIONS.REMOVE_RECIPIENT: {
    return {
      ...state,
      autosave: true,
      recipients: state.recipients.filter(({ value }) => value !== action.payload.value)
    };
  }

  case ACTIONS.UPDATE_SEND_DATE: {
    const { value, minHour } = action.payload;
    const newState = {
      ...state,
      autosave: true,
      showErrors: false,
      sendNow: false,
      sendDate: value,
      sendTime: minHour
    };

    const frequencyConfig = getfrequencyConfig(newState);

    return {
      ...newState,
      frequencyConfig
    };
  }

  case ACTIONS.UPDATE_SEND_TIME: {
    return {
      ...state,
      autosave: true,
      showErrors: false,
      sendTime: action.payload
    };
  }

  case ACTIONS.UPDATE_SEND_NOW: {
    const newState = {
      ...state,
      autosave: true,
      sendNow: action.payload
    };

    const frequencyConfig = getfrequencyConfig(newState);

    return {
      ...newState,
      frequencyConfig
    };
  }

  case ACTIONS.UPDATE_FREQUENCY: {
    const newState = {
      ...state,
      autosave: true,
      frequency: action.payload,
      closeSurveyOnDate: false,
      closingDate: null
    };

    const frequencyConfig = getfrequencyConfig(newState);

    return {
      ...newState,
      frequencyConfig
    };
  }

  case ACTIONS.UPDATE_CLOSE_ON_DATE: {
    return {
      ...state,
      autosave: true,
      closeSurveyOnDate: action.payload
    };
  }

  case ACTIONS.UPDATE_CLOSING_DATE: {
    return {
      ...state,
      autosave: true,
      closingDate: action.payload,
      closeSurveyOnDate: true
    };
  }

  case ACTIONS.UPDATE_COINS_PER_SURVEY: {
    return {
      ...state,
      autosave: true,
      coins: action.payload
    };
  }

  case ACTIONS.TOGGLE_COMMENTS: {
    return {
      ...state,
      autosave: true,
      notifications: {
        ...state.notifications,
        response: !state.notifications.response
      }
    };
  }

  case ACTIONS.TOGGLE_DIGEST: {
    return {
      ...state,
      autosave: true,
      notifications: {
        ...state.notifications,
        digest: !state.notifications.digest
      }
    };
  }

  case ACTIONS.TOGGLE_REORDER_QUESTIONS: {
    return {
      ...state,
      reorderingQuestions: !state.reorderingQuestions
    };
  }

  case ACTIONS.REORDER_QUESTIONS: {
    return {
      ...state,
      autosave: true,
      questions: action.payload.map((id) => state.questions.find((question) => question.id === id))
    };
  }

  case ACTIONS.CAN_MOVE_TO_NEXT_SCREEN: {
    const completedScreens = getCompletedScreens(state);

    return {
      ...state,
      completedScreens,
      currentScreenCompleted: completedScreens.includes(state.currentScreenName),
      questions: state.questions.map((question) => {
        const hasError = !isQuestionCompleted(question);
        const errors = [];

        if (hasError) {
          if (!question.question?.trim().length) {
            errors.push({
              message: 'Add a question',
              field: 'question'
            });
          }
  
          if (question.answerOptions?.length < 2) {
            errors.push({
              message: 'Add at least 2 options',
              field: 'options'
            });
          }

          question.answerOptions?.forEach((option, index) => {
            if (!option.label?.trim().length) {
              errors.push({
                message: 'Add an option',
                field: `option[${index}]`
              });
            } else if (option.label?.trim().length > 65) {
              errors.push({
                message: 'An option cannot be longer than 65 characters',
                field: `option[${index}]`
              });
            }
          });
        }
        
        return { ...question, errors };
      }),
      showErrors: !completedScreens.includes(state.currentScreenName)
    };
  }

  case ACTIONS.TOGGLE_ANONYMITY: {
    return {
      ...state,
      autosave: true,
      isAnonymous: !state.isAnonymous
    };
  }

  case ACTIONS.TOGGLE_REWARDS: {
    return {
      ...state,
      autosave: true,
      hasRewards: !state.hasRewards
    };
  }

  case ACTIONS.UPDATE_SEND_ON_DATES: {
    return {
      ...state,
      autosave: true,
      frequencyConfig: {
        ...state.frequencyConfig,
        sendOnDays: action.payload
      }
    };
  }

  case ACTIONS.UPDATE_REPEAT_FREQUENCY: {
    return {
      ...state,
      autosave: true,
      frequencyConfig: {
        ...state.frequencyConfig,
        repeatValue: action.payload
      }
    };
  }

  case ACTIONS.UPDATE_SEND_ON_DAY: {
    return {
      ...state,
      autosave: true,
      frequencyConfig: {
        ...state.frequencyConfig,
        sendOnDay: action.payload
      }
    };
  }

  case ACTIONS.UPDATE_TIMEZONE: {
    return {
      ...state,
      autosave: true,
      timezone: action.payload
    };
  }

  default: {
    return {};
  }
  }
}