import React from 'react';
import { compose } from 'recompose';
import { Resources } from '@matterapp/routing';
import PrivateRoute from 'modules/routes/components/PrivateRoute';
import {
  FeedbackFlowPage
} from '../pages/feedback-received';
import FeedbackReceivedViewFeedbackPage from 'routes/pages/feedback-received/components/FeedbackReceivedViewFeedbackPage';
import { withRouteParams } from '../../hocs';
import { Route } from 'react-router-dom';

const feedbackFlowHoc = compose(
  withRouteParams({
    feedbackReceivedIdEncoded: true,
    workspaceId: true,
  })
);

const FeedbackFlowPageComponent = feedbackFlowHoc(FeedbackFlowPage);

export const FeedbackFlow = (
  <Route
    exact
    path={Resources.feedbackFlow.pattern}
    element={(
      <PrivateRoute>
        <FeedbackFlowPageComponent />
      </PrivateRoute>
    )}
  />
);

export const FeedbackReceivedViewFeedback = (
  <Route
    exact
    path={Resources.feedbackReceivedViewFeedback.pattern}
    element={(
      <PrivateRoute>
        <FeedbackReceivedViewFeedbackPage />
      </PrivateRoute>
    )}
  />
);
