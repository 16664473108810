import React, { useEffect, useState } from 'react';
import { useSpring } from 'react-spring';
import Badge from 'components/SkillUnit/Badge';
import Unit from 'components/SkillUnit/Gauge/Unit';
import { getSkillColorPaletteFromSkill } from 'components/SkillUnit/helpers';
import {
  getDescriptionText,
  getDescriptionTitle,
} from 'components/SkillUnit/skill-ratings-map';
import {
  Bar,
  BarContainer,
  Container,
  SkillBadge,
  SkillBadgeContainer,
  SkillInfo,
  SkillInfoContainer,
  SkillName,
  SkillScore,
  TooltipContainer,
  UnitContainer,
} from 'components/SkillUnit/Gauge/styles';
import DarkMenu from 'components/Menu/Dark/Menu';
import { Tooltip } from '@matterapp/matter-ui';

function Gauge(props) {
  const {
    allowZeroRating,
    animateIn,
    badge,
    className,
    maxRating,
    name,
    numberOfUnits,
    onClickSkill,
    skill,
    rating,
    showScore,
    showProvidedScore, // Show score contains hacky business logic, this property enforces to display score as-is
    shouldAnimateIn,
    skillNameNumberOfLines,
    alwaysShowBar,
    rcLabel,
    topRightMenuContent,
    displayScoreFn = (number) => number?.toFixed ? number.toFixed(1) : '',
    tooltipContent
  } = props;
  const color = getSkillColorPaletteFromSkill(skill);

  const scoreMultiplier = maxRating / numberOfUnits;
  const baseRating = allowZeroRating
    ? rating
    : Math.max(rating, scoreMultiplier);
  const calculatedScore = baseRating / scoreMultiplier;
  const calculatedScoreValue = Math.floor(calculatedScore);
  const showBar = alwaysShowBar || (!isNaN(rating) && rating !== null);
  const units = [];
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isPopupInitiallyClicked, setPopupInitiallyClicked] = useState(false);
  const [isBarPopupOpen, setBarPopupOpen] = useState(false);
  const [isBarPopupInitiallyClicked, setBarPopupInitiallyClicked] = useState(
    false
  );
  const [canAnimate, setCanAnimate] = useState(false);

  let isMounted = true;
  useEffect(() => {
    if (!canAnimate && isMounted) {
      setTimeout(() => {
        setCanAnimate(true);
      }, 200);
    }
    return () => {
      isMounted = false;
    };
  });

  const tooltipProps = {
    content: tooltipContent || (
      <TooltipContainer>
        {rating ? (
          <>
            <span>
              {showScore ? calculatedScoreValue : calculatedScore}.{' '}
              {getDescriptionTitle(calculatedScoreValue)}{' '}
            </span>
            <span>{getDescriptionText(calculatedScoreValue, '', true)}</span>
          </>
        ) : (
          <>
            <span>
              <span>No feedback: </span>
            </span>
            <span>You have yet to receive feedback on this skill. Request feedback from a peer.</span>
          </>
        )}
      </TooltipContainer>
    ),
    dark: true,
  };

  for (let i = 0; i < numberOfUnits; i++) {
    const unit = <Unit key={`${i}-${rating}-cell`} />;
    const emptyState = i == 0 && (isNaN(rating) || rating == null || rating == 0);

    if (emptyState || i + 1 === Math.ceil(calculatedScore)) {
      units.push(
        <Tooltip
          key={`${i}-${rating}-tooltip`}
          {...tooltipProps}
          isOpen={isBarPopupOpen}
        >
          {unit}
        </Tooltip>
      );
    } else {
      units.push(unit);
    }
  }
  
  let barWidth = shouldAnimateIn ? 0 : baseRating / maxRating;
  let displayedScoreValue = shouldAnimateIn ? 0 : calculatedScore;
  if (shouldAnimateIn && animateIn && canAnimate) {
    barWidth = baseRating / maxRating;
    displayedScoreValue = calculatedScore;
  }
  const barProps = useSpring({
    transform: `scaleX(${barWidth})`,
    from: { transform: 'scaleX(0)' },
    config: { friction: 25 },
  });
  const skillScoreProps = showScore
    ? useSpring({ number: displayedScoreValue, from: { number: 0 } })
    : { number: { interpolate: () => {} } };
  const displayedScore = showScore
    ? skillScoreProps.number.interpolate(displayScoreFn)
    : '-';

  const handleClickPopup = () => {
    if (!isPopupInitiallyClicked) {
      // Edge case where popup toggles on first open.
      setPopupOpen(true);
      setPopupInitiallyClicked(true);
    } else {
      setPopupOpen(!isPopupOpen);
    }
  };

  const handleClickBarPopup = () => {
    if (!isBarPopupInitiallyClicked) {
      // Edge case where popup toggles on first open.
      setBarPopupOpen(true);
      setBarPopupInitiallyClicked(true);
    } else {
      setBarPopupOpen(!isBarPopupOpen);
    }
  };

  const bar = (
    <BarContainer
      color={color}
      onMouseEnter={() => {
        setBarPopupOpen(true);
      }}
      onMouseLeave={() => setBarPopupOpen(false)}
      onClick={handleClickBarPopup}
      showScore={showScore}
    >
      {rating || rating == 0 ? (
        <Bar color={color} style={barProps} />
      ) : null}
      <UnitContainer>{units}</UnitContainer>
    </BarContainer>
  );

  const skillBadge = badge || <Badge skill={skill} />;
  const skillName = name || skill.name;

  return (
    <Container className={className} showScore={showScore || showProvidedScore} data-rc={rcLabel}>
      <SkillInfoContainer showScore={showScore || showProvidedScore}>
        <SkillInfo>
          <SkillBadgeContainer>
            <SkillBadge onClick={onClickSkill} data-rc="skill-badge">
              {skillBadge}
            </SkillBadge>
            {topRightMenuContent && (
              <DarkMenu menuMap={topRightMenuContent}/>
            )}
          </SkillBadgeContainer>
          <SkillName onClick={onClickSkill} numberOfLines={skillNameNumberOfLines}>{skillName}</SkillName>
        </SkillInfo>
        {(showScore || showProvidedScore) && (
          <Tooltip {...tooltipProps} isOpen={isPopupOpen}>
            <SkillScore
              color={color}
              showScore={canAnimate && rating}
              onMouseEnter={() => setPopupOpen(true)}
              onMouseLeave={() => setPopupOpen(false)}
              onClick={handleClickPopup}
            >
              {showProvidedScore ? displayScoreFn(rating) : displayedScore}
            </SkillScore>
          </Tooltip>
        )}
      </SkillInfoContainer>
      {showBar && bar}
    </Container>
  );
};

Gauge.defaultProps = {
  ability: {},
  allowZeroRating: true,
  maxRating: 10,
  numberOfUnits: 5,
  alwaysShowBar: false,
};

export default Gauge;
