
import React, { useContext } from 'react';
import { 
  Typography
} from '@mui/material';
import { 
  SeetingsWrapper,
  IconWrapper,
  HeaderWrapper, 
  SubSection,
  SubSectionWrapper,
  SubSectionHeader
} from './styles';
import RightChevron from './icons/rightChevron';
import TeamIcon from './icons/team';
import UserContext from 'context/UserContext/UserContext';
import { useNavigate } from 'react-router-dom';
import { Resources } from '@matterapp/routing';
import AdminsSettingsHoc from './AdminsSettingsHoc';

export default function TeamSettings({ isChannelSetting }) {
  const { currentWorkspaceId: workspaceId, isAdminMember, isChannelAdmin } = useContext(UserContext);
  const navigate = useNavigate();
  const params = isChannelSetting ? { isChannelSetting: true } : {};

  if (!isAdminMember && !(isChannelSetting && isChannelAdmin)) {
    return null;
  }

  return (
    <SeetingsWrapper>
      <HeaderWrapper>
        <IconWrapper>
          <TeamIcon />
        </IconWrapper>
        <Typography variant='h4'>Member and Group Management</Typography>
      </HeaderWrapper>
      <SubSectionWrapper>
        <AdminsSettingsHoc>
          <SubSection onClick={() => navigate(Resources.workspaceSettingsMembers.path({ workspaceId }, params))}>
            <SubSectionHeader>
              <Typography variant='h5' component='p'>Members</Typography>
              <RightChevron />
            </SubSectionHeader>
            <Typography variant='body2'>
              Edit member permissions, properties, celebrations, and more.
            </Typography>
          </SubSection>
        </AdminsSettingsHoc>
        <AdminsSettingsHoc>
          <SubSection onClick={() => navigate(Resources.workspaceSettingsTeams.path({ workspaceId }, params))}>
            <SubSectionHeader>
              <Typography variant='h5' component='p'>Groups</Typography>
              <RightChevron />
            </SubSectionHeader>
            <Typography variant='body2'>
              Create and manage groups, sub-groups, and group managers.
            </Typography>
          </SubSection>
        </AdminsSettingsHoc>
      </SubSectionWrapper>
    </SeetingsWrapper>
  );
}
