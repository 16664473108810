import React, { useEffect, useState } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import getAllRewardRequests from 'graphql-queries/queries/rewards/getAllRewardRequests';
import getPendingRewardRequests from 'graphql-queries/queries/rewards/getPendingRewardRequests';
import getCurrentMemberProperties from 'graphql-queries/queries/rewards/getCurrentMemberProperties';
import approveReward from 'graphql-queries/queries/rewards/approveReward';
import declineReward from 'graphql-queries/queries/rewards/declineReward';
import { useMutation } from '@apollo/client';
import { Confirm } from '@matterapp/matter-ui';
import { TABLE_HEADERS } from './consts';
import { VALUE_TYPE } from 'routes/pages/rewards-store/components/consts';
import RewardRequestTable from './RequestTable';
import Toast from 'components/Toast/Toast';

export default function PendingTab({ pendingRequests, tenant, workspaceId, isLoading, refetchCurrentUser, refetchDebitRows }) {
  const { tier, rewardSettings, id: tenantId } = tenant;
  const isMatterPro = tier === 'pro' || tier === 'enterprise';
  const isRewardsActive = rewardSettings.isRewardsActive && isMatterPro;
  const [isRewardRequestModalOpen, setIsRewardRequestModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();

  const { rewardItem, name: rewardName, faceValue, requesterUser } = selectedRequest || {};
  const { currencyCode, valueType = VALUE_TYPE.FIXED } = rewardItem || {};

  const currencySymbol = getSymbolFromCurrency(currencyCode);
  const rewardText = valueType === VALUE_TYPE.FIXED ? rewardName : `${currencySymbol}${faceValue} ${rewardName}`;
  const subHeader = `Would you like to approve ${requesterUser?.firstName} ${requesterUser?.lastName}'s request to redeem a ${rewardText}?`;
  
  const refetchQueries = [
    {
      query: getPendingRewardRequests,
      variables: {
        tenantId,
      }
    },
    { 
      query: getAllRewardRequests,
      variables: {
        tenantId,
      }
    },
    {
      query: getCurrentMemberProperties,
      variables: {
        tenantId
      }
    }
  ];
  
  const [approveRewardMutation, { loading: isApprovalLoading, error: approvalError, data: approveData }] = useMutation(approveReward, {
    refetchQueries,
    update(cache, { data: { approveReward } }) {
      const { balanceAfterRedemption } = approveReward;

      if (balanceAfterRedemption != null) {
        cache.modify({
          id: cache.identify(tenant),
          fields: {
            rewardsFundingSettings(cached) {
              return {
                ...cached,
                balance: balanceAfterRedemption
              };
            }
          }
        });
      }
    }
  });

  const [declineRewardMutation, { loading: isDeclineLoading, error: declineError }] = useMutation(declineReward, {
    refetchQueries,
  });

  const declineRequestFunction = async (request) => {
    await declineRewardMutation({
      variables: {
        rewardRequestId: request.id,
        workspaceId
      },
    });
    setIsRewardRequestModalOpen(false);
    if (declineError) {
      Toast.error('Something went wrong, please try again later');
    } else {
      Toast.info('Request has been declined');
    }
  };

  const approveRequestFunction = async (request) => {
    await approveRewardMutation({
      variables: { 
        rewardRequestId: request.id,
        workspaceId
      },
    });
    setIsRewardRequestModalOpen(false);

    Toast.success('Request has been approved');

    setTimeout(async () => {
      refetchCurrentUser();
      refetchDebitRows();
    }, 5000);
  };

  useEffect(() => {
    if (approveData?.approveReward) {
      const { balanceAfterRedemption } = approveData.approveReward;

      console.log('balanceAfterRedemption', balanceAfterRedemption);
    }
  }, [approveData]);

  useEffect(() => {
    if (approvalError) {
      Toast.error(approvalError.message);
    }
  }, [approvalError]);

  const onClickDecline = async (request) => await declineRequestFunction(request);
  const onClickApprove = async (request) => await approveRequestFunction(request);
  const onMobileClickDecline = async () => await declineRequestFunction(selectedRequest);
  const onMobileClickApprove = async () => await approveRequestFunction(selectedRequest);

  return (
    <>
      <Confirm
        header='Reward Request'
        subHeader={subHeader}
        cancelButtonLabel='Decline'
        confirmButtonLabel='Yes, Approve'
        isOpen={isRewardRequestModalOpen}
        onClickCancel={onMobileClickDecline}
        onClickConfirm={onMobileClickApprove}
        onClose={() => {
          setIsRewardRequestModalOpen(false);
          setSelectedRequest();
        }}
      />
      <RewardRequestTable
        isPendingTab
        isRewardsActive={isRewardsActive}
        tenant={tenant}
        isLoading={isLoading || isApprovalLoading || isDeclineLoading}
        rewardRequests={pendingRequests}
        lastColumnHeader={TABLE_HEADERS.APPROVE}
        setSelectedRequest={setSelectedRequest}
        onClickApprove={onClickApprove}
        onClickDecline={onClickDecline}
        onClickMore={(request) => {
          setIsRewardRequestModalOpen(true);
          setSelectedRequest(request);
        }}
        emptyStateMessage='No Pending Requests'
      />
    </>
  );
};
