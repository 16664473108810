import { 
  Box,
  Typography,
  Paper,
  TableCell,
  TableRow
} from '@mui/material';
import styled from 'styled-components';
import { Button as MatterButton } from '@matterapp/matter-ui';
import DarkMenu from 'components/Menu/Dark/Menu';

export const NewSurveyButton = styled(MatterButton.Simple.Primary.Vivid)(({ theme }) => ({

}));

export const StyledBodyRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.palette.blacks[5],
  },
  ':active': {
    backgroundColor: theme.palette.purple[5]
  },
  position: 'relative'
}));

export const StyledDarkMenu = styled(DarkMenu)(({ theme }) => ({
  width: '40px',
  height: '40px'
}));

export const NoComments = styled(Typography)(({ theme }) => ({
  color: theme.palette.blacks[60], 
  textAlign: 'center'
}));

export const MainSection = styled(Paper)(({ theme }) => ({
  padding: 0,
  minHeight: '466px',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    borderRadius: 0
  }
}));

export const TopSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    margin: '20px'
  }
}));

export const FilterTabsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: `1px solid ${theme.palette.blacks[10]}`,
  color: theme.palette.blacks[60],
  borderRadius: theme.spacing(0.5),
  alignSelf: 'flex-start',
  margin: theme.spacing(3),
  'div:nth-child(3)': {
    borderLeft: `1px solid ${theme.palette.blacks[10]}`,
    borderRight: `1px solid ${theme.palette.blacks[10]}`
  },
  'div:first-of-type': {
    borderRight: `1px solid ${theme.palette.blacks[10]}`
  }
}));

export const FilterTab = styled(Box)(({ theme, isActive }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  fontSize: theme.font.size.S,
  lineHeight: theme.lineHeight.L,
  textAlign: 'center',
  background: isActive ? theme.palette.blues[1] : 'none',
  color: isActive ? theme.palette.blues[60] : theme.palette.blacks[60],
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  '&:first-of-type': {
    borderTopLeftRadius: theme.spacing(0.5),
    borderBottomLeftRadius: theme.spacing(0.5),
  },
  '&:last-of-type': {
    borderTopRightRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5)
  },
  '&:hover': {
    cursor: 'pointer',
    background: theme.palette.blues[1],
    color: theme.palette.blues[60]
  }
}));

export const Caption = styled.caption({
  height: '320px',
  textAlign: 'center',
  'p:first-child': {
    marginTop: '120px'
  }
});

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderTop: `solid 1px ${theme.palette.blacks[10]}`,
  borderBottom: `solid 1px ${theme.palette.blacks[10]}`
}));

export const HeaderCell = styled(TableCell)
  .withConfig({ shouldForwardProp: (prop) => {
    return prop !== 'visibleOnMobile';
  } })
  (({ theme, visibleOnMobile }) => ({
    border: 'none',
    fontWeight: 'normal',
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    color: theme.palette.blacks[60],
    [theme.breakpoints.down('md')]: {
      display: visibleOnMobile ? 'table-cell' : 'none'
    }
  }));

export const TitleHeaderCell = styled(HeaderCell)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}));

export const MainTableCell = styled(TableCell)(({ theme }) => ({
  width: '420px',
  maxWidth: '420px',
  p: {
    lineHeight: '20px'
  },
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  [theme.breakpoints.down('sm')]: {
    width: '90%'
  }
}));

export const DesktopOnlyCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

export const SurveyName = styled(Typography)(({ theme }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap', 
  width: '380px',
  [theme.breakpoints.down('sm')]: {
    width: '300px'
  }
}));

export const MenuTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  width: '40px',
  maxWidth: '40px'
}));
