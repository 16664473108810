import React, { useState, useEffect, useReducer } from 'react';
import { Advisors, Button as MatterButton, Modal, Input } from '@matterapp/matter-ui';
import { Divider, Typography, Box } from '@mui/material';
import saveSegment from 'graphql-queries/mutations/hris/saveSegment';
import deleteSegment from 'graphql-queries/mutations/hris/deleteSegment';
import getSegments from 'graphql-queries/queries/hris/getSegments';
import getSegmentMemberCountQuery from 'graphql-queries/queries/hris/getSegmentMemberCount';
import { useMutation, useLazyQuery } from '@apollo/client';
import Toast from 'components/Toast/Toast';
import { Confirm } from '@matterapp/matter-ui';
import { getInitialState, reducer, ACTIONS } from './reducer';
import { RULE_OPTIONS, defaultHrisProperties, getCanSave, rulesAreValid } from './helpers';
import { StyledModalBody, LineItem, RulesWrapper, StyledModal, InputWrapper } from './styles';
import RuleBreakdown from './RuleBreakdown';

export default function EditSegmentModal(props) {
  const { isOpen, onClose, tenant, segment, customHrisProperties } = props;
  const { id: tenantId } = tenant;
  const [state, dispatch] = useReducer(reducer, getInitialState({}));
  const [memberCount, setMemberCount] = useState(null);
  const { settings, creatingNew, initialized, operators, nameHasError } = state;
  const { name: segmentName, applyRules, rules } = settings;
  const [removingSegment, updateRemovingSegment] = useState(null);
  const [saveSegmentMutation, { loading: savingSegment, data: savedSegment, error }] = useMutation(saveSegment);
  const [deleteSegmentMutation] = useMutation(deleteSegment);
  const [getSegmentMemberCount, { data: segmentMemberCountData, loading: loadingMemberCount }] = useLazyQuery(getSegmentMemberCountQuery);
  const customProperties = customHrisProperties?.map(({ label, ...rest }) => ({ label, value: label, ...rest }));
  const properties = [...defaultHrisProperties, ...customProperties];
  const { segmentMemberCount } = segmentMemberCountData || {};
  const canSave = getCanSave(state);

  useEffect(() => { 
    if (error) {
      Toast.error(error?.message);
    }
  }, [error]);

  useEffect(() => {
    if (segment) {
      setMemberCount(segment.memberCount);
    }
  }, [segment]);

  useEffect(() => { 
    if (!loadingMemberCount && segmentMemberCount) {
      setMemberCount(segmentMemberCount.memberCount);
    }
  }, [segmentMemberCount, segment, loadingMemberCount]);

  useEffect(() => {
    if (segment && customHrisProperties) {
      dispatch({ type: ACTIONS.RESET_INITIAL_SETTINGS, payload: { ...segment, customProperties } });
    }
  }, [segment, customHrisProperties ]);

  useEffect(() => {
    if (!savingSegment && savedSegment) {
      if (creatingNew) {
        Toast.success('Your segment has been created');
      } else {
        Toast.success('Your segment has been updated');
      }

      onClose();
    }
  }, [savingSegment, savedSegment]);

  useEffect(() => {
    if (rulesAreValid(state)) {
      getSegmentMemberCount({
        variables: {
          tenantId,
          segmentDetails: {
            name: segmentName,
            applyRules,
            rules
          }
        }
      });
    }
  }, [rules, applyRules]);

  if (!initialized) {
    return null;
  }

  return (
    <StyledModal 
      isOpen={isOpen} 
      onClose={onClose}
      size="large"
    >
      <Modal.Header
        header={creatingNew ? 'Create Segment' : 'Edit Segment'}
        right={<Modal.Buttons.CloseIcon onClick={onClose} />}
      />
      <Confirm
        header="Are you sure?"
        subHeader={`All your segment settings will be deleted. Member data will not be affected.`}
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Yes, Delete"
        confirmButtonIsDestructive
        isOpen={!!removingSegment}
        onClickCancel={() => updateRemovingSegment(null)}
        onClickConfirm={async () => {
          await deleteSegmentMutation({
            variables: {
              id: segment.id,
              tenantId
            },
            refetchQueries: [{ query: getSegments, variables: { tenantId } }]
          });

          updateRemovingSegment(null);
          onClose();

          Toast.success('Your segment has been deleted');
        }}
      />
      <StyledModalBody>
        <LineItem>
          <Typography variant='body1' sx={{ fontWeight: 'bold', mb: 1 }}>
            Segment Name
          </Typography>
          <InputWrapper>
            <Input
              type="text"
              placeholder="Enter a segment name"
              onChange={(e) => dispatch({ type: ACTIONS.UPDATE_SEGMENT_NAME, payload: e.target.value })}
              value={segmentName} 
              errorMessage={nameHasError ? 'Segment name must be less than 60 characters' : ''}
              showErrorMessageBelow
            />
          </InputWrapper>
        </LineItem>
        <LineItem>
          <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
            Rules and Properties
          </Typography>
          <RulesWrapper>
            <Advisors.RecurringModal.Dropdown
              options={RULE_OPTIONS}
              selectedOption={RULE_OPTIONS.find(({ value }) => value === applyRules)}
              handleChangeSelection={(choice) => {
                dispatch({ type: ACTIONS.UPDATE_APPLY_RULES, payload: choice.value });
              }}
              width='100%'
              position={'absolute'}
            />
            <Typography variant='body1' sx={{ ml: 1 }}>of the following</Typography>
          </RulesWrapper>
        </LineItem>
        {rules.map((rule, index) => {
          return (
            <RuleBreakdown 
              key={index} 
              rule={rule} 
              rules={rules}
              operators={operators[index]}
              showDivider={rules.length > 1 && index !== rules.length - 1} 
              index={index} 
              properties={properties}
              dispatch={dispatch}
              applyRules={applyRules}
            />
          );
        })}
        {rules.length < 5 && rules.length <= properties.length && (
          <Box sx={{ mt: 2 }}>
            <MatterButton 
              size={'S'} 
              onClick={() => dispatch({ type: ACTIONS.ADD_RULE })}
            >
              Add New Rule
            </MatterButton>
          </Box>
        )}
        {memberCount != null && (
          <Typography variant='body1' sx={{ mt: 3, color: 'blacks.50' }}>
            This segment contains {memberCount} members
          </Typography>
        )}
        {!creatingNew && (
          <>
            <Divider />
            <Typography variant='body1' sx={{ fontWeight: 'bold', mt: 3 }}>
              Delete Segment
            </Typography>
            <Typography variant='body1' sx={{ mt: 1, mb: 3 }}>
              <b>Warning:</b> All your segment settings will be deleted. Member data will not be affected.
            </Typography>
            <MatterButton 
              color='red'
              size={'S'} 
              onClick={() => {
                updateRemovingSegment(segment.id);
              }}
            >
              Delete
            </MatterButton>
          </>
        )}
      </StyledModalBody>
      <Modal.Footer.WithCancelSave
        canClickSave={canSave && !savingSegment}
        saveLabel={savingSegment ? 'Saving...' : creatingNew ? 'Create Segment' : 'Save'}
        onClickCancel={onClose}
        onClickSave={() => {
          saveSegmentMutation({
            variables: {
              tenantId,
              id: segment?.id,
              segmentDetails: {
                name: segmentName.trim(),
                applyRules,
                rules
              }
            },
            refetchQueries: [{ query: getSegments, variables: { tenantId } }]
          });
        }}
        sendIsLoading={savingSegment}
      />
    </StyledModal>
  );
}
