import React, { useEffect } from 'react';
import {
  Loader
} from '@matterapp/matter-ui';
import { 
  Box,
  Typography
} from '@mui/material';
import EngagementScore from './EngagementScore';
import EngagementCategories from './EngagementCategories';
import EnpsScore from './EnpsScore';
import ParticipationRate from './ParticipationRate';
import SurveyLauncher from './SurveyLauncher';
import DateFilterBar, { DownloadButton, downloadSpreadsheet } from 'components/DateFilterBar/DateFilterBar';
import { useLazyQuery } from '@apollo/client';
import getPulseAnalyticsSpreadsheet from 'graphql-queries/queries/pulse/getPulseAnalyticsSpreadsheet';
import {
  LoaderContainer
} from './styles';
import Toast from 'components/Toast/Toast';

function MainColumn(props) {
  const {
    selectedFilter,
    xAxisMaxLabels,
    isLoading,
    handleChangeFilter,
    analyticsData,
    currentTenant,
    workspaceId,
    isLoadingData,
    handleSegmentChange,
    selectedSegmentIds,
    handleTeamChange,
    selectedTeamIds,
    selectedWorkspaceIds,
    onChannelChange
  } = props;
  const { datePoints = [], rollingEngagementScore, rollingParticipationScore } = analyticsData || {};
  const { pulseSettings, id: tenantId } = currentTenant || {};
  const { isPulseUnlocked } = pulseSettings || {};
  const [requestPulseAnalyticsSpreadsheet, { data: spreadsheetData, loading: requestingSpreadsheet }] = useLazyQuery(getPulseAnalyticsSpreadsheet, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (spreadsheetData && !requestingSpreadsheet) {
      downloadSpreadsheet(spreadsheetData.pulseAnalyticsSpreadsheet, 'pulseSurveyReport');
      Toast.success('Check downloaded spreadsheet for instructions.');
    }
  }, [requestingSpreadsheet, spreadsheetData]);

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <Box sx={{ maxWidth: '950px' }}>
      <SurveyLauncher />
      {isPulseUnlocked ? (
        <DateFilterBar 
          startDate={selectedFilter.startDate}
          endDate={selectedFilter.endDate}
          value={selectedFilter.value}
          onChange={handleChangeFilter}
          groupedBy={selectedFilter.groupBy}
          onSegmentChange={handleSegmentChange}
          isPulse
          selectedSegmentIds={selectedSegmentIds}
          onTeamChange={handleTeamChange}
          selectedTeamIds={selectedTeamIds}
          onChannelChange={onChannelChange}
          selectedWorkspaceIds={selectedWorkspaceIds}
          disclaimer={(
            <Typography variant='body2' component='p' sx={{ color: 'blacks.50' }}>
              Scores calculated on a 30-day rolling time window (<a href='https://help.matterapp.com/en/articles/8828295-pulse-survey-guide#h_1b0915163a' target='_blank' rel='noopener noreferrer'>learn more</a>).
            </Typography>
          )}
        >
          <DownloadButton 
            disabled={requestingSpreadsheet}
            onClick={() => requestPulseAnalyticsSpreadsheet({
              variables: {
                tenantId,
                segmentIds: selectedSegmentIds,
                teamIds: selectedTeamIds,
                startDate: selectedFilter.startDate.format('YYYY-MM-DD'),
                endDate: selectedFilter.endDate.format('YYYY-MM-DD'),
                groupedBy: selectedFilter.groupBy
              }
            })}>
            Download Report (.XLSX)
          </DownloadButton>
        </DateFilterBar>
      ) : null}
      <EngagementScore
        analyticsData={analyticsData}
        rollingScore={rollingEngagementScore}
        datePoints={datePoints}
        groupedBy={selectedFilter.groupedBy}
        groupBy={selectedFilter.groupBy}
        xAxisMaxLabels={xAxisMaxLabels}
        startDate={selectedFilter.startDate.format('YYYY-MM-DD')}
        endDate={selectedFilter.endDate.format('YYYY-MM-DD')}
        isLoadingData={isLoadingData}
      />
      <EngagementCategories 
        analyticsData={analyticsData} 
        isPulseUnlocked={isPulseUnlocked}
      />
      <EnpsScore 
        analyticsData={analyticsData} 
        workspaceId={workspaceId} 
        showDetailsButton 
        groupBy={selectedFilter.groupBy}
        startDate={selectedFilter.startDate.format('YYYY-MM-DD')}
        endDate={selectedFilter.endDate.format('YYYY-MM-DD')}
      />
      <ParticipationRate 
        analyticsData={analyticsData}
        rollingScore={rollingParticipationScore}
        datePoints={datePoints}
        groupedBy={selectedFilter.groupedBy}
        groupBy={selectedFilter.groupBy}
        xAxisMaxLabels={xAxisMaxLabels}
        startDate={selectedFilter.startDate.format('YYYY-MM-DD')}
        endDate={selectedFilter.endDate.format('YYYY-MM-DD')}
        isLoadingData={isLoadingData}
      />
    </Box>
  );
}

export default MainColumn;
