import { useQuery } from '@apollo/client';
import { MINI_PROFILE_QUERY } from 'graphql-queries';
import { useState } from 'react';

/**
 * Hook state for left panel / navigation.
 */
export const useProfileModalState = (props) => {
  const { data, loading } = useQuery(MINI_PROFILE_QUERY, {
    variables: {
      tenantId: props.tenantId,
      workspaceId: props.workspaceId,
    },
    skip: !props.tenantId || !props.workspaceId
  });
  const { currentUser } = props;
  const [isOpen, setIsOpen] = useState(false);

  const onClickOpenProfileModal = () => {
    setIsOpen(true);
  };

  const onCloseProfileModal = () => {
    const { onCloseMobileProfileMenu = () => null } = props;
    onCloseMobileProfileMenu();
    setIsOpen(false);
  };
  
  if (loading || !data) { 
    return {}; 
  }
  
  const {
    feedbacksGiven,
    feedbackReceivedFeedItems,
    outboundInvitations,
  } = data || {};

  if (!currentUser) {
    return {};
  }
  
  const pendingInvitations = outboundInvitations.filter((invitation) => invitation.feedbackRequestStatus.type === 'Pending');
  const feedbackCounts = {
    given: feedbacksGiven.length,
    received: feedbackReceivedFeedItems.length,
    pending: pendingInvitations.length,
  };
  const { email, fullName, headline, photoUrl, bio } = currentUser;

  return {
    isOpen,
    onClickOpenProfileModal,
    onCloseProfileModal,
    name: fullName || email,
    bio,
    headline,
    photoUrl,
    feedbacksGiven,
    feedbackReceivedFeedItems,
    loading,
    feedbackCounts
  };
};