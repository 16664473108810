import React, { useState } from 'react';
import { Button, Confirm } from '@matterapp/matter-ui';
import {
  DeleteButtonWrapper,
  MembersList,
  SearchDropdown,
  MainWrapper,
  ManagerTag
} from './styles';
import { 
  Box,
  Typography,
  Divider,
  Table
} from '@mui/material';
import { 
  Caption, 
  NoComments
} from 'components/Table/styles';
import { debounce } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';
import searchWorkspaceMembers from 'graphql-queries/queries/workspace/searchWorkspaceMembers';
import changeMatterTeamMemberRole from 'graphql-queries/mutations/matterTeams/changeMatterTeamMemberRole';
import Toast from 'components/Toast/Toast';

const debouncedSearch = debounce((searchString, tenantId, query) => {
  query({
    variables: {
      tenantId,
      searchString,
      excludeCurrentUser: false
    },
    fetchPolicy: 'network-only'
  });
}, 300);

export default function ManagersTab({ matterTeam, tenantId, refetchTeam }) {
  const { managers = [] } = matterTeam;
  const [deletingManager, setDeletingManager] = useState(null);
  const [searchMembers, { data: searchMembersData }] = useLazyQuery(searchWorkspaceMembers);
  const [matterTeamMemberRoleMutation] = useMutation(changeMatterTeamMemberRole);
  const managersCount = managers?.length || 0;

  let memberItems = (searchMembersData?.searchMembers || [])
    .filter((member) => !managers.some((m) => m.person.id === member.person.id));
    
  memberItems = [...memberItems.map((member) => {
    return {
      label: member.person.fullName,
      value: member.person.id,
      subLabel: member.person.email
    };
  })];

  let managersCountText = `This group has ${managersCount} managers.`;

  if (managersCount === 1) {
    managersCountText = `This group has ${managersCount} manager.`;
  }

  return (
    <MainWrapper>
      <Confirm
        isOpen={!!deletingManager}
        onClose={() => setDeletingManager(null)}
        header='Are you sure?'
        subHeader='Once you remove a manager, you can always add them back. Member data will not be affected.'
        confirmButtonLabel='Yes, Delete'
        onClickConfirm={async () => {
          const personId = deletingManager;

          setDeletingManager(null);

          try {
            await matterTeamMemberRoleMutation({
              variables: {
                tenantId,
                teamId: matterTeam.id,
                personId,
                isManager: false
              }
            });

            refetchTeam();
  
            Toast.success('The manager has been removed');
          } catch (error) {
            Toast.error(error.message);
          }
        }}
        onClickCancel={() => setDeletingManager(null)}
      />
      <Typography variant="h4" sx={{ marginBottom: 2 }}>Add Group Managers</Typography>
      <Box>
        <SearchDropdown
          filterItems
          items={memberItems}
          isValidValue={() => true}
          onChangeInput={(e, { value }) => { 
            debouncedSearch(value, tenantId, searchMembers); 
          }}
          onSelect={async (_, item ) => {
            if (item.item) {
              await matterTeamMemberRoleMutation({
                variables: {
                  teamId: matterTeam.id,
                  tenantId,
                  personId: item.item.value,
                  isManager: true
                }
              });

              refetchTeam();
            }
          }}
          onChange={() => {}}
          fluid
          showValuesAsSubLabels={false}
          value={[]}
          placeholder="Enter name"
        />
      </Box>
      <Typography variant='body1' sx={{ mt: 3 }}>{managersCountText}</Typography>
      <Divider />
      <MembersList
        peers={managers?.map((member) => ({
          ...member,
          fullName: member.person?.fullName,
          photoUrl: member.person?.photoUrl
        }))}
        renderPeerActions={(member) => {
          const { role } = member;

          return (
            <DeleteButtonWrapper>
              {role === 'member' ? null : (
                <ManagerTag style={{ marginRight: '0.5rem' }}>{role.toUpperCase()}</ManagerTag>
              )}
              <Button 
                color={'red'}
                size={'S'}
                onClick={() => setDeletingManager(member.person.id)}
              >
                Remove
              </Button>
            </DeleteButtonWrapper>
          );
        }}
        showEmailAsSubHeader
      />
      {managers?.length === 0 && (
        <Table size='large'>
          <Caption>
            <NoComments variant='h6' component='p'>
              <b>No group managers yet</b>
            </NoComments>
            <NoComments variant='body1' component='p'>
              Add group managers to the group.
            </NoComments>
          </Caption>
        </Table>
      )}
    </MainWrapper>
  );
};
