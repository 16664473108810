import React from 'react';
import { Input, Tooltip } from '@matterapp/matter-ui';
import { colors } from '@matterapp/matter-theme';
import { 
  StyledIcon,
  InputWrapper,
  LineWrapper,
  StyledInput,
  ClearButton,
  StyledPaper,
  TextWithInfo,
} from './styles';
import { Typography } from '@mui/material';
import { ACTIONS } from './reducer';
import XButton from 'components/ClearButton/ClearButton';

export default function MemberBasicProperties(props) {
  const { workspace, member, state, tenant, dispatch } = props;
  const { teamsWorkspace } = workspace;
  const { rewardSettings } = tenant;
  const { isRewardsActive } = rewardSettings || {};
  const infoText = `Populated from ${teamsWorkspace ? 'Teams' : 'Slack'}. Updates appear within 24 hours.`;

  const { settings, coinsHasError } = state;
  const { 
    totalCoins, 
  } = settings;

  return (
    <StyledPaper>
      <Typography variant='h3' sx={{ mb: 3 }}>{member?.person?.fullName}</Typography>
      <LineWrapper>
        <TextWithInfo>
          <Typography variant='body1' sx={{ fontWeight: 500 }}>
            Full Name
          </Typography>
          <Tooltip
            dark
            content={infoText}
            style={{ marginLeft: '4px' }}
          >
            <StyledIcon 
              name="infoCircle" 
              fill={colors.blacks[30]}
            />
          </Tooltip>
        </TextWithInfo>
        <InputWrapper>
          <Input
            type="text"
            disabled
            value={member?.person?.fullName} 
          />
        </InputWrapper>
      </LineWrapper>
      <LineWrapper>
        <TextWithInfo>
          <Typography variant='body1' sx={{ fontWeight: 500 }}>
            Email
          </Typography>
          <Tooltip
            dark
            content={infoText}
            style={{ marginLeft: '4px' }}
          >
            <StyledIcon 
              name="infoCircle" 
              fill={colors.blacks[30]}
            />
          </Tooltip>
        </TextWithInfo>
        <InputWrapper>
          <Input
            type="text"
            disabled
            value={member?.email} 
          />
        </InputWrapper>
      </LineWrapper>
      {isRewardsActive ? (
        <LineWrapper>
          <TextWithInfo>
            <Typography variant='body1' sx={{ fontWeight: 500 }}>
              Coin Balance
            </Typography>
            <Tooltip
              dark
              style={{ marginLeft: '4px' }}
              content='How many coins a user has available to redeem.'
            >
              <StyledIcon 
                name="infoCircle" 
                fill={colors.blacks[30]}
              />
            </Tooltip>
          </TextWithInfo>
          <InputWrapper>
            <StyledInput
              type="number"
              value={totalCoins} 
              id="coin_balance"
              onChange={(e) => {
                dispatch({ type: ACTIONS.UPDATE_TOTAL_COINS, payload: e.target.value });
              }}
              showErrorMessageBelow
              errorMessage={coinsHasError ? 'Must be greater than 0' : null}
            />
            {totalCoins ? (
              <ClearButton>
                <Tooltip
                  dark
                  content='Reset field to default state'
                >
                  <XButton onClick={() => dispatch({ type: ACTIONS.UPDATE_TOTAL_COINS, payload: 0 })}/>
                </Tooltip>
              </ClearButton>
            ) : null}
          </InputWrapper>
        </LineWrapper>
      ) : null}
    </StyledPaper>
  );
}
