/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Feed } from '@matterapp/matter-ui';
import { Resources } from '@matterapp/routing';
import { FEED_ITEM_TYPE } from '@matterapp/feed';
import { mixpanel } from 'libs/tracking';
import { FeedItemTypes } from '../consts';
import { DeleteConfirmModal, RemoveConfirmModal } from '../ConfirmModals';

const handleTrackEvent = {
  viewAbilityModal: mixpanel.createEventSender(
    'actionItem:ability-modal-viewed'
  ),
  clickLearnMoreButton: mixpanel.createEventSender(
    'actionItem:learn-more-clicked'
  ),
};

const PersonalFeedItems = ({
  payload,
  currentWorkspace,
  feedbackReceivedId,
  feedItemProps,
  deleteProps,
  renderProps,
  footerProps,
  totalKudosList
}) => {
  const navigate = useNavigate();
  const { feedItemId, showMenu, workspace, index } = feedItemProps;
  const { onClickDeleteFeedItem, onClickRemoveMention } = deleteProps;
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [isRemoveConfirmModalOpen, setIsRemoveConfirmModalOpen] = useState(false);

  const onClickDeleteMenu = () => setIsDeleteConfirmModalOpen(true);
  const onClickRemoveMenu = () => setIsRemoveConfirmModalOpen(true);
  const onClickCloseDeleteConfirmModal = () => setIsDeleteConfirmModalOpen(false);
  const onClickCloseRemoveConfirmModal = () => setIsRemoveConfirmModalOpen(false);
  
  if (payload.__typename === FEED_ITEM_TYPE.checkInFeedbackReceived) {
    return null;
  }

  const onClickLearnMore = (skill) => {
    const skillName = `marketingSkill${skill.name.split(' ').join('')}`;
    handleTrackEvent.clickLearnMoreButton();
    window.open(Resources[skillName].url(), '_blank');
  };

  const onClickSkillBadge = (skill) => {
    navigate(Resources.skillDetails.path({ skillSlug: skill.slug, workspaceId: workspace.id }));
  };

  const feedbackDetailsPath = Resources.feedbackReceivedViewFeedback.path({
    feedbackReceivedId,
  });

  const onClickDetails = (e, hash) => {
    if (hash) {
      navigate(feedbackDetailsPath + hash);
    } else {
      navigate(feedbackDetailsPath);
    }
  };

  let feedItem;

  switch (payload.__typename) {
  case FeedItemTypes.LONG_FORM: {
    const { feedback, giver } = payload; 

    const workspacePath = workspace ? Resources.workspacesActivityFeed.path({ workspaceId: workspace.id }) : null;
    feedItem = (
      <Feed.Personal.LongFormFeedback 
        key={`feeditem-long-form-${feedItemId}`}
        detailed
        showMenu={showMenu}
        feedback={feedback}
        giver={giver}
        workspace={workspace}
        workspacePath={workspacePath}
        feedItemProps={feedItemProps}
        renderProps={renderProps}
        deleteProps={deleteProps}
        footerProps={footerProps}
        onClickLearnMore={onClickLearnMore}
        onClickDetails={onClickDetails}
        onClickSkillBadge={onClickSkillBadge}
        onClickDelete={onClickDeleteMenu}
        trackViewAbilityModal={handleTrackEvent.viewAbilityModal}
      />
    );
    break;
  }
  case FeedItemTypes.QUICK_NOTE: {
    const { quickNote, giver } = payload;
    const workspacePath = currentWorkspace ? Resources.workspacesActivityFeed.path({ workspaceId: workspace && workspace.id }) : null;
    feedItem = (
      <Feed.Personal.QuickNoteFeedback
        key={`feeditem-quick-note-${feedItemId}`}
        showMenu={showMenu}
        quickNote={quickNote}
        giver={giver}
        workspace={workspace}
        workspacePath={workspacePath}
        deleteProps={deleteProps}
        feedItemProps={feedItemProps}
        footerProps={footerProps}
        onClickDetails={onClickDetails}
        onClickSkillBadge={onClickSkillBadge}
        onClickDelete={onClickDeleteMenu}
      />
    );
    break;
  }
  case FeedItemTypes.KUDOS: {
    const { kudos, receivers, giver } = payload;
    const workspacePath = currentWorkspace ? Resources.workspacesActivityFeed.path({ workspaceId: workspace && workspace.id }) : null;

    if (workspace) {
      feedItem = (
        <Feed.Workspaces.UserPosts.Kudos
          key={`feeditem-kudos-${feedItemId}`}
          showMenu={showMenu}
          giver={giver}
          receivers={receivers}
          kudos={kudos}
          index={index}
          workspace={workspace}
          workspacePath={workspacePath}
          feedItemProps={feedItemProps}
          deleteProps={deleteProps}
          footerProps={footerProps}
          onClickDelete={onClickDeleteMenu}
          onClickRemove={onClickRemoveMenu}
          totalKudosList={totalKudosList}
        />
      );
    }
    else {
      feedItem = (
        <Feed.Personal.Kudos
          key={`feeditem-kudos-${feedItemId}`}
          showMenu={showMenu}
          kudos={kudos}
          giver={giver}
          feedItemProps={feedItemProps}
          isWholeChannel={feedItemProps?.isWholeChannel}
          deleteProps={deleteProps}
          renderProps={renderProps}
          footerProps={footerProps}
          onClickDelete={onClickDeleteMenu}
          totalKudosList={totalKudosList}
        />
      );
    }
    break;
  }
  default:
    console.error('Unrecognized feed item type: ', payload.__typename);
    feedItem = null;
  }

  return (
    <>
      {feedItem}
      <DeleteConfirmModal 
        isOpen={isDeleteConfirmModalOpen}
        deleteProps={deleteProps}
        isWorkspace={false}
        onClose={onClickCloseDeleteConfirmModal}
        onClickDelete={() => {
          onClickDeleteFeedItem();
          onClickCloseDeleteConfirmModal();
        }}
      />
      <RemoveConfirmModal 
        isOpen={isRemoveConfirmModalOpen}
        onClose={onClickCloseRemoveConfirmModal}
        onClickRemove={() => {
          onClickRemoveMention();
          onClickCloseRemoveConfirmModal();
        }}
      />
    </>
  );
};

export default PersonalFeedItems;
