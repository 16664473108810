import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@matterapp/matter-ui';
import {
  MainModal,
  ModalBody,
  TitleInput,
  TitleDetails,
  FormGroup,
  Divider,
  ColorPanelContainer,
  ColorGrid,
  Mini,
  CustomMini,
  CheckMark,
  CheckMarkContainer,
  DetailsContainer,
  PreviewContainer,
  CustomColorsBlock,
  CustomColorsSubBlock,
  StyledInput,
  ColorPickerWrapper
} from './styles';
import { workspaceDefaultProp } from 'libs/prop-types/workspaces';
import { colorsToShow } from './consts';
import { IMAGE_FILE_UPLOAD_ERROR_DETAILS } from './consts';
import ImageUpload from 'modules/workspaces/shared/ImageUpload/ImageUpload';
import getS3PresignedUrl from 'graphql-queries/mutations/getS3PresignedUrl';
import { useMutation, useQuery } from '@apollo/client';
import { formatTitleToType } from './helpers';
import PreviewCustomKudos from './PreviewCustomKudos';
import {
  IS_EXISTING_CUSTOM_KUDOS,
  GET_CUSTOM_KUDOS_BY_ID,
  CREATE_CUSTOM_KUDOS,
  UPDATE_CUSTOM_KUDOS,
  GET_ALL_CUSTOM_KUDOS_FOR_WORKSPACE
} from 'graphql-queries';
import { debounce } from 'lodash';
import Toast from 'components/Toast';
import CustomColorPicker from './CustomColorPicker';

const TITLE_LIMIT = 50;
const TITLE_TOO_LONG = `Your title is too long. The limit is ${TITLE_LIMIT} characters.`;
const TITLE_EXISTS = `This title already exists. Try another.`;

const useOutsideClick = (refs, callback) => {
  const handleClick = e => {
    let isOutside = true;

    refs.forEach(ref => {
      if (!isOutside) {
        return;
      }

      if (ref.current && !ref.current.contains(e.target)) {
        isOutside = true;
      } else {
        isOutside = false;
      }
    });

    if (isOutside) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

const checkIfNameExists = debounce(async (name, workspaceId, mutation, setErrorMessage, errorMessage) => {
  if (!name || !name.trim()) {
    return;
  }

  const isExistingTitle = await mutation({ 
    workspaceId, 
    value: formatTitleToType(name)
  });

  if (isExistingTitle) {
    setErrorMessage(TITLE_EXISTS);
  }
}, 1000);

const AddKudosModal = (props) => {
  const {
    isOpen,
    onClose,
    workspaceId,
    editCustomKudosId
  } = props;
  const [s3PresignedUrlMutation] = useMutation(getS3PresignedUrl);
  const [customColor, setCustomColor] = useState('#7E81C8');
  const [customTextColor, setCustomTextColor] = useState('#FFFFFF');
  const [awsFilePath, setAwsFilePath] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [titleValue, setTitleValue] = useState(null);
  const [isCustomColor, setIsCustomColor] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [backgroundInputError, setBackgroundInputError] = useState(null);
  const [textColorInputError, setTextColorInputError] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [backgroundSettings, setBackgroundSettings] = useState(colorsToShow[0]);
  const [previewTextValue, setPreviewTextValue] = useState(null);
  const [url, setUrl] = useState(null);
  const [createCustomKudos, { loading: savingCustomCudos }] = useMutation(CREATE_CUSTOM_KUDOS);
  const [updateCustomKudos, { loading: updatingCustomCudos }] = useMutation(UPDATE_CUSTOM_KUDOS);
  const colorPickerRef = useRef(null);
  const colorEntryRef = useRef(null);
  const textColorEntryRef = useRef(null);
  const textColorPickerRef = useRef(null);

  const hexRegEx = /^#([0-9a-f]{3}){1,2}$/i;

  const { data } = useQuery(GET_CUSTOM_KUDOS_BY_ID, {
    variables: {
      id: editCustomKudosId,
    },
    skip: !editCustomKudosId,
  });

  useEffect(() => {
    if (data?.getCustomKudosById) {
      const { backgroundSettings, iconFileName, label } = data.getCustomKudosById;
      setTitleValue(label);
      setUrl(iconFileName);
      const { isLight, backgroundColor, backgroundImage, backgroundName, textColor } = backgroundSettings;

      if (textColor) {
        setCustomTextColor(textColor);
        setIsCustomColor(true);
        setCustomColor(backgroundColor);
        setSelectedIndex(100);
      } else {
        setBackgroundSettings({ isLight, backgroundColor, backgroundImage });
        let index = 0;
        colorsToShow.map((color, ind) => {
          if (backgroundName === color.backgroundName) {
            index = ind;
          }
          return color;
        });
        setSelectedIndex(index);
      }
    }
  }, [data]);

  useOutsideClick([colorEntryRef, colorPickerRef], () => {
    setShowColorPicker(false);
  });

  useOutsideClick([textColorEntryRef, textColorPickerRef], () => {
    setShowTextColorPicker(false);
  });

  const [isExistingCustomKudos] = useMutation(IS_EXISTING_CUSTOM_KUDOS);
  const isExistingCustomKudosForWorkspace = async ({ value, workspaceId }) => {
    const { data } = await isExistingCustomKudos({
      variables: {
        type: value,
        workspaceId,
      },
      skip: !value
    });
    return data?.isExistingCustomKudosForWorkspace;
  };

  const onClickColor = ({ option, index }) => {
    setSelectedIndex(index);
    setBackgroundSettings(option);
    setIsCustomColor(false);
  };

  const onTitleValueChange = async (e, { value }) => {
    setTitleValue(value);

    if (!value) {
      setErrorMessage(null);

      return;
    }

    if (value.length > TITLE_LIMIT) {
      setErrorMessage(TITLE_TOO_LONG);
    } else {
      setErrorMessage(null);
      
      if (!editCustomKudosId) {
        checkIfNameExists(value, workspaceId, isExistingCustomKudosForWorkspace, setErrorMessage, errorMessage);
      }
    }
  };

  const onPreviewTextChange = (e, { value }) => {
    const trimmedValue = value.substring(0, 300);
    setPreviewTextValue(trimmedValue);
  };

  const onRemoveIcon = () => {
    setUrl(null);
    return null;
  };

  let canClickSend = !errorMessage && !!url && !!titleValue?.trim().length && !savingCustomCudos && !updatingCustomCudos;

  if (isCustomColor) {
    canClickSend = canClickSend && !!customColor && !!customTextColor && !backgroundInputError && !textColorInputError;
  }

  const resetState = () => {
    setUrl(null);
    setTitleValue(null);
    setBackgroundSettings(colorsToShow[0]);
    setSelectedIndex(null);
    setPreviewTextValue(null);
    setIsCustomColor(false);
    setCustomColor('#7E81C8');
    setCustomTextColor('#FFFFFF');
    setBackgroundInputError(null);
    setTextColorInputError(null);
    setAwsFilePath(null);
  };


  const onClickClose = () => {
    resetState();
    onClose();
  };

  const colors = colorsToShow.map((option, index) => {
    return (
      <Mini key={option} onClick={() => onClickColor({ option, index })} {...option}>
        {
          index == selectedIndex &&
          <CheckMarkContainer>
            <CheckMark />
          </CheckMarkContainer>
        }
      </Mini>
    );
  });

  colors.push((
    <CustomMini 
      isCustomColor={isCustomColor}
      onClick={() => {
        setIsCustomColor(true);
        setSelectedIndex(100);
        setBackgroundSettings({
          backgroundColor: '#7E81C8'
        });
      }}
    >
      {
        isCustomColor &&
          <CheckMarkContainer>
            <CheckMark />
          </CheckMarkContainer>
      }
      <p style={{ marginLeft: isCustomColor ? '10px' : 0 }}>CUSTOM</p>
    </CustomMini>
  ));

  async function submitKudos() {
    const type = formatTitleToType(titleValue.trim());
    const label = titleValue.trim();
    
    if (!editCustomKudosId) {
      await createCustomKudos({
        variables: {
          type, 
          label, 
          workspaceId, 
          iconFileName: url, 
          awsFilePath, 
          backgroundSettings: isCustomColor ? {
            textColor: customTextColor.toUpperCase(),
            backgroundColor: customColor.toUpperCase(),
            backgroundImage: backgroundSettings.backgroundImage
          } : backgroundSettings
        },
        refetchQueries: [{ 
          query: GET_ALL_CUSTOM_KUDOS_FOR_WORKSPACE,
          variables: {
            workspaceId
          }
        }]
      });
    } else {
      await updateCustomKudos({
        variables: { 
          id: editCustomKudosId, 
          workspaceId, 
          label, 
          iconFileName: url, 
          awsFilePath, 
          backgroundSettings: isCustomColor ? {
            textColor: customTextColor.toUpperCase(),
            backgroundColor: customColor.toUpperCase(),
            backgroundImage: backgroundSettings.backgroundImage
          } : backgroundSettings 
        },
        refetchQueries: [{ 
          query: GET_ALL_CUSTOM_KUDOS_FOR_WORKSPACE,
          variables: {
            workspaceId
          }
        }]
      });
    }

    onClickClose();

    Toast.success('Success! You added a new Kudos');
  }

  return (
    <MainModal isOpen={isOpen} onClose={onClickClose}>
      <Modal.Header
        header={!editCustomKudosId ? 'Add Kudos' : 'Edit Kudos'}
        right={<Modal.Buttons.CloseIcon onClick={onClickClose} />}
      />
      <ModalBody>
        <DetailsContainer>
          <FormGroup label="Kudos Title">
            <TitleInput
              showErrorMessageBelow
              placeholder="Enter a title"
              errorMessage={errorMessage}
              onChange={onTitleValueChange}
              value={titleValue}
            />
            {!errorMessage &&
            <TitleDetails>The name your team members will see when they select the kudos.</TitleDetails>
            }
          </FormGroup>
          <FormGroup label="Background Color">
            <ColorPanelContainer> 
              <ColorGrid>{colors}</ColorGrid>
            </ColorPanelContainer>
          </FormGroup>

          {isCustomColor ? (
            <FormGroup label="Custom Colors">
              <div style={{ position: 'relative' }}>
                {showColorPicker ? (
                  <ColorPickerWrapper ref={colorPickerRef} >
                    <CustomColorPicker
                      color={{ hex: customColor }}
                      onChange={color => {
                        setCustomColor(color.hex);
                        setBackgroundInputError(null);
                      }}
                    />
                  </ColorPickerWrapper>
                ) : null}
              </div>
              <CustomColorsBlock>
                <CustomColorsSubBlock>
                  <p>Background Color</p>
                  <div ref={colorEntryRef} style={{ marginLeft: '10px' }}>
                    <StyledInput
                      className="custom-color-input"
                      showErrorMessageBelow
                      placeholder="#000000"
                      errorMessage={backgroundInputError}
                      onChange={(e, { value }) => {
                        if (value.length > 7) {
                          return;
                        }

                        let withHash = value;

                        if (value.length > 0 && value[0] !== '#') {
                          withHash = `#${value}`;
                        }

                        setCustomColor(withHash);

                        if (!hexRegEx.test(withHash)) {
                          setBackgroundInputError('Invalid hex code');
                        } else {
                          setBackgroundInputError(null);
                        }
                      }}
                      onFocus={() => {
                        setShowColorPicker(true);
                      }}
                      value={customColor}
                    />
                  </div>
                </CustomColorsSubBlock>
                <div style={{ position: 'relative' }}>
                  {showTextColorPicker ? (
                    <ColorPickerWrapper ref={textColorPickerRef} >
                      <CustomColorPicker
                        color={{ hex: customTextColor }}
                        onChange={color => {
                          setCustomTextColor(color.hex);
                          setTextColorInputError(null);
                        }}
                      />
                    </ColorPickerWrapper>
                  ) : null}
                </div>
                <CustomColorsSubBlock>
                  <p>Text Color</p>
                  <div ref={textColorEntryRef} style={{ marginLeft: '10px' }}>
                    <StyledInput
                      showErrorMessageBelow
                      placeholder="#000000"
                      errorMessage={textColorInputError}
                      onChange={(e, { value }) => {
                        if (value.length > 7) {
                          return;
                        }

                        let withHash = value;

                        if (value.length > 0 && value[0] !== '#') {
                          withHash = `#${value}`;
                        }

                        setCustomTextColor(withHash);

                        if (!hexRegEx.test(withHash)) {
                          setTextColorInputError('Invalid hex code');
                        } else {
                          setTextColorInputError(null);
                        }
                      }}
                      value={customTextColor}
                      onFocus={() => {
                        setShowTextColorPicker(true);
                      }}
                    />
                  </div>
                </CustomColorsSubBlock>
              </CustomColorsBlock>
            </FormGroup>
          ) : null}

          <FormGroup label="Upload Image">
            <ImageUpload
              url={url}
              onSaveIcon={async (props, file) => {
                const presignedUrlData = await s3PresignedUrlMutation({
                  variables: {
                    prefix: 'kudos/custom'
                  },
                });
                  
                const { presignedUrl, imgixPath, awsFilePath } = presignedUrlData.data.getS3PresignedUrl;
     
                await fetch(presignedUrl, {
                  method: 'PUT',
                  headers: { 
                    'Content-Type': 'image/png'
                  },
                  body: file
                });

                setUrl(imgixPath);
                setAwsFilePath(awsFilePath);

                return imgixPath;
              }}
              onRemoveIcon={onRemoveIcon}
              changePhotoText="EDIT IMAGE"
              changePhotoHeader="Edit Image"
              {...IMAGE_FILE_UPLOAD_ERROR_DETAILS}
            />
          </FormGroup>
        </DetailsContainer>
        <Divider />
        <PreviewContainer>
          <FormGroup label="Kudos Preview">
            <PreviewCustomKudos 
              onPreviewTextChange={onPreviewTextChange}
              backgroundSettings={isCustomColor ? { backgroundColor: customColor, textColor: customTextColor } : backgroundSettings}
              previewTextValue={previewTextValue}
              titleValue={titleValue}
              url={url}
            />
          </FormGroup>
        </PreviewContainer>
      </ModalBody>
      <Modal.Footer.WithCancelSend
        canClickSend={canClickSend}
        cancelLabel='Cancel'
        onClickCancel={onClickClose}
        onClickSend={submitKudos}
        sendLabel={savingCustomCudos || updatingCustomCudos ? 'Saving...' : 'Save'}
      />
    </MainModal>
  );
};

AddKudosModal.defaultProps = {
  showCustomMessage: false,
  hasContacts: true,
  onClose: () => null,
  isOpen: false,
  workspace: workspaceDefaultProp,
};

AddKudosModal.propTypes = {
  canClickSend: PropTypes.bool,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  workspaceId: PropTypes.string,
  editCustomKudosId: PropTypes.string
};

export default AddKudosModal;
