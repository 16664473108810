import { RULE_OPTIONS, defaultHrisProperties, getOperators } from './helpers';

export const ACTIONS = {
  RESET_INITIAL_SETTINGS: 'RESET_INITIAL_SETTINGS',
  UPDATE_SEGMENT_NAME: 'UPDATE_SEGMENT_NAME',
  UPDATE_APPLY_RULES: 'UPDATE_APPLY_RULES',
  UPDATE_PROPERTY_NAME: 'UPDATE_PROPERTY_NAME',
  UPDATE_OPERATOR: 'UPDATE_OPERATOR',
  ADD_RULE: 'ADD_RULE',
  UPDATE_SELECTED_VALUES: 'UPDATE_SELECTED_VALUES',
  UPDATE_CAN_SAVE: 'UPDATE_CAN_SAVE',
  DELETE_RULE: 'DELETE_RULE'
};

export function getInitialState({ 
  id,
  name,
  memberCount,
  applyRules = RULE_OPTIONS[0].value,
  rules,
  customProperties = []
}, initialized = false) {
  const initialSettings = {
    id,
    name,
    memberCount,
    applyRules,
    rules: (rules || [{}]).map(({ __typename, ...props }) => props)
  };

  let operators = [[]];
  
  if (rules && customProperties?.length) {
    operators = rules.map(({ propertyName }) => {
      const property = [...defaultHrisProperties, ...customProperties].find(({ value }) => value === propertyName);

      return getOperators(property);
    });
  }

  return {
    defaultSettingsHasError: false,
    settings: { ...initialSettings },
    customProperties,
    creatingNew: !id,
    initialSettings,
    operators,
    initialized
  };
}

export function reducer(state, action) {
  switch (action.type) {
  case ACTIONS.RESET_INITIAL_SETTINGS: {
    return getInitialState(action.payload, true);
  }
  case ACTIONS.UPDATE_SEGMENT_NAME: {
    return {
      ...state,
      nameHasError: action.payload?.trim().length > 60,
      settings: {
        ...state.settings,
        name: action.payload
      }
    };
  }
  case ACTIONS.UPDATE_APPLY_RULES: {
    return {
      ...state,
      settings: {
        ...state.settings,
        applyRules: action.payload
      }
    };
  }
  case ACTIONS.UPDATE_PROPERTY_NAME: {
    const { propertyName, index } = action.payload;
    
    const property = [...defaultHrisProperties, ...state.customProperties].find(({ value }) => value === propertyName);

    return {
      ...state,
      operators: state.operators.map((existing, idx) => {
        if (idx === index) {
          return getOperators(property);
        }

        return existing;
      }),
      settings: {
        ...state.settings,
        rules: state.settings.rules.map((rule, idx) => {
          if (idx === index) {
            return {
              ...rule,
              propertyName,
              customPropertyId: property?.id,
              operator: undefined,
              type: property?.type,
              values: []
            };
          }

          return rule;
        })
      }
    };
  }
  case ACTIONS.UPDATE_OPERATOR: {
    const { operator, index } = action.payload;

    return {
      ...state,
      settings: {
        ...state.settings,
        rules: state.settings.rules.map((rule, idx) => {
          if (idx === index) {
            return {
              ...rule,
              operator
            };
          }

          return rule;
        })
      }
    };
  }
  case ACTIONS.ADD_RULE: {
    return {
      ...state,
      settings: {
        ...state.settings,
        rules: [...state.settings.rules, {}]
      },
      operators: [...state.operators, []]
    };
  } 
  case ACTIONS.UPDATE_SELECTED_VALUES: {
    const { values, index } = action.payload;

    return {
      ...state,
      settings: {
        ...state.settings,
        rules: state.settings.rules.map((rule, idx) => {
          if (idx === index) {
            return {
              ...rule,
              values
            };
          }

          return rule;
        })
      }
    };
  }
  case ACTIONS.DELETE_RULE: {
    const { index } = action.payload;

    return {
      ...state,
      settings: {
        ...state.settings,
        rules: state.settings.rules.filter((_, idx) => idx !== index)
      },
      operators: state.operators.filter((_, idx) => idx !== index)
    };
  }
  default: {
    return {};
  }
  }
}